import { Component, OnInit } from '@angular/core';
import { LaunchPadEntityType, LaunchPadMenuItemGroup } from '@eva-model/menu/menu';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { LaunchPadEditComponent } from '../launch-pad-edit/launch-pad-edit.component';
import { Observable } from 'rxjs';
import { LaunchPadService } from '@eva-services/nav/launch-pad.service';

@Component({
  selector: 'app-launch-pad',
  templateUrl: './launch-pad.component.html',
  styleUrls: ['./launch-pad.component.scss']
})
export class LaunchPadComponent implements OnInit {

  menu$: Observable<LaunchPadMenuItemGroup[]>;
  entityTypes = LaunchPadEntityType;

  public entityTypeKnowledge = LaunchPadEntityType.Knowledge;
  public entityTypeWorkflow = LaunchPadEntityType.Workflow;
  public entityTypeInteraction = LaunchPadEntityType.Interaction;

  constructor(private dialog: MatDialog,
              public launchPadService: LaunchPadService) { }

  async ngOnInit(): Promise<void> {
    this.menu$ = this.launchPadService.menu$;
    await this.launchPadService.generateMenu();
  }

  dropMenuItem(event: CdkDragDrop<string[]>, list: any[]) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  editMenu() {
    this.dialog.open(LaunchPadEditComponent, {
      height: '85vh',
      width: '600px'
    });
  }

}
