<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start"
  [(selectedIndex)]="tabsSelectedIndex"
  (selectedIndexChange)="selectedIndexChanged($event)"
  (selectedTabChange)="selectedTabChanged($event)"
  class="knowledge-multi-view-tabs"
  #knowledgeTabs>
  <mat-tab *ngFor="let tab of tabs; let i = index">
    <ng-template mat-tab-label>
      {{tab.docName}}&nbsp;
      <button class="button-close-tab" mat-icon-button *ngIf="tabsSelectedIndex === i" (click)="removeTab(i)" matTooltip="Close Tab">
        <mat-icon aria-label="Close Tab">close</mat-icon>
      </button>
    </ng-template>
    <app-knowledge [data]="tab" (closeClicked)="removeTab(i)"></app-knowledge>
  </mat-tab>
</mat-tab-group>