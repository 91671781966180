
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  Action,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
  QueryDocumentSnapshot,
  DocumentReference} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { Observable , from as fromPromise, Observer, from } from 'rxjs';
import { expand, takeWhile, mergeMap, concatMap, bufferCount } from 'rxjs/operators';
import { take, map, tap } from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable()
export class FirestoreService {
  constructor(private afs: AngularFirestore) { }

  /**
   * This function returns Firestore Collection from the reference provided
   *
   * @param ref Reference of the Collection
   * @param queryFn Query Function to operate on returned collection
   */
  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  /**
   * This function returns Firestore Document from the reference provided
   *
   * @param ref Reference of the Document
   */
  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /**
   * This function returns Firestore Document's data as Observable
   *
   * @param ref Reference of the Document
   */
  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges()
      .pipe(
        map(doc => {
          return doc.payload.data() as T;
        })
      );
  }

  /**
   * This function returns Firestore Collection's data as Observable of type Array
   *
   * @param ref Reference of the Collection
   * @param queryFn Query Function to operate on returned collection
   */
  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges()
      .pipe(
        map(docs => {
          return docs.map(a => a.payload.doc.data()) as T[];
        })
      );
  }

  /**
   * This function returns Firestore Collection's data with Document IDs as Observable of type Array
   *
   * @param ref Reference of the Collection
   * @param queryFn Query Function to operate on returned collection
   */
  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn).snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            // ||| ignored the type script lint error
            // @ts-ignore
            return { id, ...data };
          });
        })
      );
  }

  /**
   * This function sets data of the document
   *
   * @param ref Reference of the document
   * @param data Data to be stored
   */
  set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const timestamp = new Date();
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  /**
   * This function updates data of the document
   *
   * @param ref Reference of the document
   * @param data Data to be stored
   */
  update<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).update({
      ...data,
      updatedAt: new Date()
    });
  }

  /**
   * This function deletes a document
   *
   * @param ref Reference of the document
   */
  delete<T>(ref: DocPredicate<T>): Promise<void> {
    return this.doc(ref).delete();
  }

  /**
   * This function adds a new document
   *
   * @param ref Reference of the document
   * @param data Data to be stored
   */
  add<T>(ref: CollectionPredicate<T>, data): Promise<DocumentReference> {
    const timestamp = new Date();
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  /**
   * This function determines if the document exists and update, otherwise set.
   *
   * @param ref Reference of the document
   * @param data Data to be inserted or updated
   */
  upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = this.doc(ref).snapshotChanges().pipe(take(1)).toPromise();

    return doc.then((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => {
      return snap.payload.exists
        ? this.update(ref, data)
        : this.set(ref, data);
    });
  }

  /**
   * This function determines if the doc exists and update, otherwise insert.
   *
   * @param ref Reference of the document
   * @param data Data to be inserted or updated
   */
  updateInsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = this.doc(ref).snapshotChanges().pipe(take(1)).toPromise();

    return doc.then((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => {
      return snap.payload.exists
        ? this.doc(ref).update(data)
        : this.doc(ref).set(data);
    });
  }

  /**
   * This function logs the load time of the document
   *
   * @param ref Reference of the document
   */
  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref).snapshotChanges()
      .pipe(
        take(1),
        tap(d => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Document in ${tock}ms`, d);
        })
      )
      .subscribe();
  }

  /**
   * This function logs the load time of the collection
   *
   * @param ref Reference of the collection
   */
  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref).snapshotChanges()
      .pipe(
        take(1),
        tap(c => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Collection in ${tock}ms`, c);
        })
      )
      .subscribe();
  }

  /**
   * Create and read document references, create a reference between two documents.
   *
   * @param host Reference of the document
   * @param key property of the document being updated
   * @param doc Reference of the document
   */
  connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>): Promise<void> {
    return this.doc(host).update({ [key]: this.doc(doc).ref });
  }

  /**
   * This function returns document reference mapped to AngularFirestoreDocument
   *
   * @param ref Reference of the document
   */
  docWithRefs$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc$(ref)
      .pipe(
        map(doc => {
          for (const k of Object.keys(doc)) {
            if (doc[k] instanceof firebase.firestore.DocumentReference) {
              doc[k] = this.doc(doc[k].path);
            }
          }
          return doc;
        })
      );
  }

  /**
   * Atomic batch example
   * This method will need to be customized in this method...
   *  this.firestoreService.atomic(objectToUpdate, 'pathToCollection', true, 'groupId');
   */
  atomic(objectsToUpdate: any[], baseCollectionPath, addDocument, objectNodeWithId): void {
    const batch = this.afs.firestore.batch();

    if (addDocument) {
      for (let i = 0; i < objectsToUpdate.length; i++) {
        const itemRef = this.afs.firestore.doc(baseCollectionPath + objectsToUpdate[i][objectNodeWithId]);
        batch.set(itemRef, objectsToUpdate[i]);
      }
    } else {
      for (let i = 0; i < objectsToUpdate.length; i++) {
        const itemRef = this.afs.firestore.doc(baseCollectionPath + objectsToUpdate[i][objectNodeWithId]);
        batch.update(itemRef, objectsToUpdate[i]);
      }
    }
  }

  /**
   * Use a path as a string to allow the deletes to a collection to occur in batches
   * @param path
   * @param batchSize
   */
  deleteCollection(path: string, batchSize: number): Observable<any> {

    const source = this.deleteBatch(path, batchSize);
    // expand will call deleteBatch recursively until the collection is deleted
    return source.pipe(
      expand(val => this.deleteBatch(path, batchSize)),
      takeWhile(val => val > 0)
    );
  }

  // Deletes documents as batched transaction
  private deleteBatch(path: string, batchSize: number): Observable<any> {
    const colRef = this.afs.collection(path, ref => ref.orderBy('__name__').limit(batchSize));

    return colRef.snapshotChanges().pipe(
      take(1),
      mergeMap(snapshot => {
        // Delete documents in a batch
        const batch = this.afs.firestore.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.payload.doc.ref);
        });
        return fromPromise(batch.commit()).pipe(map(() => snapshot.length));
      })
    );
  }

  /*
   * https://github.com/angular/@angular/fire/issues/1400
   *
   * Delete all documents in specified collections.
   *
   * @param {string} collections Collection names
   * @return {Promise<number>} Total number of documents deleted (from all collections)
   *
  */
  async deleteCollections (...collections: string[]): Promise<number> {
    let totalDeleteCount = 0;
    const batchSize = 500;
    return new Promise<number>((resolve, reject) =>
      from(collections)
      .pipe(
        concatMap(collection => fromPromise(this.afs.collection(collection).ref.get())),
        concatMap(q => from(q.docs)),
        bufferCount(batchSize),
        concatMap(
          (docs: QueryDocumentSnapshot<any>[]) =>
            new Observable(
              (o: Observer<number>) => {
                const batch = this.afs.firestore.batch();
                docs.forEach(doc => batch.delete(doc.ref));
                batch.commit()
                .then(() => {
                  o.next(docs.length);
                  o.complete();
                })
                .catch(e => o.error(e));
              }
            )
        )
      )
      .subscribe(
        (batchDeleteCount: number) => totalDeleteCount += batchDeleteCount,
        e => reject(e),
        () => resolve(totalDeleteCount)
      )
    );
  }

}
