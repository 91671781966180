export const environment = {
  production: false,
  env: 'Dev',
  appName: 'ATB EVA SPARK TEST',
  authApp: 'eva-spark-test',
  fullPath: 'test.atbeva.com',
  // eslint-disable-next-line max-len
  evaGlobalGroup: '048c5939ef6d86e860af40eee302b89da58393118d84776ac6f8bf84362729f2eb58926f22cf1934b41de6420fa5b9c034cf0f6898a8c482882f7440eafcedda93',
  // eslint-disable-next-line max-len
  atbGlobalGroup: '043a14d683b1e8fc071ac2ed8ca92d603d9d212f69e489971e66c489204e396a50de3d2c89f67a3c3d5dd3202870a12902b9b889b2c18bf7e2d2c7c59de8d6c12d',
  atbGlobalSubGroups: [
    '049c6177f3d550130d2abe9a8dfe9ea53738a63ada34c4a0203cb0309f04ae3c1250bc249520b6e05fd367ea924e4e27130a6e2929aeac85ad47dbdff185c343f5',
    '04a0b102b18399b04d7e9c926ec58a4b309d8db68f89ee5021d7d3f207b4e415c4d1a2de4c3788d8e92583284f8a2026e7ccb6d31e54456c7c0c8bd00d938c8fb0',
    '0433892a1fd1ef212c38cf58a593d29f30d921268ed24be318af79e9acfe966aeef48cddbaddded6614abb7e1323099e9a31bed2fab8f244eec794d6bece069cde'
  ],
  // eslint-disable-next-line max-len
  rcmGroup: '0427c8389c3890eb9365281c0e94f09bc1e25c669d6b0faea2ad09f7d87f38290b69bd3923b8513ce1499ada04fd9d247a592b18ca89c0e7dc7c126da3455342f1',
  notificationResultsPerGroupLimit: 10,   // Limited number of process notifications ready for "Action" per group.
  workingOnProcessesLimit: 10,   // Limited number of process notifications ready for "Accept"
  firebaseFunction: {
    endpoint: 'https://us-central1-eva-spark-test.cloudfunctions.net',
    createNewScheduledTask: '/createNewScheduledTask',
    sendCommunicationLeadEmail: '/sendCommunicationLeadEmail'
  },
  firebaseConfig: {
    apiKey: ">>>FIREBASE_TEST_API_KEY<<<",
    authDomain: "eva-spark-test.firebaseapp.com",
    databaseURL: "https://eva-spark-test.firebaseio.com",
    projectId: "eva-spark-test",
    storageBucket: "eva-spark-test.appspot.com",
    messagingSenderId: "587038537353",
    appId: "1:587038537353:web:2917c7c6f2e0266e7b7858"
  },
  blockConfig: {
    SIGNING_CURVE: 'p256',
    ENCRYPTION_CURVE: 'p256',
    pbkdf2: 'sha256',
    pbkdf2ByteSize: 128,
    pbkdf2IVByteSize: 32,
    globalDatabase: {
      publicKeyPath: 'PublicKeys'
    },
    types: {
      dynamicInteractions: {
        typeId: 'RmFyaGFkJ3MgYW1hemluZyBkeW5hbWljIGludGVyYWN0aW9ucw==',
        knownKeyInteractionPath: 'ATBDynamicInteractions',
        publicKeyInteractionPath: 'PublicDynamicInteractions'
      },
      groups: {
        typeId: 'SSBmZWxsIGRvd24uIE15IGdyb3VwIG9mIGZyaWVuZHMgcGlja2VkIG1lIHVwLg==',
        groupSigningPath: 'GroupSigningKeys',
        knownGroupEncryptionPath: 'ATBGroupEncryptionKeys',
        types: {
          dynamicData: 'RG9uJ3QgbGV0IHlvdXIgZGF0YSBnbyBzdGF0aWMsIGJlIGR5bmFtaWM=',
          flowThrough: 'QmVuIHNheXMgImdvIHdpdGggdGhlIGZsb3ci',
          invitation: 'SWxpa2VEaW5uZXJQYXJ0aWVzIQ==',
          knowledge: 'VGhlIG9ubHkgdHJ1ZSB3aXNkb20gaXMgaW4ga25vd2luZyB5b3Uga25vdyBub3RoaW5n',
          normal: 'Tm9ybWFsR3JvdXBSdWxlcw=='
        }
      },
      workflows: {
        typeId: 'VG8gd2FsayB1cHN0cmVhbSBpbiBhIHJpdmVyIGFuZCB0aHJvdWdoIGEgd2F0ZXJmYWxsLCBpcyBoYXJkIQ==',
        knownKeyWorkflowPath: 'ATBWorkflows',
        publicKeyWorkflowPath: 'PublicWorkflows'
      }
    }
  },
  searchConfig: {
    REQUESTER_EMAIL_KEY: 'b870cfb6-bfa3-45e2-b171-ac10af80de49',
    REQUIREMENT_EMAIL_KEYS: [
      '5ad66abb-5562-492f-ab08-b4d3ee3ff9a4',
      'a0b8bf4e-597f-46eb-91be-ffed82f0397b',
      '0a0c5e0d-8477-429d-84e9-073f55ba2fd5'
    ]
  },
  endPoints: {
    EVA_HEART: {
      url: 'https://us-central1-eva-heart-spark.cloudfunctions.net/',
      query: 'incomingEVAQuery',
      addTraining: 'trainingEVAData',
      addUpdateKnowledge: 'addUpdateKnowledge',
      knowledgeByGroupDocIdAndVersion: 'getEVAKnowledgeByGroupDocIdAndVersion',
      newestKnowledgeByGroupDocId: 'getNewestEVAKnowledgeByGroupDocId',
      addKnowledge: 'addEVAKnowledge',
      getKnowledge:  'getEVAKnowledge',
      getKnowledgeList: 'getEVAKnowledgeList',
      insertKnowledge: 'insertEVAKnowledge',
      updateKnowledge: 'updateEVAKnowledge',
      addRemovedImages: 'addRemovedImages',
      deleteRestoreKnowledge: 'deleteRestoreKnowledgeDoc',
      createNewScheduledTask: 'createNewScheduledTask',
      exportKnowledge: 'exportKnowledge'
    },
    EVA_STORAGE: {
      urlATB: 'https://us-central1-eva-links-atb-spark.cloudfunctions.net/',
      urlNonATB: 'https://us-central1-eva-links-spark.cloudfunctions.net/',
      addLocationData: 'storeEVAEncryptedData',
      getLocationData: 'getEVAEncryptedData'
    },
    DYNAMIC_INTERACTIONS: {
      url: 'https://us-central1-spark-dynamic-form-test.cloudfunctions.net/'
    },
    SEARCH: {
      url: 'https://us-central1-atb-eva-search-spark.cloudfunctions.net/'
    },
    WORKFLOW: {
      url: 'https://us-central1-spark-workflow-test.cloudfunctions.net/'
    },
    BLOCK_ENDPOINT: {
      url: 'https://us-central1-spark-block-genesis-test.cloudfunctions.net/'
    },
    GOOGLE_DRIVE: {
      url: 'https://www.googleapis.com/drive/v3/'
    },
    EVA_FF: {
      url: 'https://us-central1-eva-spark-test.cloudfunctions.net/',
      getActiveWorkflowById: 'getActiveWorkflowById'
    }
  },
  algolia: {
    appId: 'EV76H5OXCD',
    apiKey: '',
    indexEnding: '_SPARK'
  },
  froala: {
    key: '>>>FROALA_EDITOR_KEY<<<'
  },
  cpAddressCompleteFind: {
    url: 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/2.1/json3.ws',
    key: '>>>CANADA_POST_KEY<<<'
  },
  cpAddressCompleteRetrieve: {
    url: 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws',
    key: '>>>CANADA_POST_KEY<<<'
  },
  defaultTutorials: {
    // this is a list of tutorial definitions and the current completion value defaulted to false
    //
    // Tutorials Attributes: tutorials are defined by adding key: value pair attributes (ie. "welcome: false")
    //    adding a new tutorial is as simple as adding a new attribute (ie. "new_tutorial_name: false")
    //
    // Versioning: increment the version number to indicate that the list of tutorials has changed and all users
    //    will have their list of tutorials updated by the tutorials service
    //
    // sample usage: tutorial usage pattern example can be found in the saltchat component
    version: 7,
    tutorialList: {
      welcome: false,
      feedback: false,
      processDashboard: false,
      documentFeedback: false
    }
  }
};
