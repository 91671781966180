/**
 * This file is used for generic search items in EVA
 */

/**
 * This interface is used to determine what the incoming request for the search token is
 * and is used to generate a distinct token for that purpose.
 */
export interface IncomingTokenRequest {
    tokenType: AlgoliaTokenType; // the incoming token request type.
}

/**
 * This interface is used to determine an individual search token
 */
export interface AlgoliaSearchToken {
    securedAPIKey: string; // the secured API key
    securedAPIKeyOnlyIndex: string; // the secured API key (no filters for Algolia API v4)
    validUntil: number; // the valid until date.
    tokenType: AlgoliaTokenType; // the token type returned.
    groupIds: string[]; // all groups that the token was issued from.
    workflowIds: string[]; // all workflow ids the token was issued.
    additionalData?: AlgoliaTokenAdditionalData; // the additional data to include.
}

/**
 * Additional data that may be included in the search token.
 */
export interface AlgoliaTokenAdditionalData {
    subGroups: string[];
}

/**
 * This is the type of incoming token request needed for the search items.
 */
export enum AlgoliaTokenType {
    KNOWLEDGE_READER = 'read-knowledge', // knowledge searching
    KNOWLEDGE_EDITOR = 'write-knowledge', // knowledge searching
    DASHBOARD = 'read-process', // dashboard searching
    PROCESS = 'execute-process', // process searching
    INTERACTION_BUILDER = 'write-interaction', // interaction builder searching
    WORKFLOW_BUILDER = 'write-workflow', // workflow builder searching
    ORGANIZATION = 'read-organization', // organization searching
    USER = 'read-user' // user searching.
}

/**
 * Available Aloglia Indexes to read from
 */
export enum AlgoliaIndex {
    WORKFLOWS = 'Workflows',
    INTERACTIONS = 'Interactions',
    PROCESS_DASHBOARD = 'processDashboard',
    KNOWLEDGE = 'Knowledge',
    KNOWLEDGE_SEARCH = 'KnowledgeSearch'
}

/**
 * This is the token shape that gets sent to the EVA application to issue a secured Algolia Token
 */
export interface AlgoliaTokenResponse {
    securedAPIKey: string; // the secured API that is issued from the EVA search provider.
    validUntil: number; // the timestamp (EVA environment) that the token is valid until (60 minutes max)
    tokenType: AlgoliaTokenType; // the type of algolia token response.
}
