<div class="id-map-container">
  <button mat-icon-button mat-dialog-close class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>Map matching elements</h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <div class="form-container">
      <div class="half-width">
        <div class="type-title sticky-header">
          Current Interaction : {{dialogData.dynFormMdl.oldInteraction.name}}
          <br/>
          Environment: {{getCurrentEnvironment()}}
        </div>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight=false class="tab-form"
          *ngIf="this.oldFormScreens !== undefined && this.oldFormScreens.length > 0"
          (selectedTabChange)="onOldSelectedTabChange($event)">
          <mat-tab #oldTab *ngFor="let screen of this.oldFormScreens; let screenIndex = index;"
            [disabled]='disableOldMatTab(oldTab)'>
            <ng-template mat-tab-label>
              <div>
                <span>{{ screen.name }}</span>
                <button (click)="selectAllOld(screen)" class="select-all"
                  [class.disabled]="disableOldSelectAllButton(screenIndex)">
                  Select All
                </button>
              </div>
            </ng-template>
            <ul class="list-height">
              <li class="form-element" *ngFor="let formElement of screen.FormElements; let elementIndex = index;">
                <fieldset class="list-item" (click)="selectOldElement(screen, elementIndex)"
                  [class.disabled]="disableOldFormElement(formElement, elementIndex, screen)"
                  [class.selected]="screen.FormElements[elementIndex].selected">
                  <legend class="list-item-legend">ID: {{formElement.id}}</legend>
                  Name: {{formElement.label}} <br />
                  Type: {{formElement.type}} <br />
                  {{formElement.inputType ? 'Input Type: ' + formElement.inputType : null }}
                </fieldset>
              </li>
            </ul>
          </mat-tab>
        </mat-tab-group>
      </div>
      <span class="vertical-border"></span>
      <div class="half-width">
        <div class="type-title sticky-header">
          New Interaction : {{dialogData.dynFormMdl.newInteraction.name}}
          <br/>
          Environment: {{dialogData.dynFormMdl.environment || ''}}
        </div>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight=false class="tab-form"
          *ngIf="this.newFormScreens !== undefined && this.newFormScreens.length > 0"
          (selectedTabChange)="onNewSelectedTabChange($event)">
          <mat-tab #newTab *ngFor="let screen of this.newFormScreens; let screenIndex = index;"
            [disabled]='disableNewMatTab(newTab)'>
            <ng-template mat-tab-label>
              <div>
                <span>{{ screen.name }}</span>
                <button (click)="selectAllNew(screen)" class="select-all"
                  [class.disabled]="disableNewSelectAllButton(screenIndex)">
                  Select All
                </button>
              </div>
            </ng-template>
            <ul class="list-height">
              <li class="form-element" *ngFor="let formElement of screen.FormElements; let elementIndex = index;">
                <fieldset class="list-item" (click)="selectNewElement(screen, elementIndex)"
                  [class.selected]="screen.FormElements[elementIndex].selected"
                  [class.disabled]="disableNewFormElement(formElement, elementIndex, screen)">
                  <legend class="list-item-legend">ID: {{formElement.id}}</legend>
                  Name: {{formElement.label}} <br />
                  Type: {{formElement.type}} <br />
                  {{formElement.inputType ? 'Input Type: ' + formElement.inputType : null }}
                </fieldset>
              </li>
            </ul>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="pairs" color="primary"
      [class.disabled]="!isValid()">Submit</button>
    <button mat-stroked-button (click)="onReset()">Reset</button>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>