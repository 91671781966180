import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { ArrayUtilsService } from '@eva-services/utils/array-utils.service';
import { UtilsService } from '@eva-services/utils/utils.service';
import { ProcessStatusService } from '@eva-services/process/process-status.service';
import { LogService } from '@eva-core/log/log.service';
import { Log, LogLevel, EntityType } from '@eva-model/log';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { ProcessStatusRecord, ProcessStatusRecordDisplay } from '@eva-model/process/process';
import {MessageService} from 'primeng/api';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'eva-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss'],
  providers: [MessageService]
})
export class ProcessStatusComponent {

  prcsStatusReportData: ProcessStatusRecord[] = [];
  isWaiting = false; // used to determine if this is currently retrieving data.
  onWaitMessage: string = null; // this is the message to use when waiting.

  //#region Definition of ag-grid columns icluding colum,ns features such as sort/filter/resize and grouping
  public prcsStatusReportGridColumnDefs = [
    { headerName: 'Updated at', field: 'updatedAt', width: 180, resizable: true, sortable: true, filter: true },
    { headerName: 'Process Id', field: 'processId', width: 300, resizable: true, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Status', field: 'status', width: 120, enableRowGroup: true,
      resizable: true, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Workflow name', field: 'workflowName', width: 360, enableRowGroup: true,
      resizable: true, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Created at', field: 'createdAt', width: 180,
      resizable: true, sortable: true, filter: true, checkboxSelection: false },
    { headerName: 'Submitter Key', field: 'submitterKey', width: 1100, resizable: true, sortable: true, filter: 'agTextColumnFilter' }
  ];
  //#endregion

  // Auto group definition for grid columns (for now it's not in use but intentionally kept it here for attention)
  @ViewChild('evaLogsGrid') evaLogsGrid: AgGridAngular;

  public prcsStatusReportGridOptions: GridOptions; // Grid options object for setting the grid behaviour

  prcsStatusReportFormGrp: UntypedFormGroup; // reactive form group object to cover form elemnts for "process status report"
  fromDateTime: number; // "From" date time utilizes when searching processes as starting date and time point within a period of time
  toDateTime: number; // "To" date time utilizes when searching processes as ending date and time point within a period of time
  componentTitle: string;

  /**
   * Class constructor
   */
  constructor(
    private fb: UntypedFormBuilder,   // Injecting reactive form builder
    private ProcessStatusSrv: ProcessStatusService,   // injecting process status service which contains related functionalities
    private arrayUtilsSrv: ArrayUtilsService,   // injecting array related utilty service
    private logSrv: LogService,   // injecting log service to record whatever log which app needs
    private evaGlobalSrv: EvaGlobalService,   // injecting global EVA app service
    private utils: UtilsService,   // injecting common utility as a service which covers general functionalities
    private messageService: MessageService, // Primeng service to display messages; used with <p-toast> tag
    private activatedRoute: ActivatedRoute
  ) {

    //#region Setting the grid options
    this.prcsStatusReportGridOptions = <GridOptions>{};
    this.prcsStatusReportGridOptions.columnDefs = this.prcsStatusReportGridColumnDefs;

    this.prcsStatusReportGridOptions.animateRows = true;
    this.prcsStatusReportGridOptions.pagination = true;
    this.prcsStatusReportGridOptions.paginationPageSize = 15;

    this.prcsStatusReportGridOptions.suppressDragLeaveHidesColumns = true;
    this.prcsStatusReportGridOptions.suppressMakeColumnVisibleAfterUnGroup = true;
    this.prcsStatusReportGridOptions.rowGroupPanelShow = 'always';
    // this.prcsStatusReportGridOptions.groupUseEntireRow = true;

    this.activatedRoute.data.subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
    //#endregion

    this.createForm();
  }

  /**
   * Builds a reactive form with necessary elements
   */
  createForm(): void {
    this.prcsStatusReportFormGrp = this.fb.group({
      fromDateTime: ['', Validators.required],
      toDateTime: ['', Validators.required]
    });
  }

  /**
   * Event handler for setting date time when starting date time of the report period changes
   * @param dateString the date string in ISO format
   * @return {void}
   */
  onFromDateTimeChange(dateString: string): void {
    this.fromDateTime = new Date(dateString).getTime();
  }

  /**
   * Event handler for setting date time when ending date time of the report period changes
   * @param dateString the date string in ISO format
   * @return {void}
   */
  onToDateTimeChange(dateString: string): void {
    this.toDateTime = new Date(dateString).getTime();
  }

  /**
   * Validate report parameters and call to fetch process status report data and popup a message about the result
   * @return {void}
   */
  async getProcessStatusReport(): Promise<void> {

    let detailMessage = 'No process found at this moment';

    try {

      //#region report parameters validation
      if ( !(this.fromDateTime && this.toDateTime && this.toDateTime >= this.fromDateTime) ) {
        detailMessage  = '';
        // Validation for starting date time point of report period
        if (!this.fromDateTime) {
          detailMessage += "'From' date time should be valid. \r\f";
        }

        // Validation for ending date time point of report period
        if (!this.toDateTime) {
          detailMessage += "'To' date time should be valid. \r\f";
        }

        // Validate to be sure end time is greater than starting
        if (this.toDateTime < this.fromDateTime) {
          detailMessage += "'From' date time should be less than or equal to 'To' date time. \r\f";
        }

        // Popup message setting in case there is a any issue with report parameters
        this.messageService.clear();
        this.messageService.add({
          life: 7000,
          severity: 'warn',
          summary: 'Processes status report',
          detail: detailMessage
        });

        return;
      }
      //#endregion

      this.isWaiting = true;
      this.onWaitMessage = 'Generating process status report';

      // Fetch process status report data
      await this.prcsStatusReport(this.fromDateTime, this.toDateTime);

      // Check if process status report based on requested date time period could receive any data
      if ( this.prcsStatusReportData && Array.isArray( this.prcsStatusReportData ) && this.prcsStatusReportData.length > 0 ) {
        detailMessage =
          `Processes report is ready with ${this.prcsStatusReportData.length} record${this.prcsStatusReportData.length > 1 ? 's' : ''}.`;
      } else {
        detailMessage = `No Processes were found with the specified date range`;
      }

    } catch ( err ) {

      //#region Error handling
      detailMessage = 'Encountered with error';

      // Create the error message
      const errMsg = (err && typeof err === 'object') ? JSON.stringify(err) : ((err && typeof err === 'string') ? err : '');
      const msg =
        `Process status report encountered with err :: ERROR :: ${errMsg}`;

      // Log the error
      const log = new Log(LogLevel.Error, EntityType.process, '', msg, this.evaGlobalSrv.userId);
      this.logSrv.error(log).then( () => {} ).catch( (e) => console.log(e));
      //#endregion

    } finally {

      this.isWaiting = false;

      // Popup message setting for result of "process status report"
      this.messageService.add({
        life: 7000,
        severity: 'info',
        summary: 'Processes status report',
        detail: detailMessage
      });

    }

  }

  /**
   * Fetch and convert process status report data to shape the grid data (sorted)
   *
   * @param from the timestamp in javascript
   * @param to timestamp in javascript
   * @return {void}
   */
  async prcsStatusReport(from: number, to: number): Promise<void> {
    // Fetch the process status report data based on passing parameters for the period of time
    const processReport = await this.ProcessStatusSrv.getProcessStatusReport(from, to);

    // initialize process status report data
    let dataArray: ProcessStatusRecordDisplay[] = [];
    this.prcsStatusReportData.length = 0;

    // Validate if repot has any data, if it's empty then return
    if ( !(processReport && Array.isArray(processReport) && processReport.length > 0)) {
      return;
    }

    //#region Convert and map fetched process status report data to shape the grid data
    processReport.forEach( (prcsItem: ProcessStatusRecord) => {
      const processStatusItem: ProcessStatusRecordDisplay = {
        processId: prcsItem.id,
        createdAt: this.utils.convertTimestampToDate(prcsItem.createTimestamp),
        updatedAt: this.utils.convertTimestampToDate(prcsItem.lastUpdateTimestamp),
        status: prcsItem.status,
        workflowName: prcsItem.workflowName,
        submitterKey: prcsItem.submitterKey
      };

      dataArray.push(processStatusItem);
    });
    //#endregion

    // Generate distinct array of data for the grid
    dataArray = this.arrayUtilsSrv.uniqueArray(dataArray);
    // custom sort the data grid based on update timestamp, wooow! :-)
    this.prcsStatusReportData = this.arrayUtilsSrv.arrayObjectCustomSort(dataArray, 'updatedAt', 'DESC');
  }

  //#region Agnostic grid events
  /**
   * On grid ready event
   */
  public prcsStatusReportGridReady() {
    // On Grid Ready
  }

  /**
   * On grid data model updated event
   */
  public prcsStatusReportGridModelUpdated() {
    // On Grid Model Updated
  }

  /**
   * On grid row Expand/Collapse event
   */
  public prcsStatusReportGridRowGroupOpened() {
    // On Grid row group Expand/Collapse
  }

  /**
   * On grid first data rendering event
   */
  public prcsStatusReportGridFirstDataRendered() {
    // On Grid first data rendering
  }
  //#endregion

}
