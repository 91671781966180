<div fxLayout="row" fxLayoutAlign="start">
  <div fxFlexFill>
    <mat-card>
      <mat-card-content>
        <div fxFlex="100%" class="rightMargin10">
          <mat-form-field class="fullWidth">
            <mat-chip-grid #interactionConditionList>
              <mat-chip-row *ngFor="let condition of interactionConditions; let conditionIndex = index;" [selectable]="true"
                [removable]="true" (remove)="removeIntractionConditionConfirm(condition)"
                id={{condition.name}}{{conditionIndex}} data-interaction-condition="true"
                [attr.data-interaction-condition-index]="conditionIndex" draggable="true"
                (dragstart)="dragStartConditionHandler($event)" (drop)="dropConditionHandler($event)"
                (dragover)="dargOverConditionHandler($event)">
                {{condition.name}}
                <button class="mat-icon-button-16" mat-icon-button color="primary" *ngIf="true"
                  (click)="editInteractionConditionEvent(condition, conditionIndex);">
                  <mat-icon class="mat-Icon-16" aria-label="Edit the interaction condition">edit</mat-icon>
                </button>
                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
              </mat-chip-row>
              <input placeholder="Interaction Conditions ..." [matChipInputFor]="interactionConditionList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addInteractionCondition($event)" />
            </mat-chip-grid>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight=false *ngIf="interactionConditions !== undefined && interactionConditions.length > 0">
  <mat-tab *ngFor="let condition of interactionConditions; let conditionIndex = index;"
    (click)="callInteractionConditionDialog(conditionIndex)" label="{{ condition.name }}">

    <button *ngIf="condition.condition" mat-raised-button class="fullWidth"
      (click)="callInteractionConditionDialog(conditionIndex)">
      Interaction Condition Builder
    </button>

    <div fxLayout="row" fxLayoutAlign="start" *ngIf="false">
      <div fxFlexFill>
        <mat-card>
          <mat-card-header>
            <!-- <mat-card-title>Condition string to evaluate</mat-card-title> -->
            <mat-card-subtitle>Condition string to evaluate</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            {{ jsonStringify(condition.condition) }}
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <eva-workflow-interaction-condition-viewer [conditionIndex]="conditionIndex"
      [workflowInteractionConditionDefinition]="jsonStringify(condition)">
    </eva-workflow-interaction-condition-viewer>

    <mat-accordion multi="false">

      <mat-expansion-panel>
        <mat-expansion-panel-header class="panel-custom-header-true">
          <mat-panel-title class="panel-custom-header-true">
            True condition destination(s)
          </mat-panel-title>
          <mat-panel-description class="condition-true">
            When condition evaluates to True
          </mat-panel-description>
        </mat-expansion-panel-header>

        <eva-workflow-destination-picker [workflowInteractionId]="interaction.id"
          [workflowInteractionVersion]="interaction.version" [conditionResult]=true
          [interactionConditionId]="condition.id" [interactionsByGroupsObj]="jsonStringify(interactionsByGroups)"
          [interactionConditionsObj]="interactionConditions">
        </eva-workflow-destination-picker>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header class="panel-custom-header-false">
          <mat-panel-title class="panel-custom-header-false">
            False condition destination(s)
          </mat-panel-title>
          <mat-panel-description class="condition-false">
            When condition evaluates to False
          </mat-panel-description>
        </mat-expansion-panel-header>

        <eva-workflow-destination-picker [workflowInteractionId]="interaction.id"
          [workflowInteractionVersion]="interaction.version" [conditionResult]=false
          [interactionConditionId]="condition.id" [interactionsByGroupsObj]="jsonStringify(interactionsByGroups)"
          [interactionConditionsObj]="interactionConditions">
        </eva-workflow-destination-picker>
      </mat-expansion-panel>

    </mat-accordion>

    <br />
    <eva-workflow-destination-viewer [workflowInteractionConditionObj]="jsonStringify(condition)">
    </eva-workflow-destination-viewer>

  </mat-tab>
</mat-tab-group>
