<div class="eva-chat-workflows">
  <app-page-header [title]="componentTitle"></app-page-header>
  <div>
    <ng-template #actionTemplate let-hit="hit">
      <div class="button-row-right">
        <button mat-raised-button class="button-launch-process" (click)="workflowSelected(hit)">
          Launch Process
        </button>
        <button matTooltip="Pin to Launchpad" mat-icon-button (click)="pinToLaunchPad($event, hit)">
          <mat-icon>star_outline</mat-icon>
        </button>
      </div>
    </ng-template>
    <app-generic-search
      [actionTemplate]="actionTemplate"
      [searchFilters]="processFilters"
      [indexName]="algoliaIndex"
      [tokenType]="algoliaTokenType"
      searchResultTitleProperty="name"
      [clickableSearchResults]="true"
      searchResultSubTitleProperty="id"
      [propertyNameMapping]="processPropertyNameMapping"
      [rowHeight]="rowHeight"
      [agGridContext]="context"
      [columnDefs]="columnDefs"
      [defaultColumnDef]="defaultColDef"
      [pinToLaunchpadFunction]="pinToLaunchPad"
    >

    <ng-template #cardTemplate let-hit>
      <div class="search-result has-actions" data-default-card-template="true" [class.search-result-link]="true">
        <h4 class="search-result-title">{{hit['name']}}</h4>
        <div class="search-result-tags" *ngIf="hit.descriptions">
          <span *ngFor="let tag of hit.descriptions | fromJson">
            <span>{{ tag }}</span>
          </span>
        </div>
        <div class="search-result-content" *ngFor="let property of (hit|getProperties:processPropertyNameMapping:'name':'id')">
            <span class="search-result-content-property">{{ property | getPropertyUserFriendlyName:processPropertyNameMapping }}</span>
            <span *ngIf="!(hit | isValidArray:property)">{{ hit | getPropertyValue:property:processPropertyNameMapping }}</span>
            <span *ngIf="(hit | isValidArray:property)" class="search-result-content-value">
                <mat-select placeholder="Select a version" (selectionChange)="onSelectionChange($event, hit, property)" [value]="hit | getPropertyValue:property:processPropertyNameMapping"
                    class="select-version">
                    <mat-option *ngFor="let version of hit[property + '-modified'] ? hit[property + '-modified'] : hit[property]"
                        [value]="version.version.toString()">
                        {{('' + hit.activeVersion === '' + version.version) ? 'Active :: ' : ''}} {{version.version | date:'full'}}
                    </mat-option>
                </mat-select>
            </span>
        </div>
        <div class="search-result-actions">
          <ng-container *ngTemplateOutlet="actionTemplate;context:{hit: hit}"></ng-container>
        </div>
      </div>
    </ng-template>
  </app-generic-search>
  </div>
</div>