import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';

import instantsearch from 'instantsearch.js';
import type { Widget } from 'instantsearch.js';
import { IndexWidget } from 'instantsearch.js/es/widgets/index/index';
import algoliasearch from 'algoliasearch/lite';

@Injectable()
export class AlgoliaSearchService implements OnDestroy {

    private algoliaSearchInstance: any;

    constructor() {
        //
    }

    ngOnDestroy(): void {
        console.log('AlgoliaSearchService Destroyed')
    }

    initialize(indexName: string, apiKey: string) {
        if (this.algoliaSearchInstance) {
            console.log(`Search already initialized`)
            return;
        }

        const searchClient = algoliasearch(environment.algolia.appId, apiKey)

        // future: { preserveSharedStateOnUnmount: true },
        this.algoliaSearchInstance = instantsearch({
            searchClient,
            indexName
        });

        this.algoliaSearchInstance.start();

        console.log(`Initialized search for ${indexName}`)
    }

    addWidgets(widgets: Array<IndexWidget | Widget>) {
        return;
        // this.algoliaSearchInstance.addWidgets(widgets);
    }

    removeWidgets(widgets: Array<IndexWidget | Widget>) {
        this.algoliaSearchInstance.removeWidgets(widgets);
    }



    // /* This function updates the search config with new config from algolia
    //  *
    //  * @param algoliaIndex
    //  * @param searchParams search parameters for algolia
    //  */
    // updateSearchConfig(algoliaIndex: string, searchParams: any, conditionType?: 'AND' | 'OR'): void {
    //     // create a new config object from our searchParams
    //     const newConfig = this.createNewConfig(algoliaIndex, { filters: searchParams.filters }, conditionType);
    //     newConfig.searchParams = {
    //         ...newConfig.searchParams,
    //         distinct: searchParams.distinct,
    //         hitsPerPage: searchParams.hitsPerPage,
    //         page: searchParams.page
    //     };
    //     // replace an existing/add a new key with our updated config
    //     this.searchConfig[algoliaIndex] = newConfig;

    //     // notify all subscribers
    //     this.updateSubject();
    // }

    // // TODO: THIS WHOLE FUNCTION NEEDS A FUNDAMENTAL CHANGE. IT'S TRYING TO GENERATE A STRING AND GUESS WHAT JOINING FILTERS WE WANT USE
    // // THE CONSUMING COMPONENT SHOULD BE PASSING IN A STRING THATS CORRECTLY FORMATTED AND THIS FUNCTION JUST SIMPLY APPLIES IT
    // // TO THE SEARCH CONFIG...

    // /**
    //  * This function creates a new algolia configuration object we put in memory and send to all subscribers
    //  *
    //  * @param algoliaIndex
    //  * @param searchParams search parameters for algolia
    //  */
    // createNewConfig(algoliaIndex: string, searchParams: any = {}, conditionType?: 'AND' | 'OR'): any {
    //     const newIndexConfigParams = {
    //         index: algoliaIndex,
    //         searchParams: null,
    //         activeParams: {}
    //     };
    //     const existingIndexConfigParams = this.searchConfig[algoliaIndex];
    //     // get what we are doing.. filter, sort, etc.
    //     const keys = Object.keys(searchParams);

    //     if (existingIndexConfigParams) {
    //         // update our existing params
    //         keys.forEach(k => {
    //             if (existingIndexConfigParams.activeParams[k]) {
    //                 // see if we already are filtering this facet
    //                 const foundFacet = existingIndexConfigParams.activeParams[k].find(f => f.attribute === searchParams[k].attribute);
    //                 if (foundFacet) {
    //                     // update facet value
    //                     foundFacet.value = searchParams[k].value;
    //                 } else {
    //                     // push facet that was not found
    //                     existingIndexConfigParams.activeParams[k].push(searchParams[k]);
    //                 }
    //             } else {
    //                 existingIndexConfigParams.activeParams[k] = [searchParams[k]];
    //             }
    //         });
    //         existingIndexConfigParams.searchParams = this.createSearchParamsQuery(existingIndexConfigParams.activeParams, conditionType);
    //         return existingIndexConfigParams;
    //     } else {
    //         keys.forEach(k => {
    //             newIndexConfigParams.activeParams[k] = [searchParams[k]];
    //         });
    //         newIndexConfigParams.searchParams = this.createSearchParamsQuery(newIndexConfigParams.activeParams, conditionType);
    //         return newIndexConfigParams;
    //     }
    // }

    // /**
    //  * This function creates a query from search parameters
    //  *
    //  * @param activeConfigParams configuration parameters for algolia
    //  */
    // createSearchParamsQuery(activeConfigParams: any, conditionType?: 'AND' | 'OR'): any {
    //     const searchParams = {};
    //     const keys = Object.keys(activeConfigParams);

    //     keys.forEach(k => {
    //         // make sure there are values we are filtering/sorting by
    //         if (activeConfigParams[k].length > 0) {
    //             // there are values, create a key for our search params
    //             // searchParams[k] = null;
    //             activeConfigParams[k].forEach(i => {
    //                 if (i.value) {
    //                     let delimiter = ':';
    //                     if (Array.isArray(i.value)) {
    //                         if (i.value[0] !== null && i.value[0] !== undefined) {
    //                             i.value.forEach(value => {
    //                                 if (value && (typeof value === 'string') && (value.includes('<') || value.includes('>'))) {
    //                                     delimiter = '';
    //                                 }
    //                                 if (!searchParams[k]) {
    //                                     // if the key is empty, lets create our first query.
    //                                     searchParams[k] = (i.isNegated ? 'NOT ' : '') + i.attribute + delimiter + "\"" + value + "\"";
    //                                 } else if (searchParams[k].includes(i.attribute)) {
    //                                     // make sure we add an ' AND ' or ' OR ' operator in between queries
    //                                     searchParams[k] += ' ' + (conditionType ?? 'AND') + ' ' + (i.isNegated ? 'NOT ' : '') + i.attribute
    //                                         + delimiter + "\"" + value + "\"";
    //                                 } else {
    //                                     searchParams[k] += ' AND ' + (i.isNegated ? 'NOT ' : '') + i.attribute + delimiter + "\"" + value + "\"";
    //                                 }
    //                             });
    //                         }
    //                     } else {
    //                         if (i.value && (typeof i.value === 'string') && (i.value.includes('<') || i.value.includes('>'))) {
    //                             delimiter = '';
    //                         }
    //                         if (!searchParams[k]) {
    //                             // if the key is empty, lets create our first query.
    //                             searchParams[k] = (i.isNegated ? 'NOT ' : '') + i.attribute + delimiter + i.value;
    //                         } else {
    //                             // make sure we add an ' AND ' or ' OR ' operator in between queries
    //                             searchParams[k] += ' ' + (conditionType ?? 'AND') + ' ' + (i.isNegated ? 'NOT ' : '') + i.attribute
    //                                 + delimiter + i.value;
    //                         }
    //                     }
    //                 }
    //             });
    //         }
    //     });

    //     // let's check and make sure this object we will return has properties WITH values.
    //     // if we return an object with a key but no value, we won't get any search results from algolia.

    //     return searchParams;
    // }

    // /**
    //  * This function returns a string format for range passed
    //  *
    //  * @param range Array of size 2 with start and end numbers
    //  */
    // createRangeString(range: number[]): (string | null) {
    //     if (range[0] && range[1]) {
    //         return range[0].toString() + ' TO ' + range[1].toString();
    //     } else {
    //         if (range[0] && !range[1]) {
    //             return ' > ' + range[0];
    //         } else if (range[1] && !range[0]) {
    //             return ' < ' + range[1];
    //         }
    //     }
    //     return null;
    // }

    // /**
    //  * This function returns configuration for algolia by index
    //  *
    //  * @param index config property to retrieve
    //  */
    // getConfigByIndex(index: string): Observable<any> {
    //     return this.searchConfigs.pipe(
    //         map((configs) => {
    //             const config = configs[index];
    //             if (config) {
    //                 return config.searchParams;
    //             } else {
    //                 return {};
    //             }
    //         })
    //     );
    // }

    // clearSearchConfig(index: string) {
    //     this.searchConfig[index] = null;
    // }

}
