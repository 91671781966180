/**
 * To get around the user-friendly-pipe.pipe.ts being standalone but also relying on it's
 * code in other areas of the application, I moved all the logic to this class to be shared
 * with the Pipe and Component.
 */

import { EvaDynamicFormControlModel } from "@eva-model/evaDynamicFormControlModel";
import { IfThenLogicAction, IfThenLogicOptions, MathEquationOperators, Relation, SubRelation } from "@eva-model/interactionElementRelationDialogModel";

export class UserFriendlyConditions {
    constructor() {
        //
    }

    private message: string;

    public transform(relationControls: any[], screens: any[]): string {
        this.message = '';
        relationControls.forEach((control, index) => {
            if (control.relation) {
                (control.relation as Relation[]).forEach(relation => {
                    this.getRelationFriendlyMessage(relation, index, screens, relation.thenControl);
                });
            }
        });
        return this.message.length > 0 ? this.message : 'Condition will appear here';
    }

    private getRelationFriendlyMessage(relation: Relation, index: number, screens: any[], currentControls: EvaDynamicFormControlModel[]): void {
        if (relation.connective
            && (relation.subRelation
                ? relation.subRelation[0]
                    ? !relation.subRelation[0].ifConnective
                    : true
                : true)) {
            this.message += ` ${relation.connective.toLowerCase()}\n`;
        } else if (!relation.connective && index > 0
            && (relation.subRelation
                ? relation.subRelation[0]
                    ? !relation.subRelation[0].ifConnective
                    : true
                : true)) {
            this.message += `\n`;
        }
        if (relation.subRelation) {
            relation.subRelation.forEach((subRelation, subRelationIndex) => {
                this.getSubRelationFriendlyMessage(subRelation, index, screens, currentControls, relation, subRelationIndex);
            });
        }
    }

    private getSubRelationFriendlyMessage(subRelation: SubRelation, index: number, screens: any[],
        currentControls: EvaDynamicFormControlModel[], relation: Relation, subRelationIndex: number): void {
        let thenMessage = null;
        if (index > 0 && this.message.length > 0 && subRelation.ifConnective) {
            thenMessage = this.message.substring(this.message.lastIndexOf(' Then '));
            this.message = this.message.substring(0, this.message.lastIndexOf('Then '));
            this.message += `${subRelation.ifConnective.toLowerCase()}`;
        }
        if (subRelation.if) {
            this.getSubRelationIfFriendlyMessage(subRelation, relation, subRelationIndex);
        }
        if (subRelation.then && !thenMessage) {
            this.getSubRelationThenFriendlyMessage(subRelation, screens, currentControls, subRelationIndex);
        }
        if (thenMessage) {
            this.message += thenMessage;
        }
    }

    private getSubRelationIfFriendlyMessage(subRelation: SubRelation, relation: Relation, subRelationIndex: number): void {
        let option: string = subRelation.if.option;
        let value: string = subRelation.if.value;
        if (subRelation.if.option === IfThenLogicOptions.IsEmpty
            || subRelation.if.option === IfThenLogicOptions.IsNotEmpty
            || subRelation.if.option === IfThenLogicOptions.DateIsToday
            || subRelation.if.option === IfThenLogicOptions.DateIsAfterToday
            || subRelation.if.option === IfThenLogicOptions.DateIsBeforeToday
            || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrAfterToday
            || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrBeforeToday
            || subRelation.if.option === IfThenLogicOptions.DateIsNow
            || subRelation.if.option === IfThenLogicOptions.DateIsAfterNow
            || subRelation.if.option === IfThenLogicOptions.DateIsBeforeNow
            || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrAfterNow
            || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrBeforeNow
            || subRelation.if.option === IfThenLogicOptions.IsCurrentMonth
            || subRelation.if.option === IfThenLogicOptions.MonthIsBeforeCurrentMonth
            || subRelation.if.option === IfThenLogicOptions.MonthIsAfterCurrentMonth
            || subRelation.if.option === IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth
            || subRelation.if.option === IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth
            || subRelation.if.option === IfThenLogicOptions.IsCurrentWeek
            || subRelation.if.option === IfThenLogicOptions.WeekIsBeforeCurrentWeek
            || subRelation.if.option === IfThenLogicOptions.WeekIsAfterCurrentWeek
            || subRelation.if.option === IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek
            || subRelation.if.option === IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek
            || subRelation.if.option === IfThenLogicOptions.IsCurrentTime
            || subRelation.if.option === IfThenLogicOptions.TimeIsBeforeCurrentTime
            || subRelation.if.option === IfThenLogicOptions.TimeIsAfterCurrentTime
            || subRelation.if.option === IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime
            || subRelation.if.option === IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime
            || subRelation.if.option === IfThenLogicOptions.MathEquation) {
            value = '';
            option = option
                ? (option.indexOf('date ') === 0
                    || option.indexOf('week ') === 0
                    || option.indexOf('time ') === 0
                    ? (option.substring(5))
                    : option.indexOf('month ') === 0
                        ? (option.substring(6))
                        : option)
                : null;
        } else {
            if (subRelation.if.option === IfThenLogicOptions.IsBetween
                || subRelation.if.option === IfThenLogicOptions.IsNotBetween) {
                value = (value ?? 'value') + ' and '
                    + (subRelation.if.secondValue !== '' ? subRelation.if.secondValue ?? 'second value' : 'second value');
            }
            if (subRelation.if.option === IfThenLogicOptions.DateIsAfterX
                || subRelation.if.option === IfThenLogicOptions.TimeIsAfterX
                || subRelation.if.option === IfThenLogicOptions.WeekIsAfterX
                || subRelation.if.option === IfThenLogicOptions.DateIsBeforeX
                || subRelation.if.option === IfThenLogicOptions.MonthIsAfterX
                || subRelation.if.option === IfThenLogicOptions.TimeIsBeforeX
                || subRelation.if.option === IfThenLogicOptions.WeekIsBeforeX
                || subRelation.if.option === IfThenLogicOptions.MonthIsBeforeX
                || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrAfterX
                || subRelation.if.option === IfThenLogicOptions.TimeIsEqualToOrAfterX
                || subRelation.if.option === IfThenLogicOptions.WeekIsEqualToOrAfterX
                || subRelation.if.option === IfThenLogicOptions.DateIsEqualToOrBeforeX
                || subRelation.if.option === IfThenLogicOptions.MonthIsEqualToOrAfterX
                || subRelation.if.option === IfThenLogicOptions.TimeIsEqualToOrBeforeX
                || subRelation.if.option === IfThenLogicOptions.WeekIsEqualToOrBeforeX
                || subRelation.if.option === IfThenLogicOptions.MonthIsEqualToOrBeforeX) {
                option = option
                    ? (option.indexOf('date ') === 0
                        || option.indexOf('week ') === 0
                        || option.indexOf('time ') === 0
                        ? (option.substring(5))
                        : option.indexOf('month ') === 0
                            ? (option.substring(6))
                            : option)
                    : null;
                option = option
                    ? (option.indexOf('X') === (option.length - 1)
                        ? (option.substring(0, option.indexOf('X')))
                        : option)
                    : null;
            } else {
                option = option ? option + ' ' : null;
            }
        }
        if (subRelation.if.option === IfThenLogicOptions.MathEquation) {
            this.message += ` If ${subRelation.if.option ?? 'option'}`;
        } else {
            if (value && typeof value === 'object' && !Array.isArray(value)) {
                const labels = [];
                Object.keys(value).forEach(key => {
                    const groupControl: EvaDynamicFormControlModel = subRelation.if.model.group?.find(group => group.id === key);
                    if (groupControl && value[key]) {
                        labels.push(groupControl.label);
                    }
                });
                if (labels.length === 0) {
                    value = undefined;
                } else {
                    value = labels.toString();
                }
            }
            this.message += ` If ${relation?.ifControl?.[subRelationIndex]?.label ?? 'form element'} ${option ?? 'option '}${value
                ?? 'value'}`;
        }
    }

    private getSubRelationThenFriendlyMessage(subRelation: SubRelation, screens: any[], currentControls: EvaDynamicFormControlModel[],
        subRelationIndex: number): void {
        let action: string = subRelation.then.action;
        let option: string = subRelation.then.option;
        let value: any = subRelation.then.value;
        const ifConnective = subRelation.ifConnective;
        let label = currentControls?.[subRelationIndex]?.label;
        let index = subRelationIndex;

        while ((!currentControls?.[index]) && index > 0) {
            if (ifConnective) {
                label = currentControls?.[index - 1]?.label;
            }
            index--;
        }

        if (action === IfThenLogicAction.Enable
            || action === IfThenLogicAction.Disable
            || action === IfThenLogicAction.MathEquation
            || action === IfThenLogicAction.DefaultValue) {
            option = '';
        }
        if (subRelation.then.action === IfThenLogicAction.Enable
            || subRelation.then.action === IfThenLogicAction.Disable
            || subRelation.then.action === IfThenLogicAction.MathEquation
            || (subRelation.then.action === IfThenLogicAction.MustBe
                && (subRelation.then.option === IfThenLogicOptions.IsEmpty
                    || subRelation.then.option === IfThenLogicOptions.IsNotEmpty
                    || subRelation.then.option === IfThenLogicOptions.DateIsToday
                    || subRelation.then.option === IfThenLogicOptions.DateIsAfterToday
                    || subRelation.then.option === IfThenLogicOptions.DateIsBeforeToday
                    || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrAfterToday
                    || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrBeforeToday
                    || subRelation.then.option === IfThenLogicOptions.DateIsNow
                    || subRelation.then.option === IfThenLogicOptions.DateIsAfterNow
                    || subRelation.then.option === IfThenLogicOptions.DateIsBeforeNow
                    || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrAfterNow
                    || subRelation.then.option === IfThenLogicOptions.DateIsEqualToOrBeforeNow
                    || subRelation.then.option === IfThenLogicOptions.IsCurrentMonth
                    || subRelation.then.option === IfThenLogicOptions.MonthIsBeforeCurrentMonth
                    || subRelation.then.option === IfThenLogicOptions.MonthIsAfterCurrentMonth
                    || subRelation.then.option === IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth
                    || subRelation.then.option === IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth
                    || subRelation.then.option === IfThenLogicOptions.IsCurrentWeek
                    || subRelation.then.option === IfThenLogicOptions.WeekIsBeforeCurrentWeek
                    || subRelation.then.option === IfThenLogicOptions.WeekIsAfterCurrentWeek
                    || subRelation.then.option === IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek
                    || subRelation.then.option === IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek
                    || subRelation.then.option === IfThenLogicOptions.IsCurrentTime
                    || subRelation.then.option === IfThenLogicOptions.TimeIsBeforeCurrentTime
                    || subRelation.then.option === IfThenLogicOptions.TimeIsAfterCurrentTime
                    || subRelation.then.option === IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime
                    || subRelation.then.option === IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime))) {
            value = '';
        } else {
            if ((subRelation.then.option === IfThenLogicOptions.IsBetween
                || subRelation.then.option === IfThenLogicOptions.IsNotBetween) &&
                subRelation.then.action === IfThenLogicAction.MustBe) {
                value = (value ?? 'value') + ' and '
                    + (subRelation.then.secondValue !== '' ? subRelation.then.secondValue ?? 'second value' : 'second value');
            }
        }
        if (option === IfThenLogicOptions.StartsWith ||
            option === IfThenLogicOptions.EndsWith ||
            option === IfThenLogicOptions.TextContains ||
            option === IfThenLogicOptions.TextDoesNotContain && action) {
            action = action.indexOf(' be') !== -1 ? action.substring(0, action.indexOf(' be')) : action;
        }
        if ((action === IfThenLogicAction.MustBe || action === 'must') && option) {
            option = option.indexOf('is ') === 0
                ? (option.substring(3) + ' ')
                : option.indexOf('does ') === 0
                    ? (option.substring(5) + ' ')
                    : option.indexOf('month is ') === 0
                        ? (option.substring(9) + ' ')
                        : option.indexOf('date is ') === 0
                            || option.indexOf('week is ') === 0
                            || option.indexOf('time is ') === 0
                            ? (option.substring(8) + ' ')
                            : option.indexOf('month ') === 0
                                ? (option.substring(6) + ' ')
                                : (option + ' ');
            option = option
                ? (option.indexOf('X') === (option.length - 2)
                    ? (option.substring(0, option.indexOf('X')))
                    : option)
                : null;
        }
        if (action === IfThenLogicAction.MathEquation && subRelation.then.operations) {
            subRelation.then.operations.forEach((operation, operationIndex) => {
                let operatorText: string;
                switch (operation.operator) {
                    case MathEquationOperators.Add: operatorText = '+'; break;
                    case MathEquationOperators.Subtract: operatorText = '-'; break;
                    case MathEquationOperators.MultipliedBy: operatorText = '*'; break;
                    case MathEquationOperators.DividedBy: operatorText = '/'; break;
                    default: break;
                }
                value += operationIndex > 0 ? (operatorText ?? 'operator') + ' ' : '';
                let formElement: any;
                screens.forEach(screen =>
                    screen.FormElements.forEach(element => {
                        if (element.originalId === operation.value) {
                            formElement = element;
                        }
                    })
                );
                if (formElement) {
                    if (operationIndex !== subRelation.then.operations.length - 1) {
                        value += formElement.label + ' ';
                    } else {
                        value += formElement.label + ')';
                    }
                } else {
                    value += 'form element' + (operationIndex === subRelation.then.operations.length - 1 ? ')' : ' ');
                }
            });
            this.message += ` Then ${action ?? 'action'} ${label ?? 'form element'} = (${value ?? 'value'}`;
        } else if (action === IfThenLogicAction.Enable || action === IfThenLogicAction.Disable) {
            this.message += ` Then ${action ?? 'action'} ${label ?? 'form element'}`;
        } else {
            if (value && typeof value === 'object' && !Array.isArray(value)) {
                const labels = [];
                Object.keys(value).forEach(key => {
                    const groupControl: EvaDynamicFormControlModel = subRelation.then.model.group?.find(group => group.id === key);
                    if (groupControl && value[key]) {
                        labels.push(groupControl.label);
                    }
                });
                if (labels.length === 0) {
                    value = undefined;
                } else {
                    value = labels.toString();
                }
            }
            this.message += ` Then ${currentControls[subRelationIndex]?.label ?? 'form element'} ${action ?? 'action'} ` +
                `${option ?? 'option '}${value ?? 'value'}`;
        }
    }
}