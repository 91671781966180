<div *ngIf="visualizerFormGroup" class="polaroid form-element-visualizer">
  <div>

    <div class="eva_frm_visual_Width95prcnt" align="START">
      <div *ngIf="enableOperatorSelectors" class="float-left">
        <span class="indexes">{{ scrnIdx + 1 }}-{{ elmntIdx + 1 }}&nbsp;&nbsp;&nbsp;</span>
        <button *ngIf=false (click)="onCode(this.formControlItem)"><i class="fas fa-code"></i></button>
        <!-- <i class="fas fa-plug"></i> -->
        <button mat-icon-button class="shrinkWidthHeight" [matMenuTriggerFor]="valueOperatorMenu"
          matTooltip="Condition element value operator" [matTooltipShowDelay]="tooltipShowDelay">
          <i class="fas fa-ellipsis-h"></i>
        </button>
        <mat-menu #valueOperatorMenu="matMenu">
          <button mat-menu-item class="shrinkHeight" (click)="onValueOperator('===')">
            <span>===</span>
          </button>
          <button mat-menu-item class="shrinkHeight" (click)="onValueOperator('!==')">
            <span>!==</span>
          </button>
        </mat-menu>
        <span>&nbsp;{{valueOperator}}&nbsp;&nbsp;&nbsp;</span>
        <button mat-icon-button class="shrinkWidthHeight" [matMenuTriggerFor]="connectionOperatorMenu"
          matTooltip="Connection between condition elements" [matTooltipShowDelay]="tooltipShowDelay"
          *ngIf="formElementIndex < (formScreenElementCount - 1)">
          <i class="fas fa-ellipsis-v"></i>
        </button>
        <mat-menu #connectionOperatorMenu="matMenu">
          <button mat-menu-item class="shrinkHeight" (click)="onConnectionOperator('AND')">
            <span>AND</span>
          </button>
          <button mat-menu-item class="shrinkHeight" (click)="onConnectionOperator('OR')">
            <span>OR</span>
          </button>
        </mat-menu>
        <span>{{connectionOperator}}&nbsp;</span>
      </div>
      <div class="eva_frm_visual_Width95prcnt_element_actions">
        <button mat-button *ngIf=false (click)="onCode(this.formControlItem)" class="controlsBtn">
          <mat-icon class="controlsIcon">code</mat-icon>
        </button>
        <!-- <i class="fas fa-plug"></i> -->
        <button class="customPadding" *ngIf="enableRelation" mat-button
          (click)="onRelationForFormElement(this.formControlItem)" class="controlsBtn"
          matTooltip="Setting for the element's relation(s)" [matTooltipShowDelay]="tooltipShowDelay">
          <mat-icon class="controlsIcon">settings</mat-icon>
        </button>
        <button *ngIf="enableSetting" mat-button (click)="onSettingFormElement(this.formControlItem)" class="controlsBtn"
          matTooltip="Setting for the element's properties" [matTooltipShowDelay]="tooltipShowDelay">
          <mat-icon class="controlsIcon">edit</mat-icon>
        </button>
        <button (click)="onMoveDownFormElement(this.formControlItem)" *ngIf="formElementIndex < (formScreenElementCount - 1)"
          mat-button class="controlsBtn" matTooltip="Moves the element down" [matTooltipShowDelay]="tooltipShowDelay">
          <mat-icon class="controlsIcon">arrow_downward</mat-icon>
        </button>
        <button (click)="onMoveUpFormElement(this.formControlItem)" *ngIf='formElementIndex > 0' mat-button class="controlsBtn"
          matTooltip="Moves the element up" [matTooltipShowDelay]="tooltipShowDelay">
          <mat-icon class="controlsIcon">arrow_upward</mat-icon>
        </button>
        <button (click)="onRemoveFormElement(this.formControlItem)" mat-button class="controlsBtn"
          matTooltip="Drops the element" [matTooltipShowDelay]="tooltipShowDelay">
          <mat-icon class="controlsIcon">delete_forever</mat-icon>
        </button>
      </div>
    </div>
    <br />

    <form [formGroup]="visualizerFormGroup" class="Width95prcnt clear-left">

      <dynamic-material-form [group]="visualizerFormGroup" (dfChange)="onDynamicFormChange($event)"
        [layout]="visualizerFormLayout" [model]="visualizerFormModel">

        <ng-template let-modelId="{{this.formControlItem.id}}" class="eva_frm_visual_Width95prcnt" align="START">
          <div class="float-right">
          </div>
        </ng-template>

      </dynamic-material-form>

    </form>

  </div>
</div>