import firebase from 'firebase/compat/app';
import { environment } from "@environments/environment";
import { GenericSearchView } from "./generic-search";

/**
 * These contain the interfaces for the user preferences.
 */
export interface UserPreferences {
  speechEnabled: boolean; // whether the speech will be enabled on initial EVA load.
  name: string; // the name of the user.
  image: string; // file upload it will be a path to some storage
  voice: any; // TODO: this would enable different voices. it can be done by checking the available types on the device
  // and then the user could select and save which ones they like in order of importance.
  useUniqueKeyEachTransaction: boolean; // This is Stubbed it will be used for deterministic keys in the EVA system
  following: any; // list of people/forms/groups that the user is part of
  experimentalEva: boolean; // whether the user wants to use experimental eva.
  theming: any; // TODO: add in theming for the users.
  formJSONenabled: boolean; // TODO: set this to false and put in preferences. This would enable the form to present in JSON
  // format
  autoRefreshProcessDashboard: boolean; // whether the user wants process dashboard to auto refresh or not
  toggleEmailsProcessDashboard: boolean; // whether or not the user wants to receive process dashboard notification emails
  updatedAt?: firebase.firestore.FieldValue; // the updated at timestamp.
  createdAt?: firebase.firestore.FieldValue; // the created at timestamp.
  tutorials: PreferenceTutorials; // The tutorial that should be used.
  genericSearchView: GenericSearchView;
  isChatMinimized: boolean;
  processChatAssist: boolean; // whether selected inputs in Processes should be echoed in chat
}

/**
 * The tutorials get updated by updating the tutorial nodes in the environment variables in EVA.
 * These will be incremented by 1 when an update should change for the users and then the tutorial will be active.
 * If this number is not updated, the users will not have this tutorial started.
 */
export interface PreferenceTutorials {
  version: number; // incremented each time a new tutorial is introduced.
  tutorialList: IntroTutorials; // these are a list of tutorials that are available in the EVA Ecosystem.
}

/**
 * This is the interface for a tutorial to keep Preference Tutorials to type correctly.
 * If the tutorial hasn't been run, this is set to false.
 */
export interface IntroTutorials {
  [tutoralName: string]: boolean;
}

/**
 * This is the default user preferences to use on user create.
 */
export const defaultUserPreferences: UserPreferences = {
  speechEnabled: false,
  name: '',
  image: '',
  voice: {}, // by preference type
  useUniqueKeyEachTransaction: false,
  following: {}, // list of people/forms/groups that the user is part of
  experimentalEva: false,
  theming: {},
  formJSONenabled: false,
  autoRefreshProcessDashboard: false,
  toggleEmailsProcessDashboard: false,
  // createdAt: null,
  // updatedAt: null,
  tutorials: environment.defaultTutorials,
  genericSearchView: GenericSearchView.Tiles,
  isChatMinimized: false,
  processChatAssist: false
};
