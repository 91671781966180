import { Pipe, PipeTransform } from '@angular/core';
import { IfThenLogicOptions, LogicOption, Relation } from '@eva-model/interactionElementRelationDialogModel';
import { ConditionalObjectCheckerService } from '@eva-services/conditional-object-checker/conditional-object-checker.service';

@Pipe({
  name: 'getLogicOptions',
  standalone: true
})
export class GetLogicOptionsPipe implements PipeTransform {

  constructor(private conditionObjectCheckerService: ConditionalObjectCheckerService) {
    //
  }

  transform(relationControls: any[], relation?: Relation, controlIndex?: number, type?: 'if' | 'then', subRelationIndex?: number,
    interactionType?: 'interaction' | 'workflow', formControl?: any): string[] {
    if (interactionType === 'workflow') {
      let options = this.conditionObjectCheckerService.getLogicOptions(formControl, undefined, undefined, undefined, {})
        .map(option => option.value);
      return options;
    }
    let options = this.conditionObjectCheckerService.getLogicOptions(
      type === 'if' ? relation.ifControl[subRelationIndex] : relation.thenControl[subRelationIndex],
      relation, controlIndex, type, relation.thenControl[subRelationIndex])
      .map(option => option.value);
    if (type === 'then') {
      options = options.filter(option => option !== IfThenLogicOptions.MathEquation);
    }
    return options;
  }

}
