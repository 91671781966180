<div class="id-map-container">
  <button mat-icon-button mat-dialog-close class="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
  <h1 matDialogTitle>Map matching elements
    <button [class.disabled]="!doesPairedInteractionsHaveSameFormElementsLengthAndType()" class="map-all-button"
      (click)="mapAllElements()">Map All</button></h1>

  <mat-divider class="divider-margin"></mat-divider>

  <mat-dialog-content class="id-map-card-content">
    <div class="form-container">
      <mat-horizontal-stepper #stepperCtrl [linear]="false" #stepper="matHorizontalStepper"
        (selectionChange)="selectionStepperChange($event)" class="workflow-interaction-horizontal-stepper">
        <mat-step #currentStep *ngFor="let interaction of interactionPairs" [optional]="false"
          [class.disabled]='disableMatStep(stepperCtrl, currentStep)'>
          <form class="fullWidth form-container">
            <ng-template matStepLabel>{{interaction.old.interaction.name}} <br><i
                class="fas fa-long-arrow-alt-down"></i><br>
              {{interaction.new.interaction.name}}
            </ng-template>
            <div class="half-width">
              <div class="type-title sticky-header">
                Old Interaction : {{interaction.old.interaction.name}}
                <br/>
                Environment: {{getCurrentEnvironment()}}
              </div>
              <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight=false class="tab-form"
                *ngIf="interaction.old.interaction.FormScreens !== undefined && interaction.old.interaction.FormScreens.length > 0"
                (selectedTabChange)="onOldSelectedTabChange($event)">
                <mat-tab #oldTab
                  *ngFor="let screen of interaction.old.interaction.FormScreens; let screenIndex = index;"
                  [disabled]='disableOldMatTab(oldTab)'>
                  <ng-template mat-tab-label>
                    <div>
                      <span>{{ screen.name }}</span>
                      <button (click)="selectAllOld(screen)" class="select-all"
                        [class.disabled]="disableOldSelectAllButton(interaction, screenIndex)">
                        Select All
                      </button>
                    </div>
                  </ng-template>
                  <ul class="list-height">
                    <li class="form-element" *ngFor="let formElement of screen.FormElements; let elementIndex = index;">
                      <fieldset class="list-item" (click)="selectOldElement(screen, elementIndex)"
                        [class.disabled]="disableOldFormElement(formElement, elementIndex, screen)"
                        [class.selected]="screen.FormElements[elementIndex].selected">
                        <legend class="list-item-legend">ID: {{formElement.id}}</legend>
                        Name: {{formElement.label}} <br />
                        Type: {{formElement.type}} <br />
                        {{formElement.inputType ? 'Input Type: ' + formElement.inputType : null }}
                      </fieldset>
                    </li>
                  </ul>
                </mat-tab>
              </mat-tab-group>
            </div>
            <span class="vertical-border"></span>
            <div class="half-width">
              <div class="type-title sticky-header">
                New Interaction : {{interaction.new.interaction.name}}
                <br/>
                Environment: {{dialogData.dynFormMdl.environment || ''}}
              </div>
              <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight=false class="tab-form"
                *ngIf="interaction.new.interaction.FormScreens !== undefined && interaction.new.interaction.FormScreens.length > 0"
                (selectedTabChange)="onNewSelectedTabChange($event)">
                <mat-tab #newTab
                  *ngFor="let screen of interaction.new.interaction.FormScreens; let screenIndex = index;"
                  [disabled]='disableNewMatTab(newTab)'>
                  <ng-template mat-tab-label>
                    <div>
                      <span>{{ screen.name }}</span>
                      <button (click)="selectAllNew(screen)" class="select-all"
                        [class.disabled]="disableNewSelectAllButton(interaction, screenIndex)">
                        Select All
                      </button>
                    </div>
                  </ng-template>
                  <ul class="list-height">
                    <li class="form-element" *ngFor="let formElement of screen.FormElements; let elementIndex = index;">
                      <fieldset class="list-item" (click)="selectNewElement(screen, elementIndex)"
                        [class.selected]="screen.FormElements[elementIndex].selected"
                        [class.disabled]="disableNewFormElement(formElement, elementIndex, screen)">
                        <legend class="list-item-legend">ID: {{formElement.id}}</legend>
                        Name: {{formElement.label}} <br />
                        Type: {{formElement.type}} <br />
                        {{formElement.inputType ? 'Input Type: ' + formElement.inputType : null }}
                      </fieldset>
                    </li>
                  </ul>
                </mat-tab>
              </mat-tab-group>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="pairs" color="primary"
      [class.disabled]="!isValid()">Submit</button>
    <button mat-stroked-button
      [class.disabled]="disablePreviousButton(stepper, currentStep)"
      (click)="stepper.previous()">Previous</button>
    <button mat-stroked-button
      [class.disabled]="disableNextButton(stepper, currentStep)"
      (click)="stepper.next()">Next</button>
    <button mat-stroked-button (click)="onReset()">Reset</button>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>