<!--- Start - Search is Loading --->
<div *ngIf="loadingToken" style="text-align:center;">
    <br/><br/><br/>
    <mat-spinner diameter="48" style="margin: 2rem auto"></mat-spinner>
    <h4>Loading Search...</h4>
</div>
<!--- End - Search is Loading --->
<!--- Start - Search has Loaded (Token is acquired from API) --->
<div *ngIf="!loadingToken" #genericSearch class="generic-search">
    <!--- Start - Search Token is expired --->
    <div *ngIf="currentToken.validUntil | timestampPast | async" class="token-expired-overlay">
        <div class="token-expired-message">
            <h3>Search Paused</h3>
            <p>To continue, click the button below</p>
            <button mat-raised-button color="primary" (click)="createSearchConfig()"><mat-icon>play_arrow</mat-icon> Resume Search</button>
        </div>
    </div>
    <!--- End - Search Token is expired --->
    <app-page-header [title]="_searchTitle" [subTitle]="_searchSubTitle" [hideSearchTitle]="!_showSearchTitle" [hideSearchSubTitle]="!_showSearchSubTitle"></app-page-header>
    <div *ngIf="!showSearchComponent" style="text-align:center">
        <mat-spinner diameter="48" style="margin: 2rem auto"></mat-spinner>
    </div>
    <!-- <ais-instantsearch #instantSearchRef [config]="_searchConfig" (change)="onSearchChange($event)"> -->
        <!-- <ais-configure [searchParameters]="_searchParams"></ais-configure> -->
        <div class="search-and-filter-container" [class.full-search-width]="_hideViewToggle">
            <!-- <ais-search-box *ngIf="!_customSearchQuery$" [placeholder]="exactSearchQuery ? 'Exact Search' : 'Search'"></ais-search-box> -->
            <mat-form-field class="generic-search-query-form-field" appearance="outline" *ngIf="!_customSearchQuery$">
                <mat-label>{{ exactSearchQuery ? 'Exact Search' : 'Search' }}</mat-label>
                <input matInput type="text" [(ngModel)]="searchQuery" (ngModelChange)="searchInputChanged($event)" autocomplete="off">
                <button *ngIf="searchQuery" matSuffix mat-icon-button aria-label="Clear" (click)="searchQuery=''; doSearch('')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <div class="search-views">
                <mat-button-toggle-group *ngIf="!_hideViewToggle" #group="matButtonToggleGroup" [value]="_currentView" (change)="changeView($event.value)">
                    <mat-button-toggle [value]="GenericSearchView.Tiles" aria-label="Tiles View">
                        <mat-icon>view_module</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle [value]="GenericSearchView.Grid" aria-label="List View">
                        <mat-icon>view_list</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div *ngIf="_showExactTermSearchToggle" class="filters-checkboxes">
            <mat-checkbox [(ngModel)]="exactSearchQuery" (change)="toggleExactSearch($event)">Exact Term Search</mat-checkbox>
        </div>
        <div class="filters" *ngIf="_searchFilters">
            <app-generic-search-filter
                *ngFor="let filter of _searchFilters"
                [selectedFilter]="filter"
                (_selectedFilterChange)="filterSearch($event)">
            </app-generic-search-filter>
            <!-- <div class="spacer" style="width:100%">&nbsp;</div> -->
            <mat-divider></mat-divider>


            <!-- old -->
            <!-- <div *ngFor="let filter of _searchFilters" class="filter-container">
                <div *ngFor="let subFilters of filter.filterName; let i = index;"
                    class="subFilter-container"
                    [class.filter-disabled]="filter.disabled"
                    [class.filter-hidden]="filter.hidden"
                    [class.filter-default]="!filter.defaultValue || filter.defaultValue.length === 0 || (filter.defaultValue.length > 0 && !(i | isValidFilterValue:filter))"
                    [class.filter-selected]="filter.defaultValue && filter.defaultValue.length > 0 && (i | isValidFilterValue:filter)">
                    <div class="generic-search-filter-container"
                        *ngIf="selectedFilter && selectedFilter === filter && selectedFilterIndex === i">
                        <app-generic-search-filter [_selectedFilter]="selectedFilter" [_index]="i"
                            (_selectedFilterChange)="filterSearch($event)"></app-generic-search-filter>
                    </div>
                    <div class="filter-item" (click)="openFilterDialog(filter, i)">
                        <span class="filter-name">{{filter.filterName[i]}}</span>
                        <span class="filter-name" *ngIf="(i | isValidFilterValue:filter)">: </span>
                        <span *ngIf="(i | isValidFilterValue:filter)" class="filter-value">{{ filter.defaultValue | getFilterValue:filter:i }}</span>
                        <span class="filter-arrow">
                            <i class="fa fa-angle-down arrow-down"
                                [class.arrow-up]="selectedFilter && selectedFilter === filter && selectedFilterIndex === i"
                                aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div> -->
        </div>
        <mat-paginator
            [length]="_results ? _results.nbHits : 0"
            [pageSize]="_searchResultsPerPage"
            [pageIndex]="_searchResultsCurrentPage"
            [pageSizeOptions]="[20, 50, 200, 500, 1000]"
            (page)="paginatorPageChange($event)"
            aria-label="Select page">
        </mat-paginator>
        <div #newHitsContainer id="algoliaHitsContainer"></div>
        <div *ngIf="_currentView === GenericSearchView.Tiles">
            <div id="result-container" class="result-container" [style.grid-template-columns]="_searchCardMaxColumns ? '100%' : null">
                <ng-container
                    *ngFor="let hit of _hits"
                    [ngTemplateOutlet]="cardTemplate || defaultCardTemplate"
                    [ngTemplateOutletContext]="{ $implicit: hit }"
                ></ng-container>
                <ng-template #defaultCardTemplate let-hit>
                    <div class="search-result" #tooltip="matTooltip" data-default-card-template="true" 
                        [matTooltip]="_searchResultTooltipProperty ? (hit[_searchResultTooltipProperty + '-modified'] && hit[_searchResultTooltipProperty + '-modified'] !== '') ? hit[_searchResultTooltipProperty + '-modified'] : (hit[_searchResultTooltipProperty] && hit[_searchResultTooltipProperty] !== '') ? hit[_searchResultTooltipProperty] : hit[_searchResultTitleProperty] : hit[_searchResultTitleProperty]" matTooltipShowDelay="500" matTooltipPosition="above"
                        [class.search-result-link]="_clickableSearchResults" [class.has-actions]="actionTemplate" (click)="openSearchResultItem(hit)">
                        <h4 class="search-result-title">{{_showTitlePropertyName ? _searchResultTitleProperty : ''}}
                            {{hit[_searchResultTitleProperty]}}</h4>
                        <p class="search-result-subtitle">{{_showSubTitlePropertyName ? _searchResultSubTitleProperty : ''}}
                            {{hit[_searchResultSubTitleProperty]}}</p>
                        <div class="search-result-content" *ngFor="let property of (hit|getProperties:_propertyNameMapping:_searchResultTitleProperty:_searchResultSubTitleProperty)">
                            <span [class.search-result-content-property]="_showContentPropertyName"
                                *ngIf="_showContentPropertyName">{{_showContentPropertyName ? (property | getPropertyUserFriendlyName:_propertyNameMapping) : ''}}</span><span
                                *ngIf="!(hit | isValidArray:property)"
                                [class.search-result-content-value]="_showContentPropertyName"
                                [class.search-result-content-value-full]="!_showContentPropertyName">{{ hit | getPropertyValue:property:_propertyNameMapping }}</span>
                            <span *ngIf="(hit | isValidArray:property)"
                                [class.search-result-content-value]="_showContentPropertyName"
                                [class.search-result-content-value-full]="!_showContentPropertyName">
                                <mat-select placeholder="Select a version" (selectionChange)="onSelectionChange($event, hit, property)" [value]="hit | getPropertyValue:property:_propertyNameMapping"
                                    class="select-version">
                                    <mat-option *ngFor="let version of hit[property + '-modified'] ? hit[property + '-modified'] : hit[property]"
                                        [value]="version.version.toString()">
                                        {{('' + hit.activeVersion === '' + version.version) ? 'Active :: ' : ''}} {{version.version | date:'full'}}
                                    </mat-option>
                                </mat-select>
                            </span>
                        </div>
                        <div class="search-result-actions" *ngIf="actionTemplate">
                            <ng-container *ngTemplateOutlet="actionTemplate;context:{hit: hit, additionalActionTemplateData: _additionalActionTemplateData}"></ng-container>
                        </div>
                    </div>
                </ng-template>  
            </div>
        </div>
        <div *ngIf="_currentView === GenericSearchView.Grid">
            <ag-grid-angular
                #agGrid
                class="ag-grid-container ag-theme-material"
                [rowData]="_hits"
                [rowHeight]="_rowHeight"
                [columnDefs]="_columnDefs"
                [context]="_agGridContext"
                [defaultColDef]="_defaultColumnDef"
                [frameworkComponents]="_frameworkComponents"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
                (firstDataRendered)="onFirstDataRendered($event)"
                (filterChanged)="onFilterChange($event)"
                (columnResized)="onColumnResized()"
                >
            </ag-grid-angular>
        </div>
        <!-- <ais-pagination
            [padding]="_searchResultPaginationPadding"
            [showFirst]="_showSearchResultPaginationShowFirstButton"
            [showLast]="_showSearchResultPaginationShowLastButton"
        ></ais-pagination> -->
        <mat-paginator
            [length]="_results ? _results.nbHits : 0"
            [pageSize]="_searchResultsPerPage"
            [pageIndex]="_searchResultsCurrentPage"
            [pageSizeOptions]="[20, 50, 200, 500, 1000]"
            (page)="paginatorPageChange($event)"
            aria-label="Select page">
        </mat-paginator>
    <!-- </ais-instantsearch> -->
</div>