import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {SubscriptionLike as ISubscription, Observable, Subscription, Subject} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {GeneralDialogService} from '@eva-services/general-dialog/general-dialog.service';
import {SigningService} from '@eva-core/signing.service';

import {Guid} from '@eva-core/GUID/guid';
import {MessageService, SelectItem} from 'primeng/api';

import {GeneralDialogModel} from '@eva-model/generalDialogModel';
import {EntityPickerDialogComponent} from '@eva-ui/form-builder/entity-picker-dialog/entity-picker-dialog.component';

import {ProcessPickerDialogComponent} from './process-picker-dialog/process-picker-dialog.component';
import {ProcessService} from '@eva-services/process/process.service';
import {GeneralDialogComponent} from '@eva-ui/general-dialog/general-dialog.component';

import {ActivatedRoute, Router} from '@angular/router';
import {EvaGlobalService} from '@eva-core/eva-global.service';
import {AuthService} from '@eva-core/auth.service';
import {UserService} from '@eva-services/user/user.service';
import {ClipboardService} from 'ngx-clipboard';
import {entityPickType, entityType} from '@eva-model/entity';
import {Process, ProcessSummaryMessage} from '@eva-model/process/process';
import {ChatProcessService} from '@eva-services/chat/process/chat-process.service';
import {filter, take, tap} from 'rxjs/operators';
import {AngularFirePerformance, trace} from '@angular/fire/compat/performance';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { DialogService } from '@eva-ui/guard/dialog.service';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { DataStorageService } from '@eva-core/storage/data-storage.service';
import { EntityType, Log, LogLevel } from '@eva-model/log';
import { LogService } from '@eva-core/log/log.service';
import { ChatEntityAuthor, ChatEntityType } from '@eva-model/chat/chat';
import { LoggingService } from '@eva-core/logging.service';

@Component({
  selector: 'eva-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss'],
  providers: [MessageService]
})
export class ProcessComponent implements OnInit, OnDestroy {

  isWaiting = false;
  onWaitMessage: string = null;

  isWaitingForWorkflow  = false;
  onWaitForWorkflowMessage: string = null;

  isWaitingToFetchProcess = false;
  onWaitToFetchProcessMessage: string = null;

  frmBuilderGrp: UntypedFormGroup;
  tabIndex: number;

  user: any;
  userId: any;
  userGroupName: string = null;
  userPublicKey: string;

  userGroupSubs: ISubscription;
  entitySubs: ISubscription;
  generalDialogChangeSubs: ISubscription;
  generalDialogChangeSubscription: ISubscription;
  processDoneSubs: ISubscription;
  processSummaryDoneSubs: ISubscription;
  routerParameterSub: ISubscription;
  authUserSubs: ISubscription;
  snackBarSubs: ISubscription;
  componentSubs = new Subscription();

  actions: SelectItem[];
  selectedAction: string;

  separatorKeysCodes = [ENTER, COMMA];

  process: Process;
  isCreateProcess = false;
  isPickProcess = false;
  isProcessReadyToRun = false;
  isProcessSummary = false;

  routerProcessId: any;
  processIdFromInput: string;
  componentTitle: string;

  uniqueTabId: string;
  updatedFromLastState: boolean;

  @Input()
  set processId(processIdFromInput) {
    this.processIdFromInput = processIdFromInput;
  }

  @Input() targetId?: string;
  @Input() hideProcessTitleBar: boolean;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private generalDialogService: GeneralDialogService,
    private processService: ProcessService,
    private signingService: SigningService,
    public evaGlobalService: EvaGlobalService,
    private evaAuthService: AuthService,
    private userService: UserService,
    private _clipboardService: ClipboardService,
    private chatProcessService: ChatProcessService,
    private perf: AngularFirePerformance,
    private messageService: MessageService,
    public dialogService: DialogService,
    private multiViewService: MultiViewService,
    private dataStorageService: DataStorageService,
    private loggingService: LoggingService) {
    this.route.data.subscribe(data => {
      this.componentTitle = data.componentTitle;
    });

      this.authUserSubs = this.evaAuthService.user.subscribe(user => {
        if (user) {
          this.user = user;
          this.userId = user.uid;
        }
      });

      this.createForm();
  }

  ngOnInit() {
    const that = this;

    if ( this.route.snapshot.params && this.route.snapshot.params.msg) {

      this.messageService.clear();
      this.messageService.add({
        life: 5500,
        severity: 'error',
        summary: this.route.snapshot.params.msg,
        detail: ``
      });
    }

    this.actions = [
      {label: 'Pick a Process', value: 'pickProcess', icon: 'fas fa-cloud-download-alt'},
      {label: 'Create a Process', value: 'createProcess', icon: 'fas fa-cloud-upload-alt'}
    ];

    this.processDoneSubs = this.processService.processDone$.pipe(
      trace('process-ngOnInit-processDone')
    )
    .subscribe(
      (prcsDoneObj) => {
        if ( that.process && prcsDoneObj && prcsDoneObj.processId && that.process.id === prcsDoneObj.processId ) {
          this.processClearance({ that: that, msg: null });
        }
      },
      (err) => {
        console.log(err);
        throw err;
      },
      () => {
      }
    );

    this.processSummaryDoneSubs =
      this.processService.processSummaryDone$.pipe(
        trace('process-ngOnInit-processSummaryDone')
      )
      .subscribe(
        (prcsDoneObj: ProcessSummaryMessage) => {
          if ( that.process && prcsDoneObj && prcsDoneObj.processId && that.process.id === prcsDoneObj.processId &&
            (!prcsDoneObj.processRunnerId || prcsDoneObj.processRunnerId === '') ) {
            that.isProcessSummary = false;
          }
        },
        (err) => {
          console.log(err);
          throw err;
        },
        () => {
        }
      );

    that.routerParameterSub =
      that.route.params.pipe(
        take(1)
      ).subscribe(
        (params) => {
          if (params['id']) {
            that.routerProcessId = params['id'];
          } else {
            that.routerProcessId = that.processIdFromInput;
          }

          if ( that.routerProcessId ) {
            that.tryToFetchProcess(that.routerProcessId);
          }
        }
      );

      this.componentSubs.add(this.multiViewService.closeTab$.pipe(
        filter((closeTab) => closeTab && closeTab.entityType === 'Process'
          && this.uniqueTabId === closeTab.entityId),
        filter((closeTab) => this.tabIndex === closeTab.tabIndex)
      ).subscribe(closeTab => {
        this.cancelProcessConfirm(closeTab.closeSubject);
      }));

  }

  /**
   * Gets the process by id and then sets process variable and other state variables for view.
   *
   * @param processId id of process
   */
  private async tryToFetchProcess(processId: string): Promise<void> {
    const that = this;

    this.isWaitingToFetchProcess = true;
    this.onWaitToFetchProcessMessage = `Fetching process with id # ${processId}`;

    // First check if the process exists in memory.
    // const processInMemory = this.chatProcessService.getProcess( processId );
    const processInMemory = false; // TODO: Remove this code

    if ( processInMemory ) { // Process exists in memory
      that.process = processInMemory;
      console.log('process was in memory', this.process);
    } else { // Process does not exist in memory, get it and decrypt.
      try {
        if (this.multiViewService.tabs[Routes.Process]?.[that.tabIndex]?.additionalInstanceData?.process) {
          that.process = this.multiViewService.tabs[Routes.Process][that.tabIndex].additionalInstanceData.process;
        } else {
          this.processService.fetchProcess(processId).subscribe(async processData => {
            if ( processData ) {
              const process = processData;
              const storageId = process.storageId;
              const lastUpdatePublicKey = process.lastUpdatePublicKey;
              if (storageId && storageId !== '') {
                const lastUpdatedByMe = ( this.evaGlobalService.userPublicKey === lastUpdatePublicKey ) ? true : false;

                try {
                  const decryptedObject = await this.dataStorageService.decryptData(storageId, lastUpdatedByMe);
                  if (decryptedObject.status === 'OK' && decryptedObject.unencryptedObject) {
                    process.name = decryptedObject.unencryptedObject.name;
                    process.interactionsValues = decryptedObject.unencryptedObject.interactionsValues;
                    process.notes = decryptedObject.unencryptedObject.notes;
                    process.descriptions = decryptedObject.unencryptedObject.descriptions;
                  } else {
                    throw new Error('Error in fetching process.');
                  }
                } catch ( err ) {
                  throw new Error('Error in fetching a process');
                }
              }
              that.process = process;
              if (this.multiViewService.tabs[Routes.Process] && this.multiViewService.tabs[Routes.Process][this.tabIndex]) {
                this.multiViewService.updateTabsAndSaveToLastState(Routes.Process, 'Update', {
                  tabName: that.process && that.process.workflows && that.process.workflows.length > 0
                    ? that.process.workflows[0].name + ' - ' + that.process.name : 'Error'
                }, this.tabIndex);
              }
            }
          }, () => {});
        }
      } catch (err) {
        that.isWaitingToFetchProcess = false;
        that.isProcessReadyToRun = true;
        console.log( err );
        if (this.multiViewService.tabs[Routes.Process] && this.multiViewService.tabs[Routes.Process][this.tabIndex]) {
          this.multiViewService.updateTabsAndSaveToLastState(Routes.Process, 'Update', {
            tabName: 'Failed to fetch the process. Please refresh the window to retry'
          }, this.tabIndex);
        }
        return Promise.reject( err );
      }
    }

    if (this.multiViewService.tabs[Routes.Process] && this.multiViewService.tabs[Routes.Process][this.tabIndex] && that.process) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.Process, 'Update', {
        tabName: that.process && that.process.workflows && that.process.workflows.length > 0
          ? that.process.workflows[0].name + ' - ' + that.process.name : 'Failed to fetch the process. Please refresh the window to retry'
      }, this.tabIndex);
    }

    // Did not fail, do rest of setup
    that.isWaitingToFetchProcess = false;
    that.isProcessReadyToRun = true;
    that.isCreateProcess = false;
  }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.
    if (this.generalDialogChangeSubs) {
      this.generalDialogChangeSubs.unsubscribe();
    }

    if (this.generalDialogChangeSubscription) {
      this.generalDialogChangeSubscription.unsubscribe();
    }

    if (this.userGroupSubs) {
      this.userGroupSubs.unsubscribe();
    }

    if (this.entitySubs) {
      this.entitySubs.unsubscribe();
    }

    if (this.processDoneSubs) {
      this.processDoneSubs.unsubscribe();
    }

    if ( this.routerParameterSub ) {
      this.routerParameterSub.unsubscribe();
    }

    if ( this.authUserSubs ) {
      this.authUserSubs.unsubscribe();
    }

    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
  }

  createForm() {
    this.frmBuilderGrp = this.fb.group({
      name: ['', Validators.required],
      userGroup: ['', Validators.required],
      selectedAction: [''],
      workflow_name: [''],
      workflow_version: [''],
      workflow_description: ['']
    });
  }

  canDeactivate = (): Observable<boolean> => {
    return this.dialogService.confirm('Are you sure you want to exit? Any unsaved changes will be lost.');
  }

  cancelProcessConfirm(closeSubject: Subject<boolean>) {
    if (!this.hideProcessTitleBar) {
      return this.processExitEvent(closeSubject);
    }
    const dialogData = new GeneralDialogModel(
      'Process Cancellation Dialog', this.process ? `Do you want to cancel this process ? <br> ::
      ${this.process.workflows[0].name} - ${this.process.name} ::` : 'Do you want to cancel the process?', 'Yes', 'No');
    this.openGeneralDialog(dialogData, this.cancelProcess, { that: this, closeSubject }, () => closeSubject.next(false));
  }

  cancelProcess(data) {
    data.closeSubject.next(true);
    data.that.processService.announceProcessCancel();
    data.that.processDoneBySubmitter( data.that.process
      ? `Process "${data.that.process.workflows[0].name} - ${data.that.process.name}" is cancelled! `
      : `Process is cancelled!`);
  }

  processDoneBySubmitter = (message: string) => {
    if (message) {
      // this.chatService.newChatEntity({
      //   author: ChatEntityAuthor.EVA,
      //   type: ChatEntityType.Text,
      //   text: message
      // });
      this.loggingService.logMessage(message, false, 'info', null, 5000);
    }
  }

  onActionSelect(ev) {
    // TODO :: if a process is in progress in currently in EVA, a confirmation dialog to notify user that it will be erased would be good
    this.isCreateProcess = (ev.option.value === 'createProcess');
    if ( this.isCreateProcess ) this.isProcessReadyToRun = false;
    this.isPickProcess = !this.isCreateProcess;

    if ( this.isCreateProcess ) this.initializeNewProcess();
    else this.processPickerEvent();
  }

  initializeNewProcess() {
    this.process = this.processService.initializeNewProcess();
  }

  onProcessNameChange(ev) {
    this.process.name = ev.target.value;
  }

  onGroupSelectionChange(ev) {
    this.process.groupPublicKey = ev.value;
    this.userGroupName = this.evaGlobalService.getGroupNameByPublicKey(this.process.groupPublicKey);
  }

  getGroupNameByPublicKey(groupPk: string) {
    const grpIndex = this.evaGlobalService.userGroups.map(grp => grp.groupPublicKey).indexOf(groupPk);
    return (grpIndex !== -1) ? this.evaGlobalService.userGroups[grpIndex].groupName : null;
  }

  isProcessNotValid() {
    const isProcessValid = this.process &&
      this.process.name &&
      this.process.groupPublicKey &&
      this.process.submitterPublicKey &&
      this.process.workflows &&
      Array.isArray(this.process.workflows) &&
      this.process.workflows.length > 0;

    return !isProcessValid;
  }

  cancelCreatingProcess() {
    this.process = null;
    this.isCreateProcess = false;
  }

  saveUserNewProcess() {
    const that = this;
    if (!this.process.id) { this.process.id = Guid.newGuid().toString(); }

    this.isWaiting = true;
    this.onWaitMessage = "Saving process object ...";
    that.messageService.clear();

    this.process.lastUpdateTimestamp = ( !this.process.lastUpdateTimestamp ) ? this.process.createTimestamp : Date.now();
    // TODO: update last state processes collection here
    // this.userService.userProcessUpsert(this.process, false)
    // .then( () => {
    //   that.messageService.add({
    //     life: 5500,
    //     severity: 'info',
    //     summary: "Process submitted and saved",
    //     detail: `Process ${that.process.name} with latest update as ${(new Date(that.process.lastUpdateTimestamp)).toLocaleString()}`
    //     });

    //   that.isWaiting = false;
    //   this.isCreateProcess = false;
    // })
    // .catch( (err) => {
    //   that.messageService.add({
    //     life: 5500,
    //     severity: 'error',
    //     summary: "Process submission failed",
    //     detail: `Process ${that.process.name} failed to be recorded :: ERROR :: ${err}`
    //   });

    //   this.isWaiting = false;
    // });
  }
  removeProcessDescriptionConfirm() {
    return;
  }

  addProcessDescription() {
    return;
  }

  //#region Process picker to start perocessing the process
  processPickerEvent() {

    const dialogData = new GeneralDialogModel(
      'Process Picker dialog',
      `You may choose a group then a process`,
      'Done', 'Cancel', null,
      {
        "entityType": 'process'
      }
    );

    this.openProcessPickerDialog(dialogData, this.pickProcess, { that: this });
  }

  pickProcess(data: any): void {
    if (!data || !data.that || !data.generalDialogOnChange) { return; }

    if (data.generalDialogOnChange.groupublicKey &&
      data.generalDialogOnChange.entityId ) {

      const processId = data.generalDialogOnChange.entityId;

      data.that.isWaiting = true;
      data.that.onWaitMessage = `Fetching process ${processId}`;

      // data.that.userService.fetchUserProcessAndDecrypt(processId)
      // .then( (processData) => {
      //     data.that.process = processData;

      //     data.that.isProcessReadyToRun = true;
      //     data.that.isWaiting = false;
      // })
      // .catch( (err) => {
      //     data.that.isWaiting = false;
      //     console.log(err);
      //   }
      // );

    }
  }
  //#endregion

  //#region Workflow picker to add to a new process
  workflowPickerEvent() {

    const dialogData = new GeneralDialogModel(
      'Workflow Picker dialog',
      `You may choose a group then a workflow and next its version to pick the workflow`,
      'Done', 'Cancel', null,
      {
        "userGroups": JSON.parse(JSON.stringify(this.evaGlobalService.userGroups)), // { "userGroups": Object.assign({}, this.userGroups) };
        "entityType": entityType.workflow,
        "pickType": entityPickType.justActive
      }
    );

    this.openEntityPickerDialog(dialogData, this.pickWorkflow, { that: this });
  }

  pickWorkflow(data: any): void {
    if (!data || !data.that || !data.generalDialogOnChange) { return; }

    if (data.generalDialogOnChange.groupublicKey &&
      data.generalDialogOnChange.entityId &&
      data.generalDialogOnChange.entityVersion) {

      const workflowId = data.generalDialogOnChange.entityId;
      const version = data.generalDialogOnChange.entityVersion;

      data.that.isWaitingForWorkflow = true;
      data.that.onWaitForWorkflowMessage = `Fetching the workflow`;   // `Fetching workflow ${workflowId} version ${versionToDate}`

      data.that.entitySubs = data.that.workflowService.fetchWorkflowsByIdAndVer(workflowId, version)
        .subscribe(
          (workflowData) => {
            const workflowForProcess = data.that.workflowService.workflowObjectMapper(workflowData);
            if ( workflowForProcess && data.that.process) {
              if (!data.that.process.workflows || !Array.isArray(data.that.process.workflows)) data.that.process.workflows = [];
              data.that.process.workflows.push(workflowForProcess);

              const patchObj = {
                userGroup: workflowForProcess.groupPublicKey,
                workflow_name: workflowForProcess.name,
                workflow_version: new Date(version).toLocaleString(),
                workflow_description: workflowForProcess.descriptions
              };

              data.that.frmBuilderGrp.patchValue(patchObj);
              data.that.process.groupPublicKey = workflowForProcess.groupPublicKey;
            }
          },
          (err) => { data.that.isWaitingForWorkflow = false; console.log(err); },
          () => { data.that.isWaitingForWorkflow = false; }
        );
    }
  }
  //#endregion

  //#region Dialog related functions
  private openEntityPickerDialog(dialogModel: GeneralDialogModel, callback, callbackData: any) {
    const dialogRef = this.dialog.open(EntityPickerDialogComponent, { data: dialogModel });

    this.generalDialogChangeSubs = this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
            callbackData['generalDialogOnChange'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }

  private openProcessPickerDialog(dialogModel: GeneralDialogModel, callback, callbackData: any) {
    const dialogRef = this.dialog.open(ProcessPickerDialogComponent, { data: dialogModel });

    this.generalDialogChangeSubs = this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
            callbackData['generalDialogOnChange'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }

  private openGeneralDialog(dialogModel: GeneralDialogModel, callback, callbackData: any, callbackForFalse = null) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogModel
    });

    this.generalDialogChangeSubscription = this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid threwing error in case of dialogs which don't have callback functiona nd callback data.
            callbackData['generalDialogOnChange'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      } else {
        if (callbackForFalse) {
          callbackData.result = false;
          callbackForFalse(callbackData);
        }
      }
    });
  }
  //#endregion

  //#region Canceling the running process
  processExitEvent(closeSubject?: Subject<boolean>) {
    if (!this.process) {
      this.messageService.clear();
      this.messageService.add({
        life: 5500,
        severity: 'info',
        summary: `No process exist`,
        detail: ''
      });

      if (closeSubject) {
        setTimeout(() => {
          closeSubject.next(true);
        }, 0);
      }

      return;
    }

    const dialogData = new GeneralDialogModel(
      'Process exit dialog', `Are you sure you want to exit the process " ${this.process.name} " ?`, 'Yes', 'No');
    this.openGeneralDialog(dialogData, this.processClearance, { that: this, msg: "Exited the process - " + this.process.name,
      closeSubject });
  }

  private processClearance(data: any) {
    data.that.process = null;
    data.that.isProcessReadyToRun = false;

    data.that.growl_msgs = [];
    data.that.growl_msgs.length = 0;

    data.that.growl_msgs.push({
      severity: 'info',
      summary: data.msg,
      detail: ''
    });

    if (data.msg) {
      console.log('newChatEntity', {
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: data.msg
      });
      data.that.chatService.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: data.msg
      });
    }

    if (data.closeSubject) {
      setTimeout(() => {
        data.closeSubject.next(true);
      }, 0);
    }
  }
  //#endregion

  openSnackBarForId() {
    if ( !this.process ) return;

    const snackBarRef =
      this.snackBar.open(`Dynamic Process Id is ${this.process.id}`, 'Copy', { duration: 6000, verticalPosition: 'top' });

    this.snackBarSubs = snackBarRef.onAction()
    .subscribe(
      () => {
        this._clipboardService.copyFromContent(this.process.id);
        snackBarRef.dismiss();
      }
    );
  }

  isSummary() {
    return (
      this.process &&
      this.process.interactionsValues &&
      Array.isArray(this.process.interactionsValues) &&
      this.process.interactionsValues.length > 0);
  }

  toggleProcessSummary() {
    this.isProcessSummary = !this.isProcessSummary;
  }

}
