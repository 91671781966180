import { Pipe, PipeTransform } from '@angular/core';
import { IfThenLogicAction, SubRelation } from '@eva-model/interactionElementRelationDialogModel';
import { ConditionalObjectCheckerService } from '@eva-services/conditional-object-checker/conditional-object-checker.service';
import { DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER, DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT } from '@ng-dynamic-forms/core';

@Pipe({
  name: 'getLogicActions',
  standalone: true
})
export class GetLogicActionsPipe implements PipeTransform {

  constructor(private conditionObjectCheckerService: ConditionalObjectCheckerService) {}

  transform(relationControls: any[], subRelation: SubRelation): string[] {
    return this.conditionObjectCheckerService.getLogicActions().map(option => option.value).filter(option => {
      if (option === IfThenLogicAction.MathEquation) {
        if (subRelation?.then?.model?.['inputType'] === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT
        || subRelation?.then?.model?.['inputType'] === DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
  }

}
