/**
 * Default base launch pad menu. All users see this menu, however they can adjust order of the menu.
 */
import { LaunchPadEntityType, LaunchPadMenuItem, LaunchPadMenuItemGroup } from "@eva-model/menu/menu";
import { knowledgeSVGIcon, aboutSVGIcon, userSVGIcon, processDashboardSVGIcon } from "./icons";

/**
 * Default builder items menu
 */
export const LaunchPadDefaultItems: LaunchPadMenuItem[] = [
    // {
    //     id: 'fVzR9T0cZNs8Cp3NL14T',
    //     groupId: '32EPUhyJ5mhctyp1ubRH',
    //     type: LaunchPadEntityType.Route,
    //     data: `{"text": "Interactions", "routerLink": "/builders/Interaction"}`,
    //     icon: interactionBuilderSVGIcon,
    //     title: 'Interactions'
    // },
    // {
    //     id: 'JHlJs7u9vDach9nuTV4Y',
    //     groupId: '32EPUhyJ5mhctyp1ubRH',
    //     type: LaunchPadEntityType.Route,
    //     data: `{"text": "Workflows", "routerLink": "/builders/Workflow"}`,
    //     icon: workflowBuilderSVGIcon,
    //     title: 'Workflows'
    // },
    // {
    //     id: 'SefJ5ViLwv0NI5gO7Jr4',
    //     groupId: 'Vb13EfGSY1Yob8Oc2UG8',
    //     type: LaunchPadEntityType.Route,
    //     data: `{"text": "Knowledge", "routerLink": "/knowledge"}`,
    //     icon: knowledgeSVGIcon,
    //     title: 'Knowledge'
    // },
    // {
    //     id: 'mjOC2a54WtUof7sHqj73',
    //     groupId: 'Vb13EfGSY1Yob8Oc2UG8',
    //     type: LaunchPadEntityType.Route,
    //     data: `{"text": "Machine Learning", "routerLink": "ai/machineLearning"}`,
    //     icon: machineLearningSVGIcon,
    //     title: 'Machine Learning'
    // },
    // {
    //     id: '6NogfLrDQ3hJ9ForNTdK',
    //     groupId: 'yHgZEivJ7zFmbORuc32x',
    //     type: LaunchPadEntityType.Route,
    //     data: `{"text": "Process", "routerLink": "/dashboards/Process"}`,
    //     icon: processDashboardSVGIcon,
    //     title: 'Process'
    // },
    // {
    //     id: 'nBqxLZ1lHqjVTHmbeoij',
    //     groupId: 'emQf0Kp2nP26yahcxKhc',
    //     type: LaunchPadEntityType.Route,
    //     data: `{"text": "Search", "routerLink": "/group"}`,
    //     icon: aboutSVGIcon,
    //     title: 'Search'
    // },
    // {
    //     id: '7uCAR7Bl7NG3szf31QSZ',
    //     groupId: 'emQf0Kp2nP26yahcxKhc',
    //     type: LaunchPadEntityType.Route,
    //     data: `{"text": "My Groups", "routerLink": "/mine"}`,
    //     icon: userSVGIcon,
    //     title: 'My Groups'
    // },
    // {
    //     id: 'RAf3Ui7g8LwPRVu4efwt',
    //     groupId: 'uBRpQy9xHxlw1PQ2AMRv',
    //     type: LaunchPadEntityType.Route,
    //     data: `{"text": "Preferences", "routerLink": "/user/preferences"}`,
    //     icon: userSVGIcon,
    //     title: 'Preferences'
    // }
];

/**
 * Default launch pad groups
 */
export const LaunchPadDefaultGroups: LaunchPadMenuItemGroup[] = [
    {
        id: 'pinned',
        title: 'Pinned',
        items: []
    },
    {
        id: 'ji864Yx1Sfk67MMn0G8I',
        title: 'Your Items',
        items: []
    },
    // {
    //     id: '32EPUhyJ5mhctyp1ubRH',
    //     title: 'Builders',
    //     items: []
    // },
    {
        id: 'Vb13EfGSY1Yob8Oc2UG8',
        title: 'AI',
        items: []
    },
    {
        id: 'yHgZEivJ7zFmbORuc32x',
        title: 'Dashboards',
        items: []
    },
    {
        id: 'emQf0Kp2nP26yahcxKhc',
        title: 'Groups',
        items: []
    },
    {
        id: 'uBRpQy9xHxlw1PQ2AMRv',
        title: 'You',
        items: []
    }
];
