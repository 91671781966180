import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { KnowledgeSearchResult } from '@eva-model/knowledge/knowledge';
import { SearchAuthService } from '@eva-core/search-auth-service';
import { LoggingService } from '@eva-core/logging.service';
import { UrlShortenerService } from '@eva-services/url-shortener/url-shortener.service';
import algoliasearch from 'algoliasearch/lite';
import { AlgoliaIndex, AlgoliaSearchToken, AlgoliaTokenType } from '@eva-model/search/search';

@Component({
  selector: 'app-knowledge-document-search',
  templateUrl: './knowledge-document-search.component.html',
  styleUrls: ['./knowledge-document-search.component.scss']
})
export class KnowledgeDocumentSearchComponent implements OnInit {

  selectedDoc: KnowledgeSearchResult;

  /** Algolia */
  searchConfig = {
    ...environment.algolia,
    indexName: AlgoliaIndex.KNOWLEDGE + environment.algolia.indexEnding,
    apiKey: '',
    searchClient: null
  };

  hits: KnowledgeSearchResult[] = [];
  searchParams: any = {
    hitsPerPage: 10,
    filters: '',
    disjunctiveFacetsRefinements: {
      'isDeleted': ['false']
    }
  };

  searchToken: AlgoliaSearchToken; // set by ngOnInit
  isLoading = true;

  constructor(public dialogRef: MatDialogRef<KnowledgeDocumentSearchComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {selection: string},
              private searchAuthService: SearchAuthService,
              private urlShortenerService: UrlShortenerService,
              private loggingService: LoggingService) { }

  async ngOnInit() {
    await this.getSearchToken();
  }

  /**
   * Close dialogs and passes the selected doc data in a shape the dialog callback expects to place into the editor.
   */
  closeDialogAndEmitData() {
    let linkText: string;

    if (this.data.selection !== '') {
      linkText = this.data.selection;
    }

    if (this.selectedDoc) {
      const hrefAttr = this.urlShortenerService.createUrlWithQueryParams('/', {
        type: 'knowledge',
        id: this.selectedDoc.id,
        gpk: this.selectedDoc.groupPublicKey
      });
      this.dialogRef.close({
        href: hrefAttr,
        text: linkText ? linkText : this.selectedDoc.name
      });
      return;
    }

    this.dialogRef.close();
  }

  /**
   * Gets a limited secured aloglia api token and sets it in the component
   *
   * @param groups array of knowledge group keys the user belongs to
   */
  async getSearchToken(): Promise<void> {
    try {
      const token = await this.searchAuthService.getSearchToken(AlgoliaTokenType.KNOWLEDGE_READER).toPromise();
      if (token.securedAPIKey !== '') {
        // got our token
        this.searchConfig.apiKey = token.securedAPIKey;
        this.searchToken = token;
        const searchClient = algoliasearch(environment.algolia.appId, this.searchToken.securedAPIKey);
        this.searchConfig.searchClient = searchClient;
        // console.log('got search token');

        // init the view components
        this.isLoading = false;
      }
    } catch (err) {
      // Failed to get a secure search token
      this.loggingService.logMessage('Failed to get new search token. Please try again.', false, 'error');
      console.error('failed to get api token', err);
    }
  }

  /**
   * Fired every time Algolia search results change and binds the new results to the component property hits for display.
   *
   * @param searchResults - results from an Algolia search, from the <hits> component
   */
  onSearchChange(searchResults: { results: any, state: any }): void {
    if (searchResults && searchResults.results) {
      this.hits = searchResults.results.hits;
    }
  }

  selectDocumentResult(result: KnowledgeSearchResult) {
    this.selectedDoc = result;
  }

}
