import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { WorkFlow } from '@eva-model/workflow';
import { MatDialog } from '@angular/material/dialog';
import { WorkflowStartComponent } from '@eva-ui/workflow-start/workflow-start.component';

@Injectable({
  providedIn: 'root'
})
export class EvaChatWorkflowsService {

  private workflowSource = new Subject<WorkFlow>();

  private showAvailableWorkflowsState = false; // when change, emit threw showAvailableWorkflows
  private announceShowAvailableWorkflows = new Subject<boolean>();

  public currentWorkflow$: Observable<WorkFlow> = this.workflowSource.asObservable();
  public announceShowAvailableWorkflows$: Observable<boolean> = this.announceShowAvailableWorkflows.asObservable();

  constructor(private dialog: MatDialog) {
    // subscribe to workflow observable and open dialog
    this.currentWorkflow$.subscribe((workflow) => {
      const processStartDialog = this.dialog.open(WorkflowStartComponent, {
        data: {
          workflowName: workflow.name,
          workflowId: workflow.id
        },
        id: `workflow-start-${workflow.id}`
      });
    })
  }

  /**
   * when a workflow is selected
   */
  sendData(workflow: WorkFlow) {
    this.workflowSource.next(workflow);
  }

  /**
   * shows the workflow chooser dialog or hides it
   *
   * @param toggle - true or false if you want to show workflow ui or not
   */
  toggleAvailableWorkflows() {
    this.showAvailableWorkflowsState = !this.showAvailableWorkflowsState;
    this.announceShowAvailableWorkflows.next( this.showAvailableWorkflowsState );
  }
}

