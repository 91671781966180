<div class="multi-view-component" #multiViewComponent>
    <!-- <div [class.close-all-tabs]="!hideCloseAllTabsButton" [class.hide-button]="hideCloseAllTabsButton">
        <button mat-icon-button color="primary" [class.close-all-button]="!hideCloseAllTabsButton" [class.hide-button]="hideCloseAllTabsButton" matTooltip="Close all tabs" aria-label="Close all tabs" [disabled]="tabs.length <= 1" (click)="closeAllCurrentRouteTabs()">
            <mat-icon>playlist_remove</mat-icon>
        </button>
    </div> -->
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="tabsSelectedIndex" (selectedIndexChange)="selectedIndexChanged($event)"
        (selectedTabChange)="selectedTabChanged($event)" (animationDone)="onAnimationDone()" class="multi-view-tabs" #multiViewTabs>
        <mat-tab *ngFor="let tab of tabs; let i = index">
            <ng-template mat-tab-label>
                {{tab.tabName}}&nbsp;
                <button mat-icon-button class="button-close-tab" *ngIf="tabsSelectedIndex === i && i !== 0" (click)="removeTab(i)" matTooltip="Close Tab">
                    <mat-icon aria-label="Close Tab">close</mat-icon>
                </button>
            </ng-template>
            <!-- <div>
                <button (click)="createNewTab()">Open new tab</button>
            </div> -->
            <div class="scrollable-container">
                <div [class.component-container]="!tab.removePaddingFromContainer" [class.component-container-not-padded]="tab.removePaddingFromContainer" *ngIf="i === 0">
                    <router-outlet></router-outlet>
                </div>
                <div [class.component-container]="!tab.removePaddingFromContainer" [class.component-container-not-padded]="tab.removePaddingFromContainer" *ngIf="i !== 0">
                   <template #componentContainer></template>
                </div>
            </div>
            <!-- <app-knowledge [data]="tab" (closeClicked)="removeTab(i)"></app-knowledge> -->
        </mat-tab>
        <mat-tab *ngIf="createNewTabButtonActive" disabled>
            <ng-template mat-tab-label>
                <button mat-icon-button (click)="openNewTab()">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>