<div class="knowledge-search">
  <app-page-header [title]="componentTitle" subTitle="Search Knowledge documents by title or contents" [actions]="headerActions"></app-page-header>
  <ng-template #headerActions>
    <ng-container *ngIf="knowledgeGroupType | isUserInGroupType | async">
      <button mat-stroked-button color="primary" (click)="createKnowledgeDocument()">
        Create Knowledge
      </button>
      <button mat-stroked-button (click)="viewGlobalFindAndReplace()">
        <mat-icon>search</mat-icon> Global Find & Replace
      </button>
    </ng-container>
  </ng-template>

  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="300ms">
    <mat-tab label="Content Search">
      <ng-template matTabContent>
        <app-generic-search
        cardColumns="1"
        [searchFilters]="knowledgeSearchFilters"
        [indexName]="algoliaKnowledgeSearchIndex"
        [tokenType]="algoliaTokenType"
        [searchResultsDistinct]="true"
        searchResultTitleProperty="docName"
        [propertyNameMapping]="knowledgePropertyNameMapping"
        [rowHeight]="rowHeight"
        [columnDefs]="knowledgeSearchColumnDefs"
        [agGridContext]="context"
        [defaultColumnDef]="defaultColDef"
        forceView="tiles"
        [showExactTermSearchToggle]="true"
        customPlaceholderText="Search Knowledge Contents">
          <ng-template #cardTemplate let-hit>
            <div class="search-result has-actions">
              <h4 class="search-result-title" style="margin-bottom:1rem;">
                {{ hit.docName }}
              </h4>
              <hr/>
              <p class="document-section-draft" *ngIf="!hit.isPublished">DRAFT</p>
              <p class="document-section-archived" *ngIf="hit.isDeleted">ARCHIVED</p>
              <p class="document-section-content">
                {{ hit.text }}
              </p>
              <div class="document-section-title-path">
                {{ hit.titlePath | stringSeperatorDisplay:'|':' > ' }}
              </div>
              <div class="search-result-actions">
                <button mat-icon-button matTooltip="This information is current as of {{ hit.docVersion | date:'short' }}" style="float:right;opacity:0.4;">
                  <mat-icon>info</mat-icon>
                </button>
                <button mat-icon-button matTooltip="View Document" (click)="viewKnowledgeDocument(hit.docId, hit.groupPublicKey, hit.isPublished)">
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Edit Document" (click)="editKnowledgeDocument(hit.docId, hit.docName, hit.groupPublicKey, hit.isPublished)" *ngIf="isValidEditor(hit.groupPublicKey)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Copy Link to Document" (click)="getDocumentLink(hit)">
                  <mat-icon>link</mat-icon>
                </button>
              </div>
            </div>
          </ng-template>
        </app-generic-search>
      </ng-template>
    </mat-tab>
    <mat-tab label="Document Search">
      <!-- <ng-template matTabContent> -->
        <app-generic-search
          cardColumns="1"
          [searchFilters]="knowledgeFilters"
          [indexName]="algoliaKnowledgeIndex"
          [tokenType]="algoliaTokenType"
          [searchResultsDistinct]="true"
          searchResultTitleProperty="name"
          [propertyNameMapping]="knowledgePropertyNameMapping"
          [rowHeight]="rowHeight"
          [columnDefs]="knowledgeColumnDefs"
          [agGridContext]="context"
          [defaultColumnDef]="defaultColDef"
          [hideViewToggle]="true"
          forceView="list"
          customPlaceholderText="Search Knowledge Documents">
          <!-- <ng-template #cardTemplate let-hit>
            <div class="search-result has-actions">
              <h4 class="search-result-title" style="margin-bottom:1rem;">
                <ais-highlight attribute="name" [hit]="hit"></ais-highlight>
              </h4>
              <hr/>
              <p class="document-section-draft" *ngIf="!hit.lastPublishedVersion && !hit.isDeleted">DRAFT</p>
              <p class="document-section-archived" *ngIf="hit.isDeleted">ARCHIVED</p>
              <div class="search-result-actions">
                <button mat-icon-button matTooltip="View Document" (click)="viewKnowledgeDocument(hit.id, hit.groupPublicKey, hit.lastPublishedVersion ? true : false)">
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Edit Document" (click)="editKnowledgeDocument(hit.id, hit.name, hit.groupPublicKey, hit.lastPublishedVersion ? true : false)" *ngIf="isValidEditor(hit.groupPublicKey)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button [matTooltip]="hit.isDeleted ? 'Restore Document' : 'Archive Document'" (click)="deleteKnowledgeDocument(hit)" *ngIf="isValidEditor(hit.groupPublicKey)">
                  <mat-icon>{{hit.isDeleted ? 'undo' : 'archive'}}</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Copy Link to Document" (click)="getDocumentLink(hit)">
                  <mat-icon>link</mat-icon>
                </button>
              </div>
            </div>
          </ng-template> -->
        </app-generic-search>
      <!-- </ng-template> -->
    </mat-tab>
  </mat-tab-group>
</div>