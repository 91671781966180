<div id="formScrnZoneVisualizer" class="mat-elevation-z" *ngIf="showInteraction; else movingToSummary">
  <mat-horizontal-stepper dynamicHeight=false #stepper [linear]="true" (selectionChange)="onStepperSelectionChange($event)">

    <mat-step *ngFor="let scrn of formScreens; let scrnIndex = index;">
      <ng-template matStepLabel>
        <div class="tab-label">
          <span class="tab-label-text">{{ scrn.name }}</span>
        </div>
      </ng-template>

      <div *ngIf="formVisualizerFormGroupArray[scrnIndex]" class="width-full" [class.joinChange]="isJoinChange">
        <form [formGroup]="formVisualizerFormGroupArray[scrnIndex]">

          <dynamic-material-form  [group]="formVisualizerFormGroupArray[scrnIndex]"
                                  [layout]="formVisualizerLayoutArray[scrnIndex]"
                                  [model]="formVisualizerModelArray[scrnIndex]"
                                  (blur)="onBlur($event)"
                                  (focus)="onFocus($event)"
                                  (change)="onChange($event)">
          </dynamic-material-form>

        </form>
      <div class="interaction-actions">

          <!-- added a back button that will only show up if 'showBackButton' returns true. Show Previous is set in checkInteractionPosition()
          old back button will disappear and be replaced with a button that sends 'true' to the 'isPrevInteraction' boolean
          showBackButton determines if there is an interaction behind the current one -->

        <button *ngIf="scrnIndex > 0" mat-stroked-button matStepperPrevious type="button">Back</button>
        <button *ngIf="scrnIndex === 0 && showBackButton" mat-stroked-button (click) = "trySubmit(false, true)" type="button">Back</button>
        <ng-container *ngIf="scrnIndex < formScreens.length - 1" [matTooltip]="!isNextButtonActive ? invalidMessage : undefined"><button class="next-button" [disabled]="!isCurrentFormScreenValid(scrnIndex) || numberMinLength || numberMaxLength || numberMaxValue || numberMinValue || numberStepInvalid || !isNextButtonActive" mat-raised-button color="primary" matStepperNext type="button">Next</button></ng-container>
        <ng-container *ngIf="((scrnIndex === formScreens.length - 1) && isSubmitEnable) && !isSubmitting" [matTooltip]="!isNextButtonActive ? invalidMessage : undefined"><button class="next-button" 
          mat-raised-button color="primary" [disabled]="isFormNotValid() || checkboxIsRequired || dateIsInvalid || radioIsRequired || numberMinLength || numberMaxLength || numberMaxValue || numberMinValue || numberStepInvalid || !isNextButtonActive" (click)="trySubmit()" type="button">Next</button></ng-container>
        <button mat-raised-button *ngIf="isSubmitting" class="next-button next-button-submitting" color="primary" disabled><span class="spinner-wrapper"><mat-spinner [diameter]="24" [strokeWidth]="4"></mat-spinner><span>{{ onWaitForSubmittingMessage }}</span></span></button>
      </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<ng-template #movingToSummary>
  <div class="interaction-jumping">
    <mat-spinner [diameter]=196 [strokeWidth]=12></mat-spinner>
    <span class="verColor">
      <h6>Finding next interaction...</h6>
    </span>
  </div>
</ng-template>
