import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatChipGrid } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ArrayUtilsService } from '../../providers/utils/array-utils.service';
import { GeneralDialogService } from '../../providers/general-dialog/general-dialog.service';
import { SigningService } from '../../core/signing.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WorkFlow, WorkflowInteraction, WorkFlowCondition, Page } from '../../model/workflow';

import {Guid} from '../../core/GUID/guid';
import {forkJoin, Observable, of, Subject, Subscription, SubscriptionLike as ISubscription, BehaviorSubject} from 'rxjs';
import {GeneralDialogModel} from '../../model/generalDialogModel';
import {GeneralDialogComponent} from '../general-dialog/general-dialog.component';
import {InteractionVisualizerDialogModel} from '../../model/interactionVisualizerDialogModel';
import {InteractionVisualizerDialogComponent} from '../interaction-visualizer-dialog/interaction-visualizer-dialog.component';
import {KeysService} from '../../core/encryption/keys.service';
import {DynamicInteractionsService} from '../../providers/dynamicforms/dynamic-forms.service';
import {ProjectSettings} from '../../settings/globalvariables';
import {WorkflowService} from '../../providers/workflow/workflow.service';
import {WorkflowInteractionConditionService} from '../../providers/workflow-interaction-condition/workflow-interaction-condition.service';

import {EvaGlobalService} from '../../core/eva-global.service';
import {CreateGroupDialogComponent} from '@eva-ui/form-builder/create-group-dialog.component';
import {InteractionConditionDialogModel} from '@eva-model/interactionConditionDialogModel';
import {InteractionConditionDialogComponent} from '@eva-ui/interaction-condition-dialog/interaction-condition-dialog.component';
// eslint-disable-next-line max-len
import {WorkflowInteractionConditionBuilderService} from '@eva-services/workflow-interaction-condition-builder/workflow-interaction-condition-builder.service';
// eslint-disable-next-line max-len
import {WorkflowInteractionConditionViewerService} from '@eva-services/workflow-interaction-condition-viewer/workflow-interaction-condition-viewer.service';
import {LoggingService} from '@eva-core/logging.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DialogService} from '@eva-ui/guard/dialog.service';
import {tap, filter, take} from "rxjs/operators";
import { ActivatedRoute, Router } from '@angular/router';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { SearchAuthService } from '@eva-core/search-auth-service';
import { environment } from '@environments/environment';
import { PropertyMapping, ValueModifier } from '@eva-model/search';
import * as algoliasearch from 'algoliasearch';
import { GenericSearchFilter } from '@eva-model/generic-search';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { SearchValueModifierService } from '@eva-services/search/search-value-modifier.service';
import { InstantSearchService } from '@eva-services/search/instant-search.service';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { isEqual } from 'lodash';
import { UtilsService } from '@eva-services/utils/utils.service';
import { AnnounceNewTab } from '@eva-model/chat/chat';
import { ChatService } from '@eva-services/chat/chat.service';
import { SearchUtilsService } from '@eva-services/utils/search-utils.service';
import { AlgoliaIndex, AlgoliaSearchToken, AlgoliaTokenType } from '@eva-model/search/search';
import { MenuNavigationService } from '@eva-services/nav/menu-navigation.service';

@Component({
  selector: 'app-workflow-builder',
  templateUrl: './workflow-builder.component.html',
  styleUrls: ['./workflow-builder.component.scss']
})
export class WorkflowBuilderComponent implements OnDestroy, OnInit {

  PageEnum: typeof Page = Page;                                     // Enum for page types in workflow builder
  page: Page = this.PageEnum.MyWorkflows;                           // Current Page for Workflow Builder

  formBuilderGroup: UntypedFormGroup;                                      // This stores the group of form controls for validation

  userGroups: any[] = [];                                           // List of user groups
  interactionsByGroups: any = {};                                   // List of all the interactions belonging to the groups
  interactionsByGroups$: any = {};                                  // Array used for restoring interactions after removing on search
  private interactionsByGroupsSubscription = new Subscription();    // Subscription to the interactions belonging to the groups
  private workflowsByGroupsSubscription = new Subscription();       // Subscription to the workflows belonging to the groups

  generalDialogChangeSubscription: ISubscription;                   // Subscription to the General Dialog changes
  entitySubscription: ISubscription;                                // Subscription to the fetching workflow by version
  evaGlobalGroupsSubscription: ISubscription;                       // Subscription to the user groups

  isWaiting = false;                                                // whether waiting for workflow submission or not
  waitMessage: string = null;                                       // message displayed when isWaiting is true

  isWaitingForGroupInteractions = false;                            // whether waiting for group interactions or not
  waitForGroupInteractionsMessage: string = null;                   // message displayed when isWaitingForGroupInteractions is true

  isWaitingForGroups = false;                                       // whether waiting for groups or not
  onWaitForGroupsMessage: string = null;                            // Message displayed when isWaitingForGroups is true
  onWaitForWorkflowMessage: string = null;                          // Message displayed when loading workflows

  isWorkflowEncrypted = true;                                       // status for whether workflow is encrypted or not
  workflow: WorkFlow = null;                                        // WorkFlow object storing all the data regarding workflow

  movingItem = -1;                                                  // Index of the interaction being dragged
  workflowInteractionConditionViewer = false;                       // Status for whether or not show the Interaction Condition Builder
  workflowInteractionConditionName = null;                          // Name of the condition for Interaction Condition Builder title
  selectedInteractionIndex = 0;                                     // Interaction of the currently selected index
  interactionConditionSetDialogChangeSubscription: ISubscription;   // Subscription to the interaction condition set dialog changes
  interactionConditionChangeSubscription: ISubscription;            // Subscription to the interaction condition builder changes
  conditionDestinationChangeSubscription: ISubscription;            // Subscription to the condition Destination Changes

  interaction: WorkflowInteraction;                                 // Interaction being modified in condition builder
  intrctnIndex: number;                                             // Index of the interaction being modified in condition builder
  showDynamicWorkflowViewer = false;                                // Toggles Dynamic Workflow Viewer
  interactionConditions: WorkFlowCondition[] = [];                  // List of conditions being added in the interaction condition builder

  searchText = '';                                                  // Search text entered by user for filtering interactions and workflows

  workflowsByGroups: any;                                           // List of all the workflows belonging to the groups
  workflowsByGroups$: any;                                          // Array used for restoring workflows after removing on search
  userWorkflows: any[] = [];                                        // List of user workflows
  workflowsData: MatTableDataSource<WorkFlow>
    = new MatTableDataSource(this.userWorkflows);                   // data for MatTableDataSource

  paginator: MatPaginator;                                          // MatPaginator used for paging MatTableDataSource
  movingCondition = -1;                                             // Index of condition set being dragged
  isWaitingForGroupWorkflows: boolean;                              // whether waiting for group workflows or not
  loadWorkflow = false;                                             // Toggles Load my Workflows button and MatTableDataSource
  displayedColumns: string[]
    = ['workflow', 'group', 'lastModified', 'actions'];             // list of MatTableDataSource headings
  separatorKeysCodes = [ENTER, COMMA];                              // Separator key codes for mat-chip delimiter

  selectedInteractionVersion = -1;                                  // This is the selected interaction version for
  importedWorkflowTimestamp = '';                                   // Timestamp of the imported workflow
  workflowSubmittedToBlockSubscription: Subject<any>
    = new Subject();                                                // This is the subscription for when the imported workflow gets
                                                                    // submitted to block
  matChipListSubscription: Subscription;                            // Subscription for the workflow keyword MatChipList
                                                                    // to detect value changes and validate it

  private lastStringifiedAndSavedWorkflow = '';                     // last saved string representation of the interaction
  componentTitle: string;
  tabIndex: number;
  formBuilderGroupValues: { name: string, userGroup: string, subGroup: string };
  // contains all active subscriptions for this component.
  componentSubs = new Subscription();
  tab: AnnounceNewTab;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.workflowsData.paginator = this.paginator;
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('descriptionList') matChipList: MatChipGrid;
  context: any;
  uniqueTabId: string;

  // for algolia search
  algoliaIndex = AlgoliaIndex.WORKFLOWS; // the Algolia index to query.
  algoliaTokenType: AlgoliaTokenType = AlgoliaTokenType.WORKFLOW_BUILDER;
  interactionsAlgoliaIndex = AlgoliaIndex.INTERACTIONS; // the interactions index.
  interactionsAlgoliaTokenType: AlgoliaTokenType = AlgoliaTokenType.INTERACTION_BUILDER;
  searchAccess = false; // is search access ready.
  currentlyLoading = true; // currently getting a token
  searchToken: AlgoliaSearchToken; // the algolia search token used for this component.
  interactionFilters: GenericSearchFilter[] = []; // the search filters for algolia from interactions
  interactionPropertyNameMapping: PropertyMapping; // the property mapping for interaction config
  workflowFilters: GenericSearchFilter[] = []; // the search filters for algolia from workflows
  workflowPropertyNameMapping: PropertyMapping; // the property mapping for workflow config
  public defaultColDef: ColDef;                             // the default column definitions
  public rowHeight: number;                                 // the height of individual rows
  public columnDefs: (ColDef | ColGroupDef)[];              // stores definitions for each column

  state = {
    filters: [{defaultValue: []}],
    searchText: ''
  };

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private arrayUtil: ArrayUtilsService,
    public interactionService: DynamicInteractionsService,
    private workflowService: WorkflowService,
    private generalDialogService: GeneralDialogService,
    private keysService: KeysService,
    public signingService: SigningService,
    private interactionConditionService: WorkflowInteractionConditionService,
    public evaGlobalService: EvaGlobalService,
    private interactionConditionBuilderService: WorkflowInteractionConditionBuilderService,
    private interactionConditionViewerService: WorkflowInteractionConditionViewerService,
    public loggingService: LoggingService,
    private multiViewService: MultiViewService,
    private searchAuthService: SearchAuthService,
    public dialogService: DialogService,
    private valueModifierService: SearchValueModifierService,
    private instantSearchService: InstantSearchService,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private chatService: ChatService,
    private searchUtils: SearchUtilsService,
    private menuNavigationService: MenuNavigationService) {
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      this.componentTitle = data.componentTitle;
    });
    this.multiViewService.setCreateNewTabFunction(this.showCreateNewWorkflow);

    this.componentSubs.add(
      this.multiViewService.closeTab$.pipe(
        filter((closeTab) => closeTab && this.workflow && closeTab.entityType === 'Workflow'),
        filter((closeTab) => this.uniqueTabId === closeTab.entityId),
        filter((closeTab) => this.tabIndex === closeTab.tabIndex)).subscribe(closeTab => {
          this.componentSubs.add(
            this.canDeactivate().subscribe(value => {
              if (value) {
                setTimeout(() => {
                  closeTab.closeSubject.next(true);
                });
              }
            })
          );
      })
    );

    this.menuNavigationService.updateCurrentMenu('builders');

    this.createForm();
    this.workflow = new WorkFlow(Guid.newGuid().toString(), '', '', null, Date.now(), this.isWorkflowEncrypted,
      [], [], null, null, null, null);
  }

  /**
   * This sets the filter values of the interaction and workflow groups.
   */
  private setInteractionAndWorkflowGroups() {
    this.evaGlobalService.userGroupsPublicKeys$.subscribe(userGroupsPublicKeys => {
      if (userGroupsPublicKeys) {
        this.interactionFilters[0].filterValues = userGroupsPublicKeys;
        this.workflowFilters[0].filterValues = userGroupsPublicKeys;
      }
    });
  }

//#region Component LifeCycle Events

  async ngOnInit() {
    this.formBuilderGroup = this.fb.group({
      name: [this.formBuilderGroupValues?.name ?? this.workflow.name, Validators.required],
      userGroup: [this.formBuilderGroupValues?.userGroup ?? this.workflow.groupPublicKey, Validators.required],
      subGroup: [this.formBuilderGroupValues?.subGroup ?? this.workflow.subGroup, Validators.required]
    });
    await this.getSearchToken();
    this.tab = this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex];
  }

  ngOnDestroy() {
    if (this.generalDialogChangeSubscription) {
      this.generalDialogChangeSubscription.unsubscribe();
    }

    if (this.interactionsByGroupsSubscription) {
      this.interactionsByGroupsSubscription.unsubscribe();
    }

    if (this.workflowsByGroupsSubscription) {
      this.workflowsByGroupsSubscription.unsubscribe();
    }

    if (this.entitySubscription) {
      this.entitySubscription.unsubscribe();
    }

    if (this.evaGlobalGroupsSubscription) {
      this.evaGlobalGroupsSubscription.unsubscribe();
    }

    if (this.matChipListSubscription) {
      this.matChipListSubscription.unsubscribe();
    }

    if (this.interactionConditionSetDialogChangeSubscription) {
      this.interactionConditionSetDialogChangeSubscription.unsubscribe();
    }

    if (this.conditionDestinationChangeSubscription) {
      this.conditionDestinationChangeSubscription.unsubscribe();
    }

    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }

    // this.searchAuthService.stopRefresh(AlgoliaTokenType.WORKFLOW_BUILDER);
    // this.searchAuthService.stopRefresh(AlgoliaTokenType.INTERACTION_BUILDER);
  }

//#endregion Component LifeCycle Events

  /**
   * If this call returns false Angular will block block navigation to a new route until the user confirms.
   * @link https://angular.io/api/router/CanActivate
   */
  canDeactivate = (): Observable<boolean> => {

    if (this.lastStringifiedAndSavedWorkflow === '') {
      return of(true); // this page displays more than just a single workflow, and should not have last state in all views
    }
    if (!this.isNotEdited()) {
      return this.dialogService.confirm('You have unsaved changes that will be lost.').pipe(
        tap(x => {
          if (x && x === true) { // if true the user would like to navigate away; let's reset last saved workflow
            this.lastStringifiedAndSavedWorkflow = '';
          }
        })
      );
    }
    return of(true);
  }

  /**
   * This function opens the dialog for whether to activate the workflow or not
   */
  workflowActivationConfirm(): void {
    const dialogData = new GeneralDialogModel(
      'Workflow Activation Dialog', `Do you want to activate this version of workflow ? <br> :: ${this.workflow.name} ::`, 'Yes', 'No');
    this.openGeneralDialog(dialogData, this.submitWorkFlowToBlock, { that: this }, this.submitWorkFlowToBlock);
  }

  /**
   * This function fetches the interactions from database and sets up the object that gets submitted to block
   *
   * @param data { that: this, result: boolean }
   */
  submitWorkFlowToBlock = (data: {[key: string]: any, that: WorkflowBuilderComponent}): void => {
    const that: WorkflowBuilderComponent = data.that;
    const tasks$: Observable<any>[] = [];
    if (!that.workflow.id) { that.workflow.id = Guid.newGuid().toString(); }

    that.workflow.timestamp = Date.now();
    that.importedWorkflowTimestamp = '' + that.workflow.timestamp;
    that.workflow.encryptedByDefault = true;
    that.workflow.interactions.forEach((intrct, intrctIndex) => {
      intrct.order = intrctIndex;
      intrct.isWaiting = false;
      intrct.onWaitMessage = null;
      if (!intrct.interaction) {
        // Fetch the interactions from database and store their observables
        tasks$.push(that.interactionService.fetchInteractionsByIdAndVer(intrct.interactionId, intrct.interactionVersion));
      }
      intrct.conditions.forEach((condition, conditionIndex) => {
        condition.order = conditionIndex;
      });
    });

    that.isWaiting = true;
    that.waitMessage = "Preparing workflow object for Saving ...";
    that.page = that.PageEnum.None;
    let error = null;
    // use stored observables to get their latest value using forkJoin
    forkJoin(tasks$)
      .subscribe(
        interactionDataArray => {
          if (Array.isArray(interactionDataArray)) {
            interactionDataArray.forEach((interactionData) => {
              const interactionObj = that.interactionService.interactionObjectMapper(interactionData);

              const filteredInteractionArray =
                that.workflow.interactions.filter(
                  theInteraction => theInteraction.interactionId === interactionObj.id &&
                    ('' + theInteraction.interactionVersion) === ('' + interactionObj.version));

              if (filteredInteractionArray &&
                Array.isArray(filteredInteractionArray) &&
                filteredInteractionArray.length > 0) {
                filteredInteractionArray[0].interaction = interactionObj;
              } else {
                throw new Error(`Interaction not found, interactionId: ${interactionObj.id},
                interactionVersion: ${interactionObj.version}`);
              }
            });
          }
        },
        (err) => { console.log(err); error = err; that.isWaiting = false; },
        () => {
          // To be sure it didn't come from error event
          if (!error) {
            that.onSubmit(data).then(value => {
              that.lastStringifiedAndSavedWorkflow = that.jsonStringify(that.workflow);
              that.tab.additionalInstanceData.lastStringifiedAndSavedWorkflow = that.lastStringifiedAndSavedWorkflow;
              that.tab.additionalInstanceData.workflow = that.workflow;
              that.tab.tabName = that.workflow.name;
              if (that.multiViewService.tabs[Routes.WorkflowBuilder] && that.multiViewService.tabs[Routes.WorkflowBuilder][that.tabIndex]) {
                that.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", that.tab, that.tabIndex);
              }
            });
          } else {
            that.page = that.PageEnum.CreateNewWorkflow;
          }
        }
      );
  }

  /**
   * This function submits the workflow to block
   *
   * @param data { that: this, result: boolean }
   */
  onSubmit(data: {[key: string]: any, that: WorkflowBuilderComponent}): Promise<void> {
    const that: WorkflowBuilderComponent = data.that;
    const isImportSubmit: boolean = data.isImportSubmit;
    // check if information has been added to the workflow
    that.captureDescriptionOfWorkflow();

    that.workflow.activeVersion = data.result ? that.workflow.timestamp : null;
    that.workflow.activated = data.result ? true : false;

    const wfVersionToDateTime = (new Date(that.workflow.timestamp)).toLocaleString();
    that.isWaiting = true;
    that.waitMessage = `Saving workflow ${that.workflow.id} version ${wfVersionToDateTime} ...`;

    return that.signingService.signAndSendObject(that.workflow, ProjectSettings.types.workflows.typeId)
      .then((res) => {
        that.loggingService.logMessage(
          `Workflow submitted and saved, Workflow ${that.workflow.name} with version ${that.workflow.timestamp} recorded`
          , false, 'success');

        that.isWaiting = false;

        if (isImportSubmit) {
          that.page = that.PageEnum.MyWorkflows;
          this.multiViewService.setCreateNewTab({
            component: WorkflowBuilderComponent,
            tabName: that.workflow.name,
            additionalInstanceData: {
              componentTitle: that.componentTitle,
              page: that.PageEnum.CreateNewWorkflow,
              entitySubscription: null,
              showDynamicWorkflowViewer: false,
              workflowInteractionConditionViewer: false,
              lastStringifiedAndSavedWorkflow: this.jsonStringify(that.workflow),
              workflow: that.workflow,
              formBuilderGroupValues: {
                name: that.workflow.name,
                userGroup: that.workflow.groupPublicKey,
                subGroup: that.workflow.subGroup
              }
            }
          });
          if (that.workflowSubmittedToBlockSubscription) {
            that.workflowSubmittedToBlockSubscription.next(this.importedWorkflowTimestamp);
          }
          return;
        }
        that.page = that.PageEnum.CreateNewWorkflow;
        const patchObj = {
          name: that.workflow['name'],
          userGroup: that.workflow['groupPublicKey'],
          subGroup: that.workflow['subGroup']
        };
        data.that.formBuilderGroup.patchValue(patchObj);
        that.userWorkflows = [];
        that.userWorkflows.length = 0;
        if (that.loadWorkflow) {
          that.getWorkflowsByGroups();
        }
        if (that.workflowSubmittedToBlockSubscription) {
          that.workflowSubmittedToBlockSubscription.next(this.importedWorkflowTimestamp);
        }
      })
      .catch(err => {
        that.loggingService.logMessage(
          `Workflow submission failed, Workflow ${that.workflow.name} failed to be recorded :: ERROR :: ${err}`
          , false, 'Error');

        that.isWaiting = false;
        if (!isImportSubmit) {
          that.page = that.PageEnum.CreateNewWorkflow;
        }
        if (that.workflowSubmittedToBlockSubscription) {
          that.workflowSubmittedToBlockSubscription.next(this.importedWorkflowTimestamp);
        }
        return Promise.reject('Error');
      });
  }

  /**
   * Followup for Ben.
   */
  captureDescriptionOfWorkflow(): void {
    if (this.workflow.descriptions && this.workflow.descriptions.length > 0) {
      const workflowId = this.workflow.id;
      const workflowVersion = this.workflow.timestamp;
      const workflowPublicKey = this.workflow.groupPublicKey;
      // || this is used to update the natural language descriptions.
      const isActive = true;
      if (isActive) {
        // then update natural language functions.
      }
    }
  }

  /**
   * This function creates a new form with required fields
   */
  createForm = (): void => {
    this.formBuilderGroup = this.fb.group({
      name: ['', Validators.required],
      userGroup: ['', Validators.required],
      subGroup: ['', Validators.required]
    });
  }

  /**
   * This function returns the group Name from group Public Key
   *
   * @param groupPk The group public key
   */
  getGroupNameByPublicKey(groupPk: string): string {
    return this.evaGlobalService.getGroupNameByPublicKey(groupPk);
  }

  /**
   * This function checks if user groups are available and if they are then calls getWorkflowsByUserGroups
   */
  private getWorkflowsByGroups(): void {
    this.isWaitingForGroupWorkflows = true;
    this.onWaitForWorkflowMessage = `Fetching your workflows`;
    const that = this;

    if (that.evaGlobalService.userGroups &&
      Array.isArray(that.evaGlobalService.userGroups) &&
      that.evaGlobalService.userGroups.length > 0) {
      that.getWorkflowsByUserGroups();
    } else {
      if (that.evaGlobalService.userPublicKey) {
        that.evaGlobalGroupsSubscription = that.evaGlobalService.userGroups$
          .subscribe(
            (groups) => {
              setTimeout(() => {
                this.getWorkflowsByUserGroups();
              }, 500);
            }
          );
      } else {
        this.keysService.getUserPublicKey()
          .then(currentUserPublicKey => {
            that.evaGlobalService.userPublicKey = currentUserPublicKey.toString();
            this.getWorkflowsByGroups();
          });
      }
    }
  }

  /**
   * This function calls workflow fetcher method for all groups
   */
  private getWorkflowsByUserGroups(): void {
    this.workflowsByGroups = [];
    this.workflowsByGroups$ = [];
    this.userWorkflows = [];
    this.workflowsByGroups.length = 0;
    this.workflowsByGroups$.length = 0;
    this.evaGlobalService.userGroups.forEach(grp => {
      this.getWorkflowsByGroup(grp.groupPublicKey);
    });
  }

  /**
   * This function calls interaction fetcher method for all groups
   */
  public getInteractionsByUserGroups = (data: {[key: string]: any}): void => {
    this.isWaitingForGroupInteractions = true;
    this.waitForGroupInteractionsMessage = `Fetching interactions and workflows of the user groups`;
    this.componentSubs.add(
      this.evaGlobalService.userGroupsChange$.subscribe(() => {
        if (this.evaGlobalService.userGroups) {
          this.evaGlobalService.userGroups.forEach(grp => {
            this.getInteractionsByGroup(grp.groupPublicKey, data);
          });
        }
      })
    );
  }

  /**
   * This function fetches interactions for single user group
   *
   * @param groupPk Group public key
   */
  getInteractionsByGroup = (groupPk: string, data: {[key: string]: any}): void => {
    // this.interactionsByGroupsSubscription.add(
    //   this.interactionService.fetchInteractionsByGroup(groupPk)
    //     .subscribe(
    //       (interactionArray) => {
    //         if (!this.interactionsByGroups) this.interactionsByGroups = {};
    //         if (!this.interactionsByGroups$) this.interactionsByGroups$ = {};
    //         if (interactionArray && Array.isArray(interactionArray)) {
    //           interactionArray.forEach(intrct => {
    //             intrct.Versions = intrct.Versions && intrct.Versions.reverse();
    //             intrct.selectedVersion = intrct.Versions[0].version;
    //           });
    //           this.interactionsByGroups[groupPk] = interactionArray;
    //           this.interactionsByGroups$[groupPk] = interactionArray;
    //           const groupName = this.getGroupNameByPublicKey(groupPk);
    //           this.waitForGroupInteractionsMessage = `Adding the interactions of ${groupName} (group)`;
    //         }
    //       },
    //       (err) => {
    //         this.isWaitingForGroupInteractions = false;
    //         console.log(err);
    //         throw err;
    //       },
    //       () => {
    //         const subs: any = this.interactionsByGroupsSubscription;
    //         if (subs._subscriptions.length <= 1) {
    //           this.isWaitingForGroupInteractions = false;
    //         }
    //       }
    //     )
    // );
  }

  /**
   * This function fetches workflows for single user group
   *
   * @param groupPk Group public key
   */
  getWorkflowsByGroup(groupPk: string): void {
    this.workflowsByGroupsSubscription.add(
      this.workflowService.fetchWorkflowsByGroup(groupPk)
        .subscribe(
          (workflowArray) => {
            if (!this.workflowsByGroups) this.workflowsByGroups = {};
            if (!this.workflowsByGroups$) this.workflowsByGroups$ = {};
            if (workflowArray && Array.isArray(workflowArray)) {
              workflowArray.forEach(wrkflw => {
                wrkflw.Versions = wrkflw.Versions && wrkflw.Versions.reverse();
                wrkflw.selectedVersion = wrkflw.Versions[0].version;
              });
              this.workflowsByGroups[groupPk] = workflowArray;
              this.workflowsByGroups$[groupPk] = workflowArray;
              if (workflowArray.length > 0) {
                workflowArray.forEach(workflow => {
                  this.userWorkflows.push({
                    Versions: workflow.Versions,
                    name: workflow.name,
                    version: workflow.version,
                    group: this.evaGlobalService.userGroups
                      .filter(item => item.groupPublicKey === workflow.groupPublicKey)[0].groupName,
                    id: workflow.id,
                    groupPublicKey: workflow.groupPublicKey,
                    selectedVersion: workflow.version,
                    activeVersion: workflow.activeVersion ? workflow.activeVersion.toString() : null
                  });
                });
              }
              const groupName = this.getGroupNameByPublicKey(groupPk);
              this.waitForGroupInteractionsMessage = `Adding the workflows of ${groupName} (group)`;
            }
            // if (workflowArray.length > 0 && this.page !== this.PageEnum.CreateNewWorkflow) {
            //   this.page = this.PageEnum.MyWorkflows;
            // } else if (workflowArray.length === 0 && this.page !== this.PageEnum.CreateNewWorkflow) {
            //   this.page = this.PageEnum.MyWorkflowsEmpty;
            // }
            // // put recently updated interactions at the top
            this.userWorkflows.sort(function (a, b) { return Number(b.version) - Number(a.version); });

            // this.workflowsData.data = this.userWorkflows;
            // this.workflowsData.paginator = this.paginator;
            // this.workflowsData.sort = this.sort;
            // this.isWaitingForGroupWorkflows = false;
            // this.waitForGroupInteractionsMessage = null;
          },
          (err) => {
            this.isWaitingForGroupWorkflows = false;
            console.log(err);
            throw err;
          },
          () => {
            // put recently updated interactions at the top
            this.userWorkflows.sort(function (a, b) { return Number(b.version) - Number(a.version); });
            this.workflowsData.data = this.userWorkflows;
            this.workflowsData.paginator = this.paginator;
            this.workflowsData.sort = this.sort;
            this.isWaitingForGroupWorkflows = false;
            this.waitForGroupInteractionsMessage = null;
          }
        )
    );
  }

  /**
   * This function checks the validity of workflow
   */
  isWorkflowNotValid(): boolean {
    let isNotValid = true;

    if (this.workflow.name &&
      this.workflow.groupPublicKey &&
      this.userGroups &&
      Array.isArray(this.evaGlobalService.userGroups) &&
      this.evaGlobalService.userGroups.map(grp => grp.groupPublicKey).includes(this.workflow.groupPublicKey) &&
      this.workflow.interactions &&
      Array.isArray(this.workflow.interactions) &&
      this.workflow.interactions.length > 0 &&
      this.workflow.descriptions &&
      Array.isArray(this.workflow.descriptions) &&
      this.workflow.descriptions.length > 0 &&
      this.workflow.subGroup &&
      this.workflow.subGroup !== '') {

      isNotValid = false;
    }

    return isNotValid;
  }

  /**
   * This function removes the description from the descriptions chip list
   *
   * @param desc The description being removed
   */
  removeWorkflowDescriptionConfirm(desc: string): void {
    const descIndex = this.workflow.descriptions.indexOf(desc);
    if (descIndex !== -1) {
      this.workflow.descriptions.splice(descIndex, 1);
      this.tab.additionalInstanceData.workflow = this.workflow;
      if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
      }
    }
  }

  /**
   * This function adds description to the description chip list
   */
  addWorkflowDescription(event): void {
    const input = event.input;
    const value = event.value;

    // Add the workflow description
    if ((value || '').trim()) {
      this.workflow.descriptions.push(value.trim());
      this.tab.additionalInstanceData.workflow = this.workflow;
      if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
      }
    }

    // Reset the input value
    if (input) { input.value = ''; }
  }

  /**
   * This function changes the name of the workflow
   *
   * @param event HTML KeyUp Event
   */
  onWorkflowNameChange(event): void {
    this.workflow.name = event.target.value;
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }
  }

  onWorkflowSubGroupChange(event): void {
    this.workflow.subGroup = event.target.value;
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }
  }

  /**
   * This function changes the selected user group
   *
   * @param event MatSelect Selection Change event
   */
  onGroupSelectionChange(event): void {
    this.workflow.groupPublicKey = event.value;
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }
    // subscribe to 'Workflow Keywords' mat-chip-list for validation
    this.matChipListSubscription = this.matChipList.stateChanges.subscribe(change => {
      if (!this.matChipList.focused && this.workflow.descriptions.length === 0) {
        this.matChipList.errorState = true;
      } else if (this.workflow.descriptions.length > 0) {
        this.matChipList.errorState = false;
      }
    });
  }

  /**
   * This function toggles the encryption selection
   *
   * @param event MatSlide change event
   */
  toggleWorkflowEncryption(event): void {
    this.isWorkflowEncrypted = event.checked;
  }

  /**
   * This function is called when interaction drag starts
   *
   * @param event HTML DragStart Event
   */
  dragStartHandlerForInteraction(event): void {
    if (!event.target) return;

    event.dataTransfer.setData('interactId', event.target.id);
    event.dataTransfer.setData('interactVersion', event.target.dataset.interactVersion);
    event.dataTransfer.setData('interactGroupPublicKey', event.target.dataset.interactGroupPublicKey);

    event.dataTransfer.dropEffect = 'copy';
  }

  /**
   * This function is called when interaction is dragged over the drop zone
   *
   * @param event HTML DragOver Event
   */
  dargOverHandlerForInteraction(event): void {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  /**
   * This function is called when interaction is dropped on the drop zone
   *
   * @param event HTML Drop Event
   */
  dropHandlerForInteraction(event): void {
    event.preventDefault();

    let dropOnElement = event.target;
    // let isWorkflow = false;
    if (dropOnElement.id && dropOnElement.id !== 'interactionDropZone') {
      while (dropOnElement.offsetParent.id !== 'interactionDropZone') {
        dropOnElement = dropOnElement.offsetParent;
      }
      if (dropOnElement.offsetParent.id === 'interactionDropZone') {
        dropOnElement = dropOnElement.offsetParent;
      } else {
        return;
      }
    }

    const dragElementId = event.dataTransfer.getData('interactId');
    const dragElementVersion = +event.dataTransfer.getData('interactVersion');
    const dragElementGroupPk = event.dataTransfer.getData('interactGroupPublicKey');

    // Stops the not expected element to be dropped!
    const dragElement = document.getElementById(dragElementId);
    if (!dragElement || dragElement.dataset.interaction !== 'true') return;

    // Initialize interactions array if no interaction dropped yet
    if (!this.workflow.interactions) this.workflow.interactions = [];

    // Get the interaction object based on the element dropped
    const interactionObj =
      this.interactionsByGroups[dragElementGroupPk]
        .find(function (element) { return element.id === dragElementId; });

    // Code for future workflow integration
    /*
    if (!interactionObj) {
      interactionObj =
        this.workflowsByGroups[dragElmntGroupPk]
          .find(function (element) { return element.id === dragElmntId; });
      if (interactionObj) isWorkflow = true;
    }
    */
    if (!interactionObj) return;

    const interactionName = (interactionObj) ? interactionObj.name : '';
    const interactionGroupPublicKey = (interactionObj) ? interactionObj.groupPublicKey : '';
    // Code for future workflow integration
    /*
    if (isWorkflow) {
      this.workflow.interactions.push(
        new WorkflowInteraction(dragElmntId, interactionName, dragElmntVersion, interactionGroupPublicKey,
          null, null, null, null, null, null, interactionObj.activated, interactionObj.activeVersion));
      return;
    }
    */
    const newInteraction = new WorkflowInteraction(dragElementId, interactionName, dragElementVersion, interactionGroupPublicKey,
      null, null, null, [], null, null, null, null);
    this.workflow.interactions.push(newInteraction);
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }
  }

  /**
   * This function opens dialog box when removing an interaction
   *
   * @param event EventEmitter<object>
   */
  removeInteractionConfirm(event): void {
    const wrkflwInteractionName = event.wrkflwInteractionName;
    const wrkflwInteractionIndex = +event.wrkflwInteractionIndex;
    const wrkflwInteractionId = event.wrkflwInteractionId;

    const dialogData = new GeneralDialogModel(
      'Delete Interaction', `Are you sure to remove the ${wrkflwInteractionName} interaction ?`, 'Yes', 'Cancel');
    this.openGeneralDialog(dialogData, this.removeInteraction,
      { theWorkflow: this.workflow, wrkflwInteractionIndex: wrkflwInteractionIndex, wrkflwInteractionId: wrkflwInteractionId, that: this });
  }

  /**
   * This function removes an interaction from workflow
   *
   * @param data EventEmitter<object>
   */
  private removeInteraction = (data): void => {
    if (!data ||
      (!data.wrkflwInteractionIndex && data.wrkflwInteractionIndex !== 0) ||
      !data.wrkflwInteractionId ||
      !data.theWorkflow ||
      !data.theWorkflow.interactions ||
      !Array.isArray(data.theWorkflow.interactions) ||
      data.theWorkflow.interactions.length <= 0
    ) {
      return;
    }
    const wrkflwInteraction = data.theWorkflow.interactions[data.wrkflwInteractionIndex];
    if (wrkflwInteraction.interactionId === data.wrkflwInteractionId) {
      data.theWorkflow.interactions.splice(data.wrkflwInteractionIndex, 1);
      this.tab.additionalInstanceData.workflow = this.workflow;
      if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
      }
    }
  }

  /**
   * This function moves the interaction up by 1 index
   *
   * @param event EventEmitter<object>
   */
  moveUpInteraction(event): void {
    const wrkflwInteractionIndex = +event.wrkflwInteractionIndex;

    if (wrkflwInteractionIndex < 1 ||
      (this.workflow.interactions.length - 1) < wrkflwInteractionIndex) {
      return;
    }
    if (wrkflwInteractionIndex === this.selectedInteractionIndex) {
      this.selectedInteractionIndex -= 1;
    }
    this.arrayUtil.array_move(this.workflow.interactions, wrkflwInteractionIndex, wrkflwInteractionIndex - 1);
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }

  }
  /**
   * This function moves the interaction down by 1 index
   *
   * @param event EventEmitter<object>
   */
  moveDownInteraction(event): void {
    const wrkflwInteractionIndex = +event.wrkflwInteractionIndex;

    if (wrkflwInteractionIndex < 0 ||
      (this.workflow.interactions.length - 1) < wrkflwInteractionIndex) {
      return;
    }
    if (wrkflwInteractionIndex === this.selectedInteractionIndex) {
      this.selectedInteractionIndex += 1;
    }
    this.arrayUtil.array_move(this.workflow.interactions, wrkflwInteractionIndex, wrkflwInteractionIndex + 1);
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }
  }

  /**
   * This function fetches the version of interaction being viewed
   *
   * @param event EventEmitter<object>
   * @param interaction user interaction
   * @param interactionIndex Index of user interaction
   * @param interactionVersion Version of the interaction being viewed
   */
  showInteraction(event, interaction?: any, interactionIndex?: number, interactionVersion?: number): void {

    let intrctnName = event.wrkflwInteractionName;
    let intrctnIndex = +event.wrkflwInteractionIndex;
    let intrctnId = event.wrkflwInteractionId;
    let intrctnVersion = +event.wrkflwInteractionVersion;
    let intrctnGroupPublicKey = event.wrkflwInteractionGroupPublicKey;

    if (interaction) {
      event.stopPropagation();
      event.preventDefault();

      intrctnName = interaction.name;
      intrctnIndex = interactionIndex;
      intrctnId = interaction.id;
      intrctnVersion = interactionVersion;
      intrctnGroupPublicKey = interaction.groupPublicKey;
    }

    this.isWaiting = true;
    this.waitMessage = `Fetching interaction ${intrctnName} version ${intrctnVersion}`;

    // Code for future workflow integration
    /*
    if (this.workflow.interactions[intrctnIndex].activeVersion) {
      this.workflowService.fetchWorkflowsByIdAndVer(intrctnId, intrctnVersion)
        .subscribe(
          (workflowData) => {
            const workflowObj = this.workflowService.workflowObjectMapper(workflowData);
            this.workflow.interactions[intrctnIndex].interaction = workflowObj;

            this.callWorkflowVisualizerDialog(intrctnName, workflowObj);
          },
          (err) => { this.workflow.interactions[intrctnIndex].isWaiting = false; console.log(err); throw err; },
          () => { this.workflow.interactions[intrctnIndex].isWaiting = false; }
        );
      return;
    }
    */
    // Fetch the given version of interaction
    this.interactionService.fetchInteractionsByIdAndVer(intrctnId, intrctnVersion)
      .subscribe(
        (interactionData) => {
          const interactionObj = this.interactionService.interactionObjectMapper(interactionData);
          this.callInteractionVisualizerDialog(intrctnName, interactionObj);
        },
        (err) => {
          this.isWaiting = false;
          console.log(err); throw err; },
        () => {
          this.isWaiting = false;
          }
      );
  }

  /**
   * This function sets the dialog data for form visualizer for interaction
   *
   * @param intrctnName Name of the interaction being viewed
   * @param interactionObj Interaction Object containing form content
   */
  private callInteractionVisualizerDialog(intrctnName: any, interactionObj: any): void {
    const dialogData =
      new InteractionVisualizerDialogModel(
        `${intrctnName} Details`,
        '', interactionObj, false, '', 'Cancel', false, true);
    this.openInteractionVisualizerDialog(dialogData);
  }

  /**
   * This function sets the dialog data for form visualizer for workflow
   * Code for future workflow integration
   */
  private callWorkflowVisualizerDialog(intrctnName: any, interactionObj: any): void {
    // const dialogData =
    //   new WorkflowVisualizerDialogModel(
    //     `${intrctnName} Details`,
    //     '', interactionObj, false, '', 'Cancel', false, true);
    // this.openInteractionVisualizerDialog(dialogData);
  }

  /**
   * This function loads the interaction condition builder for the chosen interaction
   *
   * @param event EventEmitter<object>
   */
  setInteractionCondition(event) {
    const intrctnName = event.wrkflwInteractionName;
    const intrctnIndex = +event.wrkflwInteractionIndex;
    const intrctnId = event.wrkflwInteractionId;
    const intrctnVersion = +event.wrkflwInteractionVersion;
    const intrctnGroupPublicKey = event.wrkflwInteractionGroupPublicKey;

    this.selectedInteractionIndex = intrctnIndex;
    this.selectedInteractionVersion = intrctnVersion;
    this.interactionConditions = this.workflow.interactions[intrctnIndex].conditions
      ? JSON.parse(JSON.stringify(this.workflow.interactions[intrctnIndex].conditions)) : [];

    if (this.workflow.interactions[intrctnIndex].interaction) {
      this.getInteractionConditionSettingDialogData(intrctnName, intrctnIndex);
    } else {
      this.workflow.interactions[intrctnIndex].isWaiting = true;
      this.workflow.interactions[intrctnIndex].onWaitMessage = `Fetching interaction ${intrctnName} version ${intrctnVersion}`;

      this.interactionService.fetchInteractionsByIdAndVer(intrctnId, intrctnVersion)
        .subscribe(
          (interactionData) => {
            const interactionObj = this.interactionService.interactionObjectMapper(interactionData);
            this.workflow.interactions[intrctnIndex].interaction = interactionObj;
            this.workflow.interactions[intrctnIndex].isWaiting = false;
            this.getInteractionConditionSettingDialogData(intrctnName, intrctnIndex);
          },
          (err) => { this.workflow.interactions[intrctnIndex].isWaiting = false; console.log(err); throw err; },
          () => { this.workflow.interactions[intrctnIndex].isWaiting = false; }
        );
    }
  }

  /**
   * This function sets up interaction condition builder with the conditions from workflow interactions.
   *
   * @param intrctnName Name of the interaction whose conditions being modified
   * @param intrctnIndex Index of the interaction being modified
   */
  private getInteractionConditionSettingDialogData(intrctnName: any, intrctnIndex: number): void {
    this.workflowInteractionConditionViewer = true;
    this.workflowInteractionConditionName = intrctnName;
    // const dialogData = new GeneralDialogModel("Workflow Interaction's Condition dialog", `${intrctnName}`, 'Ok');
    // dialogData.extra = {};
    this.intrctnIndex = intrctnIndex;
    this.interaction = this.workflow.interactions[intrctnIndex].interaction;
    this.userGroups = this.evaGlobalService.userGroups;
    // If no interaction condition, create new one
    if (this.interactionConditions && this.interactionConditions.length === 0) {
      this.interactionConditions.push(
        new WorkFlowCondition(Guid.newGuid().toString(), "Start", "true", [], [], null, false,
          -1, -1, -1, -1, [], [], -1, -1, -1, -1, -1)
      );
    } else {
      this.interactionConditions.forEach(condition => {
        const trueInteraction = condition.trueDestination.find(destination => destination.type === 'interaction');
        const falseInteraction = condition.falseDestination.find(destination => destination.type === 'interaction');
        const trueCondition = condition.trueDestination.find(destination => destination.type === 'condition');
        const falseCondition = condition.falseDestination.find(destination => destination.type === 'condition');
        const trueGroups = condition.trueDestination.filter(destination => destination.type === 'group');
        const falseGroups = condition.falseDestination.filter(destination => destination.type === 'group');

        condition.selectedTrueInteractionVersion = trueInteraction ? trueInteraction.version : -1;
        condition.selectedFalseInteractionVersion = falseInteraction ? falseInteraction.version : -1;
        condition.selectedTrueConditionIndex = trueCondition ? trueCondition.name.split(' ').join('') : -1;
        condition.selectedFalseConditionIndex = falseCondition ? falseCondition.name.split(' ').join('') : -1;
        condition.selectedTrueGroups = [];
        condition.selectedFalseGroups = [];
        trueGroups.forEach(group => {
          condition.selectedTrueGroups.push(group.publicKey);
        });
        falseGroups.forEach(group => {
          condition.selectedFalseGroups.push(group.publicKey);
        });
      });
    }
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }
  }

  /**
   * This function opens a General Dialog with GeneralDialogComponent
   *
   * @param dialogModel GeneralDialogModel Dialog box data
   * @param callback callback function to be called on true
   * @param callbackData data being passed to callback function
   * @param callbackForFalse callback function to be called on false
   */
  public openGeneralDialog = (dialogModel: GeneralDialogModel, callback, callbackData: any, callbackForFalse = null): void => {
    const dialogRef = this.dialog.open(GeneralDialogComponent, { data: dialogModel, minWidth: '500px' });

    this.generalDialogChangeSubscription = this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid throwing error in case of dialogs which don't have callback function and callback data.
            callbackData['generalDialogOnChange'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callbackData.result = true;
          callback(callbackData);
        }
      } else {
        if (callbackForFalse) {
          callbackData.result = false;
          callbackForFalse(callbackData);
        }
      }
    });
  }

  /**
   * This function opens a Interaction Visualizer Dialog box with InteractionVisualizerDialogComponent
   *
   * @param dialogModel InteractionVisualizerDialogModel Dialog box data
   * @param callback callback function to be called on true
   * @param callbackData data being passed to callback function
   */
  private openInteractionVisualizerDialog(dialogModel: InteractionVisualizerDialogModel, callback?, callbackData?: any): void {
    const dialogRef = this.dialog.open(InteractionVisualizerDialogComponent, { data: dialogModel, minWidth: '560px' });

    this.generalDialogChangeSubscription = this.generalDialogService.generalDialogChanged$.subscribe(
      changeObj => {
        if (changeObj) {
          if (callbackData) {   // To avoid throwing error in case of dialogs which don't have callback function and callback data.
            callbackData['generalDialogOnChange'] = changeObj;
          }
        }
      },
      err => { console.log(err); }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }

  /**
   * This function toggles the dynamic workflow viewer
   */
  dynamicWorkflowViewer(): void {
    this.showDynamicWorkflowViewer = !this.showDynamicWorkflowViewer;
    this.selectedInteractionIndex = -1;
    this.workflowInteractionConditionViewer = false;
  }

  /**
   * This function toggles interaction condition builder
   */
  workflowInteractionConditionViewerSwitch(): void {
    this.workflowInteractionConditionViewer = !this.workflowInteractionConditionViewer;
    this.showDynamicWorkflowViewer = false;
  }

  /**
   * This function opens dialog box for creating new group
   */
  openNewGroupDialog(): void {
    const newGroupDialogRef = this.dialog.open(CreateGroupDialogComponent);
    newGroupDialogRef.afterClosed().subscribe(groupData => {
      if (groupData) {
        this.componentSubs.add(
          this.evaGlobalService.userGroupsChange$.subscribe(
            (userChangeMsg: boolean) => {
              const group = this.evaGlobalService.userGroups.filter(groupInfo => {
                return groupInfo['groupName'] === groupData['name']
                  && groupInfo['groupDescription'] === groupData['description']
                  && groupInfo['groupType'] === groupData['type'];
              });
              if (group.length > 0 && group[0]) {
                this.workflow.groupPublicKey = group[0]['groupPublicKey'];
                this.formBuilderGroup.controls['userGroup'].setValue(this.workflow.groupPublicKey);
                this.tab.additionalInstanceData.workflow = this.workflow;
                if (this.multiViewService.tabs[Routes.WorkflowBuilder]
                  && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
                  this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
                }
              }
            },
            (err) => { console.log(err); }
          )
        );
      }
    });
  }

  /**
   * This function prevents default action on dragOver event
   * @param event HTML dragOver event
   */
  allowdrop(event: any): void {
    event.preventDefault();
  }

  /**
   * This function re-orders the interactions on drag and drop event
   *
   * @param event HTML drop event
   * @param index Index of the interaction being switched with the interaction being dragged
   */
  reorderControl(event: any, index: number): void {
    if (this.movingItem === -1) {
      return;
    }
    const formElements = this.workflow.interactions;

    // [ arr[0], arr[1] ] = [ arr[1], arr[0] ] -- ES6 destructured swapping
    [formElements[this.movingItem], formElements[index]]
      = [formElements[index], formElements[this.movingItem]];
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }
    this.selectedInteractionIndex = index;
    this.movingItem = -1;
  }

  /**
   * This function stores the index for interaction being dragged
   * @param event HTML dragStart event
   * @param index Index of the interaction being dragged
   */
  onDragControl(event: any, index: number): void {
    this.movingItem = index;
  }

  /**
   * This function opens dialog box for removing a condition or if no changes have been made, removes the condition
   *
   * @param conditionIndex Index of the condition being removed
   */
  removeConditionConfirm(conditionIndex: number): void {
    const conditionName = this.interactionConditions[conditionIndex].name;

    if (this.interactionConditions[conditionIndex].trueDestination.length === 0
      && this.interactionConditions[conditionIndex].falseDestination.length === 0
      && this.interactionConditions[conditionIndex].condition === "true") {
      this.removeCondition({ that: this, conditionIndex: conditionIndex });
      return;
    }

    const dialogData = new GeneralDialogModel(
      'Delete Condition', `Are you sure to remove the ${conditionName} condition ?`, 'Yes', 'Cancel');
    this.openGeneralDialog(dialogData, this.removeCondition,
      {
        conditionIndex: conditionIndex, that: this
      });
  }

  /**
   * This function removes the condition in the interaction condition builder
   *
   * @param data Object from removeConditionConfirm function containing this object and index of condition being removed
   */
  removeCondition(data: {[key: string]: any, that: WorkflowBuilderComponent}): void {
    const condition = data.that.interactionConditions[data.conditionIndex];
    // Remove condition from any of the destinations for any condition before deleting the condition
    data.that.interactionConditions.forEach(cndtn => {
      cndtn.trueDestination.forEach((dest, destIndex) => {
        if (dest.type === 'condition') {
          if (condition.name === dest.name) {
            cndtn.selectedTrueConditionIndex = -1;
            cndtn.trueDestination.splice(destIndex, 1);
          }
        }
      });
      cndtn.falseDestination.forEach((dest, destIndex) => {
        if (dest.type === 'condition') {
          if (condition.name === dest.name) {
            cndtn.selectedFalseConditionIndex = -1;
            cndtn.falseDestination.splice(destIndex, 1);
          }
        }
      });
    });
    data.that.interactionConditions.splice(data.conditionIndex, 1);
  }

  /**
   * This function adds a new condition in interaction condition builder
   */
  addCondition(): void {
    if (!Array.isArray(this.interactionConditions)) { this.interactionConditions = []; }
    let length = this.interactionConditions.length + 1;
    let conditionWithSameNameIndex
      = this.interactionConditions.findIndex(cndtn => cndtn.name === `Condition ${length}`);
    while (conditionWithSameNameIndex !== -1) {
      length += 1;
      conditionWithSameNameIndex
        = this.interactionConditions.findIndex(cndtn => cndtn.name === `Condition ${length}`);
    }
    this.interactionConditions.push(new WorkFlowCondition(Guid.newGuid().toString(),
      `Condition ${length}`
      , "true", [], [], null, false, -1, -1, -1, -1, [], [], -1, -1, -1, -1, -1));
  }

  /**
   * This function is called when hovered on tab label to display delete button
   *
   * @param conditionIndex Index of the condition being hovered
   */
  onHoverTabLabel(conditionIndex: number): void {
    this.interactionConditions[conditionIndex].hovered = true;
  }

  /**
   * This function is called when hovered away from tab label to hide delete button
   *
   * @param conditionIndex Index of the condition being hovered away from
   */
  onHoverLeaveTabLabel(conditionIndex: number): void {
    this.interactionConditions[conditionIndex].hovered = false;
  }

  /**
   * This function updates the label of the tab
   * @param event HTML key down event
   * @param index Index of the tab being changed
   */
  onTabLabelChange(event: any, index: number): void {
    event.stopPropagation();
    if (event.keyCode === 13 || event.charCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }

  /**
   * This function checks for the validity of new tab label
   * @param event HTML focus out event
   * @param index Index of the tab being changed
   */
  onTabLabelBlur(event: any, index: number): void {
    const input = event.target.innerText;
    const conditionWithSameNameIndex = this.interactionConditions.findIndex((cndtn, cndtnIndex) =>
      cndtnIndex !== index && cndtn.name === input
    );
    if (input === '') {
      alert('Condition name cannot be empty');
      event.target.innerText = this.interactionConditions[index].name;
    } else if (conditionWithSameNameIndex !== -1) {
      alert('Condition name must be unique');
      event.target.innerText = this.interactionConditions[index].name;
    } else {
      this.interactionConditions.forEach(cndtn => {
        cndtn.trueDestination.forEach(dest => {
          if (dest.type === 'condition') {
            if (this.interactionConditions[index].name === dest.name) {
              dest.name = input;
            }
          }
        });
        cndtn.falseDestination.forEach(dest => {
          if (dest.type === 'condition') {
            if (this.interactionConditions[index].name === dest.name) {
              dest.name = input;
            }
          }
        });
      });
      this.interactionConditions[index].name = input;
    }
  }

  /**
   * This function sets up the dialog data for interaction condition builder dialog box
   *
   * @param interactionIndex Index of the interaction being updated
   * @param conditionIndex Index of the condition being updated
   */
  openConditionBuilder(interactionIndex: number, conditionIndex: number): void {
    const groupName = this.evaGlobalService.userGroups.filter(group => {
      return group['groupPublicKey'] === this.workflow.interactions[interactionIndex].interactionGroupPublicKey;
    })[0].groupName;
    const dialogData =
      new InteractionConditionDialogModel(
        `${groupName} Condition Builder`,
        `${this.workflow.interactions[interactionIndex].interactionName}`,
        this.workflow.interactions[interactionIndex].interaction,
        conditionIndex,
        this.interactionConditions[conditionIndex].condition,
        'Save', 'Cancel');
    this.openInteractionConditionBuilderDialog(dialogData, this.interactionConditionUpdate, { that: this, conditionIndex: conditionIndex });
  }

  /**
   * This function opens the dialog box with InteractionConditionDialogComponent
   *
   * @param dialogModel InteractionConditionDialogModel Data for Dialog box
   * @param callback Function that gets called on true
   * @param callbackData Data passed to callback function
   */
  private openInteractionConditionBuilderDialog(dialogModel: InteractionConditionDialogModel, callback?, callbackData?: any): void {
    const dialogRef = this.dialog.open(InteractionConditionDialogComponent, { data: dialogModel, minWidth: 80 + 'vw' });

    this.interactionConditionSetDialogChangeSubscription =
      this.interactionConditionBuilderService.conditionSetDialogChanged$
        .subscribe(
          changeObj => {
            if (changeObj) {
              if (callbackData) {   // To avoid throwing error in case of dialogs which don't have callback function and callback data.
                callbackData['interactionConditionDialogOnChange'] = changeObj;
              }
            }
          },
          err => { console.log(err); }
        );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }

  /**
   * This is the callback function for interactionConditionBuilderDialog box which updates the changes
   *
   * @param data Data from interactionConditionBuilderDialog containing condition updates
   */
  interactionConditionUpdate(data: {[key: string]: any, that: WorkflowBuilderComponent}): void {
    console.log(data.interactionConditionDialogOnChange);
    if (!(data && (data.conditionIndex || data.conditionIndex === 0) && data.interactionConditionDialogOnChange)) { return; }
    if (data.that.interactionConditions[data.conditionIndex]) {
      data.that.interactionConditions[data.conditionIndex].condition = data.interactionConditionDialogOnChange;

      const conditionChange = {
        conditionIndex: data.conditionIndex,
        condition: data.that.interactionConditions[data.conditionIndex].condition
      };

      data.that.interactionConditionViewerService.announceInteractionConditionChange(conditionChange);
      data.that.interactionConditionService.announceChange(data.that.interactionConditions);
    }
  }

  /**
   * This function searches for the interactions and workflows and updates the list
   *
   * @param event HTML keyUp event
   */
  onSearch(event): void {
    this.searchText = event.target.value;
    if (this.interactionsByGroups$) {
      Object.assign(this.interactionsByGroups, this.interactionsByGroups$);
    } else {
      return;
    }
    // Code for future workflow integration
    // if (this.workflowsByGroups$) {
    //   Object.assign(this.workflowsByGroups, this.workflowsByGroups$);
    // }
    if (event.target.value.trim() === '') {
      return;
    }

    this.evaGlobalService.userGroups.forEach(group => {
      // Code for future workflow integration
      /*
      if (this.workflowsByGroups$) {
        Object.assign(this.workflowsByGroups, this.workflowsByGroups$);
      }
      if ((this.workflowsByGroups && this.workflowsByGroups[group.groupPublicKey])
        && this.interactionsByGroups[group.groupPublicKey]) {
        this.interactionsByGroups[group.groupPublicKey]
          = this.interactionsByGroups[group.groupPublicKey].concat(this.workflowsByGroups[group.groupPublicKey]);
      } else if ((this.workflowsByGroups && this.workflowsByGroups[group.groupPublicKey])
        && !this.interactionsByGroups[group.groupPublicKey]) {
        this.interactionsByGroups[group.groupPublicKey] = this.workflowsByGroups[group.groupPublicKey];
      } else {
      */
      this.interactionsByGroups[group.groupPublicKey] = this.interactionsByGroups[group.groupPublicKey];
      // }
      // this.workflowsByGroups = [];
      this.interactionsByGroups[group.groupPublicKey]
        = this.interactionsByGroups[group.groupPublicKey] && this.interactionsByGroups[group.groupPublicKey]
          .filter(item => {
            return item.name.toLowerCase().includes(event.target.value.toLowerCase())
              || group.groupName.toLowerCase().includes(event.target.value.toLowerCase());
          });
    });
  }

  /**
   * This function changes the selected version for the interaction
   *
   * @param event MatSelect selectionChange event
   * @param interaction Interaction being updated
   */
  versionSelectionChange(event, interaction: any): void {
    interaction.selectedVersion = event.value;
  }

  /**
   * This function changes the selected groups in interaction condition builder
   *
   * @param event MatSelect selectionChange event
   * @param index Index of the condition being updated
   * @param conditionFlag Flag for whether true destination being updated or false
   */
  groupSelectionChange(event, index: number, conditionFlag: boolean): void {
    // If Group Selection Changed in True Destination Condition
    if (conditionFlag) {
      // If selected any group(s)
      if (event.value.length > 0) {
        this.groupSelectionChangeTrueDestination(event, index);
      } else {
        // Else If not selected any group, remove them from the True destination list
        this.interactionConditions[index]
          .selectedTrueGroups = [];
        this.interactionConditions[index].trueDestination
          = this.interactionConditions[index]
            .trueDestination.filter(condition => {
              return condition.type !== "group";
            });
      }
    } else {
      // Else If Group Selection Changed in False Destination Condition
      // If selected any group(s)
      if (event.value.length > 0) {
        this.groupSelectionChangeFalseDestination(event, index);
      } else {
        // Else If not selected any group, remove them from the False destination list
        this.interactionConditions[index]
          .selectedFalseGroups = [];
        this.interactionConditions[index].falseDestination
          = this.interactionConditions[index]
            .falseDestination.filter(condition => {
              return condition.type !== "group";
            });
      }
    }
  }

  /**
   * This function changes the selected groups in interaction condition builder in True Destination
   *
   * @param event MatSelect selectionChange event
   * @param index Index of the condition being updated
   */
  groupSelectionChangeTrueDestination(event: any, index: number): void {
    // If selected groups length is less than the true destination groups, remove the ones that got removed
    if (event.value.length < this.interactionConditions[index].selectedTrueGroups.length) {
      this.interactionConditions[index].selectedTrueGroups
        = this.interactionConditions[index].selectedTrueGroups.filter(value => event.value.includes(value));
    } else {
      // Else If selected groups length is greater than or equal to the true destination groups, update the selected groups list
      if (this.interactionConditions[index].selectedTrueGroups.length > 0) {
        event.value.forEach(group => {
          if (!this.interactionConditions[index]
            .selectedTrueGroups.includes(group)) {
            this.interactionConditions[index].selectedTrueGroups.push(group);
          }
        });
      } else {
        this.interactionConditions[index].selectedTrueGroups.push(event.value[0]);
      }
    }
    // Remove all groups from true destination list
    this.interactionConditions[index].trueDestination
      = this.interactionConditions[index]
        .trueDestination.filter(condition => {
          return condition.type !== "group";
        });

    const groups = [];
    // add all the groups in correct order back to True destination list
    this.interactionConditions[index]
      .selectedTrueGroups.forEach(selectedGroup => {
        groups.push(this.evaGlobalService.userGroups.filter(grp => grp.groupPublicKey === selectedGroup)[0]);
      });
    groups.forEach(group => {
      this.interactionConditions[index].trueDestination.push({
        "type": "group",
        "name": group.groupName,
        "publicKey": group.groupPublicKey,
        "icon": "fas fa-object-group"
      });
    });
  }

  /**
   * This function changes the selected groups in interaction condition builder in false destination
   *
   * @param event MatSelect selectionChange event
   * @param index Index of the condition being updated
   */
  groupSelectionChangeFalseDestination(event: any, index: number): void {
    // If selected groups length is less than the false destination groups, remove the ones that got removed
    if (event.value.length < this.interactionConditions[index].selectedFalseGroups.length) {
      this.interactionConditions[index].selectedFalseGroups
        = this.interactionConditions[index].selectedFalseGroups.filter(value => event.value.includes(value));
    } else {
      // Else If selected groups length is greater than or equal to the false destination groups, update the selected groups list
      if (this.interactionConditions[index]
        .selectedFalseGroups.length > 0) {
        event.value.forEach(group => {
          if (!this.interactionConditions[index]
            .selectedFalseGroups.includes(group)) {
            this.interactionConditions[index]
              .selectedFalseGroups.push(group);
          }
        });
      } else {
        this.interactionConditions[index].selectedFalseGroups.push(event.value[0]);
      }
    }
    // Remove all groups from false destination list
    this.interactionConditions[index].falseDestination
      = this.interactionConditions[index]
        .falseDestination.filter(condition => {
          return condition.type !== "group";
        });

    const groups = [];
    // add all the groups in correct order back to False destination list
    this.interactionConditions[index]
      .selectedFalseGroups.forEach(selectedGroup => {
        groups.push(this.evaGlobalService.userGroups.filter(grp => grp.groupPublicKey === selectedGroup)[0]);
      });

    groups.forEach(group => {
      this.interactionConditions[index].falseDestination.push({
        "type": "group",
        "name": group.groupName,
        "publicKey": group.groupPublicKey,
        "icon": "fas fa-object-group"
      });
    });
  }

  /**
   * This function changes the selected interaction in interaction condition builder
   *
   * @param event MatCheckbox change event
   * @param interaction Interaction being selected
   * @param interactionVersion Version of the interaction being selected
   * @param index Index of the condition being updated
   * @param conditionFlag Flag for whether true destination being updated or false
   */
  onInteractionCheckboxClick(interaction: any, interactionVersion: any, index: number, conditionFlag: boolean): void {
    // If Interaction Selection Changed in True Destination Condition
    if (conditionFlag) {
      // If Interaction checkbox is checked
      if (this.interactionConditions[index].selectedTrueInteractionVersion === -1) {
        this.onInteractionCheckboxClickTrueDestination(index, interactionVersion, interaction);
      } else if ('' + this.interactionConditions[index].selectedTrueInteractionVersion !== '' + interactionVersion) {
        this.interactionConditions[index].selectedTrueInteractionVersion = -1;
        this.interactionConditions[index].trueDestination
          = this.interactionConditions[index].trueDestination.filter(condition => {
              return condition.type !== "interaction";
            });
        this.onInteractionCheckboxClickTrueDestination(index, interactionVersion, interaction);
      } else {
        // else If Interaction checkbox is unchecked
        this.interactionConditions[index].selectedTrueInteractionVersion = -1;
        this.interactionConditions[index].trueDestination
          = this.interactionConditions[index].trueDestination.filter(condition => {
              return condition.type !== "interaction";
            });
      }
    } else {
      // Else If Interaction Selection Changed in False Destination Condition
      // If Interaction checkbox is checked
      if (this.interactionConditions[index].selectedFalseInteractionVersion === -1) {
        this.onInteractionCheckboxClickFalseDestination(index, interactionVersion, interaction);
      } else if ('' + this.interactionConditions[index].selectedFalseInteractionVersion !== '' + interactionVersion) {
        this.interactionConditions[index].selectedFalseInteractionVersion = -1;
        this.interactionConditions[index].falseDestination
          = this.interactionConditions[index].falseDestination.filter(condition => {
              return condition.type !== "interaction";
            });
        this.onInteractionCheckboxClickFalseDestination(index, interactionVersion, interaction);
      }  else {
        // else If Interaction checkbox is unchecked
        this.interactionConditions[index].selectedFalseInteractionVersion = -1;
        this.interactionConditions[index].falseDestination
          = this.interactionConditions[index].falseDestination.filter(condition => {
              return condition.type !== "interaction";
            });
      }
    }
  }

  /**
   * This function changes the selected interaction in interaction condition builder in True Destination
   *
   * @param index Index of the condition being updated
   * @param interactionVersion Version of the interaction being selected
   * @param interaction Interaction being selected
   */
  onInteractionCheckboxClickTrueDestination(index: number, interactionVersion: any, interaction: any): void {
    if (Array.isArray(this.interactionsByGroups$[interaction.groupPublicKey])) {
      this.interactionsByGroups$[interaction.groupPublicKey].push(interaction);
      this.interactionsByGroups[interaction.groupPublicKey].push(interaction);
    } else {
      this.interactionsByGroups$[interaction.groupPublicKey] = [interaction];
      this.interactionsByGroups[interaction.groupPublicKey] = [interaction];
    }
    this.interactionConditions[index].selectedTrueInteractionVersion = interactionVersion;
    // check if interaction already exists in true destination
    const flag = this.interactionConditions[index].trueDestination.filter(condition => {
        return condition.type === "interaction";
      });
    // if interaction exists remove it first before adding a new one
    if (flag.length > 0) {
      this.interactionConditions[index].trueDestination
        = this.interactionConditions[index].trueDestination.filter(condition => {
            return condition.type !== "interaction";
          });
    }
    this.interactionConditions[index].trueDestination.push({
      "type": "interaction",
      "name": `${interaction.name}`,
      "version": interactionVersion,
      "groupPublicKey": interaction.groupPublicKey,
      "id": interaction.id,
      "icon": "far fa-file"
    });
  }

  /**
   * This function changes the selected interaction in interaction condition builder in False Destination
   *
   * @param index Index of the condition being updated
   * @param interactionVersion Version of the interaction being selected
   * @param interaction Interaction being selected
   */
  onInteractionCheckboxClickFalseDestination(index: number, interactionVersion: any, interaction: any): void {
    if (Array.isArray(this.interactionsByGroups$[interaction.groupPublicKey])) {
      this.interactionsByGroups$[interaction.groupPublicKey].push(interaction);
      this.interactionsByGroups[interaction.groupPublicKey].push(interaction);
    } else {
      this.interactionsByGroups$[interaction.groupPublicKey] = [interaction];
      this.interactionsByGroups[interaction.groupPublicKey] = [interaction];
    }
    this.interactionConditions[index].selectedFalseInteractionVersion = interactionVersion;
    // check if interaction already exists in false destination
    const flag = this.interactionConditions[index].falseDestination.filter(condition => {
        return condition.type === "interaction";
      });
    // if interaction exists remove it first before adding a new one
    if (flag.length > 0) {
      this.interactionConditions[index].falseDestination
        = this.interactionConditions[index].falseDestination.filter(condition => {
            return condition.type !== "interaction";
          });
    }

    this.interactionConditions[index].falseDestination.push({
      "type": "interaction",
      "name": `${interaction.name}`,
      "version": interactionVersion,
      "groupPublicKey": interaction.groupPublicKey,
      "id": interaction.id,
      "icon": "far fa-file"
    });
  }

  /**
   * This function changes the selected condition in interaction condition builder
   *
   * @param event MatCheckbox change event
   * @param condition Condition being selected
   * @param index Index of the condition being updated
   * @param selectIndex Index of the condition being updated
   * @param conditionFlag Flag for whether true destination being updated or false
   */
  onConditionCheckboxClick(event: any, condition: any, index: number, selectIndex: any, conditionFlag: boolean): void {
    // If Condition Selection Changed in True Destination Condition
    if (conditionFlag) {
      // If Condition Checkbox is checked
      if (event.checked) {
        this.interactionConditions[index]
          .selectedTrueConditionIndex = selectIndex;
        // check if condition already exists in true destination
        const flag = this.interactionConditions[index]
          .trueDestination.filter(cndtn => {
            return cndtn.type === "condition";
          });
        // If condition exists remove it first before adding a new one
        if (flag.length > 0) {
          this.interactionConditions[index].trueDestination
            = this.interactionConditions[index]
              .trueDestination.filter(cndtn => {
                return cndtn.type !== "condition";
              });
        }
        this.interactionConditions[index].trueDestination.push({
          "type": "condition",
          "name": `${condition.name}`,
          "id": condition.id,
          "icon": "fa fa-random"
        });
      } else {
        // else If condition checkbox is unchecked
        this.interactionConditions[index]
          .selectedTrueConditionIndex = -1;
        this.interactionConditions[index].trueDestination
          = this.interactionConditions[index]
            .trueDestination.filter(cndtn => {
              return cndtn.type !== "condition";
            });
      }
    } else {
      // Else If Condition Selection Changed in False Destination Condition
      // If Condition Checkbox is checked
      if (event.checked) {
        this.interactionConditions[index]
          .selectedFalseConditionIndex = selectIndex;
        // check if condition already exists in false destination
        const flag = this.interactionConditions[index]
          .falseDestination.filter(cndtn => {
            return cndtn.type === "condition";
          });
        // If condition exists remove it first before adding a new one
        if (flag.length > 0) {
          this.interactionConditions[index].falseDestination
            = this.interactionConditions[index]
              .falseDestination.filter(cndtn => {
                return cndtn.type !== "condition";
              });
        }
        this.interactionConditions[index].falseDestination.push({
          "type": "condition",
          "name": `${condition.name}`,
          "id": condition.id,
          "icon": "fa fa-random"
        });
      } else {
        // else If condition checkbox is unchecked
        this.interactionConditions[index]
          .selectedFalseConditionIndex = -1;
        this.interactionConditions[index].falseDestination
          = this.interactionConditions[index]
            .falseDestination.filter(cndtn => {
              return cndtn.type !== "condition";
            });
      }
    }
  }

  /**
   * This function returns the conditions for interaction condition builder without current one
   *
   * @param condition Condition being removed from the condition list
   */
  getConditions(condition: WorkFlowCondition): WorkFlowCondition[] {
    const conditionIndex = this.interactionConditions.findIndex(cndtn => cndtn.id === condition.id);
    return this.interactionConditions.slice(conditionIndex + 1);
  }

  /**
   * This function returns the interactionVersions for interaction condition builder without current one
   *
   * @param interaction Condition being removed from the condition list
   */
  getInteractionVersions(interaction: any): any[] {
    return interaction.Versions.filter(version => {
      return version.version !== this.selectedInteractionVersion;
    });
  }

  /**
   * This function returns and combines userInteractions and userWorkflows arrays
   *
   * @param userGroup User group whose interactions and workflows are getting accessed
   */
  getCombinedArray(userGroup: any): any[] {
    // code for future workflow integration
    // if (this.workflowsByGroups
    //   && this.workflowsByGroups[userGroup.groupPublicKey]
    //   && this.workflowsByGroups[userGroup.groupPublicKey].length > 0) {
    //   return this.interactionsByGroups[userGroup.groupPublicKey]
    //     .concat(this.workflowsByGroups[userGroup.groupPublicKey]);
    // } else {
    return this.interactionsByGroups[userGroup.groupPublicKey];
    // }
  }

  /**
   * This function shows the my workflow page
   */
  showMyWorkflows(): void {
    this.componentSubs.add(
      this.canDeactivate().subscribe(value => { // Blocks user from navigating away from page if they have unsaved changes
        if (value === true) {
          if (this.userWorkflows.length > 0 || this.isWaitingForGroupWorkflows) {
            this.page = this.PageEnum.MyWorkflows;
          } else {
            if (!this.loadWorkflow) {
              this.page = this.PageEnum.MyWorkflows;
            } else {
              this.page = this.PageEnum.MyWorkflowsEmpty;
            }
          }
          this.workflowsData.filter = '';
        }
      })
    );
  }

  /**
   * This function loads the user workflows on button click
   */
  loadWorkflows(): void {
    this.loadWorkflow = true;
    this.getWorkflowsByGroups();
  }

  /**
   * This function shows create new workflow page in workflow builder
   */
  showCreateNewWorkflow = (): void => {
    this.chatService.setChatMinimizedState(true);
    // this.page = this.PageEnum.CreateNewWorkflow;
    // set everything to default
    // fetch user Interactions
    // this.getInteractionsByUserGroups({that: this});
    // this.entitySubscription = null;
    // this.showDynamicWorkflowViewer = false;
    // this.workflowInteractionConditionViewer = false;
    // this.createForm();
    const workflow = new WorkFlow(Guid.newGuid().toString(), '', '', null, Date.now(), this.isWorkflowEncrypted, [], [],
      null, null, null, null);
    // update workflow state, so user is prompted to save
    // const lastStringifiedAndSavedWorkflow = this.jsonStringify(this.workflow);

    this.multiViewService.setCreateNewTab({
      component: WorkflowBuilderComponent,
      tabName: 'New Workflow',
      additionalInstanceData: {
        componentTitle: this.componentTitle,
        page: this.PageEnum.CreateNewWorkflow,
        entitySubscription: null,
        showDynamicWorkflowViewer: false,
        workflowInteractionConditionViewer: false,
        lastStringifiedAndSavedWorkflow: this.jsonStringify(workflow),
        workflow: workflow
      },
      functionCalls: [{
        functionName: 'createForm'
      }]
    });
  }

  /**
   * This function filters the workflows based on their name
   *
   * @param filterValue User input search string
   */
  applyFilter(filterValue: string): void {
    this.workflowsData.filter = filterValue.trim().toLowerCase();

    if (this.workflowsData.paginator) {
      this.workflowsData.paginator.firstPage();
    }
  }

  /**
   * This function starts the editing process for the workflow
   */
  startEdit = (row: WorkFlow): void => {
    const changeObj = {
      "groupublicKey": row.groupPublicKey,
      "entityId": row.id,
      "entityVersion": row['version']
    };

    this.generalDialogService.announceChange(changeObj);
    this.getInteractionsByUserGroups({});
    this.editWorkflow({
      that: this,
      generalDialogOnChange: changeObj
    });
  }

  /**
   * This function opens dialog box for activating workflow
   *
   * @param workflow workflow being activated
   */
  onActiveVersion = (workflow: WorkFlow) => {
    const dialogData = new GeneralDialogModel(
      'Workflow Activation Dialog', `Do you want to activate ${workflow['version']}
       version of workflow ? <br> :: ${workflow.name} ::`, 'Yes', 'No');
    this.openGeneralDialog(dialogData, this.activateWorkflow, { that: this, workflow: workflow });
  }

  /**
   * This function activates the selected version for the workflow
   *
   * @param data { that: this, workflow: workflow }
   */
  activateWorkflow(data: {[key: string]: any, that: WorkflowBuilderComponent}) {
    const workflow = data.workflow;
    const that = data.that;

    that.isWaiting = true;
    that.waitMessage = `Activating ${workflow.name} workflow with version ${workflow['version']}`;

    that.workflowService.activateWorkflowVersion(workflow.id, workflow['version'])
      .subscribe(
        (resultObj: any) => {
          workflow.activeVersion = workflow['version'];
        },
        (err: any) => { console.log(err); },
        () => {
          that.isWaiting = false;
          that.waitMessage = '';
        }
      );
  }

  /**
   * This function fetches data for the selected workflow for editing
   *
   * @param data The data of the workflow being fetched
   */
  editWorkflow(data: {[key: string]: any, that: WorkflowBuilderComponent}): void {
    this.chatService.setChatMinimizedState(true);
    // set everything to default
    // data.that.page = data.that.PageEnum.None;
    data.that.showDynamicWorkflowViewer = false;
    data.that.workflowInteractionConditionViewer = false;
    if (!data || !data.that || !data.generalDialogOnChange) {
      return;
    }

    if (data.generalDialogOnChange.groupublicKey &&
      data.generalDialogOnChange.entityId &&
      data.generalDialogOnChange.entityVersion) {

      const workflowId = data.generalDialogOnChange.entityId;
      const version = data.generalDialogOnChange.entityVersion;
      const versionToDate = new Date(Number(version));

      data.that.isWaiting = true;
      data.that.waitMessage = `Fetching Workflow ${workflowId} version ${versionToDate}`;

      data.that.entitySubscription = data.that.workflowService.fetchWorkflowsByIdAndVer(workflowId, version)
        .subscribe(
          (workflowData) => {
            const workflow = data.that.workflowService.workflowObjectMapper(workflowData);

            const lastStringifiedAndSavedWorkflow = data.that.jsonStringify(workflow);
            const patchObj = {
              name: workflow['name'],
              userGroup: workflow['groupPublicKey'],
              subGroup: workflow['subGroup']
            };
            // data.that.formBuilderGroup.patchValue(patchObj);

            data.that.multiViewService.setCreateNewTab({
              component: WorkflowBuilderComponent,
              tabName: workflow.name,
              additionalInstanceData: {
                componentTitle: data.that.componentTitle,
                page: data.that.PageEnum.CreateNewWorkflow,
                entitySubscription: null,
                showDynamicWorkflowViewer: false,
                workflowInteractionConditionViewer: false,
                workflow: workflow,
                lastStringifiedAndSavedWorkflow: lastStringifiedAndSavedWorkflow,
                formBuilderGroupValues: patchObj
              }
            });
          },
          (err) => {
            data.that.isWaiting = false;
            console.log(err);
            data.that.page = data.that.PageEnum.MyWorkflows;
          },
          () => {
            data.that.isWaiting = false;
            // data.that.page = data.that.PageEnum.CreateNewWorkflow;
            data.that.page = data.that.PageEnum.MyWorkflows;
          }
        );
    }
  }

  /**
   * This function stores the selected version of workflow from the drop down
   */
  setSelectedVersion(event, workflow: WorkFlow): void {
    workflow.selectedVersion = event.value;
  }

  /**
   * This function opens the preview for workflow
   *
   * @param workflow Workflow being previewed
   */
  openPreview(workflow: WorkFlow): void {
    // open a workflow visualizer here
  }

  /**
   * This function saves the interaction condition builder changes
   */
  saveConditionBuilderChanges(): void {
    this.workflow.interactions[this.selectedInteractionIndex].conditions = JSON.parse(JSON.stringify(this.interactionConditions));
    this.interactionConditions.forEach(condition => {
      const destinationArray = ['trueDestination', 'falseDestination'];
      destinationArray.forEach(destinationType => {
        condition[destinationType].forEach(destination => {
          if (destination.type === 'interaction') {
            if (!this.workflow.interactions.find(interaction => interaction.interactionId === destination.id
              && Number(interaction.interactionVersion) === Number(destination.version))) {
              this.workflow.interactions.push(new WorkflowInteraction(destination.id, destination.name,
                Number(destination.version), destination.groupPublicKey, null, false, null, [], null, null, null,
                null));
            }
          }
        });
      });
    });
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }
    this.interactionConditionService.announceWorkflowInteractionConditionChange(this.interactionConditions);
    this.interactionConditions = [];
    this.interactionConditions.length = 0;
    this.workflowInteractionConditionViewerSwitch();
  }

  /**
   * This function re-orders the conditions on drag and drop event
   *
   * @param event HTML drop event
   * @param index Index of the condition being switched with the condition being dragged
   */
  reorderScreens(event: any, index: number): void {
    if (this.movingCondition === -1) {
      return;
    }
    const conditions = this.interactionConditions;

    // [ arr[0], arr[1] ] = [ arr[1], arr[0] ] -- ES6 destructured swapping
    [conditions[this.movingCondition], conditions[index]]
      = [conditions[index], conditions[this.movingCondition]];
    this.movingCondition = -1;
  }

  /**
   * This function stores the index for condition being dragged
   * @param event HTML dragStart event
   * @param index Index of the condition being dragged
   */
  onDrag(event: any, index: number): void {
    this.movingCondition = index;
  }

  addInteraction(interaction: any, version: number) {
    const newInteraction = new WorkflowInteraction(interaction.id, interaction.name, version, interaction.groupPublicKey,
      null, null, null, [], null, null, null, null);
    this.workflow.interactions.push(newInteraction);
    this.tab.additionalInstanceData.workflow = this.workflow;
    if (this.multiViewService.tabs[Routes.WorkflowBuilder] && this.multiViewService.tabs[Routes.WorkflowBuilder][this.tabIndex]) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.WorkflowBuilder, "Update", this.tab, this.tabIndex);
    }
  }

  /**
   * This function shows the current workflow ID
   */
  showWorkflowId(): void {
    this.loggingService.logMessage('Workflow ID is ' + this.workflow.id, false, 'info');
  }

  getAllWorkflowInteractions(interactionsArray: WorkflowInteraction[]): void {
    this.workflow.interactions.forEach((interaction, interactionIndex) => {
      if (interactionIndex === 0) {
        interactionsArray.push(interaction);
        this.getAllDestinationInteractions(interaction, interactionsArray);
      }
    });
  }

  getAllDestinationInteractions(interaction: WorkflowInteraction, interactionsArray: WorkflowInteraction[]): void {
    interaction.conditions.forEach(condition => {
      condition.trueDestination.forEach(destination => {
        if (destination.type === 'interaction') {
          const fetchedInteraction = this.workflow.interactions.find(workflowInteraction =>
            workflowInteraction.interactionId === destination.id);
          if (fetchedInteraction) {
            interactionsArray.push(fetchedInteraction);
            this.getAllDestinationInteractions(fetchedInteraction, interactionsArray);
          }
        }
      });
      condition.falseDestination.forEach(destination => {
        if (destination.type === 'interaction') {
          const fetchedInteraction = this.workflow.interactions.find(workflowInteraction =>
            workflowInteraction.interactionId === destination.id);
          if (fetchedInteraction) {
            interactionsArray.push(fetchedInteraction);
            this.getAllDestinationInteractions(fetchedInteraction, interactionsArray);
          }
        }
      });
    });
  }

  /**
   * This function converts any json object to string
   *
   * @param object JSON object to stringify
   */
  jsonStringify(object: any): string {
    return JSON.stringify(object, this.utilsService.getCircularReplacer());
  }

  /**
   * This returns true if the current workflow is the same as the last saved dynamic form.
   */
  isNotEdited() {
    if (!this.lastStringifiedAndSavedWorkflow) {
      return;
    }
    const stringifiedWorkflow: WorkFlow = JSON.parse(this.lastStringifiedAndSavedWorkflow);
    stringifiedWorkflow.interactions = stringifiedWorkflow.interactions.map(interaction => {
      interaction.conditions = interaction.conditions.map(condition => {
        return new WorkFlowCondition(condition.id, condition.name, condition.condition, condition.trueDestination,
          condition.falseDestination, condition.order, condition.hovered, condition.selectedTrueInteractionVersion,
          condition.selectedFalseInteractionVersion, condition.selectedTrueConditionIndex, condition.selectedFalseConditionIndex);
      });
      return new WorkflowInteraction(interaction.interactionId, interaction.interactionName, interaction.interactionVersion,
        interaction.interactionGroupPublicKey, interaction.interaction, interaction.isWaiting, interaction.onWaitMessage,
        interaction.conditions, interaction.order, interaction.submitted, interaction.activated, interaction.activeVersion,
        interaction.index);
    });
    const workflow = new WorkFlow(stringifiedWorkflow.id, stringifiedWorkflow.name, stringifiedWorkflow.groupType,
      stringifiedWorkflow.groupPublicKey, stringifiedWorkflow.timestamp, stringifiedWorkflow.encryptedByDefault,
      stringifiedWorkflow.descriptions, stringifiedWorkflow.interactions, stringifiedWorkflow.activeVersion,
      stringifiedWorkflow.activated, stringifiedWorkflow.subGroup, stringifiedWorkflow.selectedVersion);
    this.workflow.interactions.forEach((interaction, index) => {
      if (!(interaction instanceof WorkflowInteraction)) {
        workflow.interactions[index] = Object.assign({}, workflow.interactions[index]);
      }
    });
    workflow.interactions?.forEach(interaction => {
      Object.keys(interaction).forEach(key => {
        if (interaction[key] === undefined) {
          delete interaction[key];
        }
      });
      interaction.conditions?.forEach(condition => {
        Object.keys(condition).forEach(key => {
          if (condition[key] === undefined || condition[key] === -1 || key === 'selectedFalseGroups' || key === 'selectedTrueGroups') {
            delete condition[key];
          }
        });
      });
    });
    this.workflow.interactions?.forEach(interaction => {
      Object.keys(interaction).forEach(key => {
        if (interaction[key] === undefined) {
          delete interaction[key];
        }
      });
      interaction.conditions?.forEach(condition => {
        Object.keys(condition).forEach(key => {
          if (condition[key] === undefined || condition[key] === -1 || key === 'selectedFalseGroups' || key === 'selectedTrueGroups') {
            delete condition[key];
          }
        });
      });
    });
    return JSON.stringify(workflow) === JSON.stringify(this.workflow);
  }

//#region Token Functions

  /**
   * This gets the search token on processes
   */
  private async getSearchToken(): Promise<void> {
    this.searchAuthService.getSearchToken(AlgoliaTokenType.WORKFLOW_BUILDER).pipe(take(1)).subscribe(
      (token) => {
        this.searchToken = token;

        this.instantSearchService.clearSearchConfig(this.algoliaIndex);

        this.setInteractionSearchFilters(); // set the interaction search filters.
        this.setInteractionPropertyNameMapping(); // set the property mappings for interactions
        this.setWorkflowSearchFilters(); // set the search filters for workflows.
        this.setWorkflowPropertyNameMapping(); // set the property mappings for workflows
        this.setDefaultColumnDefinitions();
        this.setInteractionAndWorkflowGroups(); // start the process of getting interaction and workflow groups.

        this.rowHeight = 48;
        this.context = { componentParent: this };

        this.setColumnDefinitions();

        this.currentlyLoading = false;
        if (this.searchToken.securedAPIKey === '') {
          this.searchAccess = true;
        }

        return Promise.resolve();
      },
      (err) => Promise.reject(err)
    );
  }

//#region Setup Search Grid Items.

//#region Interaction Settings

  /**
   * this sets the interaction search filters.
   */
  private setInteractionSearchFilters() {
    this.interactionFilters = [{
      filterName: ['Group'],
      defaultValue: [null],
      attribute: 'groupPublicKey',
      type: 'select',
      filterValueModifier: ValueModifier.GroupName
    }];
  }

  /**
   * This sets the interaction property name mapping for search.
   */
  private setInteractionPropertyNameMapping() {
    this.interactionPropertyNameMapping = {
      properties: [
        { propertyName: 'groupPublicKey', propertyUserFriendlyName: 'Group Name', valueModifiers: [ValueModifier.GroupName] },
        { propertyName: 'Versions', propertyUserFriendlyName: 'Last Modified',
          valueModifiers: [ValueModifier.ParseJSONString], defaultValueProperty: 'version' }
      ]
    };
  }

//#endregion Interaction Settings

//#region Workflow Settings

  /**
   * this sets the workflow search filters.
   */
  private setWorkflowSearchFilters() {
    this.workflowFilters = [{
      filterName: ['Group'],
      defaultValue: [null],
      attribute: 'groupPublicKey',
      type: 'select',
      filterValueModifier: ValueModifier.GroupName
    }];
  }

  /**
   * This sets the interaction property name mapping for search.
   */
  private setWorkflowPropertyNameMapping() {
    this.workflowPropertyNameMapping = {
      properties: [
        { propertyName: 'groupPublicKey', propertyUserFriendlyName: 'Group Name', valueModifiers: [ValueModifier.GroupName] },
        { propertyName: 'Versions', propertyUserFriendlyName: 'Last Modified',
          valueModifiers: [ValueModifier.ParseJSONString], defaultValueProperty: 'version' }
      ]
    };
  }

//#endregion Workflow Settings


  /**
   * This sets the column definitions for search.
   */
  private setColumnDefinitions(): void {
    this.columnDefs = [
      (this.searchUtils.getColumnDefinition('Name', 'name')),
      (this.searchUtils.getColumnDefinition('ID', 'id')),
      {...(this.searchUtils.getColumnDefinition('Group Name', 'groupPublicKey')),
      cellRenderer: (data) => {
        return this.valueModifierService.modifyValues(ValueModifier.GroupName, data.data.groupPublicKey) as string;
      }},
      {...(this.searchUtils.getColumnDefinitionForDateTime('Last Modified', 'Versions')),
      cellRenderer: (data) => {
        const versions: any[] = this.valueModifierService.modifyValues(ValueModifier.ParseJSONString, data.data.Versions) as any[];
        versions.sort((a, b) => Number(b['version']) - Number(a['version']));
        return this.valueModifierService.modifyValues(ValueModifier.DateShort,
          versions.length > 0 ? versions[0]['version'] : '') as string;
      }},
      {...(this.searchUtils.getColumnDefinitionForDateTime('Action', 'Versions')),
      cellRenderer: 'actionTemplateRenderer'}
    ];
  }

  /**
   * This sets the default column definitions.
   */
  private setDefaultColumnDefinitions(): void {
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true,
      tooltipComponent: 'CustomTooltipComponent'
    };
  }

//#endregion Setup Search Grid Items.

//#endregion Token Functions

}
