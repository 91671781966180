export const environment = {
  production: false,
  env: 'Dev',
  appName: 'ATB EVA WIKI STAGING',
  authApp: 'eva-wiki-test',
  // eslint-disable-next-line max-len
  evaGlobalGroup: '044025ef669e957f567866cce24672e635f2779d58804fe6b1306a2d25ff071203c3c5db9f72821fe191f0a7d82ce35dee4fa7a28f72b4b2f5a636d03f8a9ddc66',
  // eslint-disable-next-line max-len
  atbGlobalGroup: '04f0d9483a19b2a3fe5e86c4813245bb123a5edd65db3b0780fc7893fe74f01232df08db7c07e1623691c294f90111d2985208b15624717b7305d6fea68e34f90f',
  atbGlobalSubGroups: [
    '04704337385502c0125dbdd5d0cba557f96b7aba58785f1f3aa1725aae80e2c60c37d05aed975e2e50aca02780e8ae79bb4bce7ae23a5c9fde6a27292b873696da',
    '04ae8195500dd43579e035ba9ba27f0d933823076d19d943f469572a42ee1d40551796a76f0eb2ac693cce3cad9a36664a86e14231cfe186a664b6734ec036a32e',
    '04b55612f499c9b6f37040acb81ee005a05789bad64746c7d6d0bddfab0163d882c0656bd58179abff467ac944359bb808df8f274871d76243f1515028f1d50b93',
    '0409921fe4b7c0e634693d90350048aa86e98021a3ae5a96b08166f911b2e9f6b75914fe72fd1fd2350a26ce3c72859fcb02448c96e2e9b618eca6b028a78272dc',
    '04c18628ce4eab27b923c87132ff63c7f7d48d7afb0e473ee81da330ea32aee804997e553f1ea624be3f267c90ee3a531ea21522014f05d1ea64e1ee5bf27e6668',
    '0463bef6847d84718e47df733b44b91429537534c1e3b2ced6f251370609887faa169c58306a0f9f661d247b382a5b1f520514f3aee53f49398bd50c614638acf3'
  ],
  // eslint-disable-next-line max-len
  rcmGroup: '0484b6a97b8bd74269423048b2ae94c3fe428577eedb72064bce3b2938d047276a70508234d1f74f1cac7b7404631d9232f567fa5647451bd9b73e85b26654ca3f',
  fullPath: 'staging.atbeva.com',
  notificationResultsPerGroupLimit: 10,   // Limited number of process notifications ready for "Action" per group.
  workingOnProcessesLimit: 10,            // Limited number of process notifications ready for "Accept"
  firebaseFunction: {
    endpoint: 'https://us-central1-eva-wiki-test.cloudfunctions.net',
    createNewScheduledTask: '/createNewScheduledTask',
    sendCommunicationLeadEmail: '/sendCommunicationLeadEmail'
  },
  firebaseConfig: {
    apiKey: "AIzaSyDnIfueoSpQ0Gnu_arXTGuRKxytQor_pno",
    authDomain: "eva-wiki-test.firebaseapp.com",
    databaseURL: "https://eva-wiki-test.firebaseio.com",
    projectId: "eva-wiki-test",
    storageBucket: "eva-wiki-test.appspot.com",
    messagingSenderId: "1081366721249",
    appId: "1:1081366721249:web:150e508658e6035e20fda0"
  },
  blockConfig: {
    SIGNING_CURVE: 'p256',
    ENCRYPTION_CURVE: 'p256',
    pbkdf2: 'sha256',
    pbkdf2ByteSize: 128,
    pbkdf2IVByteSize: 32,
    globalDatabase: {
      publicKeyPath: 'PublicKeys'
    },
    types: {
      dynamicInteractions: {
        typeId: 'RmFyaGFkJ3MgYW1hemluZyBkeW5hbWljIGludGVyYWN0aW9ucw==',
        knownKeyInteractionPath: 'ATBDynamicInteractions',
        publicKeyInteractionPath: 'PublicDynamicInteractions'
      },
      groups: {
        typeId: 'SSBmZWxsIGRvd24uIE15IGdyb3VwIG9mIGZyaWVuZHMgcGlja2VkIG1lIHVwLg==',
        groupSigningPath: 'GroupSigningKeys',
        knownGroupEncryptionPath: 'ATBGroupEncryptionKeys',
        types: {
          dynamicData: 'RG9uJ3QgbGV0IHlvdXIgZGF0YSBnbyBzdGF0aWMsIGJlIGR5bmFtaWM=',
          flowThrough: 'QmVuIHNheXMgImdvIHdpdGggdGhlIGZsb3ci',
          invitation: 'SWxpa2VEaW5uZXJQYXJ0aWVzIQ==',
          knowledge: 'VGhlIG9ubHkgdHJ1ZSB3aXNkb20gaXMgaW4ga25vd2luZyB5b3Uga25vdyBub3RoaW5n',
          normal: 'Tm9ybWFsR3JvdXBSdWxlcw=='
        }
      },
      workflows: {
        typeId: 'VG8gd2FsayB1cHN0cmVhbSBpbiBhIHJpdmVyIGFuZCB0aHJvdWdoIGEgd2F0ZXJmYWxsLCBpcyBoYXJkIQ==',
        knownKeyWorkflowPath: 'ATBWorkflows',
        publicKeyWorkflowPath: 'PublicWorkflows'
      }
    }
  },
  searchConfig: {
    REQUESTER_EMAIL_KEY: 'b870cfb6-bfa3-45e2-b171-ac10af80de49',
    REQUIREMENT_EMAIL_KEYS: [
      '5ad66abb-5562-492f-ab08-b4d3ee3ff9a4',
      'a0b8bf4e-597f-46eb-91be-ffed82f0397b',
      '0a0c5e0d-8477-429d-84e9-073f55ba2fd5'
    ]
  },
  endPoints: {
    EVA_HEART: {
      url: 'https://us-central1-eva-heart-wiki.cloudfunctions.net/',
      query: 'incomingEVAQuery',
      addTraining: 'trainingEVAData',
      addUpdateKnowledge: 'addUpdateKnowledge',
      knowledgeByGroupDocIdAndVersion: 'getEVAKnowledgeByGroupDocIdAndVersion',
      newestKnowledgeByGroupDocId: 'getNewestEVAKnowledgeByGroupDocId',
      addKnowledge: 'addEVAKnowledge',
      getKnowledge:  'getEVAKnowledge',
      getKnowledgeList: 'getEVAKnowledgeList',
      insertKnowledge: 'insertEVAKnowledge',
      updateKnowledge: 'updateEVAKnowledge',
      addRemovedImages: 'addRemovedImages',
      deleteRestoreKnowledge: 'deleteRestoreKnowledgeDoc',
      createNewScheduledTask: 'createNewScheduledTask',
      exportKnowledge: 'exportKnowledge'
    },
    EVA_STORAGE: {
      urlATB: 'https://us-central1-eva-links-atb-wiki.cloudfunctions.net/',
      urlNonATB: 'https://us-central1-eva-links-wiki.cloudfunctions.net/',
      addLocationData: 'storeEVAEncryptedData',
      getLocationData: 'getEVAEncryptedData'
    },
    DYNAMIC_INTERACTIONS: {
      url: 'https://us-central1-wiki-dynamic-form-test.cloudfunctions.net/'
    },
    SEARCH: {
      url: 'https://us-central1-atb-eva-search-wiki.cloudfunctions.net/'
    },
    WORKFLOW: {
      url: 'https://us-central1-wiki-workflow-test.cloudfunctions.net/'
    },
    BLOCK_ENDPOINT: {
      url: 'https://us-central1-wiki-block-genesis-test.cloudfunctions.net/'
    },
    GOOGLE_DRIVE: {
      url: 'https://www.googleapis.com/drive/v3/'
    },
    EVA_FF: {
      url: 'https://us-central1-eva-wiki-test.cloudfunctions.net/',
      getActiveWorkflowById: 'getActiveWorkflowById'
    }
  },
  algolia: {
    appId: 'EV76H5OXCD',
    apiKey: '',
    indexEnding: '_WIKI'
  },
  froala: {
    key: '4NC5fE4C3E4E3B2C4B-16UJHAEFZMUJOYGYQEa2d2ZJd1RAeF3C8B5E5E3D3G2G3A17A6=='
  },
  cpAddressCompleteFind: {
    url: 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/2.1/json3.ws',
    key: 'fe81-fc68-bj96-cb99'
  },
  cpAddressCompleteRetrieve: {
    url: 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws',
    key: 'fe81-fc68-bj96-cb99'
  },
  defaultTutorials: {
    // this is a list of tutorial definitions and the current completion value defaulted to false
    //
    // Tutorials Attributes: tutorials are defined by adding key: value pair attributes (ie. "welcome: false")
    //    adding a new tutorial is as simple as adding a new attribute (ie. "new_tutorial_name: false")
    //
    // Versioning: increment the version number to indicate that the list of tutorials has changed and all users
    //    will have their list of tutorials updated by the tutorials service
    //
    // sample usage: tutorial usage pattern example can be found in the saltchat component
    version: 7,
    tutorialList: {
      welcome: false,
      feedback: false,
      processDashboard: false,
      documentFeedback: false
    }
  }
};
