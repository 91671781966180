import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ProcessStatusRecord, ProcessStatusReportRequestBody } from '@eva-model/process/process';
import { trace } from '@angular/fire/compat/performance';

@Injectable({
  providedIn: 'root'
})
export class ProcessStatusService {

  processes$: Observable<any[]>;

  constructor(
    private evaGlobalSrv: EvaGlobalService,
    private http: HttpClient
  ) { }

  /**
   * Fetch process status report data based on passing time period parameters
   *
   * @from :: Starting timestamp parameter for process status report period of time
   * @to :: Ending timestamp parameter for process status report period of time
   * @return {ProcessStatusRecord[] | null} returns either the process status record or null
   */
  async getProcessStatusReport(from: number, to: number): Promise<ProcessStatusRecord[] | null> {
    const url = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'processStatus';

    try {

      // Body object of the POST method to pass user if and period of time parameters
      const bodyData: ProcessStatusReportRequestBody = {
        'uid': this.evaGlobalSrv.userId,
        'from': from,
        'to': to
      };

      // POST the request to fetch process status report data
      const processesStatus = await this.http.post<ProcessStatusRecord[]>(url, bodyData).pipe(
        trace('process-status-getProcessStatusReport')
      ).toPromise();
      return processesStatus;

    } catch ( err ) {
      // Throw error to get handled in calling level as a best practice
      throw err;

    }
  }

}
