<!-- font awesome code pen example for svg codes:
https://codepen.io/fontawesome/pen/mZOqKp
-->
<nav class="navbar" [class.expand-navbar]="(menuNavigationService.isExpanded$ | async)" [class.minimized-full-chat]="(chatService.isChatMinimized$ | async)">
  <!-- logo -->
  <ul class="navbar-nav">
    <li class="logo">
      <a routerLink="/" class="nav-link nav-link-home" (click)="resetMainMenu()">
        <span class="link-text logo-text">EVA</span>
        <svg *ngIf="!appReload" width="34px" height="34px" viewBox="0 -960 960 960" class="bi bi-house-door" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z"/>
        </svg>
        <mat-icon (click)="applicationReload()" class="app-reload" *ngIf="appReload" [matTooltip]="reloadMessage" color="primary">refresh</mat-icon>
      </a>
    </li>
  </ul>

  <!-- This is the main menu -->
  <div class="menu-items-container">
    <!-- <div style="flex: 1 0 0; position: relative;" [@slideListLeft]="menuItems.length && currentMenuName !== 'main'" [@slideListRight]="menuItems.length && currentMenuName === 'main'"> -->
    <div style="flex: 1 0 0; position: relative;">
      <ul class="navbar-nav" *ngFor="let menu of menus" [@menuMovement]="currentMenuName === 'main' ? 'right' : 'left'" [class.minimized-full-chat]="(chatService.isChatMinimized$ | async)">
        <li class="nav-item" [class.nav-item-active]="isActiveRoute(menuItem.routerLink)" *ngFor="let menuItem of menu" #tooltip="matTooltip" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
          [matTooltip]="menuItem.text" matTooltipPosition="right" matTooltipHideDelay="100" [attr.id]="menuItem.id ? menuItem.id : null">
          <a *ngIf="menuItem.isBackButton" (click)="resetMainMenu()" class="nav-link">
            <div *ngIf="menuItem.svgIcon && menuItem.svgIcon.path.length > 0">
              <svg [ngClass]="menuItem.svgIcon.svgClass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="menuItem.svgIcon.viewBox">
                <path *ngFor="let path of menuItem.svgIcon.path" fill="currentColor" [attr.d]="path.d" [ngClass]="path.class"></path>
              </svg>
            </div>
            <span class="link-text">{{ menuItem.text }}</span>
          </a>
          <a *ngIf="menuItem.routerLink" [routerLink]="menuItem.routerLink" class="nav-link">
            <div *ngIf="menuItem.svgIcon && menuItem.svgIcon.path.length > 0">
              <svg [ngClass]="menuItem.svgIcon.svgClass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="menuItem.svgIcon.viewBox">
                <path *ngFor="let path of menuItem.svgIcon.path" fill="currentColor" [attr.d]="path.d" [ngClass]="path.class"></path>
              </svg>
              <span *ngIf="isValidBadgeCount(menuItem)" class="nav-link-badge">{{ getBadgeCount(menuItem) }}</span>
            </div>
            <span class="link-text">{{ menuItem.text }}</span>
          </a>
          <a *ngIf="menuItem.isEmptyRoute" (click)="updateMenu(menuItem.emptyRouteName)" class="nav-link">
            <div *ngIf="menuItem.svgIcon && menuItem.svgIcon.path.length > 0">
              <svg [ngClass]="menuItem.svgIcon.svgClass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="menuItem.svgIcon.viewBox">
                <path *ngFor="let path of menuItem.svgIcon.path" fill="currentColor" [attr.d]="path.d" [ngClass]="path.class"></path>
              </svg>
              <span *ngIf="isValidBadgeCount(menuItem)" class="nav-link-badge">{{ getBadgeCount(menuItem) }}</span>
            </div>
            <span class="link-text">{{ menuItem.text }}</span>
          </a>
          <a *ngIf="menuItem.isLogout" (click)="logoutUser()" class="nav-link">
            <div *ngIf="menuItem.svgIcon.path.length > 0">
              <svg [ngClass]="menuItem.svgIcon.svgClass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="menuItem.svgIcon.viewBox">
                <path *ngFor="let path of menuItem.svgIcon.path" fill="currentColor" [attr.d]="path.d" [ngClass]="path.class"></path>
              </svg>
            </div>
            <span class="link-text">{{ menuItem.text }}</span>
          </a>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav">
      <li class="nav-item launch-pad" #tooltip="matTooltip" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
        matTooltip="Notifications" matTooltipPosition="right" matTooltipHideDelay="100">
        <!-- <a [routerLink]="'/notifications'" class="nav-link"> -->
        <a (click)="openNotifications()" class="nav-link">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="fix-width" viewBox="0 -960 960 960">
            <path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z"/>
          </svg>
          <span *ngIf="notificationsService.notifications.length > 0 || notificationsService.invites.length > 0 || notificationsService.requests.length > 0" class="nav-link-badge">{{ notificationsService.notifications.length + notificationsService.invites.length + notificationsService.requests.length }}</span>
          <span class="link-text">Notifications</span>
        </a>
      </li>
      <li class="nav-item launch-pad launch-pad-button" (click)="clickLaunchPad()" #tooltip="matTooltip" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
        matTooltip="Bookmarks" matTooltipPosition="right" matTooltipHideDelay="100">
        <a class="nav-link">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="fix-width" viewBox="0 -960 960 960">
            <path d="M160-80v-560q0-33 23.5-56.5T240-720h320q33 0 56.5 23.5T640-640v560L400-200 160-80Zm80-121 160-86 160 86v-439H240v439Zm480-39v-560H280v-80h440q33 0 56.5 23.5T800-800v560h-80ZM240-640h320-320Z"/>
          </svg>
          <span class="link-text">Bookmarks</span>
        </a>
      </li>
      <li class="nav-item launch-pad launch-pad-button" (click)="openFeedback()" #tooltip="matTooltip" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
        matTooltip="Help" matTooltipPosition="right" matTooltipHideDelay="100">
        <a class="nav-link">
          <svg xmlns="http://www.w3.org/2000/svg" class="fix-width" fill="currentColor" viewBox="0 -960 960 960">
            <path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
          </svg>
          <span class="link-text">Help</span>
        </a>
      </li>
      <li class="nav-item" #tooltip="matTooltip" *ngIf="userData$ | async as user" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)"
        [matTooltip]="user.name" matTooltipPosition="right" matTooltipHideDelay="100">
        <a (click)="this.updateMenu('user')" class="nav-link">
          <app-user-avatar size="32px" [name]="user.name" [photo]="user.photoURL"></app-user-avatar>
          <span class="link-text">{{user.name}}</span>
        </a>
      </li>
    </ul>
  </div>
  <div (click)="expandMenu()" class="expand-button" [class.expanded]="(menuNavigationService.isExpanded$ | async)" matTooltip="Expand Menu" matTooltipPosition="right" matTooltipHideDelay="100" [matTooltipDisabled]="(menuNavigationService.isExpanded$ | async)">
    <i class="fa fa-chevron-right expand-icon" aria-hidden="true"></i>
  </div>
</nav>
