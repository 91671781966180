<mat-progress-bar mode="indeterminate" *ngIf="fetchingWorkflow"></mat-progress-bar>
<h2 mat-dialog-title>Start Process &mdash; {{ data.workflowName }}</h2>
<mat-dialog-content>
    <br />
    <mat-form-field appearance="outline">
        <mat-label>Process Name</mat-label>
        <input matInput cdkFocusInitial [formControl]="processName" (blur)="updateErrorMessage()" required (keydown.enter)="startProcess(processName.value)" />
        <mat-error>{{errorMessage()}}</mat-error>
        <mat-hint>&nbsp;</mat-hint>
    </mat-form-field>
    <p style="margin-top:0;">Please provide the BP Number for this request.</p>
    <p>You can add the last 4 digits of a Loan or Account Number if needed. (ex. BP1111 LN1111)</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="processName.invalid || fetchingWorkflow" (click)="startProcess(processName.value)">
        @if (!fetchingWorkflow) { Start } @else { Starting... }
        <mat-icon *ngIf="!fetchingWorkflow" iconPositionEnd>arrow_right</mat-icon>
        <!-- <mat-spinner *ngIf="fetchingWorkflow" diameter="20" strokeWidth="2" iconPositionEnd style="float:right;margin-left:4px;"></mat-spinner> -->
    </button>
</mat-dialog-actions>