import {
  Component, OnInit, ViewEncapsulation, Input, OnDestroy,
  AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef
} from '@angular/core';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';

import {
  DynamicFormService,
  DynamicFormValueControlModel,
  DynamicCheckboxGroupModel,
  DynamicFormControlModel,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
  DYNAMIC_FORM_CONTROL_TYPE_INPUT,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
  DYNAMIC_FORM_CONTROL_TYPE_SELECT,
  DYNAMIC_FORM_CONTROL_TYPE_SWITCH,
  DYNAMIC_FORM_CONTROL_TYPE_SLIDER,
  DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP,
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE, DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL, DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
  DynamicInputModel
} from '@ng-dynamic-forms/core';

import { AbstractControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { InteractionSubmissionService } from '@eva-services/dynamic-interactions/interaction-submission.service';
import { SubscriptionLike as ISubscription, Subscription, fromEvent } from 'rxjs';
import { DynamicInteractionSyncService } from '@eva-services/dynamic-interactions/dynamic-interaction-sync.service';
import { InteractionEmitValueModel, InteractionValueEmitter } from '@eva-model/InteractionEmitValueModel';
import { debounceTime, map, distinctUntilChanged, skip, take, filter } from 'rxjs/operators';
import { ProcessInteractionEmitValueModel, submitDirectionType } from '@eva-model/processInteractionEmitValueModel';
import { FrmControlSpecialType, InteractionSpecialControl,
  FrmControlSpecialCustomType, CpApiType, FRM_CNTRL_SPECIAL } from '@eva-model/formControls';
import { SubCntrl } from '@eva-model/interactionSpecialControlBind';
import { CanadapostService } from '@eva-services/canadapost/canadapost.service';
import { InteractionControlModel, InteractionFrmCntrl } from '@eva-model/visualizedInteractionModel';
import { InteractionFormControlIndexModel, searchDirection, InteractionControlRequest } from '@eva-model/interactionFormControlModel';
import { Guid } from '@eva-core/GUID/guid';
import { ArrayUtilsService } from '@eva-services/utils/array-utils.service';
import { FormControlValidAnnouncement, InteractionAndElementEmitModel } from '@eva-model/interactionAndElementEmitModel';
import { InteractionVisualizerMode } from '@eva-model/interactionVisualizerDialogModel';
import { DynamicComponent, CpCntrlChangeWatch } from '@eva-model/interaction/interaction';
import { ConditionalObjectCheckerService } from '@eva-services/conditional-object-checker/conditional-object-checker.service';
import { isString } from 'lodash';
import { UtilsService } from '../../providers/utils/utils.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { EvaDynamicFormValueControlModel } from '@eva-model/dynamicFormControls/evaDynamicFormValueControlModel';
import { EvaDynamicCheckboxGroupModel } from '@eva-model/dynamicFormControls/evaDynamicCheckboxGroupModel';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { Router } from '@angular/router';
import { ProcessInteractionValues } from '@eva-model/process/process';
import { EvaDynamicFormControlModel } from '@eva-model/evaDynamicFormControlModel';
import { IfThenLogicAction, Relation } from '@eva-model/interactionElementRelationDialogModel';
import { DynamicMaterialCheckboxComponent, DynamicMaterialChipsComponent, DynamicMaterialDatePickerComponent, DynamicMaterialFormComponent, DynamicMaterialInputComponent, DynamicMaterialRadioGroupComponent, DynamicMaterialSelectComponent, DynamicMaterialSlideToggleComponent, DynamicMaterialTextAreaComponent } from '@ng-dynamic-forms/ui-material';
import { CommonModule} from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-form-visualizer',
  templateUrl: './form-visualizer.component.html',
  styleUrls: ['./form-visualizer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DynamicMaterialFormComponent,
    DynamicMaterialCheckboxComponent,
    DynamicMaterialChipsComponent,
    DynamicMaterialDatePickerComponent,
    DynamicMaterialInputComponent,
    DynamicMaterialRadioGroupComponent,
    DynamicMaterialSelectComponent,
    DynamicMaterialSlideToggleComponent,
    DynamicMaterialTextAreaComponent,
    MatDatepickerModule,
    MatButtonModule,
    MatTooltipModule,
    MatStepperModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule
  ],
  providers: [
    provideNativeDateAdapter()
  ]
})
export class FormVisualizerComponent implements OnInit, AfterViewInit, DynamicComponent, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('stepper', { read: ElementRef }) stepperElement: ElementRef;
  formVisualizerModelArray = [];   // Array of DynamicFormControlModel[]
  formVisualizerFormGroupArray: UntypedFormGroup[] = [];
  formVisualizerLayoutArray = [];

  tempFrmMdlArray = [];
  tempFrmGrp: UntypedFormGroup;

  formScreens = [];
  dynFrm: any = {};
  flatInteraction: any = {};
  originalDynamicForm: any = {};
  eventEmittedFrom = '';
  isSubmitting = false;
  isAnyValueChanged = false;
  onWaitForSubmittingMessage: string;
  dateIsInvalid = false;
  numInvalidDates = 0; // number of invalid dates on the screen
  numRadioRequired = 0; // number of radio buttons on the screen marked as required
  numCheckboxRequired = 0; // number of singular checkboxs marked as required
  showInteraction = true;
  countrySelectId = null;
  addressInput = null;
  radioIsRequired = false; // disables the submit button if there is a requried radio field
  checkboxIsRequired = false;
  numberMinLength = false;
  numberMaxLength = false;
  numberMinValue = false;
  numberMaxValue = false;
  numberStepInvalid = false;
  invalidDates = [];

  cpCntrlChangeWatch: CpCntrlChangeWatch[] = [];

  private visualizerSubs = new Subscription(); // stores subscription for controlValueUpdated observable
  private formGroupsValueChangeSubs = new Subscription();
  private interactionSpecialControlSubs = new Subscription();
  isPreview = false;
  isEditInteractionFlow = false;
  isNextButtonActive = true;
  invalidMessage: string;

  @Input() isAllDisabled = false;
  @Input() updatedFromLastState: boolean;
  @Input()
  set dynFrmObj(dynFrmObj: any) {
    if (!dynFrmObj) { return; }
    const dynamicFormObj = dynFrmObj;

    this.dynFrmInit(dynamicFormObj);
  }

  @Input() isSubmitEnable = true;
  @Input() dbId: string = null;
  @Input() isJoinChange = false;
  @Input()
  set selectControlsOptions(selectControlsOptions: any[]) {
    if (!selectControlsOptions ||
      !Array.isArray(selectControlsOptions) ||
      !this.formVisualizerModelArray ||
      !Array.isArray(this.formVisualizerModelArray) ||
      !(this.formVisualizerModelArray.length > 0)
    ) { return; }
    selectControlsOptions.forEach(slctCntrlOptns => {
      if (slctCntrlOptns.elementId && Array.isArray(slctCntrlOptns.options) && slctCntrlOptns.options.length > 0) {
        for (const frmGrpModel of this.formVisualizerModelArray) {
          const slctCntrl = frmGrpModel.find(element => element.originalId === slctCntrlOptns.elementId);
          if (slctCntrl) {
            //#region Sort the options :: slctCntrlOptns.sortOptions :: undefined/No/DESC/ASC and default is ASC
            if (!(slctCntrlOptns.sortOptions && slctCntrlOptns.sortOptions === 'No')) {
              slctCntrlOptns.options = this._arrayUtils.arrayObjectCustomSort(slctCntrlOptns.options, 'label');
            }
            //#endregion

            slctCntrl.options = slctCntrlOptns.options;

            if (slctCntrlOptns.value) {
              if (slctCntrl.value && slctCntrl.value === slctCntrlOptns.value) slctCntrl.value = '';
              slctCntrl.value = slctCntrlOptns.value;
            }

            break;
          }
        }
      }
    });
  }

  //#region processId and workflowId input parameters utilizes by a "Process" to collect corresponding values from interactions
  @Input() processId?: string;
  @Input() workflowId?: string;
  @Input() processTitle?: string;
  @Input() tabIndex?: number;
  //#endregion

  @Input() targetId?: string;

  @Input()
  set isSubmittingSimulate(isSubmittingSimulate: boolean) {
    this.isSubmitting = (isSubmittingSimulate) ? true : false;
  }
  @Input()
  set isSubmittingSimulateMsg(isSubmittingSimulateMsg: string) {
    this.onWaitForSubmittingMessage = isSubmittingSimulateMsg;
  }

  // #Region inputs accepted from process-runner
  @Input() previouslyCompleted?: boolean; // determines if the interaction has been completed before, but not submitted
  @Input() selectedFormScreen?: number; // stores the form screen selected by user
  @Input() visualizerMode?: InteractionVisualizerMode; // determines if it is being edited or is the first run through
  @Input() showBackButton?: boolean; // determines if the back button should be shown
  private _processInteractionValues: ProcessInteractionValues;
  @Input()
  set processInteractionValues(processInteractionValues: ProcessInteractionValues) {
    this._processInteractionValues = processInteractionValues;
  }
  // #End region

  constructor(
    private ngDynFormService: DynamicFormService,
    public formBuilderService: FormBuilderService,
    public interactionSubmissionService: InteractionSubmissionService,
    private interactionSyncService: DynamicInteractionSyncService,
    private changeDetectorRef: ChangeDetectorRef,
    private cpService: CanadapostService,
    private _arrayUtils: ArrayUtilsService,
    private _conditionalObjectCheckerService: ConditionalObjectCheckerService,
    private _utilsService: UtilsService
  ) {
  }

  ngOnInit() {
    if (this.previouslyCompleted) {
      this.showInteraction = false;
    } else {
      this.showInteraction = true;
    }

    this.visualizerSubs.add(
      this.interactionSyncService.controlValueUpdated$.pipe(
          filter(update => update.processId === this.processId)
        ).subscribe(
          (update) => {
            // if (update instanceof InteractionEmitValueModel) {
              let cntrl = null;

              if (update.interactionId &&
                this.dynFrm.id &&
                update.formControlId &&
                (update.scrnIndex || update.scrnIndex === 0) &&
                update.interactionId === this.dynFrm.id) {

                // const formModel = this.formVisualizerModelArray[update.scrnIndex];
                cntrl = this.getElementByOriginalId(update.elementOriginalId, update.scrnIndex);

              } else if (update.processId &&
                update.interactionOriginalId &&
                update.elementOriginalId &&
                (update.scrnIndex || update.scrnIndex === 0) &&
                update.interactionOriginalId === this.dynFrm.originalId) {

                cntrl = this.getElementByOriginalId(update.elementOriginalId, update.scrnIndex);

              }

              //#region form control value updates
              if (cntrl as EvaDynamicFormValueControlModel<any>) {
                // TODO :: Chips :: type === "INPUT" && inputType === "text" && multiple === true
                if (cntrl.type && cntrl.type === "CHECKBOX_GROUP") {
                  //#region Handling update of checkbox(es) underneath of the checkbob group control
                  if ((cntrl as EvaDynamicCheckboxGroupModel).group) {
                    const newValueIndices = [];
                    const updatedValue = typeof update.value === 'string' ? update.value.split(',') : update.value;
                    (cntrl as EvaDynamicCheckboxGroupModel).group.forEach((dynChkBoxMdl, index) => {
                      if (Array.isArray(updatedValue)) {
                        const flag = updatedValue.find(value => value === dynChkBoxMdl.label);
                        if (flag) {
                          dynChkBoxMdl.value = true;
                          newValueIndices.push(index);
                        }
                      } else {
                        if (updatedValue[dynChkBoxMdl.id]) {
                          dynChkBoxMdl.value = updatedValue[dynChkBoxMdl.id];
                        }
                      }
                    });
                    newValueIndices.forEach(selectedIndex => {
                      (cntrl as DynamicCheckboxGroupModel).check(selectedIndex);
                    });
                  }
                  //#endregion

                } else {
                  if (cntrl.type && cntrl.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT && cntrl.multiple) {
                    (cntrl as EvaDynamicFormValueControlModel<any>).value =
                      typeof update.value === 'string' ? update.value?.split(',') : update.value;
                  } else {
                    if (cntrl.type && cntrl.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT
                      && cntrl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL) {
                        let newValue = '';
                        // get all numbers from the text provided
                        update.value?.match(/\d/g)?.join('')?.split('')?.forEach((char: string, index: number) => {
                          if (index === 0) {
                            newValue += '(';
                          }
                          if (index < 3 || (index > 3 && index < 6) || index > 6) {
                            newValue += char;
                          }
                          if (index === 3) {
                            newValue += ') ' + char;
                          }
                          if (index === 6) {
                            newValue += '-' + char;
                          }
                        });
                        (cntrl as EvaDynamicFormValueControlModel<any>).value = newValue ?? update.value;
                    } else if (cntrl.type && cntrl.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT
                      && cntrl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT) {
                        if (cntrl.multiple) {
                          cntrl.value = update.value.length > 0
                            ? update.value.split(',').filter(value => value && value.trim().length > 0)
                            : [];
                        } else {
                          if (update.isSpecialControl && update.isCanadaPostWatchControl) {
                            this.cpSpclCntrlChangeNext({
                              model: cntrl,
                              control: {
                                value: update.value
                              }
                            });
                          } else {
                            (cntrl as EvaDynamicFormValueControlModel<any>).value = update.value;
                          }
                        }
                      } else {
                      (cntrl as EvaDynamicFormValueControlModel<any>).value = update.value;
                    }
                  }
                }

                const interactionControlRequest = new InteractionControlRequest(
                  update.interactionId,
                  update.formControlId,
                  update.direction,
                  Guid.newGuid().toString(),
                  this.targetId,
                  update.interactionOriginalId,
                  update.elementOriginalId,
                  update.lastEmittedFrom,
                  null,
                  update.isEditInteractionFlow,
                  true
                );
                if (!update.isCanadaPostWatchControl) {
                  this.broadcastInteractionControlRequest(interactionControlRequest);
                }
              }
              //#endregion
            // }
          },
          (err) => { }
        )
    );

    this.visualizerSubs.add(
      this.interactionSyncService.controlValueChanged$.pipe(
        filter(data => data.processId === this.processId)
      ).subscribe(data => {
        if (data.processId
          && data.interactionOriginalId
          && data.elementOriginalId
          && (data.scrnIndex || data.scrnIndex === 0)
          && data.interactionOriginalId === this.dynFrm.originalId) {
            const formControl = this.getElementByOriginalId(data.elementOriginalId, data.scrnIndex);
            let formControlFromFormGroup: AbstractControl = null;

            this.formVisualizerFormGroupArray.forEach(group => {
              if (group.controls[data.formControlId]) {
                formControlFromFormGroup = group.controls[data.formControlId];
              }
            });

            if (!formControlFromFormGroup) {
              const elementModel = this.formVisualizerModelArray[data.scrnIndex].find(element =>
                element.originalId === data.elementOriginalId);
              const elementId = elementModel.id;
              this.formVisualizerFormGroupArray.forEach(group => {
                if (group.controls[elementId]) {
                  formControlFromFormGroup = group.controls[elementId];
                }
              });
            }

            if (formControl) {
              switch (data.type) {
                case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
                case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
                  if (data.value && data.value.length > 0) {
                    if (formControl.multiple) {
                      if (typeof data.value === 'string') {
                        data.value = data.value.split(',').filter(value => value.trim().length > 0);
                      }
                      const newValue = [];
                      formControl.options.forEach(option => {
                        if (data.value.find(value => value.trim().toLowerCase() === option.value.trim().toLowerCase())) {
                          newValue.push(option.value);
                        }
                      });
                      formControl.value = newValue;
                    } else {
                      const newValue = formControl.options.find(option =>
                        data.value.trim().toLowerCase() === option.value.trim().toLowerCase());
                      formControl.value = newValue?.value ?? data.value;
                    }
                  }
                  break;
                case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
                  if (data.value.length > 0) {
                    const newValue = {};
                    if (typeof data.value === 'string') {
                      data.value = data.value.split(',').filter(value => value.trim().length > 0);
                    }
                    formControl.group.forEach(group => {
                      if (data.value.find(value => value.trim().toLowerCase() === group.label.trim().toLowerCase())) {
                        newValue[group.id] = true;
                        group.value = true;
                      } else {
                        newValue[group.id] = false;
                        group.value = false;
                      }
                    });
                    formControl.value = newValue;
                  }
                  break;
                case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:
                case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
                  formControl.value = data.value === 'true';
                  break;
                case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
                  switch (data.inputType) {
                    case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT:
                      if (formControl.multiple) {
                        formControl.value = data.value.length > 0
                          ? data.value.split(',').filter(value => value && value.trim().length > 0)
                          : [];
                      } else {
                        formControl.value = data.value;
                        if (data.isSpecialControl) {
                          const inputElement: HTMLInputElement = document.getElementById(formControl.id) as HTMLInputElement;
                          if (inputElement) {
                            const event = new Event('input');
                            inputElement.dispatchEvent(event);
                          }
                        }
                      }
                      break;
                    case DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL:
                      let newValue = '';
                      // get all numbers from the text provided
                      data.value?.match(/\d/g)?.join('')?.split('')?.forEach((char: string, index: number) => {
                        if (index === 0) {
                          newValue += '(';
                        }
                        if (index < 3 || (index > 3 && index < 6) || index > 6) {
                          newValue += char;
                        }
                        if (index === 3) {
                          newValue += ') ' + char;
                        }
                        if (index === 6) {
                          newValue += '-' + char;
                        }
                      });
                      formControl.value = data.value.length > 0 && newValue !== '' ? newValue : '';
                      break;
                    default:
                      formControl.value = data.value;
                      break;
                  }
                  break;
                default:
                  formControl.value = data.value;
                  break;
              }

              let elementInputType = formControl.inputType;

              if ((elementInputType === undefined) && (formControl.multiple === true)) {
                elementInputType = "multiple";
              }

              const validAnnouncement: FormControlValidAnnouncement = {
                valid: formControlFromFormGroup.valid,
                errors: formControlFromFormGroup.errors,
                type: formControl.type,
                inputType: elementInputType,
                hint: formControl.hint
              };

              this.interactionSyncService.announceControlValidChange(validAnnouncement);
            }
        }
      })
    );

    this.visualizerSubs.add(
      this.interactionSyncService.processInteractionChange$.pipe(
        filter(update => update.processId === this.processId)
      ).subscribe(update => {
        if (update.direction === searchDirection.backward) {
          this.interactionSyncService.announceInvalidCurrentForm([], update.fulfillmentText, update.processTitle);
          this.trySubmit(false, true);
        } else if (update.direction === searchDirection.forward) {
          if (this.isFormNotValid()) {
            const invalidElements = this.getCurrentInvalidFormElements();
            this.interactionSyncService.announceInvalidCurrentForm(invalidElements, update.fulfillmentText, update.processTitle);
            return;
          }
          this.interactionSyncService.announceInvalidCurrentForm([], update.fulfillmentText, update.processTitle);
          this.trySubmit(false, false);
        }
      })
    );

    //#region Interaction Control request handling
    this.visualizerSubs.add(
      this.interactionSyncService.interactionControlRequest$.pipe(
        filter(interactionControlRequest => interactionControlRequest.processId === this.processId)
      ).subscribe(
        (intrctCntrlReq: InteractionControlRequest) => {
          this.broadcastInteractionControlRequest(intrctCntrlReq);
        },
        (err) => console.error(err)
      )
    );
    //#endregion

    this.visualizerSubs.add(
      this.interactionSyncService.processStayOnCurrentInteraction$.pipe(
        filter(update => update.processId === this.processId)
      ).subscribe(data => {
        if (data.stay) {
          // announce to chat to print all elements with values and ordinal to choose with
          this.interactionSyncService.announceProcessInteractionEdit(this.formVisualizerModelArray, this.processTitle);
        }
      })
    );

    this.visualizerSubs.add(
      this.interactionSyncService.controlValueDatabase$.pipe(
        // ensure it's not the preview mode from form builder
        filter(control => !control.isPreview)
      )
      .subscribe(
        (data) => {
          const interactionValues = this.formBuilderService.flatInteraction(this.dynFrm);
          const element = interactionValues.elements.find(formElement => formElement.originalId === data.elementOriginalId);
          if (element && element.type !== DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
            element.value = data.value;
          }
          this.interactionSyncService.announceInteractionValues({
            interactionValues, processId: this.processId, fromLastState: this.updatedFromLastState
          });
        })
    );
  }


  ngAfterViewInit() {
    this.toggleStepHeaders();
    //#region :: If there is a value for selectedFormScreen input parameter it will set the interaction screen page
    
    if (this.selectedFormScreen) {
      let index = 0;
      while (index <= this.selectedFormScreen) {
        if (this.stepper) {
          this.stepper.selectedIndex = index;
        }
        index++;
      }
    }
    //#endregion

    // Reset isAnyValueChanged property of the component
    this.isAnyValueChanged = false;

    //#region :: apply interaction elements default values
    if (!this.visualizerMode || (this.visualizerMode && this.visualizerMode !== InteractionVisualizerMode.edit)) {
      this.applyElementsDefaultValue();
    }
    //#endregion

    // TODO :: Apply necessary codes for special controls
    // Special Controls can be the ones which need extra code after form generation to handle the situation & provide target functionality

    //#region Special Controls Handling
    if (this.dynFrm && this.dynFrm.specialControls &&
      Array.isArray(this.dynFrm.specialControls) &&
      this.dynFrm.specialControls.length > 0) {

      this.dynFrm.specialControls.forEach((formCntrlSpcl: InteractionSpecialControl) => {

        //#region Check to see if Special Control type is TYPEAHEAD (for now Canada POST)
        if (formCntrlSpcl.subType === FrmControlSpecialType.Typeahead) {
          this.specialControlTypeaheadHandler(formCntrlSpcl);
        }
        //#endregion
      });

    }
    //#endregion

    //#region Emit interaction Id and first control
    if (this.visualizerMode === InteractionVisualizerMode.edit) {
      // announce to chat to print all elements with values and ordinal to choose with
      this.interactionSyncService.announceProcessInteractionEdit(this.formVisualizerModelArray, this.processTitle);
    }
    if (!this.previouslyCompleted) {
      this.announceVisualizedInteraction();
    }
    //#endregion

    // if interaction  data is being loaded from preserved interactions, check if the interaction was marked as done
    // if it was already finished, submit data
    if (this.previouslyCompleted) {
      this.trySubmit(false);
    }

    this.numRadioRequired = 0;
    this.numCheckboxRequired = 0;
    // needs second loop to count required elements in interaction, must check inside each element
    setTimeout(() => {
      this.dynFrm.FormScreens.forEach((formScreen, scrnIndex) => {
        // TODO: Remove this once the bug is fixed
        /**
         * Temporary code to fix Chrome browser crashing.
         * See: https://issues.chromium.org/issues/335553723?pli=1
         */
        document.querySelectorAll('[aria-owns]').forEach((element) => {
          element.removeAttribute('aria-owns');
        });
        document.querySelectorAll('[aria-labelledby]').forEach((element) => {
          element.removeAttribute('aria-labelledby');
        });
        formScreen.FormElements.forEach(formElement => {
          // if it is a radio group check if it is required, if it is and there is no value yet, disable submit
          if (formElement.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP && formElement.required === true
            && (typeof formElement.value === undefined || formElement.value === '' || formElement.value === null)
            && this.formVisualizerFormGroupArray[scrnIndex]?.controls[formElement.id]?.disabled === false) {
            this.radioIsRequired = true; // if a radio is required, set to true and count the number of required radio buttons
            this.numRadioRequired++;
          }

          // get  count of number of checkboxes required
          if (formElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX && formElement.required === true
            && (typeof formElement.value === undefined || formElement.value === '' || formElement.value === null)
            && this.formVisualizerFormGroupArray[scrnIndex]?.controls[formElement.id]?.disabled === false) {
            this.checkboxIsRequired = true; // if a radio is required, set to true and count the number of required radio buttons
            this.numCheckboxRequired++;
          }

        });
      });
      // if the number of checkboxes still required is at 0, enable the submit button
      if (this.numCheckboxRequired === 0) {
        this.checkboxIsRequired = false;
      }

      if (this.numRadioRequired === 0) {
        this.radioIsRequired = false;
      }
    });

  }

  /**
   *  #region To apply default values for the interaction elements in initial mode
   */
  private applyElementsDefaultValue(): void {

    if (Array.isArray(this.dynFrm.FormScreens) && this.dynFrm.FormScreens.length > 0) {
      const intrctScrns = this.dynFrm.FormScreens;
      intrctScrns.forEach(intrctScrn => { // for each form screen
        if (intrctScrn &&
          intrctScrn.FormElements &&
          Array.isArray(intrctScrn.FormElements) &&
          intrctScrn.FormElements.length > 0) {
          intrctScrn.FormElements.forEach(element => { // for each element

            // check if element is of type select or multi-select and has a default value/no previous value
            if (element.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT
              && (element.additional && element.additional.defaultVal)) {
              if ( element.multiple === false ) {
                if (element.additional.defaultVal.length > 0 && (!element.value || (Array.isArray(element.value) && element.value?.length === 0) || element.value === '')) {
                  element.value = element.additional.defaultVal[0];
                } else if ((Array.isArray(element.value) && element.value?.length === 0) || element.value === '') {
                  element.value = null;
                }
              } else if (element.multiple === true) {
                element.value = element.additional.defaultVal;
              }
            }

            if (element.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT && element.multiple === true && Array.isArray(element.additional)) {
              element.value = element.additional;
            }

            if (element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
              element.group.forEach(checkBox => {
                if (!element.value || (Array.isArray(element.value) && element.value?.length === 0) || element.value === '') {
                  if (checkBox.additional?.['defaultVal']) {
                    checkBox.value = true;
                    checkBox.value = checkBox.value;
                  }
                }
              });
              if (element.disabled) {
                element.group.forEach(checkBox => {
                  checkBox.disabled = true;
                });
              }

              // if it is a checkbox group or a select, can not set value this way
            } else if (element.type !== DYNAMIC_FORM_CONTROL_TYPE_SELECT) {

              if (element.additional && element.additional.currentDateDefault
                && (!element.value || (Array.isArray(element.value) && element.value?.length === 0) || element.value === '')) {
                element.value = element.value;
              } else if (element.additional && element.additional.defaultVal
                && (!element.value || (Array.isArray(element.value) && element.value?.length === 0) || element.value === '')) {
                if (typeof element.additional.defaultVal === 'object' && !Array.isArray(element.additional.defaultVal)) {
                  if (Object.keys(element.additional.defaultVal).find(property => property === 'defaultVal')) {
                    element.additional.defaultVal = element.additional.defaultVal.defaultVal;
                  }
                }
                element.value = element.additional.defaultVal;
              } else if (!element.multiple && this.processId && element.value) {
                element.value = element.value;
              }
            }

          });
        }
      });
    }
  }
  //#endregion

  /**
   * This function announces the Interaction Control Request
   *
   * @param intrctCntrlReq The Interaction Control Request being broadcasted
   */
  private broadcastInteractionControlRequest(intrctCntrlReq: InteractionControlRequest): void {
    if (intrctCntrlReq && intrctCntrlReq.requestId && intrctCntrlReq.interactionOriginalId === this.dynFrm.originalId) {
      if (intrctCntrlReq.isEditInteractionFlow && intrctCntrlReq.isValueUpdate) {
        setTimeout(() => {
          this.interactionSyncService.announceInteractionControl(new InteractionControlModel(
            this.dynFrm.id,
            this.dynFrm.originalId,
            null,
            null,
            (this.processId) ? this.processId : null,
            intrctCntrlReq.sourceId ? intrctCntrlReq.sourceId : null,
            intrctCntrlReq.requestId,
            null,
            intrctCntrlReq.lastEmittedFrom,
            null,
            null,
            intrctCntrlReq.searchControlDirection,
            this.isPreview,
            null,
            null,
            InteractionVisualizerMode.edit,
            intrctCntrlReq.isEditInteractionFlow,
            this.tabIndex,
            this.processTitle));
        });
        this.interactionSyncService.announceProcessInteractionEdit(this.formVisualizerModelArray, this.processTitle);
        return;
      }
      this.isEditInteractionFlow = intrctCntrlReq.isEditInteractionFlow;
      // const frmCntrl = this.getElementById( intrctCntrlReq.formControlId, intrctCntrlReq.searchControlDirection );
      const frmCntrlObj = this.getNextPrevElementByFormCntrlId(intrctCntrlReq.formControlOriginalId, intrctCntrlReq.searchControlDirection,
        intrctCntrlReq.elementIndex);
      const formControl = frmCntrlObj.cntrl;
      const errMsg = frmCntrlObj.errMsg;
      const errType = frmCntrlObj.errType;
      const screenIndex = formControl ? this.getElementAndScreenIndexById(formControl.originalId).scrnIdx : null;

      let isSpecialControl = false;
      isSpecialControl = this.dynFrm?.specialControls?.some(specialControl => specialControl.controls?.find(subControl =>
          subControl?.id === formControl?.originalId) ? true : false
      );

      const intrctCntrl =
        new InteractionControlModel(
          this.dynFrm.id,
          this.dynFrm.originalId,
          formControl,
          screenIndex,
          (this.processId) ? this.processId : null,
          intrctCntrlReq.sourceId ? intrctCntrlReq.sourceId : null,
          intrctCntrlReq.requestId,
          errMsg,
          intrctCntrlReq.lastEmittedFrom,
          errType,
          isSpecialControl,
          intrctCntrlReq.searchControlDirection,
          this.isPreview,
          null,
          this.cpCntrlChangeWatch.find(canadaPostControl => canadaPostControl.id === formControl?.originalId)
            ? true : false,
          null,
          intrctCntrlReq.isEditInteractionFlow,
          this.tabIndex,
          this.processTitle);

      setTimeout(() => {
        this.interactionSyncService.announceInteractionControl(intrctCntrl);
      });
    }
  }

  /**
   * This function announces the interaction control model of the first form control
   */
  private announceVisualizedInteraction(): void {
    let frmCntrl = null;
    const firstFrmCntrl = this.getElementByIndex(0, 0);

    if (firstFrmCntrl) {
      frmCntrl = new InteractionFrmCntrl(
        firstFrmCntrl.id,
        firstFrmCntrl.originalId,
        firstFrmCntrl.name,
        firstFrmCntrl.hint,
        firstFrmCntrl.label,
        firstFrmCntrl.type,
        firstFrmCntrl.value,
        firstFrmCntrl.inputType,
        firstFrmCntrl.disabled,
        firstFrmCntrl,
        firstFrmCntrl.options,
        firstFrmCntrl.multiple,
        firstFrmCntrl.group,
        firstFrmCntrl._list);
    }

    let isSpecialControl = false;
    this.dynFrm?.specialControls?.forEach(specialControl => {
      isSpecialControl = specialControl.controls?.find(subControl =>
        subControl?.id === frmCntrl?.originalId) ? true : false;
    });

    const screenIndex = 0;

    const interactionControl =
      new InteractionControlModel(
        this.dynFrm.id,
        this.dynFrm.originalId,
        frmCntrl,
        screenIndex,
        (this.processId) ? this.processId : null,
        this.targetId,
        null,   // requestId
        null,   // error msg
        null,
        null,
        isSpecialControl,
        searchDirection.forward,
        this.isPreview,
        null,
        this.cpCntrlChangeWatch.find(canadaPostControl => canadaPostControl.id === frmCntrl?.originalId)
          ? true : false,
        this.visualizerMode,
        this.isEditInteractionFlow,
        this.tabIndex,
        this.processTitle);

    this.interactionSyncService.announceInteractionControl(interactionControl);
  }

  //#region Special Control
  /**
   * This is used to add a typeahead feature to a special control loaded by the user. Can load the AddressComplete data
   * into either an input, or a select. If it is an international data list, the country selected will be stored here and
   * later be used to filter the addresses in CanadapostService
   * @param formCntrlSpcl the special control array that will be given a typeahead feature
   */
  private specialControlTypeaheadHandler(formCntrlSpcl: InteractionSpecialControl): void {
    let typeaheadInput = null,
      typeaheadInputFrmCntrl = null,
      typeaheadInputScrnIdx = null,
      typeaheadSelect = null,
      typeaheadSelectFrmCntrl = null,
      typeaheadSelectScrnIdx = null;

    if (!(formCntrlSpcl.controls && Array.isArray(formCntrlSpcl.controls) && formCntrlSpcl.controls.length > 0)) return;

    //#region Find the TYPEAHEAD Special Control elements :: Get the factory element
    formCntrlSpcl.controls.every((elmnt: SubCntrl) => {

      if (typeaheadInput && typeaheadSelect) return false;

      if (Array.isArray(this.dynFrm.FormScreens) && this.dynFrm.FormScreens.length > 0) {
        const intrctScrns = this.dynFrm.FormScreens;
        if (intrctScrns[elmnt.interactionScrnIndex] && Array.isArray(intrctScrns[elmnt.interactionScrnIndex].FormElements) &&
          intrctScrns[elmnt.interactionScrnIndex].FormElements.length > 0) {

          const intrctElmnts = intrctScrns[elmnt.interactionScrnIndex].FormElements;
          const elmntIdx = intrctElmnts.map(e => e.originalId).indexOf(elmnt.id);

          if (elmntIdx !== -1) {
            const spclCntrlMdl = intrctElmnts[elmntIdx];
            // if international data list, store the address input and the Country select id
            if (!typeaheadInput && spclCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT &&
              formCntrlSpcl.customType === FrmControlSpecialCustomType.CanadaPostInternationalDataListRetrieve && this.stepper) {
                this.countrySelectId = formCntrlSpcl.controls[0].id;
                this.addressInput = formCntrlSpcl.controls[1];
                typeaheadInput = this.dynFrm.FormScreens[this.stepper.selectedIndex].FormElements.find(
                  formElement => formElement.originalId === this.addressInput.id);
                typeaheadInputScrnIdx = elmnt.interactionScrnIndex;
                typeaheadInputFrmCntrl =
                  this.ngDynFormService.findById(typeaheadInput.id, this.formVisualizerModelArray[elmnt.interactionScrnIndex]);
            } else if (!typeaheadInput &&
              spclCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT &&
              spclCntrlMdl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT) {
              typeaheadInput = spclCntrlMdl;
              typeaheadInputScrnIdx = elmnt.interactionScrnIndex;
              typeaheadInputFrmCntrl =
                this.ngDynFormService.findById(typeaheadInput.id, this.formVisualizerModelArray[elmnt.interactionScrnIndex]);
            } else if (!typeaheadSelect &&
              spclCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {
              typeaheadSelect = spclCntrlMdl;
              typeaheadSelectScrnIdx = elmnt.interactionScrnIndex;
              typeaheadSelectFrmCntrl =
                this.ngDynFormService.findById(typeaheadSelect.id, this.formVisualizerModelArray[elmnt.interactionScrnIndex]);
            }
          }

        }
      }

      return true;
    });
    //#endregion

    if (typeaheadInput) {
      const isCanadaPostTypeahead =
        formCntrlSpcl.customType === FrmControlSpecialCustomType.CanadaPostFind ||
        formCntrlSpcl.customType === FrmControlSpecialCustomType.CanadaPostRetrieve ||
        formCntrlSpcl.customType === FrmControlSpecialCustomType.CanadaPostDataListRetrieve ||
        formCntrlSpcl.customType === FrmControlSpecialCustomType.CanadaPostInternationalDataListRetrieve;

      let typeaheadTargetFrmCntrl: any;
      if (isCanadaPostTypeahead) {
        //#region Keep typeahead canada post select control to watch changes over there
        const iscpDataListRetrieve = formCntrlSpcl.customType === FrmControlSpecialCustomType.CanadaPostDataListRetrieve ||
          FrmControlSpecialCustomType.CanadaPostInternationalDataListRetrieve;
        typeaheadTargetFrmCntrl = (iscpDataListRetrieve) ? typeaheadInputFrmCntrl : typeaheadSelectFrmCntrl;

        this.cpCntrlChangeWatch.push({
          id: typeaheadTargetFrmCntrl.originalId,
          scrnIdx: (iscpDataListRetrieve) ? typeaheadInputScrnIdx : typeaheadSelectScrnIdx,
          customType: formCntrlSpcl.customType,
          spclCntrlId: formCntrlSpcl.id
        }
        );
        //#endregion
      } else if (formCntrlSpcl.customType === FrmControlSpecialCustomType.TypeaheadTextList) {
        typeaheadInputFrmCntrl.additional = { ...typeaheadInputFrmCntrl.additional, originalList: typeaheadInputFrmCntrl._list };
      }

      setTimeout(() => {
        const typeaheadInputDOM: HTMLInputElement = document.getElementById(typeaheadInput.id) as HTMLInputElement;

        if (typeaheadInputDOM) {
          //#region TypeAhead Observable Subscription
          this.interactionSpecialControlSubs.add(
            fromEvent(typeaheadInputDOM, "input")
              .pipe(
                debounceTime(400),
                map(() => typeaheadInputDOM.value),
                // startWith(''),
                distinctUntilChanged(),
              )
              .subscribe(
                (srchTerm) => {
                  if (isCanadaPostTypeahead) {   // CP typeahead
                    const country = this.getCountry();
                    if (country === 'Country not found') {
                      this.cpSpclCntrlFindAddressComplete(srchTerm, typeaheadTargetFrmCntrl);
                    } else {
                      this.cpSpclCntrlFindAddressComplete(srchTerm, typeaheadTargetFrmCntrl, '', country);
                    }
                  } else {   // Data list type ahead
                    const orgnlList = typeaheadInputFrmCntrl.additional.originalList;
                    if (orgnlList) {
                      const listFiltered = orgnlList.filter((w: string) => w.toLowerCase().includes(srchTerm.toLowerCase()));
                      typeaheadInputFrmCntrl.list = listFiltered;
                      this.interactionSyncService.announceInteractionControlListChange(typeaheadInputFrmCntrl.id, typeaheadInputFrmCntrl);
                    }
                  }
                }
              )
          );
        }
      }, 100);
      //#endregion
    }
  }

  /**
   * function for returning the user selected country for international addresses
   */
  private getCountry(): string {
    // check if it is an international address control, if it is, send variable to change country code
    let country;
    if (this.stepper) {
      country = this.dynFrm.FormScreens[this.stepper.selectedIndex].FormElements.find(
        formElement => formElement.originalId === this.countrySelectId);
    }
    if (country === undefined) {
      return 'Country not found';
    } else {
      switch (country.value) {
        case "Canada":
          country = 'CAN';
          break;
        case 'USA':
          country = 'USA';
          break;
        // can place other country codes here in future
      }
    }
    return country;
  }

  /**
   * this function is used to find and attach a list of addresses to an element based on user text input, and select input if international
   * @param srchTerm string the user has entered
   * @param typeaheadTargetFrmCntrl the object of the control that will contain the coutnry list
   * @param lastId id from last element triggered on change
   * @param country the country to look up addresses in. Defaults to Canada.
   */
  private cpSpclCntrlFindAddressComplete(srchTerm: string, typeaheadTargetFrmCntrl: any, lastId?: string, country?: string): void {
    this.cpService.findAddressComplete(srchTerm, lastId, country)
      .pipe(take(1))
      .subscribe(
        (adrsClctnResult: any) => {
          let adrsClctn = [];
          if (adrsClctnResult && adrsClctnResult.Items &&
            Array.isArray(adrsClctnResult.Items) && adrsClctnResult.Items.length > 0) {
            // if no results were found, change error message to be more descriptive
            if (adrsClctnResult.Items && adrsClctnResult.Items[0].Error === '1001') {
              adrsClctnResult.Items[0].Description = 'No Results found. Please check that a valid address was supplied.';
              adrsClctnResult.Items[0]['Text'] = ''; // needs to have a value for when the Items array is mapped below
            }
            adrsClctn =
              adrsClctnResult.Items
                .map(a => ({
                  label: `${a.Text} ${a.Description}`,
                  value: { Id: a.Id, Address: `${a.Text} ${a.Description}`, Text: a.Text, Description: a.Description, Next: a.Next }
                }));
          }

          if (typeaheadTargetFrmCntrl.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT &&
            typeaheadTargetFrmCntrl.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT) {

            const typeaheadInputDOM: HTMLInputElement = document.getElementById(typeaheadTargetFrmCntrl.id) as HTMLInputElement;

            typeaheadTargetFrmCntrl.additional = { ...typeaheadTargetFrmCntrl.additional, originalList: adrsClctn };
            const orgnlList = adrsClctn.map(a => a.label);
            typeaheadTargetFrmCntrl.list = orgnlList;
            this.interactionSyncService.announceInteractionControlListChange(typeaheadTargetFrmCntrl.id, typeaheadTargetFrmCntrl);

            if (lastId && lastId.length > 0) {
              typeaheadInputDOM.value = typeaheadInputDOM.value + ' ';
              typeaheadInputDOM.dispatchEvent(new Event('input'));
              typeaheadInputDOM.value = typeaheadInputDOM.value.slice(0, -1);
            }

          } else if (typeaheadTargetFrmCntrl.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT) {
            typeaheadTargetFrmCntrl.options = adrsClctn;
            this.interactionSyncService.announceInteractionControlListChange(typeaheadTargetFrmCntrl.id, typeaheadTargetFrmCntrl);
          }
        }, (err) => console.error(err)
      );
  }

 /**
   * This function is used to open a subscription which retrieves the details for the special control element
   * Retrieve Address Complete. If you update the names of the controls in the canadaPostRetrieve factory, you must
   * update them here as well
   *
   * @param addressId Address Id being searched
   * @param spclCntrlId Special Control Id
   * @param scrnIdx Index of the form screen
   */
  private cpSpclCntrlRetrieveAddressComplete(addressId: string, spclCntrlId: string, scrnIdx: number): void {
    this.cpService.retrieveAddressComplete(addressId)
      .pipe(take(1))
      .subscribe((adrsRetrieveResult: any) => {

        if (!(adrsRetrieveResult &&
          adrsRetrieveResult.Items &&
          Array.isArray(adrsRetrieveResult.Items) &&
          adrsRetrieveResult.Items.length > 0)) return;

        const spclCntrlIdx = this.dynFrm.specialControls.map(sc => sc.id).indexOf(spclCntrlId);
        if (spclCntrlIdx === -1) return;

        const spclCntrls = this.dynFrm.specialControls[spclCntrlIdx].controls;
        if (!(spclCntrls && Array.isArray(spclCntrls) && spclCntrls.length > 0)) return;

        const spclCntrlCustomType = this.dynFrm.specialControls[spclCntrlIdx].customType;
        const frmSpclCntrlIdx = FRM_CNTRL_SPECIAL.map(sc => sc.customType).indexOf(spclCntrlCustomType);
        if (frmSpclCntrlIdx === -1) return;
        const spclCntlFactory = FRM_CNTRL_SPECIAL[frmSpclCntrlIdx].factory.map(i => i.name);

        const result = adrsRetrieveResult.Items[0];

        spclCntrls.every((retrieveCntrl: any, idx: number) => {

          const cntrl = this.getElementByOriginalId(retrieveCntrl.id, scrnIdx);

          let valueChanged = false;
          let newValue = null;

          switch (spclCntlFactory[idx]) {
            case 'Street Address':
            case 'Line1':
              newValue = result.Line1;
              valueChanged = true;
              break;
            case 'Address Line 2':
            case 'Line2':
              newValue = result.Line2;
              valueChanged = true;
              break;
            case 'City':
              newValue = result.City;
              valueChanged = true;
              break;
            case 'Province':
            case 'Province/State':
              newValue = result.ProvinceName;
              valueChanged = true;
              break;
            case 'Postal Code':
            case 'Postal Code/Zip Code':
              newValue = result.PostalCode;
              valueChanged = true;
              break;
            case 'Country':
              newValue = result.CountryName;
              valueChanged = true;
              break;
          }

          // const valueChange = new InteractionEmitValueModel(
          //   this.dynFrm.id,
          //   this.dynFrm.originalId,
          //   cntrl.hint,
          //   cntrl.label,
          //   cntrl.originalId,
          //   scrnIdx, retrieveCntrl.id, cntrl.value,
          //   cntrl.inputType,
          //   cntrl.type,
          //   null,
          //   cntrl.options,
          //   (this.processId) ? this.processId : null,
          //   cntrl.group?.map(group => {
          //     return { id: group.id, label: group.label };
          //   }),
          //   true
          // );

          // this.interactionSyncService.announceControlValueUpdate(valueChange);

          if (valueChanged) {
            cntrl.value = newValue;
            const screenIndex = this.getElementAndScreenIndexById(cntrl.originalId).scrnIdx;
            const interactionControl = new InteractionControlModel(
              this.dynFrm.id,
              this.dynFrm.originalId,
              cntrl,
              screenIndex,
              this.processId,
              this.targetId,
              null,
              null,
              InteractionValueEmitter.DialogFlow,
              null,
              true,
              null,
              this.isPreview,
              false,
              this.cpCntrlChangeWatch.find(canadaPostControl => canadaPostControl.id === cntrl?.originalId)
                ? true : false,
              this.visualizerMode,
              this.isEditInteractionFlow,
              this.tabIndex,
              this.processTitle
            );
            setTimeout(() => {
              this.interactionSyncService.announceInteractionControl(interactionControl);
            });
            if (idx === spclCntrls.length - 1) {
              const interactionControlRequest = new InteractionControlRequest(
                this.dynFrm.id,
                cntrl.id,
                searchDirection.forward,
                Guid.newGuid().toString(),
                this.targetId,
                this.dynFrm.originalId,
                cntrl.originalId,
                InteractionValueEmitter.DialogFlow
              );
              this.broadcastInteractionControlRequest(interactionControlRequest);
            }

            // const valueChange = new InteractionEmitValueModel(
            //   this.dynFrm.id,
            //   this.dynFrm.originalId,
            //   cntrl.hint,
            //   cntrl.label,
            //   cntrl.originalId,
            //   scrnIdx, retrieveCntrl.id, newValue,
            //   cntrl.inputType,
            //   cntrl.type,
            //   InteractionValueEmitter.DialogFlow, // using DialogFlow as emitter so as to distinguish in SaltChatComponent
            //   cntrl.options,
            //   (this.processId) ? this.processId : null,
            //   cntrl.group?.map(group => {
            //     return { id: group.id, label: group.label };
            //   }),
            //   true,
            //   searchDirection.forward,
            //   this.isPreview
            // );

            // this.interactionSyncService.announceControlValueUpdate(valueChange);
          }

          return true;
        });

      }, (err) => console.error(err));
  }

  /**
   * This function is called when the next address find becomes necessary, based on corresponding select item change
   * @param ev the object of the control being updated
   */
  private cpSpclCntrlChangeNext(ev): void {
    if (!(this.cpCntrlChangeWatch &&
      Array.isArray(this.cpCntrlChangeWatch) &&
      this.cpCntrlChangeWatch.length > 0 &&
      ev.model &&
      ev.model.originalId &&
      ev.control &&
      ev.control.value)) return;

    const elmntId = ev.model.id;
    const elmntOriginalId = ev.model.originalId;
    const cpSelectIdx = this.cpCntrlChangeWatch.map(s => s.id).indexOf(elmntOriginalId);
    if (cpSelectIdx === -1) return;

    //#region :: Find the element value
    let elmntValue = ev.control.value;
    if (ev.model.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT &&
      ev.model.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT) {
      if (ev.model.additional && ev.model.additional.originalList &&
        Array.isArray(ev.model.additional.originalList) &&
        ev.model.additional.originalList.length > 0) {
        const cpSuggestionList = ev.model.additional.originalList;
        const elmntValueIdx = cpSuggestionList.map(a => a.label).indexOf(elmntValue);
        elmntValue = (elmntValueIdx !== -1) ? cpSuggestionList[elmntValueIdx].value : null;
      } else elmntValue = null;
    }
    //#endregion
    if (!(elmntValue && elmntValue.Next)) return;

    const spclCntrlId = this.cpCntrlChangeWatch[cpSelectIdx].spclCntrlId;
    const customType = this.cpCntrlChangeWatch[cpSelectIdx].customType;
    const scrnIdx = this.cpCntrlChangeWatch[cpSelectIdx].scrnIdx;
    const typeaheadCntrlFrmCntrl =
      this.ngDynFormService.findById(elmntId, this.formVisualizerModelArray[scrnIdx]);

    if (elmntValue.Next === CpApiType.Find) {
      this.cpSpclCntrlFindAddressComplete(elmntValue.Text, typeaheadCntrlFrmCntrl, elmntValue.Id);
    } else if (elmntValue.Next === CpApiType.Retrieve &&
      (customType === FrmControlSpecialCustomType.CanadaPostRetrieve ||
        customType === FrmControlSpecialCustomType.CanadaPostDataListRetrieve ||
        customType === FrmControlSpecialCustomType.CanadaPostInternationalDataListRetrieve)
    ) {
      this.cpSpclCntrlRetrieveAddressComplete(elmntValue.Id, spclCntrlId, scrnIdx);
    }
  }
  //#endregion

  //#region Get interaction element(s) methods
  /**
   * This function returns the from control where id is originalId
   *
   * @param originalId Id of the control item
   * @param scrnIdx: Index of the screen
   */
  private getElementByOriginalId(originalId: string, scrnIdx: number): any {
    let cntrl = null;
    const modelArray = this.formVisualizerModelArray[scrnIdx];
    if (!modelArray) return cntrl;

    const elmntIdx = modelArray.map(m => m.originalId).indexOf(originalId);
    if (elmntIdx === -1) return cntrl;

    cntrl = this.ngDynFormService.findById(modelArray[elmntIdx].id, this.formVisualizerModelArray[scrnIdx]);

    return cntrl;
  }

  /**
   * This function returns control element from index
   * @param scrnIdx Index of the screen
   * @param elmntIdx Index of the element
   */
  private getElementByIndex(scrnIdx: number, elmntIdx: number): any {
    let cntrl = null;
    if (!((scrnIdx || scrnIdx === 0) && (elmntIdx || elmntIdx === 0))) return cntrl;

    if (!(this.formVisualizerModelArray &&
      Array.isArray(this.formVisualizerModelArray) &&
      this.formVisualizerModelArray.length > 0 &&
      this.formVisualizerModelArray.length > scrnIdx)) return cntrl;

    if (elmntIdx < 0) {   // The element is on the previous screen
      return cntrl;
    }

    const modelArray = this.formVisualizerModelArray[scrnIdx];
    if (!(modelArray && Array.isArray(modelArray) && modelArray.length > 0)) return cntrl;
    if (elmntIdx >= modelArray.length) {   // this screen does not contain the element index or we're at end of screen
      return cntrl;
    }

    cntrl = this.ngDynFormService.findById(modelArray[elmntIdx].id, this.formVisualizerModelArray[scrnIdx]);

    return cntrl;
  }

  /**
   * This function returns form control based on id
   *
   * @param frmCntrlId Id of the form control
   */
  private getElementAndScreenIndexById(frmCntrlId, elementIndex?: number): InteractionFormControlIndexModel {
    const cntrlIndxMdl = new InteractionFormControlIndexModel(frmCntrlId, null, null);

    if (!(this.formVisualizerModelArray &&
      Array.isArray(this.formVisualizerModelArray) &&
      this.formVisualizerModelArray.length > 0)) return cntrlIndxMdl;

    let scrnIdx: number = null;
    this.formVisualizerModelArray.every(frmMdlArray => {

      scrnIdx = (scrnIdx === null) ? 0 : ++scrnIdx;
      const cntrl = frmMdlArray.find(formModel => formModel.originalId === frmCntrlId);
      cntrlIndxMdl.scrnIdx = scrnIdx;

      if (cntrl) {
        const elmntIdx = frmMdlArray.map(c => c.originalId).indexOf(frmCntrlId);
        if (elmntIdx !== -1) {
          cntrlIndxMdl.elmntIdx = elmntIdx;
          return false;
        }
      }

      return true;
    });

    let newTotal = 0;
    if (elementIndex && elementIndex > (this.formVisualizerModelArray[0].length - 1)) {
      let modelIndex = 0;
      for (const formModel of this.formVisualizerModelArray) {
        const previousTotal = newTotal;
        newTotal += formModel.length;
        if (elementIndex <= newTotal - 1) {
          cntrlIndxMdl.scrnIdx = modelIndex;
          cntrlIndxMdl.elmntIdx = elementIndex - previousTotal - 1;
          break;
        }
        modelIndex++;
      }
    } else {
      // if ((!cntrlIndxMdl.elmntIdx && cntrlIndxMdl.elmntIdx !== 0) || elementIndex > 0) {
        if (elementIndex || elementIndex === 0) {
          cntrlIndxMdl.scrnIdx = 0;
          cntrlIndxMdl.elmntIdx = elementIndex - 1;
        }
      // }
    }

    return cntrlIndxMdl;
  }

  private getElementById(frmCntrlId: string, direction?: string): any {
    let cntrl = null;

    const elmntAndScrnIdx = this.getElementAndScreenIndexById(frmCntrlId);
    if (!((elmntAndScrnIdx.scrnIdx || elmntAndScrnIdx.scrnIdx === 0) &&
      (elmntAndScrnIdx.elmntIdx || elmntAndScrnIdx.elmntIdx === 0))) return cntrl;

    // if ( direction === searchDirection.forward ) ++elmntAndScrnIdx.elmntIdx;
    // else if (direction === searchDirection.backward ) --elmntAndScrnIdx.elmntIdx;

    cntrl = this.getElementByIndex(elmntAndScrnIdx.scrnIdx, elmntAndScrnIdx.elmntIdx);

    return cntrl;
  }
  //#endregion

  /**
   * This function gets form control based on form control id and direction
   * @param frmCntrlId Id of the form control
   * @param direction Direction of the search
   */
  private getNextPrevElementByFormCntrlId(frmCntrlId: string, direction?: string, elementIndex?: number): any {
    const cntrl = {
      cntrl: null,
      errMsg: "",
      errType: null
    };

    let currentElement = this.getElementAndScreenIndexById(frmCntrlId, elementIndex);
    if (!frmCntrlId) {
      if (!elementIndex) {
        elementIndex = this.formVisualizerModelArray[currentElement.scrnIdx].length - 1;
      }
      currentElement = {
        scrnIdx: currentElement.scrnIdx,
        elmntIdx: currentElement.elmntIdx,
        id: this.getElementByIndex(0, elementIndex - 1)?.originalId
      };
    }
    // check to see if we don't have a screen index and an element id
    if (!((currentElement.scrnIdx || currentElement.scrnIdx === 0) &&
      (currentElement.elmntIdx || currentElement.elmntIdx === 0))) {
      return cntrl;   // nothing found
    }
    let screenDirection: number;
    // increment or decrement based on searchDirection

    let directionalMethodCall;
    if (direction === searchDirection.forward) {
      ++currentElement.elmntIdx;
      screenDirection = 1;
      directionalMethodCall = this.getFirstElementOfInteraction;
    } else if (direction === searchDirection.backward) {
      --currentElement.elmntIdx;
      screenDirection = -1;
      directionalMethodCall = this.getLastElementOfInteraction;
    } else {
      cntrl.errMsg = `Search Direction Not found: ${direction}`;
      return cntrl;
    }

    // search on current screen with new elementIndex to see if we find something
    cntrl.cntrl = this.getElementByIndex(currentElement.scrnIdx, currentElement.elmntIdx);

    if (!cntrl.cntrl) {
      // increment/decrement screen and get element
      // if next screen then get first element
      // if previous then get last element
      // let elementIndex = currentElement.elmntIdx;

      // increment/decrement screen and search for element
      cntrl.cntrl = directionalMethodCall(currentElement.scrnIdx + screenDirection, this.formVisualizerModelArray);

      // cntrl = this.getElementByIndex(screenIndex, currentElement.elmntIdx);

      if (!cntrl.cntrl && (direction === searchDirection.forward)) {
        cntrl.errMsg = `We are at the end of ${this.dynFrm.name} interaction`;
        cntrl.errType = searchDirection.forward;
      } else if (!cntrl.cntrl && (direction === searchDirection.backward)) {
        cntrl.errMsg = `We are at the beginning of ${this.dynFrm.name} interaction`;
        cntrl.errType = searchDirection.backward;
      } else {
        if (this.stepper) {
          this.stepper.selectedIndex = currentElement.scrnIdx + screenDirection;
        }
      }
    } else {
      if (cntrl.cntrl.value !== null && typeof cntrl.cntrl.value === 'object') {
        cntrl.cntrl.value = '';
      }
    }

    return cntrl;
  }

  /**
   * This function returns the last form control of interaction
   *
   * @param screenIndex Index of the screen
   * @param formVisualizerModelArray Array containing all the screens
   */
  private getLastElementOfInteraction(screenIndex: number, formVisualizerModelArray: Array<any>): any {
    let formControlElement = null;
    if (screenIndex < formVisualizerModelArray.length && screenIndex >= 0) {
      const formLength = formVisualizerModelArray[screenIndex].length;
      formControlElement = formVisualizerModelArray[screenIndex][formLength - 1];
    }
    return formControlElement;
  }

  /**
   * This function returns the first form control of interaction
   *
   * @param screenIndex Index of the screen
   * @param formVisualizerModelArray Array containing all the screens
   */
  private getFirstElementOfInteraction(screenIndex: number, formVisualizerModelArray: Array<any>): any {
    let formControlElement = null;
    if (screenIndex < formVisualizerModelArray.length && screenIndex > 0) {

      formControlElement = formVisualizerModelArray[screenIndex][0];
    }

    return formControlElement;
  }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.

    if (this.visualizerSubs) {
      this.visualizerSubs.unsubscribe();
    }

    if (this.formGroupsValueChangeSubs) {
      this.formGroupsValueChangeSubs.unsubscribe();
    }

    if (this.interactionSpecialControlSubs) {
      this.interactionSpecialControlSubs.unsubscribe();
    }

  }

  /**
   * This function initializes the dynamic form
   *
   * @param dynamicFormObj Dynamic Form Object being cloned
   */
  dynFrmInit(dynamicFormObj): void {
    const that = this;
    dynamicFormObj = this.formBuilderService.fixOldShapeForRelations(dynamicFormObj);
    this.originalDynamicForm = JSON.parse(JSON.stringify(dynamicFormObj));
    this.originalDynamicForm['FormScreens'].forEach(screen => {
      screen['FormElements'].forEach(element => {
        if (element['additional'] && Array.isArray(element['additional'].relation)) {
          element.relation = element['additional'].relation;
        }
      });
    });
    this.dynFrm = this.formBuilderService.cloneInteraction(dynamicFormObj);
    if (!this.dynFrm) return;
    this.formScreens = this.dynFrm.FormScreens;   // Utilized in template

    //#region :: date time related setups! :: if it's not in edit mode
    // must loop before elements are disabled to set to current time
    this.dynFrm.FormScreens.forEach((frmScrn, scrnIndex) => {
      frmScrn.FormElements.forEach(element => {
        if (!that.visualizerMode || (that.visualizerMode && that.visualizerMode !== InteractionVisualizerMode.edit)) {
          that.formatIfCurrentDateTime(element);
        }
        if (element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          let formElement;
          dynamicFormObj['FormScreens'].forEach(screen => {
            screen['FormElements'].forEach(incomingElement => {
              if (incomingElement.id === element.originalId) {
                formElement = incomingElement;
              }
            });
          });
          if (formElement) {
            formElement.group?.forEach(groupControl => {
              if (groupControl.additional?.['defaultVal']) {
                element.group?.forEach(elementGroupControl => {
                  if (elementGroupControl.label === groupControl.label) {
                    if (!elementGroupControl.additional) {
                      elementGroupControl.additional = {};
                    }
                    elementGroupControl.additional['defaultVal'] = groupControl.additional?.['defaultVal'];
                  }
                });
              }
            });
          }
        }
      });
    });
    setTimeout(() => {
      this.dynFrm.FormScreens.forEach((formScreen, screenIndex) => {
        formScreen.FormElements.forEach(element => {
          if (Array.isArray(element.relations) && element.relations.length > 0) {
            element.relations.forEach(relation => {
              // TODO Test this
              // relation.when.forEach(whenRelation => {
              //   const conditionElement = formScreen.FormElements.find(formElement => formElement.id === whenRelation.id);
              //   if (conditionElement &&
              //     ((Array.isArray(conditionElement.value) && Array.isArray(whenRelation.value))
              //     ? (conditionElement.value.length === whenRelation.value.length
              //       && conditionElement.value.every((value, index) => value === whenRelation.value[index]))
              //     : conditionElement.value === whenRelation.value)) {
              //     element.disabled = true;
              //   }
              // });
            });
          }
        });
      });
    });
    //#endregion
    if (this.isAllDisabled) {
      this.dynFrm.FormScreens.forEach((frmScrn, scrnIndex) => {
        frmScrn.FormElements.forEach(element => {
          element.disabled = true;
          if (element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
            element.group.forEach(subElement => {
              subElement.disabled = true;
            });
          }
        });
      });
    }

    this.flatInteraction = this.formBuilderService.flatInteraction(this.dynFrm);

    // needs second loop to count required elements in interaction, must check inside each element
    this.dynFrm.FormScreens.forEach((frmScrn, scrnIndex) => {
      frmScrn.FormElements.forEach(element => {
        // if it is a radio group check if it is required, if it is and there is no value yet, disable submit
        if (element.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP && element.required === true && !element.value
          && element.disabled === false) {
          this.radioIsRequired = true; // if a radio is required, set to true and count the number of requried radio buttons
          this.numRadioRequired++;
        }

        // get  count of number of checkboxs required
        if (element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX && element.required === true && !element.value
          && element.disabled === false) {
          this.checkboxIsRequired = true; // if a radio is required, set to true and count the number of requried radio buttons
          this.numCheckboxRequired++;
        }

      });
    });

    if (this._processInteractionValues?.interactionValues?.elements) {
      this._processInteractionValues.interactionValues.elements.forEach(values => {
        const currentElement = this.dynFrm.FormScreens[values.scrnIndex].FormElements.find(element =>
          element.originalId === values.originalId);
        if (currentElement) {
          currentElement.value = values.value;
          currentElement.disabled = values.disabled;
        }
      });
    }

    //#region Loop through the interaction screens to generate form groups beside keep collecting form's model and layouts
    this.formVisualizerModelArray = [];
    this.formVisualizerFormGroupArray = [];
    this.dynFrm.FormScreens.forEach((frmScrn, scrnIndex) => {
      this.formVisualizerModelArray.push(frmScrn.FormElements);
      const formGroup: UntypedFormGroup = this.ngDynFormService.createFormGroup(frmScrn.FormElements);

      Object.keys(formGroup.controls).forEach(function (key, index) {

        const frmGrpControl: UntypedFormControl = formGroup.controls[key] as UntypedFormControl;
        const frmCntrlMdl = that.formVisualizerModelArray[scrnIndex].find(element => element.id === key);

        if (frmCntrlMdl && frmCntrlMdl.additional && frmCntrlMdl.additional.defaultVal && !frmCntrlMdl.value) {
          frmCntrlMdl.value = frmCntrlMdl.additional.defaultVal;
        }

      // check for hyperlinks entered as labels
      // if they are one of the types below, the hyperlinks will work with no extra handling
      if ( isString(frmCntrlMdl.label) &&
        (frmCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_SLIDER ||
        frmCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_SWITCH ||
        frmCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX)
      ) {
        frmCntrlMdl.label = that._utilsService.findURL(frmCntrlMdl.label);
      } else if (frmCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP
      || frmCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          // is not a mat label, but is a checkbox group, or a radio button
          if (frmCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP ) {
            frmCntrlMdl.options.forEach(element => {
              element.label = that._utilsService.findURL(element.label);
            });
          } else if (frmCntrlMdl.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
            frmCntrlMdl.group.forEach(element => {
              element.label = that._utilsService.findURL(element.label);
            });
          }
          frmCntrlMdl.label = that._utilsService.findURL(frmCntrlMdl.label, false, frmCntrlMdl);
      } else {
        // check for url, send boolean to mark it as a material design input control
        frmCntrlMdl.label = that._utilsService.findURL(frmCntrlMdl.label, true, frmCntrlMdl);
      }

      // convert hints into urls
      if (frmCntrlMdl.hint) {
        frmCntrlMdl.hint = that._utilsService.findURL(frmCntrlMdl.hint);
      }

      //#region :: date time related setups! :: if it's not in edit mode
      if ( !that.visualizerMode || ( that.visualizerMode && that.visualizerMode !== InteractionVisualizerMode.edit ) ) {
        that.formatIfCurrentDateTime(frmCntrlMdl);
      }
      //#endregion

        that.formGroupsValueChangeSubs.add(
          frmGrpControl.valueChanges.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)), skip(1))
            .subscribe(
              (value) => {
                that.isAnyValueChanged = true;
                let intrctFlatValues: any = null;
                //#region emit value change through whole flat interaction object with values
                if (that.flatInteractionValueChange(frmScrn, index, value, that.formVisualizerFormGroupArray)) {
                  that.interactionSyncService.announceInteractionValueChange(that.flatInteraction);
                  intrctFlatValues = that.flatInteraction;
                }
                //#endregion

              let elementInputType = frmScrn.FormElements[index].inputType;

              if ((elementInputType === undefined) && (frmScrn.FormElements[index].multiple === true)) {
                elementInputType = "multiple";
              }

              let isSpecialControl = false;
              that.dynFrm?.specialControls?.forEach(specialControl => {
                isSpecialControl = specialControl.controls?.find(subControl =>
                  subControl?.id === frmScrn.FormElements[index].originalId) ? true : false;
              });

              const valueChange = new InteractionEmitValueModel(
                that.dynFrm.id,
                that.dynFrm.originalId,
                frmScrn.FormElements[index].hint,
                frmScrn.FormElements[index].label,
                frmScrn.FormElements[index].originalId,
                scrnIndex, key, value,
                elementInputType,
                frmScrn.FormElements[index].type,
                null,
                frmScrn.FormElements[index].options,
                (that.processId) ? that.processId : null,
                frmScrn.FormElements[index].group?.map(group => {
                  return { id: group.id, label: group.label };
                }),
                isSpecialControl,
                searchDirection.forward,
                that.isPreview,
                that.cpCntrlChangeWatch.find(canadaPostControl => canadaPostControl.id === frmScrn.FormElements[index].originalId)
                  ? true : false
              );

              const intrctAndLastElmntValues =
              new InteractionAndElementEmitModel(
                intrctFlatValues,
                valueChange,
                (that.processId) ? that.processId : null,
                that.isPreview
              );

              const validAnnouncement: FormControlValidAnnouncement = {
                valid: frmGrpControl.valid,
                errors: frmGrpControl.errors,
                type: frmScrn.FormElements[index].type,
                inputType: elementInputType,
                hint: frmScrn.FormElements[index].hint
              };

              if ((((frmScrn.FormElements[index].type === DYNAMIC_FORM_CONTROL_TYPE_SELECT
                  && frmScrn.FormElements[index].multiple)
                || (frmScrn.FormElements[index].type === DYNAMIC_FORM_CONTROL_TYPE_INPUT
                && frmScrn.FormElements[index].inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT)) && Array.isArray(value))
                ? false
                : (frmScrn.FormElements[index].type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP
                  && typeof value === 'object')
                  ? false
                  : (frmScrn.FormElements[index].type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX
                    || frmScrn.FormElements[index].type === DYNAMIC_FORM_CONTROL_TYPE_SWITCH
                    && typeof value === 'boolean')
                    ? false
                    : ((frmScrn.FormElements[index].type === DYNAMIC_FORM_CONTROL_TYPE_INPUT
                      && frmScrn.FormElements[index].inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL))
                      // && (value.includes('(') || value.includes('-') || value.includes(')')))
                      ? false
                      : true) {
                that.interactionSyncService.announceControlValueChange(valueChange);   // To announce any value changes on form controls
                that.interactionSyncService.announceInteractionAndLastElementValueChange(intrctAndLastElmntValues);
                that.interactionSyncService.announceControlValidChange(validAnnouncement);
              }
              that.interactionSyncService.announceControlValueChangeForDatabase(valueChange);
            },
            (err) => { console.error(err); throw err; }
          )
        );
      });

      this.formVisualizerFormGroupArray.push(formGroup);
      this.formVisualizerLayoutArray.push(frmScrn.FormElementsLayout);
      if (this.dynFrm.FormScreens?.[0]?.FormElements?.[0]) {
        const response = this._conditionalObjectCheckerService.checkElementRelations(this.flatInteraction,
          this.dynFrm.FormScreens[0], this.dynFrm.FormScreens, this.formVisualizerFormGroupArray);
        this.isNextButtonActive = response.valid;
        this.invalidMessage = response.invalidMessage;
        this.setMathOperatorsReadOnly();
      }
    });
    //#endregion

  }

  setMathOperatorsReadOnly() {
    this.dynFrm.FormScreens.forEach(screen => {
      screen.FormElements.forEach(element => {
        if (element['additional']
        && element['additional'].relation
        && element['additional'].relation.length > 0) {
        (<Relation[]>element['additional'].relation).forEach(relation => {
          relation.subRelation.forEach(subRelation => {
            if (subRelation.then.action === IfThenLogicAction.MathEquation) {
              element.readOnly = true;
            }
          });
        });
      }
      });
    });
  }

  setupProcessFromInteractionValues(interactionValues: any[], executingInteraction: any): void {
    if ( !(interactionValues) ) return;

    interactionValues.forEach(formElementValue => {
      // check if not null value
      if (formElementValue) {
        const elementToUpdate = formElementValue;
        const screenElement = executingInteraction.FormScreens[elementToUpdate.scrnIndex]
          .FormElements.find(element => element.id === elementToUpdate.originalId);

        const emitObject = new InteractionEmitValueModel(
          executingInteraction.id,
          executingInteraction.originalId,
          screenElement.hint,
          screenElement.label,
          elementToUpdate.originalId,
          elementToUpdate.scrnIndex,
          screenElement.id,
          elementToUpdate.value,
          elementToUpdate.inputType,
          elementToUpdate.type,
          null,
          null,
          null,
          null,
          false,
          searchDirection.forward,
          null,
          null,
          null
        );
        this.interactionSyncService.announceControlValueUpdate(emitObject);
      }
    });
  }
  /**
   * If the property "currentDateDefault" is set to true, the date for the field will be automatically set to the current time.
   * This function is to ensure that the date returned by the getDate/getMonth etc. calls will return a value that conforms to
   * the ISO-8601 format that datetimes are being stored and displayed in.
   * @param frmCntrlMdl the form control model of the element with currentDateDefault set to true
   */
  private formatIfCurrentDateTime(frmCntrlMdl: any): void {
    if (frmCntrlMdl && frmCntrlMdl.additional && frmCntrlMdl.additional.currentDateDefault) {
      const today = new Date();
      const dd = today.getDate();
      const mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      const hours = today.getHours();
      const minutes = today.getMinutes();
      let formattedMinutes;
      let formattedHours;
      let formattedmm;
      let formatteddd;

      // by default, the 'get' functions return a single digit if it is under 10. Must add a '0' to conform to ISO standard.
      // conforming values are stored with the "formatted" prefix
      if (minutes < 10) {
        formattedMinutes = '0' + minutes.toString();
      } else {
        formattedMinutes = minutes.toString();
      }

      if (dd < 10) {
        formatteddd = '0' + dd.toString();
      } else {
        formatteddd = dd.toString();
      }

      if (mm < 10) {
        formattedmm = '0' + mm.toString();
      } else {
        formattedmm = mm.toString();
      }

      if (hours < 10) {
        formattedHours = '0' + hours.toString();
      } else {
        formattedHours = hours.toString();
      }

      // based on the input type, arrange the newly formatted values to create the string to be displayed
      switch (frmCntrlMdl.inputType) {
        case 'date':
          frmCntrlMdl.value = yyyy + '-' + formattedmm + '-' + formatteddd;
          break;
        case 'datetime-local':
          const foundTime = yyyy + '-' + formattedmm + '-' + formatteddd + 'T' + formattedHours + ':' + formattedMinutes;
          frmCntrlMdl.value = foundTime;
          break;
        case 'month':
          frmCntrlMdl.value = yyyy + '-' + formattedmm;
          break;
        case 'week':
          // to get the week number, create a date for the first of january, then subtract the current time and divide it all by 7
          const onejan = new Date(today.getFullYear(), 0, 1);
          const week = Math.ceil((((today.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);

          // add 0 if below 10 and a -W after the year to maintain format
          let formattedWeek;
          if (week < 10) {
            formattedWeek = '0' + week.toString();
          } else {
            formattedWeek = week.toString();
          }
          frmCntrlMdl.value = yyyy + '-W' + formattedWeek;
          break;
        case 'time':
          frmCntrlMdl.value = formattedHours + ':' + formattedMinutes;
          break;
      }
    }
  }

  /**
   * This function is called whenever an element that is stored in the flat interaction array of the dynamic form is
   * changed. It is used to check if any action must be taken after the elements value has changed
   *
   * @param frmScrn Form Screen containing the form control
   * @param index Index of the form control
   * @param value New value of the form control fo type DynamicFormControlMode
   */
  private flatInteractionValueChange(frmScrn: any, index: number, value: any, formGroups: UntypedFormGroup[]): boolean {
    if (!(this.flatInteraction &&
      this.flatInteraction.elements &&
      Array.isArray(this.flatInteraction.elements))) return false;

    const element = this.flatInteraction.elements.find(elmnt => elmnt.originalId === frmScrn.FormElements[index].originalId);
    let formGroupControl: AbstractControl;
    formGroups.forEach(formGroup => {
      if (formGroup.controls[element.id]) {
        formGroupControl = formGroup.controls[element.id];
      }
    });
    let elementValue = {};
    let isRelationValid = true;
    let conditionElement = null;
    // if element is a checkbox group, disable elements based on the relation set on the element
    if (element.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
      const formElement = frmScrn.FormElements.find(screenElement => screenElement.id === element.id);
      if (formElement) {
        formElement.group.forEach(checkbox => {
          Object.keys(value).forEach(checkboxId => {
            if (checkboxId === checkbox.id) {
              elementValue[checkbox.label] = value[checkboxId];
            }
          });
        });
      }
      frmScrn.FormElements.forEach(screenElement => {
        if (screenElement.relation) {
          screenElement.relation.forEach(relation => {
            // TODO: Test this
            // relation.when.forEach(whenRelation => {
            //   if (whenRelation.id === element.id) {
            //     conditionElement = screenElement;
            //     Object.keys(whenRelation.value).forEach(checkbox => {
            //       if (whenRelation.value[checkbox] !== elementValue[checkbox]) {
            //         isRelationValid = false;
            //       }
            //     });
            //   }
            // });
          });
        }
      });
      if (isRelationValid && conditionElement) {
        conditionElement.disabled = true;
      } else if (!isRelationValid && conditionElement) {
        conditionElement.disabled = false;
      }
    } else if ((element.type === DYNAMIC_FORM_CONTROL_TYPE_SELECT
      || (element.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT && Array.isArray(value)))
      || element.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP) {
      // else if element is a select, multi-select, chips, or radio group, disable elements based on the relation set on the element
      elementValue = value;
      frmScrn.FormElements.forEach(screenElement => {
        if (screenElement.id === element.id && !screenElement.multiple && Array.isArray(value) && value.length === 0) {
          elementValue = null;
        }
        if (screenElement.relation) {
          screenElement.relation.forEach(relation => {
            // TODO: Test this
            // relation.when.forEach(whenRelation => {
            //   if (whenRelation.id === element.id) {
            //     conditionElement = screenElement;
            //     if (Array.isArray(whenRelation.value)) {
            //       for (let i = 0; i < (whenRelation.value ? whenRelation.value.length : 0); i++) {
            //         if (element.type !== DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP && !value.includes(whenRelation.value[i])
            //           || (value.length !== (whenRelation.value ? whenRelation.value.length : 0))) {
            //           isRelationValid = false;
            //         }
            //       }
            //     } else {
            //       // element is radio group radio group
            //       if (value !== whenRelation.value) {
            //         isRelationValid = false;
            //       }
            //     }
            //   }
            // });
          });
        }
      });
      if (isRelationValid && conditionElement) {
        setTimeout(() => {
          conditionElement.disabled = true;
        });
        if (element.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP) {
          setTimeout(() => {
            conditionElement.disabled = true;
          });
        }
      }
    } else if (element.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT
      && element.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER) {
      if (element.maxLength || element.minLength) {
        if (('' + value).length < element.minLength) {
          this.numberMinLength = true;
          formGroupControl?.setErrors({ 'incorrect': true });
        } else {
          this.numberMinLength = false;
        }
        if (('' + value).length > element.maxLength) {
          this.numberMaxLength = true;
          formGroupControl?.setErrors({ 'incorrect': true });
        } else {
          this.numberMaxLength = false;
        }
      }
      if (element.max || element.min) {
        if (value < element.min) {
          this.numberMinValue = true;
          formGroupControl?.setErrors({ 'incorrect': true });
        } else {
          this.numberMinValue = false;
        }
        if (value > element.max) {
          this.numberMaxValue = true;
          formGroupControl?.setErrors({ 'incorrect': true });
        } else {
          this.numberMaxValue = false;
        }
        if (element.step) {
          if (value % element.step !== 0) {
            this.numberStepInvalid = true;
            formGroupControl?.setErrors({ 'incorrect': true });
          } else {
            this.numberStepInvalid = false;
          }
        }
      }
      elementValue = value;
    } else {
      elementValue = value;
    }

    frmScrn.FormElements.forEach(screenElement => {
      if (screenElement.disabled) {
        element.disabled = true;
      }
    });
    element.value = elementValue;
    const formGroupForElement = this.formVisualizerFormGroupArray[element.scrnIndex];

    if (formGroupForElement) {
      // check if the newly entered value matches any conditions
      const response = this._conditionalObjectCheckerService.checkElementRelations(this.flatInteraction, frmScrn,
        this.dynFrm.FormScreens, formGroups);
      this.isNextButtonActive = response.valid;
      this.invalidMessage = response.invalidMessage;
      const frmCntrlForElement = formGroupForElement.get(element.id);
      if (frmCntrlForElement) {
        element.valid = frmCntrlForElement.valid;
      }
      const cntrlMdl = frmScrn.FormElements[index];

      this.numRadioRequired = 0;
      this.numCheckboxRequired = 0;
      // needs second loop to count required elements in interaction, must check inside each element
      setTimeout(() => {
        this.dynFrm.FormScreens.forEach((formScreen, scrnIndex) => {
          formScreen.FormElements.forEach(formElement => {
            // if it is a radio group check if it is required, if it is and there is no value yet, disable submit
            if (formElement.type === DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP && formElement.required === true
              && (typeof formElement.value === undefined || formElement.value === '' || formElement.value === null)
              && this.formVisualizerFormGroupArray[scrnIndex]?.controls[formElement.id]?.disabled === false) {
              this.radioIsRequired = true; // if a radio is required, set to true and count the number of required radio buttons
              this.numRadioRequired++;
            }

            // get  count of number of checkboxes required
            if (formElement.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX && formElement.required === true
              && (typeof formElement.value === undefined || formElement.value === '' || formElement.value === null)
              && this.formVisualizerFormGroupArray[scrnIndex]?.controls[formElement.id]?.disabled === false) {
              this.checkboxIsRequired = true; // if a radio is required, set to true and count the number of required radio buttons
              this.numCheckboxRequired++;
            }

          });
        });
        // checks the status of required fields that are not validated by ng-dynamioc-forms library
      });
      this.checkForInvalidFields(frmScrn, cntrlMdl, value, element, formGroups);
    }
    return true;
  }

  /**
   * checks the status of required fields that are not validated by ng-dynamioc-forms library
   * @param frmScrn Form Screen containing the form control
   * @param cntrlMdl the model of the element the new value was entered on
   * @param value the new value entered
   * @param element the element the new value was entered on
   */
  private checkForInvalidFields(frmScrn: any, cntrlMdl: any, value: any, element: any, formGroups: UntypedFormGroup[]) {

    /* this code is to ensure that on country switch, the user will start seeing address from the other country
      wihtout having to type in the input field first */
    if (this.countrySelectId && this.countrySelectId === element.originalId) { // only run when country select control is changed
      const country = this.getCountry();
      let currentValue;
      if (this.stepper) {
        currentValue = this.dynFrm.FormScreens[this.stepper.selectedIndex].FormElements.find(
          formElement => formElement.originalId === this.addressInput.id);
      }
      if (currentValue ===  -1 || !currentValue) currentValue = ''; // if there is not a value, change -1 into ''
      this.cpSpclCntrlFindAddressComplete(currentValue.value, currentValue,  '',  country);

    }
    setTimeout(() => {
      // if the number of checkboxes still required is at 0, enable the submit button
      if (this.numCheckboxRequired === 0) {
        this.checkboxIsRequired = false;
      } else {
        this.checkboxIsRequired = true;
      }

      if (this.numRadioRequired === 0) {
        this.radioIsRequired = false;
      }
    });

    //#region :: is checking if new value is valid providing it's not disabled
    if (cntrlMdl.additional && cntrlMdl.additional.isPastDate) {
      if (!cntrlMdl.disabled) {   // When the date control is enabled it checks if invalid and add it to invalid date control collection
        this.checkIfDateIsAfterCurrent(cntrlMdl, value);
      } else {   // When the date control is disabled if checks if the date control is in invalid date control collections then drops it
        const invalidDateIndex = this.invalidDates.map(elementId => elementId).indexOf(cntrlMdl.id);
        if (invalidDateIndex !== -1) {   // If the date control was in invalid collection now that it's disabled the item can gets dropped
          this.invalidDates.splice(invalidDateIndex, 1);
          this.dateIsInvalid = (this.invalidDates.length === 0) ? false : true;
        }
      }
    }
    //#endregion

    // check if the newly entered value matches any conditions
    const response = this._conditionalObjectCheckerService.checkElementRelations(this.flatInteraction, frmScrn,
      this.dynFrm.FormScreens, formGroups);
    this.isNextButtonActive = response.valid;
    this.invalidMessage = response.invalidMessage;

  }

/**
 * A datetime property allows users to make it so the date can not be in the past.
 * This function is to check if the datetime value is before or after the current date.
 * Triggered by frmGrpControl.valueChanges observable, called in flatInteractionValueChange
 * @param element Datetime form element to be checked
 * @param newValue the new value that was entered by a user
 */
checkIfDateIsAfterCurrent(element: any, newValue: any) {
  if (this.dateIsInvalid) {
    setTimeout(() => {
      if (element.disabled) {
        this.dateIsInvalid = false;
      }
    }, 200);
  }

  let isBeforeCurrent = false;

  //#region validation based on the type of datetime related control
  switch (element.inputType) {
    case 'date':
      // const currentDate = new Date((Date.now())).toLocaleDateString().slice(0, 10);
      const startOfCurrentDate = new Date();
      const dd = String(startOfCurrentDate.getDate()).padStart(2, '0');
      const mm = String(startOfCurrentDate.getMonth() + 1).padStart(2, '0');
      const yyyy = startOfCurrentDate.getFullYear();
      const startOfCurrentDateString = yyyy + '-' + mm + '-' + dd;
      // convert to milliseconds
      const startOfCurrentDateMilliseconds = new Date(startOfCurrentDateString).getTime();
      const test = new Date(newValue).getTime();
      // if it is before the very start of the current day, return true
      if (new Date(newValue).getTime() < startOfCurrentDateMilliseconds) {
        isBeforeCurrent = true;
      }

      break;
    case 'datetime-local':
      if (new Date(newValue).getTime() + 60000 < Date.now()) {
        isBeforeCurrent = true;
      }

      break;
    case 'month': // defaults to 1 day before start of month, should default to end of month
      if (typeof newValue !== 'object') {
        newValue = new Date(newValue);
        newValue.setMonth(newValue.getMonth() + 1);

        if (new Date(newValue).getTime() < Date.now()) {
          isBeforeCurrent = true;
        }
      }
      break;
    case 'week':
      if (typeof newValue !== 'object') { // newvalue initially loads as an object, causes errors
        const year = newValue.substr(0, newValue.indexOf('-'));
        const dayNum = (newValue.substr(newValue.indexOf('-') + 2, newValue.length)) * 7;
        const date = new Date(year, 0); // initialize a date in `year-01-01`
        const finDate = new Date(date.setDate(dayNum)); // add the number of days

        if (new Date(finDate).getTime() < Date.now()) {
          isBeforeCurrent = true;
        }
      }
      break;
    case 'time':
      if (typeof newValue !== 'object') {
        const hours = Number((newValue as string).split(':')[0]);
        const minutes = Number((newValue as string).split(':')[1]);
        const date = new Date().setHours(hours, minutes + 1, 0);
        if (date < Date.now()) {
          isBeforeCurrent = true;
        }
      }
  }
  //#endregion

  if (!element.label) { // will throw error if label is null
    element.label = "";
  }

  // If the value is in the past a warning message must be displayed to users.
  // to display the message, it is appended to the element label
  // if date is before current datetime, and has not been added already, add to invalid dates array
  if (isBeforeCurrent && !this.invalidDates.includes(element.id)) {
    this.invalidDates.push(element.id);
    element.label = element.label + ' **Please note that the date cannot be in the past';
  } else if (element.disabled && isBeforeCurrent || !isBeforeCurrent) {
    // if date is after current date time or disabled, look for a match to remove from the array
    for (let invalidPosition = 0; invalidPosition < this.invalidDates.length; invalidPosition++) {
      if (this.invalidDates[invalidPosition] === element.id) {
        // match found, splice from array and remove label text
        this.invalidDates.splice(invalidPosition, 1);
        element.label = element.label.replace(' **Please note that the date cannot be in the past', '');
      }
    }

  }

  // if array is empty, all dates are valid
  if (this.invalidDates.length === 0) {
    this.dateIsInvalid = false;
  } else {
    this.dateIsInvalid = true;
  }

  //#endregion
}

  /**
   * This function will attempt to submit the interaction. If it is marked as
   * @param isPrevInteraction stores direction interaction will move in. If back button is pushed it will set isPrevInteraction to true.
   * If previous is not true or doesnt exist, it will default to 'next'
   */
    async trySubmit(redirectBool: boolean, isPrevInteraction?: boolean): Promise<void> {
    // for each element in each formscreen, check if the element was disabled. If it was, nullify the value
    this.formVisualizerFormGroupArray.forEach(formGroup => {
      Object.keys(formGroup.controls).forEach(id => {
        if (formGroup.controls[id].disabled) {
          let element;
          this.dynFrm.FormScreens.forEach((frmScrn, scrnIndex) => {
            frmScrn.FormElements.forEach(formElement => {
              if (formElement.id === id) {
                element = formElement;
              }
            });
          });
          element.disabled = true;
          if (element.type === DYNAMIC_FORM_CONTROL_TYPE_SWITCH) {
            element.value = false;
          } else {
            element.value = null;
          }
        }
      });
    });

    // processInteractionSubmit will announce a new submission to interactionSyncService.processInteractionSubmit$ observable
    if (this.processId && this.workflowId) return this.processInteractionSubmit(isPrevInteraction, true);

    // if there is no processId or workflowId it is not an interaction, it is a change, so submit it.
    this.isSubmitting = true;
    this.onWaitForSubmittingMessage = 'Submitting ...';

    try {
      await this.interactionSubmissionService.submit(this.dynFrm, this.originalDynamicForm, this.dbId, redirectBool);
      this.isSubmitting = false;
      this.isAnyValueChanged = false;
    } catch (err) {
      this.isSubmitting = false;
      console.error(err);
    }
  }

  /**
   * This function exists to create a ProcessInteractionEmitValueModel to be annouced to the process-runner
   * @param isPrevInteraction boolean to determine which direction the workflow will move in
   */
  private processInteractionSubmit(isPrevInteraction?: boolean, submitInteraction?: boolean): void {
    if (!(this.processId && this.workflowId)) return;

    const flatInteraction = this.formBuilderService.flatInteraction(this.dynFrm);
    const prcsIntrctValues = new ProcessInteractionEmitValueModel(
      this.processId,
      this.workflowId,
      flatInteraction.id,
      flatInteraction.originalId,
      flatInteraction,
      (isPrevInteraction) ? submitDirectionType.previous : submitDirectionType.next,
      submitInteraction
    );

    // sends object to observable to be read by interactionSyncService.processInteractionSubmit$ in process-runner oninit
    this.interactionSyncService.announceProcessInteractionSubmit(prcsIntrctValues);

  }

  /**
   * This function checks the validity of the form
   */
  isFormNotValid(): boolean {
    let isNotValid = false;
    this.formVisualizerFormGroupArray.forEach(frmGrp => {
      if (!isNotValid && !frmGrp.valid) {
        let isAllDisable = true;
        Object.keys(frmGrp.controls).forEach(function (key, index) {
          const frmGrpControl: any = frmGrp.controls[key];
          if (!frmGrpControl.disabled) { isAllDisable = false; }
        });
        isNotValid = true && !isAllDisable;
      }
    });

    return isNotValid;
  }

  getCurrentInvalidFormElements = (): any[] => {
    const notValidElements = [];
    this.formVisualizerFormGroupArray.forEach((frmGrp, groupIndex) => {
      if (!frmGrp.valid) {
        Object.keys(frmGrp.controls).forEach((key, index) => {
          const frmGrpControl: any = frmGrp.controls[key];
            const formGroupModel = this.formVisualizerModelArray[groupIndex].find(model => model.id === key);
          if (!frmGrpControl.disabled && !frmGrpControl.valid) {
            notValidElements.push(formGroupModel);
          }
        });
      }
    });

    return notValidElements;
  }

  /**
   * This function checks if current form screen is valid or not
   *
   * @param screenIndex current form screen index
   */
  isCurrentFormScreenValid(screenIndex: number): boolean {
    let isValid = true;
    let isAllDisabled = false;
    let count = 0;
    if (this.formScreens[screenIndex].FormElements) {
      this.formScreens[screenIndex].FormElements.forEach(formElement => {
        if (formElement.disabled) {
          count++;
        }
        if (formElement.required && !this.formVisualizerFormGroupArray[screenIndex]?.controls[formElement.id]?.disabled
          && (formElement.value === '' || !formElement.value)) {
          isValid = false;
        }
      });
      if (count === this.formScreens[screenIndex].FormElements.length) {
        isAllDisabled = true;
      }
    }
    return (this.formVisualizerFormGroupArray[screenIndex].valid && isValid) || isAllDisabled;
  }

  /**
   * This function disables the mat-headers based on validity of current screen
   *
   * @param screenIndex current form screen index
   */
  toggleStepHeaders(): void {
    if (!this.stepperElement) {
      return;
    }

    const headerContainer: Element = (<HTMLElement>this.stepperElement.nativeElement).children[0];
    const headers = headerContainer.children;
    let anyDisabled = false;
    for (let index = 0, screenIndex = 0; index < headers.length - 2; index += 2, screenIndex++) {
      if (!this.formVisualizerFormGroupArray[screenIndex]?.valid) {
        (<HTMLElement>headers[index + 2]).style.pointerEvents = 'none';
        anyDisabled = true;
      } else {
        if (!anyDisabled) {
          (<HTMLElement>headers[index + 2]).style.pointerEvents = 'auto';
        } else {
          (<HTMLElement>headers[index + 2]).style.pointerEvents = 'none';
        }
      }
    }

    //#region :: is checking if new value is valid providing it's not disabled
    const formGroup: FormGroup = this.ngDynFormService.createFormGroup(this.dynFrm.FormScreens[this.stepper?.selectedIndex]?.FormElements);
    Object.keys(formGroup.controls).forEach((key, index) => {
      const cntrlMdl = this.formVisualizerModelArray[this.stepper?.selectedIndex].find(element => element.id === key);
      if (cntrlMdl.additional && cntrlMdl.additional.isPastDate) {
        if (!cntrlMdl.disabled) {   // When the date control is enabled it checks if invalid and add it to invalid date control collection
          this.checkIfDateIsAfterCurrent(cntrlMdl, cntrlMdl.value);
        } else {   // When the date control is disabled if checks if the date control is in invalid date control collections then drops it
          const invalidDateIndex = this.invalidDates.map(elementId => elementId).indexOf(cntrlMdl.id);
          if (invalidDateIndex !== -1) {   // If the date control was in invalid collection now that it's disabled the item can gets dropped
            this.invalidDates.splice(invalidDateIndex, 1);
            this.dateIsInvalid = (this.invalidDates.length === 0) ? false : true;
          }
        }
      }
    });
    //#endregion
  }

  /**
   * This function is called when input gets out of focus
   * @param ev HTML blur event
   */
  onBlur(ev): void {

  }

  /**
   * Special control Canada Post address complete when next find necessary based on corresponding select item change
   * @param ev HTML change event
   */
  onChange(ev: any): void {
    this.cpSpclCntrlChangeNext(ev);
    this.toggleStepHeaders();
  }

  onStepChange($event: any): void {
    const screenIndex = $event.selectedIndex;
    const screenControls = this.formVisualizerFormGroupArray[screenIndex].controls;
    // for (const key in screenControls) {
    //   this.flatInteractionValueChange(this.dynFrm.FormScreens[screenIndex], 0, screenControls[key].value);
    // }
  }

  /**
   * This function is called when input gets focused
   *
   * @param ev HTML focus event
   */
  onFocus(event): void {
    // Check the model type and show picker if needed (added in ng18)
    if (event.model && (event.model.inputType === 'date' || event.model.inputType === 'datetime-local' || event.model.inputType === 'week' || event.model.inputType === 'month' || event.model.inputType === 'time')) {
      // check if DOM element is available
      if (event.$event && event.$event.target) {
        event.$event.target.showPicker();
      } 
    }

    if (event.model.originalId !== this.interactionSyncService.currentInteractionControlId) {
      const screenIndex = this.getElementAndScreenIndexById(event.model.originalId).scrnIdx;

      let formControlFromFormGroup: AbstractControl = null;
      this.formVisualizerFormGroupArray.forEach(group => {
        if (group.controls[event.model.id]) {
          formControlFromFormGroup = group.controls[event.model.id];
        }
      });

      const interactionControl = new InteractionControlModel(
        this.dynFrm.id,
        this.dynFrm.originalId,
        event.model,
        screenIndex,
        this.processId,
        this.targetId,
        null,
        null,
        null,
        null,
        null,
        null,
        this.isPreview,
        null,
        this.cpCntrlChangeWatch.find(canadaPostControl => canadaPostControl.id === event.model.originalId)
          ? true : false,
        this.visualizerMode,
        this.isEditInteractionFlow,
        this.tabIndex,
        this.processTitle
      );
      setTimeout(() => {
        this.updatedFromLastState = false;
        this.interactionSyncService.announceInteractionControl(interactionControl);
      });
    }
  }

  /**
   * This function is called when Stepper selected index changes
   *
   * @param event Stepper selection event
   */
  onStepperSelectionChange(event: StepperSelectionEvent): void {
    this.toggleStepHeaders();

    // TODO: Remove this once the bug is fixed
    /**
     * Temporary code to fix Chrome browser crashing.
     * See: https://issues.chromium.org/issues/335553723?pli=1
     */
    document.querySelectorAll('[aria-owns]').forEach((element) => {
      element.removeAttribute('aria-owns');
    });
    document.querySelectorAll('[aria-labelledby]').forEach((element) => {
      element.removeAttribute('aria-labelledby');
    });
  }
}
