<div class="landing-page">
  <mat-toolbar color="primary">
    <div
      class="tab-bar">
      <div class="atb-logo" (click)="goToHome()">
        <img src="/assets/images/atb-eva-logo-300.png" height="34px" alt="ATB Logo" />
      </div>

      <div class="menu-div">
        <a (click)="loginButton()">Log in</a>
        <button class="signupBtn" mat-button [routerLink]="['/login', 4]">Sign up</button>
      </div>
    </div>
  </mat-toolbar>

  <div
    class="landing-page-content">

    <div class="landing-hero-banner">
      <!-- <img src="/assets/images/atb-hero-banner-withTriangle-noTry.jpg" alt="ATB EVA Landing Banner"> -->
      <div class="hero-banner-text">
        <h1>ATB Listens, EVA Learns</h1>
        <p>
          EVA, or Easy Voice Autonomation is here to help team members by simplifying processes and preventing
          repetitive work. EVA will allow requests to be fulfilled within minutes as opposed to days. As EVA learns,
          more and more systems will be accessed through this exciting new tool.
        </p>
      </div>
    </div>

    <div class="cardsContainer">

      <div class="actions">
        <div class="actionTabs">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" fxLayout="row" id="actionTabGroup">
            <mat-tab label="Ask">
              <ng-template mat-tab-label>
                <div>
                  <div class="askIcon"></div>
                  <span class="textClass">Ask</span>
                </div>
              </ng-template>
              <div class="ask-tab-content">
                <div class="upper-text">Ask EVA anything and it will answer.</div>
                <div class="eva-question-section">
                  <p class="question">What is a balance account transfer?
                    <span class="vertical-pipe"></span>
                  </p>
                  <div class="horizontal-bottom-border"></div>
                  <div class="image-n-button">
                    <img src="../../../assets/images/voice.svg" alt="Voice">
                    <button class="fake-send-button" mat-button>Send</button>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Learn">
              <ng-template mat-tab-label>
                <div>
                  <div class="learnIcon"></div>
                  <span class="textClass">Learn</span>
                </div>
              </ng-template>
              <div class="learn-tab-content">
                <div class="upper-text">EVA’s answers are provided from across ATB’s systems.</div>
                <div class="eva-question-section">
                  <div class="learn-communication">
                    <div class="blue-rectangle">
                      <div class="blue-rectangle-text">
                        You said:<br />
                        <p>What is a balance account transfer?</p>
                      </div>
                    </div>
                    <div class="white-rectangle">
                      <div class="white-rectangle-text">
                        EVA says:<br />
                        <p>This process helps a customer transfer their balance owing between credit cards.</p>
                      </div>
                    </div>
                  </div>
                  <div class="horizontal-bottom-border"></div>
                  <div class="image-n-button" fxLayout="row">
                    <img src="../../../assets/images/voice.svg" alt="Voice">
                    <button class="fake-send-button" mat-button>Send</button>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Do">
              <ng-template mat-tab-label>
                <div>
                  <div class="doIcon"></div>
                  <span class="textClass">Do</span>
                </div>
              </ng-template>
              <div class="do-tab-content">
                <div class="upper-text">EVA can complete tasks for you. Just ask.</div>
                <div class="eva-question-section">
                  <div class="do-communication no-pad-communication">
                    <div class="blue-rectangle">
                      <div class="blue-rectangle-text">
                        You said:<br />
                        <p>What is a balance account transfer?</p>
                      </div>
                    </div>
                    <div class="white-rectangle">
                      <div class="white-rectangle-text">
                        EVA says:<br />
                        <p>This process helps a customer transfer their balance owing between credit cards.</p>
                      </div>
                    </div>
                  </div>
                  <p class="question">I want to do a balance account transfer.
                    <span class="vertical-pipe"></span>
                  </p>
                  <div class="horizontal-bottom-border"></div>
                  <div class="image-n-button" fxLayout="row">
                    <img src="../../../assets/images/voice.svg" alt="Voice">
                    <button class="fake-send-button" mat-button>Send</button>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

      <div class="introduction">
        <div class="introductionTabs">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" id="introductionSection">
            <mat-tab label="Using EVA">
              <div class="tabsContent">
                <div class="tab-item">
                  <div class="tabContentDiv1-text">
                    <h4>Ask, Learn, Do</h4>
                    <p>
                      You can ask EVA anything you like. EVA will answer you and point you in the right direction.
                      EVA can also complete tasks for you 99% faster than our traditional systems. EVA can change a
                      phone number on file, fulfill Mastercard requirements or even update Loan payment details.
                    </p>
                  </div>
                  <div class="custom-image">
                    <img class="full-width-image" src="../../../assets/images/illy-ask-learn-do.png">
                  </div>
                </div>
                <div class="tab-item">
                  <div class="custom-image">
                    <img class="full-width-image" src="../../../assets/images/illy-talk-or-type.png">
                  </div>
                  <div class="tabContentDiv2-text">
                    <h4>Talk or type</h4>
                    <p>
                      You will be able to talk to EVA in much the same way you talk to Siri or Alexa. EVA will answer
                      your questions and fill in processes based on your voice direction. If you prefer texting, there
                      is always a spot to type in your needs and requests.
                    </p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="How it works">
              <div class="tabsContent">
                <div class="tab-item">
                  <div class="tabContentDiv1-text">
                    <h4>Machine Learning</h4>
                    <p>
                      EVA gets better the more it learns. The more we teach EVA, the more it will be able to help you.
                      Providing EVA with more data will allow it to be smarter next time you need an answer.
                    </p>
                  </div>
                  <div class="custom-image ml-image">
                    <img src="../../../assets/images/machine-learning-infographic.png">
                  </div>
                </div>
                <div class="tab-item">
                  <div class="custom-image ml-image">
                    <img src="../../../assets/images/eva-subscription.png">
                  </div>
                  <div id="subscription-text" fxFlexAlign="center">
                    <h4>Contribution</h4>
                    <p>
                      EVA will know a lot about ATB. EVA will also learn what’s important to you, and EVA will
                      make your life and job easier over time.
                    </p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Enabling You">
              <div class="tabsContent">
                <div class="tab-item">
                  <div class="tabContentDiv1-text">
                    <h4>All of ATB's systems in one place</h4>
                    <p>
                      As EVA learns, it will be a one stop shop to all of ATB’s systems. Instead of searching in
                      different
                      systems like SAP or the Wiki, just ask EVA. EVA will develop collective knowledge from many
                      systems
                      to get you what you need, fast.
                    </p>
                  </div>
                  <div class="custom-image ml-image">
                    <img class="image-80" src="../../../assets/images/illy-atb-system.png">
                  </div>
                </div>
                <div class="tab-item">
                  <div class="custom-image ml-image">
                    <img class="image-80" src="../../../assets/images/streamline-work.png">
                  </div>
                  <div class="tabContentDiv2-text">
                    <h4>Your work streamlined</h4>
                    <p>
                      EVA will connect with systems to prevent time consuming repetition. Imagine not having to do the
                      same changes in four places anymore. EVA will anticipate your needs and give you time back to
                      personally connect.
                    </p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Security">
              <div class="tabsContent">
                <div class="tab-item">
                  <div class="tabContentDiv1-text">
                    <h4>Encryption</h4>
                    <p>
                      Got Security on your mind? So do we. EVA follows a model made popular with Blockchain and crypto
                      currencies, and your data is as secure as it can be. EVA uses public and private security keys to
                      encrypt each piece of data separately. Don’t be concerned, you’ll be walked through the steps as
                      you sign up.
                    </p>
                  </div>
                  <div class="custom-image ml-image">
                    <img class="image-65" src="../../../assets/images/encryption.png">
                  </div>
                </div>
                <div class="image-container-protection">
                  <div class="custom-image ml-image">
                    <img class="image-65" src="../../../assets/images/protection.png">
                  </div>
                  <div id="protection-text" fxFlexAlign="center" fxFlex="40%">
                    <h4>Unique protection</h4>
                    <p>
                      Dual encryption means that no individual piece of data can be accessed without both security
                      keys.
                      EVA rivals the security of world leading tech companies like Google and major Canadian banking
                      platforms.
                    </p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Possibilities">
              <div class="tabsContent">
                <div class="tab-item">
                  <div class="tabContentDiv1-text">
                    <h4>Eva anywhere</h4>
                    <p>
                      The future vision is for EVA to be open to the world. You will control your own data within EVA,
                      but you’ll be able to choose who can access that data. This will allow faster processing of things
                      like bill payments and transfers, and really, the sky is the limit. You’ll find more information
                      in the future on the About page.
                    </p>
                  </div>
                  <div class="ml-image">
                    <img class="image-70" src="../../../assets/images/Possibilities.png">
                  </div>
                </div>
                <div class="tab-item">
                  <div class="ml-image">
                    <img class="image-80" src="../../../assets/images/Unique-Protection.png">
                  </div>
                  <div class="tabContentDiv2-text">
                    <h4>Join the chain</h4>
                    <p>
                      This open vision will also include potential ATB partners who share our vision to make lives
                      easier.
                      If companies wish to develop their own version of EVA, they are welcome to. For any questions,
                      contact
                      <a href="mailto:evateam@atb.com?Subject=Question%20about%20EVA" target="_top">EVA</a>
                    </p>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

      <div class="reputaionChange" *ngIf="isReputaionChangeManagement">
        <div class="reputationChangeHead">
          <p>Using EVA for reputation change management</p>
        </div>
        <div class="reputationChangeTabs">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" id="reputationChangeSection">
            <mat-tab label="Using EVA for change">
              <div class="tabsContent" fxLayout="row">
                <div class="tabContentDiv" fxFlex="33%">
                  <div class="custom-image">
                    <img src="../../../assets/images/Getting_started_with_EVA.svg" alt="Get started with EVA image">
                  </div>
                  <div class="tabContentDiv-text">
                    <h4>Get started with EVA</h4>
                    <p>
                      When you sign up with EVA you will be assigned a public and private key,
                      as well as reputation credits. You will be able to use these credits to
                      create and participate in change at ATB.
                    </p>
                  </div>
                </div>
                <div class="tabContentDiv withSideBorder" fxFlex="33%">
                  <div class="custom-image">
                    <img src="../../../assets/images/Change_is_coming.svg" alt="Change is coming image">
                  </div>
                  <div class="tabContentDiv-text">
                    <h4>Changing is coming</h4>
                    <p>
                      You might also be invited to participate in change via EVA. You can accept or
                      decline changes. An example in which you might accept a change would be if you
                      created or contributed to a requirements document. You might decline if you were
                      not really involved in the change.
                    </p>
                  </div>
                </div>
                <div class="tabContentDiv" fxFlex="33%">
                  <div class="custom-image">
                    <img src="../../../assets/images/Rewarding_Success.svg" alt="Rewarding success image">
                  </div>
                  <div class="tabContentDiv-text">
                    <h4>Rewarding success</h4>
                    <p>
                      At the end of the project or initiative, if the change is successful, team members
                      will receive more reputation credits that they can use to drive further change at ATB.
                    </p>
                  </div>
                </div>
              </div>
            </mat-tab>

            <!-- Need to be finalized what goes in FAQ, until then it is commented out -->
            <mat-tab label="FAQ">
              <div class="tabsContent" fxLayout="row">
                <div class="tabContentDiv1" fxFlex="40%">
                  <div class="tabContentDiv1-text">
                    <h4>Machine Learning</h4>
                    <p>
                      EVA gets better the more it learns. And the more EVA learns,
                      the more the application will be able to help you. You only
                      have to tell EVA once, and that data makes it smarter for next time.
                    </p>
                  </div>
                  <div class="custom-image">
                    <img id="machine-learning" src="../../../assets/images/machine-learning-infographic.png">
                  </div>
                </div>
                <div class="tabContentDiv2" fxLayoutGap="20px" fxFlex="60%">
                  <div class="" fxLayout="row">
                    <img id="subscription" src="../../../assets/images/eva-subscription.png">
                    <div id="subscription-text" fxFlexAlign="center">
                      <h4>Subscription</h4>
                      <p>
                        EVA will know a lot about ATB. EVA will also learn what’s important to you,
                        and that will help make your life easier.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

      <div class="nextstep-root">
        <div class="nextstep">
          <div class="nextstepActions">
            <h4 class="whiteText">Next steps</h4>
            <!-- Hiding until we have "Try Eva" implemented -->
            <!-- <button class="tryEvaBtn" mat-button routerLink="/signup">Try EVA</button> -->
            <button class="signupBtn" mat-button [routerLink]="['/login', 4]">Sign up</button>
            <a routerLink="/login">Log in</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>