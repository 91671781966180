import { Pipe, PipeTransform } from '@angular/core';
import { EvaDynamicFormControlModel } from '@eva-model/evaDynamicFormControlModel';
import { IfThenLogicAction, IfThenLogicOptions, MathEquationOperators, Relation,
  SubRelation } from '@eva-model/interactionElementRelationDialogModel';
import { UserFriendlyConditions } from './user-friendly-condition';

@Pipe({
  name: 'userFriendlyCondition',
  standalone: true,

})
export class UserFriendlyConditionPipe implements PipeTransform {
  message: string;

  transform(relationControls: any[], screens: any[]): string {
    const userFriendlyConditionsClass = new UserFriendlyConditions();
    return userFriendlyConditionsClass.transform(relationControls, screens)
  }
}
