<div class="minimized-chat" [class.minimized-full-chat]="(isChatMinimized$ | async) === null || (isChatMinimized$ | async) === undefined || (isChatMinimized$ | async) === false">
    <!-- *ngIf="isChatMinimized$ | async" -->
    <div class="unseen-count" *ngIf="unseenMessages$ | async as unseen">
        {{ unseen }}
    </div>
    <button class="chat-icon-container" (click)="maximizeChat()" matTooltip="Open EVA Chat">
        <svg class="svg-inline--fa fa-comment-alt-lines fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="comment-alt-lines" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
            <path fill="currentColor" d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 352c0 8.8-7.2 16-16 16H288l-12.8 9.6L208 428v-60H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288zm-96-216H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h224c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-96 96H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z"></path>
        </svg>
    </button>
</div>

<div class="main-container">
    <div class="left-pane" [class.left-pane-active]="chatService.isLeftPaneActive$ | async" [class.border-separator]="((isChatMinimized$ | async) === null || (isChatMinimized$ | async) === undefined || (isChatMinimized$ | async) === false) && (chatService.isLeftPaneActive$ | async)" [class.minimized-chat-left]="isChatMinimized$ | async">
        <div class="left-component-container">
            <div class="left-component-title-container">
                <span class="left-component-title">{{ leftComponentTitle }}</span>
                <div class="save-indicator" matTooltip="This indicator shows whether your current changes have been saved or not. Once saved, you can access these changes when you comeback to EVA.">
                    <ng-container *ngIf="showSavingIcon"><mat-icon>sync</mat-icon> <span class="saving-message">{{savingMessage}}</span></ng-container>
                    <ng-container *ngIf="!showSavingIcon"><mat-icon>cloud_done</mat-icon> <span class="saving-message">{{savingMessage}}</span></ng-container>
                </div>
            </div>
            <!-- <button mat-icon-button (click)="closeLeftRouterComponent()" aria-label="Close" matTooltip="Close" focusable="true">
                <mat-icon>close</mat-icon>
            </button> -->
        </div>
        <div class="multi-view-container" [class.hide-container]="router.url === '/' || activeLeftComponent">
            <app-multi-view
                [destroyLeftComponentAndResetView]="destroyLeftComponentAndResetView"
                [adjustSideWidths]="adjustSideWidths"
                [initScrollIndicatorWatch]="initScrollIndicatorWatch"
            ></app-multi-view>
        </div>
        <div [class.active-left-component]="activeLeftComponent" class="scrolling-container">
            <div> <!-- This div makes the tabs scrollable along with the content, removing this will make the tabs fixed on top-->
                <div *ngIf="isScrollable && !scrollAtBottom" class="scroll-indicator scroll-down fa fa-chevron-down"></div>
                <template #leftPane></template>
            </div>
        </div>
    </div>
    <div #rightPaneContainer class="right-pane" [class.left-pane-open]="chatService.isLeftPaneActive$ | async" [class.minimized-chat-right]="isChatMinimized$ | async">
        <template #rightPane></template>
    </div>
</div>
