import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, Inject, ElementRef, AfterViewInit,
  ComponentRef, ComponentFactoryResolver, QueryList, ViewChildren, AfterViewChecked, Input, HostListener } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { OpenKnowledgeDocument } from '@eva-model/knowledge/knowledge';
import { MatTabGroup, MatTabBody, MatTabChangeEvent } from '@angular/material/tabs';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';
import { KnowledgeMultiService } from '@eva-services/knowledge/knowledge-multi/knowledge-multi.service';
import { DOCUMENT } from '@angular/common';
import { filter, throttleTime, debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { AnnounceKnowledgeShow } from '@eva-model/chat/knowledge/chatKnowledge';
import { Router, NavigationEnd, UrlSegment } from '@angular/router';
import { ChatService } from '@eva-services/chat/chat.service';
import { FormBuilderComponent } from '@eva-ui/form-builder/form-builder.component';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { AnnounceNewTab, FunctionCall } from '@eva-model/chat/chat';
import { Page } from '@eva-model/interactionBuilder';
import { KnowledgeComponent } from '@eva-ui/admin-overview/knowledge/knowledge/knowledge.component';
import { AnnounceProcessStart, AnnounceInteractionStart } from '@eva-model/chat/process/chatProcess';
import { ProcessComponent } from '@eva-ui/process/process.component';
import { ChatProcessService } from '@eva-services/chat/process/chat-process.service';
import { ChatInteractionService } from '@eva-services/chat/process/chat-interaction.service';
import { InteractionComponent } from '@eva-ui/interaction/interaction.component';
import { WindowScrollingService } from '@eva-services/window-scrolling/window-scrolling.service';
import { LoggingService } from '@eva-core/logging.service';
import { Guid } from '@eva-core/GUID/guid';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { LastStateService } from '@eva-services/last-state/last-state.service';
import { WorkflowBuilderComponent } from '@eva-ui/workflow-builder/workflow-builder.component';
import { LastStateTab } from '@eva-model/userLastState';
import { UserService } from '@eva-services/user/user.service';
import { KnowledgeCreateComponent } from '@eva-ui/admin-overview/knowledge/knowledge-create/knowledge-create.component';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogService } from '@eva-services/general-dialog/general-dialog.service';

@Component({
  selector: 'app-multi-view',
  templateUrl: './multi-view.component.html',
  styleUrls: ['./multi-view.component.scss']
})
export class MultiViewComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  // tabs = this.knowledgeMultiService.getOpenDocs;
  tabs: AnnounceNewTab[] = [];  // stores current route's tabs
  tabsSelectedIndex = 0;        // current selected tab index

  showAnnouncer: Subscription;
  componentTitle: string;       // title of the component currently open
  createNewTabButtonActive = false;   // whether to show the Add '+' button for creating new tabs or not
  componentSubs: Subscription = new Subscription();
  knowledgeViewAnnouncementSub: Subscription;
  innerWidth = 0;     // width of this component
  @ViewChild('multiViewComponent') elementRef: ElementRef;
  @ViewChild('multiViewTabs') multiViewTabs: MatTabGroup;
  @ViewChildren('componentContainer', { read: ViewContainerRef }) componentContainerList: QueryList<ViewContainerRef>;
  componentArray: ViewContainerRef[]; // array containing references to all opened tab view containers
  createNewTabFunction: Function;    // Function to be invoked on clicking Add '+' button
  createNewTabFunctionParams: any[];  // Function parameters for createNewTabFunction
  hideCloseAllTabsButton = false;

  @Input() destroyLeftComponentAndResetView: Function;
  @Input() adjustSideWidths: Function;
  @Input() initScrollIndicatorWatch: Function;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private chatKnowledgeService: ChatKnowledgeService,
    private chatProcessService: ChatProcessService,
    private chatService: ChatService,
    private chatInteractionService: ChatInteractionService,
    private windowScrollingService: WindowScrollingService,
    private loggingService: LoggingService,
    private lastStateService: LastStateService,
    private multiViewService: MultiViewService,
    private userService: UserService,
    private dialog: MatDialog,
    private generalDialogService: GeneralDialogService,) {
      // check for any route changes within EVA and load appropriate component
      this.componentSubs.add(
        this.router.events.subscribe(async event => {
          if (event instanceof NavigationEnd) {
            await this.getRouteConfig(event.url);
            this.setCurrentEntityType(event.url);
            this.toggleCloseAllTabsButton(event.url);
            this.tabsSelectedIndex = 0;
            if (!this.knowledgeViewAnnouncementSub) {
              this.knowledgeViewAnnouncementSub = this.createKnowledgeViewSub();
            }
          }
        })
      );

      this.userService.getUserLaunchPadItems();
    }

  ngOnInit() {
    this.componentSubs.add(this.lastStateService.lastState$.pipe(
      filter(state => !!state),
      filter(state => state.sessionId !== this.lastStateService.getSessionId() || state.firstStateUpdate)
    ).subscribe(
      (state) => {
        // this updates the local tabs from last state when changes are made in other EVA instances
        setTimeout(() => {
          if (Object.keys(this.multiViewService.tabs).length === 0) {
            this.multiViewService.tabs = state.tabs ?? {};
          }
          Object.keys(this.multiViewService.tabs).forEach((route: Routes) => {
            if (route === Routes.Home) {
              return;
            }
            // check if any tabs were updated in other session
            if (state.tabsUpdated && state.tabsUpdated.length > 0) {
              // using a reverse loop so as to remove all tabs that need removing
              // since we are updating the same array while looping it, the index changes as we call splice() function
              // and reverse array overcomes any index issues
              for (let index = this.multiViewService.tabs[route].length - 1; index >= 0; index--) {
                const tab = this.multiViewService.tabs[route][index];
                // if the tab is not the 'Home' or 'Search' tab
                if (index > 0) {
                  const updatedTab = state.tabsUpdated.find(tabsUpdate => tabsUpdate === tab.additionalInstanceData.uniqueTabId);
                  // if the tab being updated has already been loaded in current session, re-fetch the tab data from the route collection
                  // the tab additionalInstanceData will only have uniqueTabId as a property if not fetched already
                  // since that's the only property that gets saved on the last state object doc
                  if (updatedTab && Object.keys(tab.additionalInstanceData).length > 1) {
                    this.fetchTabData(index, route);
                  }
                  const incomingExistingTab = state.tabs[route].find(stateTab =>
                    stateTab?.additionalInstanceData?.uniqueTabId === tab?.additionalInstanceData?.uniqueTabId);
                  // if the updated tab already exists, update it
                  if (incomingExistingTab) {
                    Object.keys(incomingExistingTab).forEach(key => {
                      if (key !== 'component' && key !== 'componentRef' && key !== 'additionalInstanceData') {
                        tab[key] = incomingExistingTab[key];
                      }
                    });
                    Object.keys(incomingExistingTab.additionalInstanceData).forEach(key => {
                      tab.additionalInstanceData[key] = incomingExistingTab.additionalInstanceData[key];
                    });
                  } else {
                    if (state.updatedAt > this.multiViewService.tabs[route][index].updatedAt) {
                      // otherwise remove it from local tabs array as that tab was closed in other session
                      this.multiViewService.tabs[route].splice(index, 1);
                      this.tabsSelectedIndex = 0;
                    }
                  }
                }
              }
              // if there are any new tabs added in the other session, update local tabs array with those new tabs
              if (state.tabs[route] && state.tabs[route].length > this.multiViewService.tabs[route].length) {
                const newTabs = state.tabs[route].filter(tab =>
                  this.multiViewService.tabs[route].map(existingTab =>
                    existingTab.additionalInstanceData?.uniqueTabId).indexOf(tab.additionalInstanceData?.uniqueTabId) === -1);
                if (newTabs.length > 0) {
                  this.multiViewService.tabs[route] = this.multiViewService.tabs[route].concat(newTabs);
                }
              }
            }
            // update component property of our local tabs since this is only used locally and not saved to database
            this.multiViewService.tabs[route].forEach((tab, index) => {
              if (index > 0 && !tab.component) {
                switch (route) {
                  case Routes.InteractionBuilder: tab.component = FormBuilderComponent; break;
                  case Routes.Knowledge:
                    if (tab.tabName.includes('Edit') || tab.tabName === 'New Document') {
                      tab.component = KnowledgeCreateComponent;
                    } else {
                      tab.component = KnowledgeComponent;
                    }
                    break;
                  case Routes.Process: tab.component = ProcessComponent; break;
                  case Routes.WorkflowBuilder: tab.component = WorkflowBuilderComponent; break;
                  default: break;
                }
              }
              // update the existing opened tabs' tabIndex property since the tabs are updated
              // this tabIndex property refers to the respective dynamic tab component tabIndex property
              if (tab.componentRef && tab.componentRef.instance) {
                tab.componentRef.instance.tabIndex = index;
              }
            });
          });

          // update local component tabs with newly updated service tabs
          switch (this.getCurrentRouterUrl()) {
            case Routes.InteractionBuilder:
            case Routes.Knowledge:
            case Routes.Process:
            case Routes.WorkflowBuilder:
              this.tabs = this.multiViewService.tabs[this.getCurrentRouterUrl()];
              break;
            default:
              break;
          }
        });
      }
    ));

    // this updates the CSS for MatTabHeader when chat is minimized or maximized
    this.componentSubs.add(this.chatService.isChatMinimized$.pipe(debounceTime(500)).subscribe(value => {
      this.updateMatTabHeaderWidth();
    }));

    // this creates a new tab
    this.componentSubs.add(this.multiViewService.createNewTab$.subscribe(data => {
      if (data) {
        this.createNewTab(data);
      }
    }));

    // this function assigns the function to the Add '+' button in this component in the MatHeader
    // this assigned function will be called when Add button is clicked to create a new tab
    this.componentSubs.add(this.multiViewService.createNewTabFunction$.subscribe(createNewTabFunction => {
      if (createNewTabFunction) {
        this.createNewTabFunction = createNewTabFunction.data;
        this.createNewTabFunctionParams = createNewTabFunction.params ? createNewTabFunction.params : [];
      }
    }));

    /**
     * INTENT TO EDIT A KNOWLEDGE DOCUMENT
     */
     this.componentSubs.add(
      this.chatKnowledgeService.announceEditKnowledge$.pipe(
        filter(v => !!(v)) // emit if not null
      ).subscribe((knowledge) => {
        this.router.navigate([Routes.Knowledge]).then(() => {
          const alreadyOpenedDocIndex
            = this.multiViewService.tabs[Routes.Knowledge]?.findIndex(tab =>
              tab.additionalInstanceData?.editDocInit?.id === knowledge.docId);
          if (alreadyOpenedDocIndex === -1) {
            const queryParams = {
              id: knowledge.docId,
              group: knowledge.docGroup,
              published: knowledge.published,
              viewKey: 'bGV0IG1lIGluIHBsZWFzZSE'
            };

            this.multiViewService.setCreateNewTab({
              component: KnowledgeCreateComponent,
              tabName: `${knowledge.docName} - Edit`,
              removePaddingFromContainer: true,
              additionalInstanceData: {
                editDocInit: queryParams
              }
            });
          } else {
            this.tabsSelectedIndex = alreadyOpenedDocIndex;
          }
        });

        this.destroyLeftComponentAndResetView(); // when there's an announcement, clean any active left side view first.
        this.adjustSideWidths('60%', '40%'); // add room to the view
      })
    );

    /**
     * INTENT TO LOAD A PROCESS
     */
    this.componentSubs.add(
      this.chatProcessService.announceStartingProcess$.pipe(
        distinctUntilChanged()
      ).subscribe((announce: AnnounceProcessStart) => {
        this.adjustSideWidths('60%', '40%'); // add room to the view
        this.initScrollIndicatorWatch();
      })
    );

    /**
     * INTENT TO LOAD AN INTERACTION
     */
    this.componentSubs.add(
      this.chatInteractionService.announceStartingInteraction$.subscribe((announce: AnnounceInteractionStart) => {

        this.destroyLeftComponentAndResetView(); // when there's an announcement, clean any active left side view first.

        this.adjustSideWidths('60%', '40%'); // add room to the view
        // if the Add button is clicked to create a new interaction, only then create a new tab
        // this value is false if trying to open this tab from last state
      })
    );

    this.componentSubs.add(
      this.multiViewService.removeTab$.subscribe(value => {
        setTimeout(() => {
          this.tabsSelectedIndex = 0;
        }, 0);
      })
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    // on window resize, update the MatTabHeader CSS
    this.updateMatTabHeaderWidth();
  }

  async ngAfterViewInit() {
    // load the appropriate component on load initially since the above subscription doesn't fire then
    await this.getRouteConfig(this.getCurrentRouterUrl());
    this.setCurrentEntityType(this.getCurrentRouterUrl());
    // update MatTabHeader on load
    this.updateMatTabHeaderWidth();

    // this prevents users from closing EVA instance without confirming no unsaved changes were left
    window.onbeforeunload = async (event: BeforeUnloadEvent) => {
      let preventClose = false;
      const isSaving = await this.lastStateService.saveIndicator$.pipe(take(1)).toPromise();
      Object.keys(this.multiViewService.tabs).forEach(key => {
        // if there are any tabs open in any of the routes, then prompt user
        if (this.multiViewService.tabs[key].length > 1) {
          preventClose = true;
        }
      });
      if (!isSaving.show) {
        preventClose = false;
      }
      if (preventClose) {
        event.returnValue = "Are you sure you want to exit? Any unsaved changes will be lost";
      }
    };
  }

  ngAfterViewChecked() {
    // updates the local component array with all the dynamic components opened every time the view is checked
    // so that it's up to date on any changes happening in other event loops
    this.componentArray = this.componentContainerList.toArray();
  }

  /**
   * This function gets the information about the current route being opened
   *
   * @param fullUrl current url being accessed
   */
  async getRouteConfig(fullUrl: string): Promise<void> {
    // split queryParams from url
    const splitUrl = fullUrl.split(`?`);
    const url = splitUrl[0];

    const routerConfig = this.router.config;

    this.tabs = this.multiViewService.tabs[url] ? this.multiViewService.tabs[url] : [];
    // update service tabs with current opened tabs only if it is a static route, dynamic routes opens as tabs in a static route
    if (!this.multiViewService.tabs[url] && Object.values(Routes).includes(url as Routes)) {
      this.multiViewService.tabs[url] = this.tabs;
    }

    // if processId query param present, look for tab with this processId
    if (splitUrl.length > 1 && splitUrl[1].includes('processTab')) {
      const targetProcessId = splitUrl[1].split('=')[1];

      // try to find process if more than 1 tab open
      if (this.tabs.length > 1) {
        const index = this.tabs.findIndex((tab) => {
          return tab.additionalInstanceData && tab.additionalInstanceData.processId
            && tab.additionalInstanceData.processId === targetProcessId;
        });

        if (index >= 0) {
          setTimeout(() => {
            this.tabsSelectedIndex = index;
          }, 0);
        }
      }
    }

    // find the current route config from all the routes
    for (const config of routerConfig) {
      // if the route config have children, find the current route there
      if (config.children) {
        config.children.forEach(child => {
          const existingPaths = child.path.split('/');
          const currentPath = url.substring(1).split('/');
          const dynamicIdIndex = existingPaths.findIndex(path => path.includes(':'));
          let flag = false;
          if (existingPaths.every((path, index) => path.includes(':')
            ? true
            : (currentPath.includes(path) && currentPath.slice(0, dynamicIdIndex)[index]?.includes(path)))) {
            flag = true;
          }
          if ((child.path === url.substring(1) && child.data) || flag) {
            this.componentTitle = child.data?.componentTitle;
            this.createNewTabButtonActive = child.data?.createNewTabButtonActive;
            const firstPage = { tabName: '' };
            if (this.tabs.length === 0) {
              if (child.data?.searchEnabled) {
                firstPage.tabName = 'Search';
                this.tabs.push(firstPage);
              } else {
                firstPage.tabName = 'Home';
                this.tabs.push(firstPage);
              }
            }
          }
        });
      } else {
        // else compare the current route directly with current route config
        if (url.substring(1).includes(config.path) && config.data) {
          this.componentTitle = config.data.componentTitle;
          this.createNewTabButtonActive = config.data.createNewTabButtonActive;
          const firstPage = { tabName: '' };
          if (this.tabs.length === 0) {
            if (config.data.searchEnabled) {
              firstPage.tabName = 'Search';
              this.tabs.push(firstPage);
            } else {
              firstPage.tabName = 'Home';
              this.tabs.push(firstPage);
            }
          }
        }
      }
    }

    let userPreferences = await this.userService.userPreferences$.pipe(take(1)).toPromise();
    if (!userPreferences) {
      userPreferences = await this.userService.getUserPreferences();
    }

    if (userPreferences.isChatMinimized && fullUrl !== '' && fullUrl !== '/') {
      this.chatService.setChatMinimizedState(true);
    }
  }

  /**
   * This function sets the current entity type based on current route
   *
   * @param url current route
   */
  setCurrentEntityType(url: string): void {
    const lowerCaseUrl = url.toLowerCase();
    switch (true) {
      case lowerCaseUrl.includes('process'): this.multiViewService.currentEntityType = 'Process'; break;
      case lowerCaseUrl.includes('knowledge'): this.multiViewService.currentEntityType = 'Knowledge'; break;
      case lowerCaseUrl.includes('interaction'): this.multiViewService.currentEntityType = 'Interaction'; break;
      case lowerCaseUrl.includes('workflow'): this.multiViewService.currentEntityType = 'Workflow'; break;
      case lowerCaseUrl.includes('change'): this.multiViewService.currentEntityType = 'Change'; break;
      case lowerCaseUrl.includes('technical'): this.multiViewService.currentEntityType = 'Technical'; break;
      default: break;
    }
  }

  toggleCloseAllTabsButton(url: string): void {
    switch (this.getCurrentRouterUrl()) {
      case Routes.Knowledge:
        this.hideCloseAllTabsButton = false;
        setTimeout(() => {
          const tabList = this.document.getElementsByClassName('mat-mdc-tab-label-container');
          // const tabHeader = this.document.getElementsByClassName('mat-tab-header');

          (<HTMLElement>tabList[0]).style.paddingRight = '54px';
          // (<HTMLElement>tabHeader[0]).style.paddingRight = '54px';
        }, 100);
        break;
      default:
        this.hideCloseAllTabsButton = true;
        setTimeout(() => {
          const tabList = this.document.getElementsByClassName('mat-mdc-tab-label-container');
          // const tabHeader = this.document.getElementsByClassName('mat-tab-header');

          (<HTMLElement>tabList[0]).style.paddingRight = '0px';
          // (<HTMLElement>tabHeader[0]).style.paddingRight = '0px';
        }, 100);
        break;
    }
  }

  /**
   * This function updates the CSS of MatTabHeader based on the size of the container
   */
  updateMatTabHeaderWidth(): void {
    // if ((this.elementRef?.nativeElement as HTMLElement)?.offsetWidth) {
    //   this.innerWidth = (this.elementRef.nativeElement as HTMLElement).offsetWidth;
    //   const tabHeader = document.getElementsByClassName('mat-tab-header')[0];
    //   const tabGroup = document.getElementsByClassName('mat-tab-group')[0];
    //   const tabLabelsContainer = document.getElementsByClassName('mat-tab-list')[0];
    //   const tabLabels = document.getElementsByClassName('mat-tab-label');

    //   let totalLabelsWidth = 0;

    //   for (let index = 0; index < tabLabels.length; index++) {
    //     totalLabelsWidth += (tabLabels[index] as HTMLElement).offsetWidth;
    //   }

    //   // const translateWidth = (((totalLabelsWidth - this.innerWidth) * (this.tabsSelectedIndex + 1)) - 32) + 'px';
    //   (tabLabelsContainer as HTMLElement).style.transform = '';
    //   (tabHeader as HTMLElement).style.width = (tabGroup as HTMLElement).offsetWidth + 'px';

    //   if ((this.innerWidth - 40) <= totalLabelsWidth) {
    //     if (!tabHeader.classList.contains('mat-tab-header-pagination-controls-enabled')) {
    //       tabHeader.classList.add('mat-tab-header-pagination-controls-enabled');
    //     }
    //   } else {
    //     if (tabHeader.classList.contains('mat-tab-header-pagination-controls-enabled')) {
    //       tabHeader.classList.remove('mat-tab-header-pagination-controls-enabled');
    //     }
    //   }
    // }
  }

  ngOnDestroy() {
    if (this.showAnnouncer) {
      this.showAnnouncer.unsubscribe();
    }

    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
  }

  /**
   * Creates and renders a component inside the template. You must pass the component token as a param
   * and also define the type as the component token also.
   *
   * @param componentToken component you'd like to render, eg. `SaltChatComponent`
   * @param index current index
   * @param additionalInstanceData any additional data to be stored in the tab
   * @param functionCalls any functions that are needed to be called before ngOnInit is invoked in the dynamic Component being created
   */
  private async createChildComponent<T>(componentToken: any, index: number, additionalInstanceData?: any,
    functionCalls?: FunctionCall[], additionalLocalInstanceData?: any): Promise<ComponentRef<T>> {
      // if the component doesn't already exists
    if (this.componentArray[index] && this.componentArray[index].length === 0) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory<T>(componentToken);
      componentFactory.create(this.componentArray[index].injector);
      // get the reference to the dynamic component created
      const compRef = this.componentArray[index].createComponent<T>(componentFactory);
      // assign the reference to the tab for ease of access
      this.tabs[index + 1].componentRef = compRef;
      // assign the tabIndex and uniqueTabId to the dynamic component instance
      // these values are accessible in the dynamic component ngOnInit() life event onwards
      (<any>compRef.instance).tabIndex = index + 1;
      (<any>compRef.instance).uniqueTabId = additionalInstanceData.uniqueTabId;
      // if there is any additional data to be added to the dynamic component, add it
      if (additionalInstanceData) {
        Object.keys(additionalInstanceData).forEach(property => {
          (<any>compRef.instance)[property] = additionalInstanceData[property];
        });
      }
      if (additionalLocalInstanceData) {
        Object.keys(additionalLocalInstanceData).forEach(property => {
          (<any>compRef.instance)[property] = additionalLocalInstanceData[property];
        });
      }
      // if any functions are needed to be called before ngOnInit() in the dynamic component,
      // invoke them with additional parameters passed
      if (functionCalls) {
        for (const functionCall of functionCalls) {
          if (functionCall.async) {
            await (<Function>(<any>compRef.instance)[functionCall.functionName]).apply(null, functionCall.parameters);
          } else {
            (<Function>(<any>compRef.instance)[functionCall.functionName]).apply(null, functionCall.parameters);
          }
        }
      }
      return compRef;
    }
  }

  /**
   * This function opens a new tab on ADD '+' button clicked
   */
  openNewTab(): void {
    if (this.createNewTabFunction) {
      this.createNewTabFunction.apply(null, this.createNewTabFunctionParams);
    }
  }

  /**
   * This function creates a new tab and updates last state tabs object
   *
   * @param data data for the new tab being opened
   */
  createNewTab(data: AnnounceNewTab): void {
    // create a new uniqueTabId and assign it to the tab
    data.additionalInstanceData = {...data.additionalInstanceData, uniqueTabId: Guid.newGuid().toString()};
    // update tabs and save last state
    this.multiViewService.updateTabsAndSaveToLastState(this.getCurrentRouterUrl(), "Add", {
      tabName: data.tabName,
      component: data.component,
      additionalInstanceData: data.additionalInstanceData,
      functionCalls: data.functionCalls,
      removePaddingFromContainer: data.removePaddingFromContainer
    });
    // update local tabs object with newly updated service tab object for current route
    this.tabs = this.multiViewService.tabs[this.getCurrentRouterUrl()];

    // if component is specified
    if (data.component) {
      setTimeout(() => {
        this.createChildComponent(data.component, this.tabs.length - 2, data.additionalInstanceData, data.functionCalls,
          data.additionalLocalInstanceData);
      }, 0);
    }
    // update MatTabHeader CSS
    this.updateMatTabHeaderWidth();
    // update the selected index to the newly opened tab
    setTimeout(() => {
      this.tabsSelectedIndex = this.tabs.length - 1;
    }, 0);

  }

  /**
   * This function runs once the animation is done on the mat tab while switching tabs to restore scroll position
   */
  onAnimationDone(): void {
    const tabScrollElement = this.document.querySelector('.scrollable-container');
    if (tabScrollElement && this.tabs[this.tabsSelectedIndex] && this.tabs[this.tabsSelectedIndex].scrollPosition) {
      tabScrollElement.scrollTop = this.tabs[this.tabsSelectedIndex].scrollPosition.scrollTop;
    }

    setTimeout(() => {
      this.componentSubs.add(
        this.windowScrollingService.initialize('scrollable-container').subscribe(scrollPosition => {
          if (this.tabs && this.tabsSelectedIndex < this.tabs.length && this.tabsSelectedIndex >= 0
            && this.tabs[this.tabsSelectedIndex] && scrollPosition) {
            this.tabs[this.tabsSelectedIndex].scrollPosition = scrollPosition;
          }
        })
      );
    });
  }

  /**
   * listener for the angular material tabs component when the active tab index is changed to another.
   */
  selectedIndexChanged(index: number): void {
    const tabIndex = index;
    // if the current tab is not 'Home' or 'Search' tab
    if (tabIndex > 0) {
      // if the tab data has not been fetched yet, fetch it from the database from respective route collection
      if (Object.keys(this.tabs[tabIndex].additionalInstanceData).length === 1
        || (Object.keys(this.tabs[tabIndex].additionalInstanceData).length === 2
          && this.tabs[tabIndex].additionalInstanceData.fetchInteraction)) {
        this.fetchTabData(tabIndex, this.getCurrentRouterUrl());
      } else {
        // otherwise update the uniqueTabId of the dynamic component instance
        if (this.tabs[tabIndex]?.componentRef?.instance && this.tabs[tabIndex].additionalInstanceData) {
          this.tabs[tabIndex].componentRef.instance.uniqueTabId = this.tabs[tabIndex].additionalInstanceData.uniqueTabId;
        }
        // create the tab with the dynamic component
        this.createChildComponent(this.tabs[tabIndex].component, index - 1, this.tabs[tabIndex].additionalInstanceData);
      }
    }
  }

  /**
   * This function fetches the tab data from the respective route collection containing the tab
   * Note: Could be a problem in future, this function does async work while not being treated async by invoking function...
   *
   * @param tabIndex Index of the tab being fetched
   * @param route Route containing the tab
   */
  fetchTabData(tabIndex: number, route?: Routes): void {
    const tab = route ? this.multiViewService.tabs[route][tabIndex] : this.tabs[tabIndex];
    // if tab exists, fetch it from the database based on the uniqueTabId
    if (tab) {
      // sanity check to ensure function is not returned undefined.
      if (!this.lastStateService.getRouteTabFromCollection(tab, this.getCurrentRouterUrl())) {
        return;
      }

      const routerUrl = 
      this.lastStateService.getRouteTabFromCollection(tab, this.getCurrentRouterUrl())
        .pipe(take(1)).subscribe(tabDoc => {
          const tabData = tabDoc.data();
          let parsedTabData: LastStateTab;
          // if tabData.data exists, parse it since we store the stringified version of it
          if (tabData && tabData.data) {
            parsedTabData = JSON.parse((tabData as {data: string}).data);
          }

          const data = {
            component: this.tabs[tabIndex]?.component,
            additionalInstanceData: {
              ...parsedTabData
            }
          };
          // if local tab object doesn't have additionalInstanceData property, create it, since it will be updated from the fetched doc
          if (this.tabs[tabIndex] && !this.tabs[tabIndex].additionalInstanceData) {
            this.tabs[tabIndex].additionalInstanceData = {};
          }
          // if any fetched data, update local tab data with it
          if (parsedTabData) {
            Object.keys(parsedTabData).forEach(key => {
              this.tabs[tabIndex].additionalInstanceData[key] = parsedTabData[key];
            });
          }
          // if the local tab has already been fetched and has dynamic component created, update that instance with new data directly
          if (route && this.tabs[tabIndex] && this.tabs[tabIndex].componentRef && this.tabs[tabIndex].componentRef.instance) {
            this.tabs[tabIndex].componentRef.instance.updatedFromLastState = true;
            if (parsedTabData) {
              Object.keys(parsedTabData).forEach(key => {
                this.tabs[tabIndex].componentRef.instance[key] = parsedTabData[key];
              });
            }
          }
          // re-assign the uniqueTabId for re-assurance
          if (this.tabs[tabIndex] && this.tabs[tabIndex].componentRef && this.tabs[tabIndex].componentRef.instance) {
            this.tabs[tabIndex].componentRef.instance.uniqueTabId = this.tabs[tabIndex].additionalInstanceData.uniqueTabId;
          }
          // create the tab with the dynamic component
          this.createChildComponent(data.component, tabIndex - 1, data.additionalInstanceData);
        });
    }
  }

  /**
   * listener for the angular material tabs component when the active tab is changed to another tab.
   */
  selectedTabChanged(tab: MatTabChangeEvent): void {
    const tabIndex = tab.index;
    // if the current tab is not 'Home' or 'Search' tab
    if (tabIndex > 0) {
      // if the tab data has not been fetched yet, fetch it from the database from respective route collection
      if (Object.keys(this.tabs[tabIndex].additionalInstanceData).length === 1
        || (Object.keys(this.tabs[tabIndex].additionalInstanceData).length === 2
          && this.tabs[tabIndex].additionalInstanceData.fetchInteraction)) {
        this.fetchTabData(tabIndex, this.getCurrentRouterUrl());
      } else {
        // otherwise update the uniqueTabId of the dynamic component instance
        if (this.tabs[tabIndex]?.componentRef?.instance && this.tabs[tabIndex].additionalInstanceData) {
          this.tabs[tabIndex].componentRef.instance.uniqueTabId = this.tabs[tabIndex].additionalInstanceData.uniqueTabId;
        }
        // create the tab with the dynamic component
        this.createChildComponent(this.tabs[tabIndex].component, tabIndex - 1, this.tabs[tabIndex].additionalInstanceData);
      }
    }
  }

  /**
   * Removes an item from our tabs array, and updates last state.
   */
  removeTab(index: number) {
    let entityId = '';
    const close$: Subject<boolean> = new Subject();

    if (entityId === '') {
      entityId = this.tabs[index].additionalInstanceData.uniqueTabId;
    }

    // sends the remove tab request to the dynamic component being closed so that the dynamic component can make the judgement
    // whether to close the tab directly or wait for user's confirmation so as to prevent any loss of unsaved data
    this.multiViewService.setCloseTab({
      entityType: this.multiViewService.currentEntityType,
      entityId: entityId,
      closeSubject: close$,
      tabIndex: index
    });
    // listen to the dynamic component update of whether to close the tab or not
    close$.pipe(take(1)).subscribe(value => {
      if (value) {
        // if closing the tab, update last state
        this.multiViewService.updateTabsAndSaveToLastState(this.getCurrentRouterUrl(), 'Remove', null, index);
        this.tabsSelectedIndex = 0;
        this.tabs = this.multiViewService.tabs[this.getCurrentRouterUrl()];
        // update the tabIndexes of all the tabs containing dynamic component
        this.tabs.forEach((tab, tabIndex) => {
          if (tab.componentRef) {
            tab.componentRef.instance.tabIndex = tabIndex;
          }
        });
      }
    });
    // update MatTabHeader CSS
    this.updateMatTabHeaderWidth();
  }

  /**
   * INTENT TO VIEW A KNOWLEDGE DOCUMENT
   *
   * This is it's own function and not in ngOnInit because of an issue with
   * this being announced, then in this components AfterViewInit, the selectedIndex
   * reverts back to 0, but this tab should be the tab that is focused.
   */
  private createKnowledgeViewSub(): Subscription {
    return this.chatKnowledgeService.announceShowKnowledge$.pipe(
      filter(v => !!(v)) // emit if not null
    ).subscribe((knowledge) => {
      this.router.navigate([Routes.Knowledge]).then(value => {
        // eslint-disable-next-line max-len
        const alreadyOpenedDocIndex = this.multiViewService.tabs[Routes.Knowledge]?.findIndex(tab => tab.additionalInstanceData?.data?.docId === knowledge.docId);

        if (alreadyOpenedDocIndex >= 1) {
          this.removeTab(alreadyOpenedDocIndex);
          setTimeout(() => {
            const data = { ...JSON.parse(JSON.stringify(knowledge)) };
            delete data.document;
            delete data.dialogflowResponse;
            this.multiViewService.setCreateNewTab({
              component: KnowledgeComponent,
              additionalInstanceData: {
                data: {
                  ...data,
                  docName: knowledge.document?.name ?? 'Loading...'
                }
              },
              additionalLocalInstanceData: {
                dialogflowResponse: knowledge.dialogflowResponse,
                knowledgeDoc: knowledge.document
              },
              tabName: knowledge.document?.name ?? 'Loading...',
              removePaddingFromContainer: true
            });
          }, 50);
        } else {
          const data = { ...JSON.parse(JSON.stringify(knowledge)) };
          delete data.document;
          delete data.dialogflowResponse;
          this.multiViewService.setCreateNewTab({
            component: KnowledgeComponent,
            additionalInstanceData: {
              data: {
                ...data,
                docName: knowledge.document?.name ?? 'Loading...'
              }
            },
            additionalLocalInstanceData: {
              dialogflowResponse: knowledge.dialogflowResponse,
              knowledgeDoc: knowledge.document
            },
            tabName: knowledge.document?.name ?? 'Loading...',
            removePaddingFromContainer: true
          });
        }
      });

      this.destroyLeftComponentAndResetView(); // when there's an announcement, clean any active left side view first.
      this.adjustSideWidths('60%', '40%'); // add room to the view
    });
  }

  /**
   * Returns current route.
   * If EVA is launching via a knowledge link, intercept it and return the Knowledge route. Otherwise, things break.
   */
  private getCurrentRouterUrl(): Routes {
    const url = this.router.url as Routes;
    // Intercept a knowledge link and return the knowledge route
    if (url.includes('type=knowledge')) {
      return Routes.Knowledge;
    }
    return url;
  }

  closeAllCurrentRouteTabs(): void {
    if (this.tabs.length > 1) {
      const dialogContent = `Are you sure you want to close all tabs?`;

      const dialogData = new GeneralDialogModel(
        'Close all tabs', dialogContent, 'Close', 'Cancel');
      this.openGeneralDialog(
        dialogData,
        this.closeAllTabs,
        null
      );
    }
  }

  closeAllTabs = (): void => {
    for (let index = this.tabs.length - 1; index > 0; index--) {
      // if closing the tab, update last state
      this.multiViewService.updateTabsAndSaveToLastState(this.getCurrentRouterUrl(), 'Remove', null, index);
      this.tabsSelectedIndex = 0;
      this.tabs = this.multiViewService.tabs[this.getCurrentRouterUrl()];
      // update the tabIndexes of all the tabs containing dynamic component
      this.tabs.forEach((tab, tabIndex) => {
        if (tab.componentRef) {
          tab.componentRef.instance.tabIndex = tabIndex;
        }
      });
    }
  }

  /**
   * This is a general purpose function to open dialog window
   *
   * @param dialogModel GeneralDialogModel object
   * @param callback Callback function to call when observable is resolved
   * @param callbackData Callback Data to pass through
   */
   private openGeneralDialog(dialogModel: GeneralDialogModel, callback: Function, callbackData: any): void {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      data: dialogModel,
      minWidth: '500px'
    });

    this.componentSubs.add(
      this.generalDialogService.generalDialogChanged$.subscribe(
        changeObj => {
          if (changeObj) {
            if (callbackData) {   // To avoid throwing error in case of dialogs which don't have callback functions and callback data.
              callbackData['generalDialogOnChange'] = changeObj;
            }
          }
        },
        err => { console.log(err); }
      )
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (callback) {
          callback(callbackData);
        }
      }
    });
  }

}
