<mat-toolbar>
  <span>Feedback</span>
  <span class="feedback-span"></span>
  <button mat-icon-button mat-tooltip="Close Feedback" aria-label="Close feedback panel" (click)="closeFeedback()"><mat-icon>close</mat-icon></button>
</mat-toolbar>

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="tabChanged($event)">
  <mat-tab label="Unreviewed">

    <!-- loader -->
    <div class="loader-spinner" *ngIf="loading; else showUnreviewedfeedbackItems">
      <mat-spinner></mat-spinner>
    </div>
    <ng-template #showUnreviewedfeedbackItems>
      <ng-container *ngIf="unreviewedFeedbackItems.length > 0; else noUnreviewedFeedbackItems">
        <app-knowledge-feedback-item *ngFor="let feedback of unreviewedFeedbackItems" [feedback]="feedback"></app-knowledge-feedback-item>
      </ng-container>
      <ng-template #noUnreviewedFeedbackItems>
        <div class="no-feedback-data">
          No Feedback to show
        </div>
      </ng-template>
    </ng-template>

    <!-- Error -->
    <ng-container *ngIf="error">
      Error getting feedback
    </ng-container>

  </mat-tab>

  <mat-tab label="Reviewed">

    <!-- loader -->
    <div class="loader-spinner" *ngIf="loading; else showReviewedfeedbackItems">
      <mat-spinner></mat-spinner>
    </div>
    <ng-template #showReviewedfeedbackItems>
      <ng-container *ngIf="reviewedFeedbackItems.length > 0; else noReviewedFeedbackItems">
        <app-knowledge-feedback-item *ngFor="let feedback of reviewedFeedbackItems" [feedback]="feedback"></app-knowledge-feedback-item>
      </ng-container>
      <ng-template #noReviewedFeedbackItems>
        <div class="no-feedback-data">
          No Feedback to show
        </div>
      </ng-template>
    </ng-template>

    <!-- Error -->
    <ng-container *ngIf="error">
      Error getting feedback
    </ng-container>

  </mat-tab>
</mat-tab-group>
