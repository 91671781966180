
<!-- <button mat-raised-button (click)=getEntropy()>Click me!</button> -->

<form [formGroup]="frmGrp">
  <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
    <div fxFlexFill>
        <div fxFlex="100%">
          <mat-card>

            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab label="Verify Encryption">

                <br/>
                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
                  <div fxFlexFill>
                    <div fxFlex="89%" fxFlex.lt-md="79%" fxFlex.lt-sm="59%">
                    </div>
                    <div fxFlex="1%">
                    </div>
                    <div fxFlex="10%" fxFlex.lt-md="20%" fxFlex.lt-sm="40%">
                      <button mat-raised-button class="fullWidth" [disabled]="isWaiting" (click)=mnemonicVerify(this.mntype.encryption)>Verify</button>
                    </div>
                    <div fxFlex="10%" fxFlex.lt-md="20%" fxFlex.lt-sm="40%" *ngIf=false>
                      <button mat-raised-button class="fullWidth" [disabled]="isWaiting" (click)=getUserCryptoKey()>User Device</button>
                    </div>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
                  <div fxFlexFill>
                    <div fxFlex="100%">
                      <mat-form-field class="fullWidth">
                        <textarea matInput placeholder="Encryption mnemonic" formControlName="encryptMnemonic"></textarea>

                        <div class="app-loading" *ngIf="isWaiting && isWaitingForEncryption">
                          <mat-spinner></mat-spinner>
                          <ng-content></ng-content>
                          <h6>
                            <span class="verColor">{{ onWaitMessage }}</span>
                          </h6>
                        </div>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

              </mat-tab>
              <mat-tab label="Verify Signing">

                <br/>
                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
                  <div fxFlexFill>
                    <div fxFlex="89%" fxFlex.lt-md="79%" fxFlex.lt-sm="59%">
                    </div>
                    <div fxFlex="1%">
                    </div>
                    <div fxFlex="10%" fxFlex.lt-md="20%" fxFlex.lt-sm="40%">
                      <button mat-raised-button class="fullWidth" [disabled]="isWaiting" (click)=mnemonicVerify(this.mntype.signing)>Verify</button>
                    </div>
                  </div>
                </div>

                <mat-form-field class="fullWidth">
                  <textarea matInput placeholder="Signing mnemonic" formControlName="signMnemonic"></textarea>

                  <div class="app-loading" *ngIf="isWaiting && isWaitingForSigning">
                    <mat-spinner></mat-spinner>
                    <ng-content></ng-content>
                    <h6>
                      <span class="verColor">{{ onWaitMessage }}</span>
                    </h6>
                  </div>
                </mat-form-field>

              </mat-tab>
              <mat-tab label="Reset security keys">

                <ng-template appViewContainer></ng-template>

              </mat-tab>
            </mat-tab-group>

          </mat-card>
        </div>
    </div>
  </div>
</form>

<p-toast></p-toast>
