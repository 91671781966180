<h2 mat-dialog-title>
    Your Bookmarks<button mat-button (click)="editMenu()" color="primary">Edit</button>
</h2>
<mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-action-list *ngFor="let group of (menu$ | async)">
        <ng-container *ngIf="group.items.length > 0">
            <button mat-list-item  *ngFor="let item of group.items" (click)="launchPadService.doAction(item)" mat-dialog-close>
                <h6 matListItemTitle>{{item.title}}</h6>
                <p matListItemLine>
                    <ng-container *ngIf="item.type === entityTypeKnowledge">Knowledge</ng-container>
                    <ng-container *ngIf="item.type === entityTypeWorkflow">Process</ng-container>
                    <ng-container *ngIf="item.type === entityTypeInteraction">Process</ng-container>
                </p>
            </button>
        </ng-container>
    </mat-action-list>
</mat-dialog-content>
