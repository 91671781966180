<div *ngFor="let interaction of compactProcess.interactions">

  <h2 class="interaction-summary-header">Process Summary for {{ interaction.name }}</h2>

  <div *ngFor="let formScreen of interaction.screens">
    <h3 class="interaction-summary-step-header">
      {{ formScreen.name }}
      <a class="edit-interaction-btn" (click)="goToInteraction(interaction.originalId, interaction.order, formScreen.order)" matTooltip="Edit"><mat-icon>edit</mat-icon></a>
    </h3>
    <ul>
      <li *ngFor="let field of formScreen.elements">
        <ng-container *ngIf="field.isDisabled === false">
          <strong [innerHTML]="field.label"></strong>:
          <div [innerHTML]="getCompactElementValue(field)"></div>
        </ng-container>
      </li>
    </ul>
  </div>

  <mat-divider></mat-divider>

  <!-- <div class="interaction-summary-header">
        <h3 class="interaction-name">&nbsp;&nbsp;&nbsp;</h3>
  </div>

  <mat-card class="interaction-summary-body">
    <mat-dialog-content class="interaction-summary-step">
      <div *ngFor="let formScreen of interaction.screens">
        <h4 class="interaction-summary-step-header"> {{ formScreen.name }}
            
        </h4>
        <mat-list>
          <div *ngFor="let field of formScreen.elements">
            <mat-list-item *ngIf='field.isDisabled === false' class="summary-list-item">
              <div><div class="bold inline" [innerHTML]="field.label"> </div>: <div class="inline" [innerHTML]="getCompactElementValue(field)"></div></div>
            </mat-list-item>
          </div>
        </mat-list>
      </div>
    </mat-dialog-content>
  </mat-card> -->

</div>

<div class="action-button-right">
  <button mat-raised-button color="primary" (click)="submitProcess()">Submit</button>
</div>
