
<div class="user-preferences">
    <app-page-header [title]="componentTitle"></app-page-header>
    <div>
            <app-spinner name="getting-preferences" show="true" *ngIf="!userPreferences">
              <h6>Getting your preferences...</h6>
            </app-spinner>
            
            <div *ngIf="userPreferences">
                <form [formGroup]="userForm" name="form" autocomplete="off">
                  <mat-form-field class="full-width-input">
                    <mat-label>Preferred Name</mat-label>
                    <input matInput type="text" placeholder="Preferred Name" formControlName="preferredName">
                  </mat-form-field>
                  <p *ngIf="!this.nameValid" class="red">Name field cannot be empty</p>
                  <mat-card-actions>
                    <div fxFlex>
                    </div>
                  </mat-card-actions>

                  <mat-radio-group matInput formControlName="selectedTheme" class="pref-radio-group" >
                    <mat-label>Themes: </mat-label>
                    <mat-radio-button *ngFor="let theme of themes" class="pref-radio-button" [value]="theme.id">{{ theme.displayName }}</mat-radio-button>
                  </mat-radio-group>
            
                  <mat-radio-group matInput formControlName="processChatAssist" class="pref-radio-group" >
                    <mat-label>Assist Process entry from EVA Chat: </mat-label>
                    <mat-radio-button class="pref-radio-button" [value]="true">On</mat-radio-button>
                    <mat-radio-button class="pref-radio-button" [value]="false">Off</mat-radio-button>
                  </mat-radio-group>

                  <mat-radio-group matInput formControlName="speechEnabled" class="pref-radio-group" >
                    <mat-label>Speech Enabled: </mat-label>
                    <mat-radio-button class="pref-radio-button" [value]="true">On</mat-radio-button>
                    <mat-radio-button class="pref-radio-button" [value]="false">Off</mat-radio-button>
                  </mat-radio-group>
              
                  <mat-radio-group matInput formControlName="experimentalPreferences" class="pref-radio-group" >
                    <mat-label>Experimental EVA: </mat-label>
                    <mat-radio-button class="pref-radio-button" [value]="true">On</mat-radio-button>
                    <mat-radio-button class="pref-radio-button" [value]="false">Off</mat-radio-button>
                  </mat-radio-group>
            
                  <mat-radio-group matInput formControlName="uniqueKeyPreferences" class="pref-radio-group" >
                    <mat-label>Unique Key for Each Transaction Enabled: </mat-label>
                    <mat-radio-button class="pref-radio-button" [value]="true">On</mat-radio-button>
                    <mat-radio-button class="pref-radio-button" [value]="false">Off</mat-radio-button>
                  </mat-radio-group>
            
                  <mat-radio-group matInput formControlName="formJsonPreferences" class="pref-radio-group" >
                    <mat-label>Display Form in JSON format: </mat-label>
                    <mat-radio-button class="pref-radio-button" [value]="true">On</mat-radio-button>
                    <mat-radio-button class="pref-radio-button" [value]="false">Off</mat-radio-button>
                  </mat-radio-group>

                  <mat-radio-group matInput formControlName="autoRefreshProcessDashboard" class="pref-radio-group" >
                    <mat-label>Auto-Refresh Process Dashboard: </mat-label>
                    <mat-radio-button class="pref-radio-button" [value]="true">On</mat-radio-button>
                    <mat-radio-button class="pref-radio-button" [value]="false">Off</mat-radio-button>
                  </mat-radio-group>

                  <mat-radio-group matInput formControlName="toggleEmailsProcessDashboard" class="pref-radio-group" >
                    <mat-label>Receive emails on status changes selected on Process Dashboard: </mat-label>
                    <mat-radio-button class="pref-radio-button" [value]="true">On</mat-radio-button>
                    <mat-radio-button class="pref-radio-button" [value]="false">Off</mat-radio-button>
                  </mat-radio-group>

                  <mat-radio-group matInput formControlName="isChatMinimized" class="pref-radio-group" >
                    <mat-label>Whether the chat be minimized by default on search: </mat-label>
                    <mat-radio-button class="pref-radio-button" [value]="true">On</mat-radio-button>
                    <mat-radio-button class="pref-radio-button" [value]="false">Off</mat-radio-button>
                  </mat-radio-group>

                  <mat-form-field class="full-width-input">
                    <mat-label>Search default view</mat-label>
                    <mat-select formControlName="genericSearchView">
                      <mat-option *ngFor="let view of GenericSearchViewKeys; let i=index" [value]="GenericSearchView[view]">{{view}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <h4>Tutorials</h4>
                  <div formArrayName="tutorialList">
                    <mat-form-field  *ngFor="let tutorial of tutorialForms.controls; let i=index" [formGroupName]="i" class="full-width-input">
                        <mat-label>{{tutorial.get('tutorialName').value}}</mat-label>
                        <mat-select [formControl]="tutorial.get('completed')" [compareWith]="compareTutorialObjects">
                          <mat-option value="true">Complete</mat-option>
                          <mat-option value="false">To Do</mat-option>
                        </mat-select>
                    </mat-form-field>
                  </div>
                  <mat-card-actions>
                    <button mat-stroked-button type="button" (click)="revertDefaultUserPreferences()" >Revert to Default</button>
                    <div fxFlex>
                    </div>
                    <button mat-raised-button color="primary" type="button" (click)="saveUserPreferences()" [disabled]="!userForm.dirty && !isPreferredNameEmpty()"> Save Preferences</button>
                  </mat-card-actions>
                </form>
              </div>
            <br/>

    </div>
</div>


