<h2 mat-dialog-title>EVA Help</h2>
<mat-dialog-content>
  <div class="feedback-content">
    <h3>I'd like to...</h3>
    <!-- <mat-form-field appearance="fill" style="width:400px;">
      <mat-label>Feedback Type</mat-label>
      <mat-select (selectionChange)="changeFeedbackType($event.value)">
        <mat-option *ngFor="let category of categories; let i = index;" [value]="category">{{category}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <div class="get-help-content">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Make an ATB Knowledge update/change
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>To amend existing or propose new Knowledge, complete the <a href="https://docs.google.com/forms/d/e/1FAIpQLSdbp48HWqJtVaE_SaEgSKqgZfEUnlfCN_xsk9mcM2WjPRAhog/viewform" target="_blank">ATB Knowledge Update Request</a> form. If you need help understanding ATB Knowledge, contact <a href="https://atbconnect.atb.com/pages/1dl5ns57slgvvlh4qr/HelpAndSupport/1e0t7vs5hqru6mtmmc" target="_blank">ATB Support</a>.</p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Get help with ATB Knowledge content or EVA Processes
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>If you need help finding or understanding ATB Knowledge or require help with an EVA Process, contact <a href="https://atbconnect.atb.com/pages/1dl5ns57slgvvlh4qr/HelpAndSupport/1e0t7vs5hqru6mtmmc" target="_blank">ATB Support</a>.</p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Suggest an EVA Process content change
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>If you'd like to submit a <em>Process update</em> (information contained within a Process is wrong or outdated), please submit a Data and AI Request in ServiceNow.</p>
          <p>Please ensure you <strong><u>select IPA (Automation/Robotics)</u></strong> and <u>not EVA</u> in your request.</p>
          <p><a href="https://atb.service-now.com/sp?id=sc_cat_item&sys_id=ffbe8706dbf8a30456b5c443059619ac" target="_blank">Data and AI Requests Form</a></p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- EVA Knowledge Feedback -->
    <!-- <div *ngIf="selectedCategory === categories[0]">
      <p>To amend existing or propose new Knowledge, complete the <a href="https://docs.google.com/forms/d/e/1FAIpQLSdbp48HWqJtVaE_SaEgSKqgZfEUnlfCN_xsk9mcM2WjPRAhog/viewform" target="_blank">ATB Knowledge Update Request</a> form. If you need help understanding ATB Knowledge, contact <a href="https://atbconnect.atb.com/pages/1dl5ns57slgvvlh4qr/HelpAndSupport/1e0t7vs5hqru6mtmmc" target="_blank">Client Care Guides</a>.</p>
    </div> -->

    <!-- EVA Knowledge Feedback -->
    <!-- <div *ngIf="selectedCategory === categories[1]">
      <p>If you're having trouble <em>finding or understanding</em> ATB Knowledge, please contact the <a href="https://atbconnect.atb.com/pages/1dl5ns57slgvvlh4qr/HelpAndSupport/1e0t7vs5hqru6mtmmc" target="_blank">Client Care Guides</a>.</p>
    </div> -->

    <!-- Get Process Help -->
    <!-- <div *ngIf="selectedCategory === categories[2]">
      <p>If you're having issues <em>completing or understanding</em> a Process, please contact the <a href="https://atbconnect.atb.com/pages/1dl5ns57slgvvlh4qr/HelpAndSupport/1e0t7vs5hqru6mtmmc" target="_blank">Client Care Guides</a>.</p>
    </div> -->

    <!-- Process Updates -->
    <!-- <div *ngIf="selectedCategory === categories[3]">
      <p>If you'd like to submit a <em>Process update</em> (information contained within a Process is wrong or outdated), please submit a Data and AI Request in ServiceNow.</p>
      <p>Please ensure you <strong><u>select IPA (Automation/Robotics)</u></strong> and not EVA in your request.</p>
      <p><a href="https://atb.service-now.com/sp?id=sc_cat_item&sys_id=ffbe8706dbf8a30456b5c443059619ac" target="_blank">Data and AI Requests Form</a></p>
    </div> -->

    <!-- EVA Platform Feedback -->
    <!-- <div *ngIf="selectedCategory === categories[4]">
      <p>If you'd like to <em>send feedback about the EVA platform</em> please submit a Data and AI Request in ServiceNow.</p>
      <p>Please ensure you <strong><u>select EVA</u></strong> in your request.</p>
      <p><a href="https://atb.service-now.com/sp?id=sc_cat_item&sys_id=ffbe8706dbf8a30456b5c443059619ac" target="_blank">Data and AI Requests Form</a></p>
    </div> -->
    <!-- <ng-container *ngIf="selectedCategory === categories[4]">
      <form class="feedback-form" [formGroup]="sendFeedbackForm">
        <mat-form-field>
          <textarea matInput placeholder="Describe your feedback regarding the EVA Platform" rows="7" formControlName="feedbackDescription"
            [ngClass]="{ 'is-invalid': submitted && f.feedbackDescription.errors }"></textarea>
          <div *ngIf="submitted && f.feedbackDescription.errors" class="invalid-feedback">
            <div *ngIf="f.feedbackDescription.errors.required">Please enter feedback</div>
            <div *ngIf="f.feedbackDescription.errors.minLength">Please enter at least five characters of feedback.</div>
          </div>
        </mat-form-field>
  
        <br />
    
        <button mat-raised-button color="primary" [class.button-submitting]="submitted" (click)="sendFeedback()" [disabled]="!sendFeedbackForm.valid">
          <span class="spinner-wrapper" *ngIf="submitted"><mat-spinner [diameter]="24" [strokeWidth]="4"></mat-spinner><span>Sending...</span></span>
          <ng-container *ngIf="!submitted">Send Feedback</ng-container>
        </button>
      </form>
    </ng-container> -->
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>