import { Component, Inject, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { merge } from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ProcessService } from '@eva-services/process/process.service';
import { Process } from '@eva-model/process/process';
import { ProcessState, userLastStateType } from '@eva-model/userLastState';
import { Router, RouterModule } from '@angular/router';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { ProcessComponent } from '@eva-ui/process/process.component';
import { LoggingService } from '@eva-core/logging.service';
import { ChatProcessService } from '@eva-services/chat/process/chat-process.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-workflow-start',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    RouterModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatProgressBarModule
  ],
  templateUrl: './workflow-start.component.html',
  styleUrl: './workflow-start.component.scss'
})
export class WorkflowStartComponent {
  readonly processName = new FormControl('', [Validators.required]);
  public fetchingWorkflow = false;

  private dialogId: string; 
  private processState: ProcessState;
  private chatId: string = this.generateChatId();

  errorMessage = signal('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {workflowName: string, workflowId: string},
    private dialog: MatDialog,
    private processService: ProcessService,
    private router: Router,
    private multiViewService: MultiViewService,
    private loggingService: LoggingService,
    private chatProcessService: ChatProcessService
  ) {
    this.dialogId = `workflow-start-${this.data.workflowId}`;
    merge(this.processName.statusChanges, this.processName.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateErrorMessage());
  }

  startProcess(processName: string) {
    const sanitizedProcessName = processName.trim();

    if (!sanitizedProcessName) {
      return;
    }

    // Set state to loading workflow
    this.enableStateToFetchingWorkflow();

    this.processService.initializeProcess(this.data.workflowId, sanitizedProcessName)
    .then( async (processData: {processId: string, tabIndex: number, process: Process}) => {
      // Process is created and ready, announce process id and chat id target
      this.chatProcessService.announceProcessStart(processData.processId, this.chatId);

      this.processState = new ProcessState(
        processData.processId,
        null,
        null,
        'Process has loaded',
        userLastStateType.process
      );

      const clonedProcess: any = JSON.parse(JSON.stringify(processData.process));
      const clonedProcessState: any = JSON.parse(JSON.stringify(this.processState));

      const additionalInstanceData = {
        processId: processData.processId,
        hideProcessTitleBar: true,
        targetId: this.chatId,
        ...clonedProcessState,
        process: clonedProcess
      };

      this.router.navigate([Routes.Process]).then(value => {
        this.disableStateToFetchingWorkflow();
        this.multiViewService.setCreateNewTab({
          component: ProcessComponent,
          additionalInstanceData,
          tabName: 'Loading...'
        });

        // this.initializeBasedOnLastState(this.processState);

        // await this.saveChat.saveChatSession(this.processState.query, false, clonedProcessState);
      });

      // process is initialized.
      // disable any loading indicator if showing
      // this.chatService.newChatEntity({
      //   author: ChatEntityAuthor.EVA,
      //   type: ChatEntityType.Text,
      //   text: `Process has loaded, You can go to next or previous element by saying 'next element' or 'previous element'`
      // }, null);

      // if (this.knowledgeOnly) {
      //   this.toggleKnowledgeOnly();
      // }

      // this.currentProcessId = processData.processId;
      // this.isInProcessSetup = false;
      // this.processWorkflowId = null;

      // TODO: Look at this
      // this.subscribeToProcessDone(processData.processId);

      this.dialog.getDialogById(this.dialogId).close();
    })
    .catch(err => {
      console.error('Error in actionIsKnownWorkflow: ', err);
      this.loggingService.logMessage('An Error occurred while getting this started. Please try again later.', false, 'error');
    });
  }

  updateErrorMessage() {
    if (this.processName.hasError('required')) {
      this.errorMessage.set('You must enter a name for the Process');
    } else {
      this.errorMessage.set('');
    }
  }

  private generateChatId(): string {
      const S4 = function() {
        // eslint-disable-next-line no-bitwise
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
  }

  private enableStateToFetchingWorkflow(): void {
    this.fetchingWorkflow = true;
    // Disable backdrop close
    const dialogInstance = this.dialog.getDialogById(this.dialogId);
    if (dialogInstance) {
      dialogInstance.disableClose = true;
    }
    this.processName.disable();
  }

  private disableStateToFetchingWorkflow(): void {
    this.fetchingWorkflow = false;
    // Enable backdrop close
    const dialogInstance = this.dialog.getDialogById(this.dialogId);
    if (dialogInstance) {
      dialogInstance.disableClose = false;
    }
    this.processName.enable();
  }
}
