import {Injectable} from '@angular/core';
import {UserPreferences} from "@eva-model/UserPreferences";
import { HttpClient } from "@angular/common/http";
import {environment} from "@environments/environment";
import {AngularFirePerformance, trace} from "@angular/fire/compat/performance";
import {NotificationPreferences} from "@eva-model/notifications/notifications";

/**
 * AngularFirestore persistence for Dynamic Form data.
 * @extends {@link FirestoreService}
 */
@Injectable({
  providedIn: 'root'
})
export class DynamicDatabaseService {

  /**
   * Constructor injected HttpClient and AngularFirePerformance
   */
  constructor(private http: HttpClient, private perf: AngularFirePerformance) {
  }

  /**
   * saveNotificationPreferences resource location
   */
  private saveNotificationPreferencesUrl = environment.endPoints.DYNAMIC_INTERACTIONS.url + 'saveNotificationPreferences';

  /**
   * Map EVA Core user preferences object to Dynamic Form user preferences object.
   * @param preferences {@link UserPreferences}
   * @return {@link NotificationPreferences}
   */
  private static mapPreferences(preferences: UserPreferences): NotificationPreferences {
    return {
      preference: {
        email: preferences.toggleEmailsProcessDashboard
      }
    };
  }

  /**
   * Call dynamic-forms-eva.saveNotificationPreferences, passing {@link NotificationPreferences}
   * @param userPreferences
   */
  async saveNotificationPreferences(userPreferences: UserPreferences): Promise<void> {
    try {
      await this.http.post(this.saveNotificationPreferencesUrl, DynamicDatabaseService.mapPreferences(userPreferences)).pipe(
        trace('send-notification-preferences-to-dynamic-form-service')
      ).toPromise();
      return Promise.resolve();
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }
}
