
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@eva-core/auth-guard/auth.guard';
import { HomeLayoutComponent } from '@eva-ui/layouts/home-layout.component';
import { LoginLayoutComponent } from '@eva-ui/layouts/login-layout.component';
import { LoginPageComponent } from '@eva-ui/login-page/login-page.component';
import { FormBuilderComponent } from '@eva-ui/form-builder/form-builder.component';
import { UserPreferencesComponent } from '@eva-ui/user-preferences/user-preferences.component';
import { WorkflowBuilderComponent } from '@eva-ui/workflow-builder/workflow-builder.component';
import { ErrorPageComponent } from '@eva-ui/error-page/error-page.component';
import { NotificationsComponent } from '@eva-ui/notifications/notifications.component';
import { GroupSearchComponent } from '@eva-ui/group-search/group-search.component';
import { GroupAdminComponent } from '@eva-ui/group-admin/group-admin.component';
import { ProcessComponent } from '@eva-ui/process/process.component';
import { LandingPageComponent } from '@eva-ui/landing-page/landing-page.component';
import { AdminOverviewComponent } from '@eva-ui/admin-overview/admin-overview.component';
import { AboutEvaComponent } from '@eva-ui/about-eva/about-eva.component';
import { GroupComponent } from '@eva-ui/group/group.component';
import { KnowledgeComponent } from '@eva-ui/admin-overview/knowledge/knowledge/knowledge.component';
import { KnowledgeCreateComponent } from '@eva-ui/admin-overview/knowledge/knowledge-create/knowledge-create.component';
import { KnowledgeSearchComponent } from '@eva-ui/admin-overview/knowledge/knowledge-search/knowledge-search.component';
import { CanDeactivateGuard } from './core/auth-guard/can-deactivate.guard';
import { NotificationsFeedbackComponent } from '@eva-ui/notifications-feedback/notifications-feedback.component';
import { DeadEndComponent } from '@eva-ui/common/dead-end/dead-end.component';
import { ProcessDashboardComponent } from '@eva-ui/process/process-dashboard/process-dashboard.component';

import { WrongEnvironmentComponent } from '@eva-ui/wrong-environment/wrong-environment.component';
import { AdministrationGuard } from '@eva-core/auth-guard/administration.guard';
import { AboutPageFeedbackComponent } from '@eva-ui/about-eva/about-page-feedback/about-page-feedback.component';
import { EvaChatWorkflowsComponent } from '@eva-ui/eva-chat-workflows/eva-chat-workflows.component';
import { StatusComponent } from '@eva-ui/status/status.component';
import { BuilderGuard } from '@eva-core/auth-guard/builder.guard';
import { OrganizationGuard } from '@eva-core/auth-guard/organization.guard';
import { OrganizationAdminGuard } from '@eva-core/auth-guard/organization-admin.guard';
import { ATBGlobalGuard } from '@eva-core/auth-guard/atbglobal.guard';

const routes: Routes = [
  // Authenticated users required for this component
  {
    path: '',
    component: HomeLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AboutPageFeedbackComponent,
        canActivate: [AuthGuard]
      },
      {
        // extra route to the same component to capture generated urls and action them from the home component.
        // this route can also support any query parameters
        path: 'i/:urlId',
        component: AboutPageFeedbackComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'about',
        component: AboutEvaComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'About EVA'
        }
      },
      {
        path: 'Administration',
        component: AdminOverviewComponent,
        canActivate: [AuthGuard, AdministrationGuard]
      },
      {
        path: 'admin',
        // loadChildren: () => import('@eva-ui/administration/administration.module').then(m => m.AdministrationModule),
        loadChildren: () => import('@eva-ui/administration/administration.module').then(m => m.AdministrationModule),
        canActivate: [AuthGuard],
        canLoad: [AdministrationGuard],
        data: {
          componentTitle: 'EVA Administration'
        }
      },
      {
        path: 'InteractionBuilder',
        redirectTo: 'builders/Interaction',
        pathMatch: 'full'
      },
      {
        path: 'builders/Interaction',
        component: FormBuilderComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          componentTitle: 'Interaction Builder',
          searchEnabled: true,
          createNewTabButtonActive: true
        }
      },
      {
        path: 'machineLearning',
        redirectTo: 'ai/machineLearning',
        pathMatch: 'full'
      },
      {
        path: 'ai/machineLearning',
        loadChildren: () => import('@eva-ui/machine-learning/machine-learning.module').then(m => m.MachineLearningModule),
        canActivate: [AuthGuard, BuilderGuard],
        data: {
          componentTitle: 'Machine Learning'
        }
      },
      {
        path: 'WorkflowBuilder',
        redirectTo: 'builders/Workflow',
        pathMatch: 'full'
      },
      {
        path: 'builders/Workflow',
        component: WorkflowBuilderComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          componentTitle: 'Workflow Builder',
          searchEnabled: true,
          createNewTabButtonActive: true
        }
      },
      {
        path: 'ProcessDashboard',
        redirectTo: 'dashboards/Process',
        pathMatch: 'full'
      },
      {
        path: 'dashboards/Process',
        component: ProcessDashboardComponent,
        canActivate: [AuthGuard, ATBGlobalGuard],
        data: {
          componentTitle: 'Process Dashboard',
          searchEnabled: true
        }
      },
      {
        path: 'user-preferences',
        redirectTo: 'user/preferences',
        pathMatch: 'full'
      },
      {
        path: 'user/preferences',
        component: UserPreferencesComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'Preferences'
        }
      },
      {
        path: 'group',
        component: GroupSearchComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'Group Search',
          searchEnabled: true,
          createNewTabButtonActive: true
        }
      },
      {
        path: 'group/:pk',
        component: GroupComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'Group'
        }
      },
      {
        path: 'mine',
        component: GroupAdminComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'Group Administration'
        }
      },
      {
        path: 'error',
        component: ErrorPageComponent,
        data: {
          componentTitle: 'Error'
        }
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'Notifications'
        }
      },
      {
        path: 'feedback-notifications',
        component: NotificationsFeedbackComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'Knowledge Feedback'
        }
      },
      {
        path: 'Process/:id',
        component: EvaChatWorkflowsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          componentTitle: 'Process'
        }
      },
      {
        path: 'Process',
        component: EvaChatWorkflowsComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'Process Search',
          searchEnabled: true
        }
      },
      {
        path: 'knowledge/view/:id/:groupPk/:version/:viewKey',
        component: KnowledgeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'knowledge/create',
        component: KnowledgeCreateComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          componentTitle: 'Knowledge Create'
        }
      },
      {
        // Used when you want to edit a knowledge document.
        // Will always get the latest version of the doc if
        // you do not include a specific version number.
        //
        // optional query parameters for this route that the component should check for:
        // docId, docGroupPk, published, version
        //
        path: 'knowledge/edit',
        component: KnowledgeCreateComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          componentTitle: 'Knowledge Edit'
        }
      },
      {
        path: 'knowledge/search',
        redirectTo: 'knowledge',
        pathMatch: 'full'
      },
      {
        path: 'knowledge',
        component: KnowledgeSearchComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'Knowledge',
          searchEnabled: true,
          createNewTabButtonActive: false
        }
      },
      {
        // Go to the knowledge search page with a preset search query
        path: 'knowledge/search/:query',
        component: KnowledgeSearchComponent,
        canActivate: [AuthGuard],
        data: {
          componentTitle: 'Knowledge',
          searchEnabled: true,
          createNewTabButtonActive: true
        }
      }
    ]
  },
  // Components that don't require a logged in user to use
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'welcome',
        component: LandingPageComponent
      },
      {
        path: 'login',
        component: LoginPageComponent
      },
      {
        path: 'login/:init',
        component: LoginPageComponent
      },
      {
        path: 'login/:returnUrl',
        component: LoginPageComponent
      },
      {
        path: 'end',
        component: DeadEndComponent
      },
      {
        path: 'wrongEnvironment',
        component: WrongEnvironmentComponent
      },
      {
        path: 'status',
        component: StatusComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/error',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
