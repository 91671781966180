import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';

import { FormsModule, UntypedFormGroup } from '@angular/forms';

import {
  DynamicFormControlModel,
  DynamicFormLayout,
  DynamicFormService
} from '@ng-dynamic-forms/core';
import { FormBuilderService } from '@eva-services/form-builder/form-builder.service';
import { Subscription } from 'rxjs';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ElementSyncService } from '@eva-services/dynamic-interactions/element-sync.service';
import { ElementEmitValueModel } from '@eva-model/elementEmitValueModel';
import { RemoveFormElement, MoveFormElement, SettingAndRelationFormElement } from '@eva-model/interaction/form-element-visualizer';
import { IfThenLogicOptions } from '@eva-model/interactionElementRelationDialogModel';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DynamicMaterialFormComponent, DynamicMaterialCheckboxComponent, DynamicMaterialChipsComponent, DynamicMaterialDatePickerComponent, DynamicMaterialInputComponent, DynamicMaterialRadioGroupComponent, DynamicMaterialSelectComponent, DynamicMaterialSlideToggleComponent, DynamicMaterialTextAreaComponent } from '@ng-dynamic-forms/ui-material';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import {provideNativeDateAdapter} from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-form-element-visualizer',
  templateUrl: './form-element-visualizer.component.html',
  styleUrls: ['./form-element-visualizer.component.scss'],
  standalone: true,
  providers: [ provideNativeDateAdapter() ],
  imports: [
    CommonModule,
    FormsModule,
    NgTemplateOutlet,
    DynamicMaterialFormComponent,
    DynamicMaterialCheckboxComponent,
    DynamicMaterialChipsComponent,
    DynamicMaterialDatePickerComponent,
    DynamicMaterialInputComponent,
    DynamicMaterialRadioGroupComponent,
    DynamicMaterialSelectComponent,
    DynamicMaterialSlideToggleComponent,
    DynamicMaterialTextAreaComponent,
    MatDatepickerModule,
    MatButtonModule,
    MatTooltipModule,
    MatStepperModule,
    MatDatepickerModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class FormElementVisualizerComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  set fromControlItem(fromControlItem: string ) {
    // if the formControlItem doesn't exist, return to prevent unwanted behavior
    if (!fromControlItem) {
      return;
    }
    const formControlObj = JSON.parse(fromControlItem);
    this.formControlItemOriginalId = formControlObj.id;
    this.formControlItem = this.formBuilderService.createDynamicFormControlModel(formControlObj);
  }

  @Input() formScreenName ? = '';
  @Input() formScreenIndex: number; // the index number of the form screen in the array
  @Input() formElementIndex: number; // this is the item number in the array that the form element is.
  @Input() formScreenElementCount: number; // this is the total number of items in the form screen element.
  @Input() enableSetting = true; // should this relationship by settable.
  @Input() enableRelation = false; // should conditions be available on the form.
  @Input() enableOperatorSelectors = false;
  @Input() enableValueEmiiter = false;
  @Input() interactionId = '';

  @Input()
  set elementValueOperator(elementValueOperator: string) {
    // TODO :: Validate elementConnectionOperator
    if (!this.enableOperatorSelectors) return;
    if (elementValueOperator === '===' || elementValueOperator === '!==') {
      this.valueOperator = elementValueOperator === '===' ? IfThenLogicOptions.IsEqualTo : IfThenLogicOptions.IsNotEqualTo;
      return;
    }
    this.valueOperator = elementValueOperator === '' || !elementValueOperator ? IfThenLogicOptions.IsEqualTo : elementValueOperator;
  }
  @Input()
  set elementConnectionOperator(elementConnectionOperator: string) {
    // TODO :: Validate elementConnectionOperator
    if (!this.enableOperatorSelectors) return;
    if (elementConnectionOperator === 'AND' || elementConnectionOperator === 'OR' || elementConnectionOperator === '') {
      this.connectionOperator = elementConnectionOperator;
    }
  }
  @Input() scrnIdx = 0; // used in the html side of the component.
  @Input() elmntIdx = 0; // used in the html side of the component.
  @Input() initValue: any;

  @Output() removeFormElementConfirm = new EventEmitter<RemoveFormElement>();
  @Output() moveUpFormElement = new EventEmitter<MoveFormElement>();
  @Output() moveDownFormElement = new EventEmitter<MoveFormElement>();
  @Output() settingFormElement = new EventEmitter<SettingAndRelationFormElement>();
  @Output() relationForFormElement = new EventEmitter<SettingAndRelationFormElement>();

  formControlItem: any;
  formControlItemOriginalId: string;
  visualizerFormModel: DynamicFormControlModel[] = [];
  visualizerFormGroup: UntypedFormGroup;
  visualizerFormLayout: DynamicFormLayout = null;

  tooltipShowDelay = 700;

  connectionOperator: string = null;
  valueOperator: string = null;
  isInitialized = false;

  private elementValueChangeSubs = new Subscription();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private ngDynFormService: DynamicFormService,
    private formBuilderService: FormBuilderService,
    private elementSyncService: ElementSyncService) {
    this.matIconRegistry.addSvgIcon(
      "conditional",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/images/condition-icon.svg"));
    this.matIconRegistry.addSvgIcon(
      "conditionalReverse",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/images/conditionalReverse.svg"));
  }

  ngOnInit() {
    this.formInitializer();
    if (this.formScreenName && this.formScreenName !== '' && this.formControlItem) {
      this.formControlItem.label += ` (${this.formScreenName})`;
    }
  }

  formInitializer() {
    const that = this;
    if ( this.formControlItem ) {

      //#region Initialize the form control if init value exist
      if ( this.initValue && !this.isInitialized ) {
        this.formControlItem.value = this.initValue;
        this.isInitialized = true;
      }
      //#endregion

      //#region setting the form control layout
      const formControlLayout = new Object();
      const isformControlItemElmntLayOut = this.formControlItem.layout && this.formControlItem.layout.element;
      formControlLayout[this.formControlItem.id] = {
        element: {
          // container: "eva_frm_elmnt_container_class",
          // control: 'eva_frm_elmnt_control_class',
          host: 'eva_frm_elmnt_Width95prcnt',
          // group: "eva_frm_elmnt_group_class",
          // hint: "eva_frm_elmnt_hint_class",
          option: isformControlItemElmntLayOut ? this.formControlItem.layout.element.option : '',
          label: isformControlItemElmntLayOut ? this.formControlItem.layout.element.label : '',
        }
      };
      //#endregion

      this.visualizerFormLayout = formControlLayout as DynamicFormLayout;
      if ( this.visualizerFormModel.length > 0 )  {
        this.visualizerFormModel[0] = this.formControlItem;
      } else {
        this.visualizerFormModel.push(this.formControlItem);
      }

      this.visualizerFormGroup = this.ngDynFormService.createFormGroup(this.visualizerFormModel);

      //#region Enable emit value changes
      if (this.enableValueEmiiter) {
        Object.keys(this.visualizerFormGroup.controls).forEach(function (key, index) {
          const frmGrpControl: any = that.visualizerFormGroup.get(key);
          // const formControlMdl = that.formVisualizerModelArray[scrnIndex].find(element => element.id === key);

          //#region Element value change observable subscription
          that.elementValueChangeSubs.add(
            frmGrpControl.valueChanges // .pipe(debounceTime(3000))
            .subscribe(
              (value) => {
                const valueChange =
                  new ElementEmitValueModel(
                    that.interactionId,
                    that.formControlItem.originalId,
                    that.formScreenIndex,
                    that.formElementIndex,
                    value,
                    that.enableOperatorSelectors ? that.valueOperator : null,
                    that.enableOperatorSelectors ? that.connectionOperator : null
                  );

                that.elementSyncService.announceElementValueChange(valueChange);
              },
              (err) =>  { console.log( err ); throw err; }
            )
          );
          //#endregion
        });
      }
      //#endregion
    }
  }

  onDynamicFormChange(ev) {
    // TODO :: action based on the changes, the value changes!
    if (ev) {
      // console.log(ev);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {

      switch (propName) {
        case 'fromControlItem':
          if (!changes.fromControlItem.firstChange) {
            this.formInitializer();
          }

          break;
      }

    }
  }

  /**
   * This event is fired when an element is removed from the interaction builder.
   * @param formControl the form control that was changed.
   */
  onRemoveFormElement(formControl: any): void {
    this.removeFormElementConfirm.emit({ formScreenIndex: this.formScreenIndex, formElementId: this.formControlItemOriginalId });
  }

  /**
   * This fires an event from the interaction builder when an item if moved up. It is fired as an event in the component
   * HTML.
   * @param formControl the form control that had changes occuring to it.
   */
  onMoveUpFormElement(formControl: any): void {
    this.moveUpFormElement.emit({ formScreenIndex: this.formScreenIndex, formElementIndex: this.formElementIndex });
  }

  /**
 * This fires an event from the interaction builder when an item if moved down. It is fired as an event in the component
 * HTML.
 * @param formControl the form control that had changes occuring to it.
 */
  onMoveDownFormElement(formControl: any): void {
    this.moveDownFormElement.emit({ formScreenIndex: this.formScreenIndex, formElementIndex: this.formElementIndex });
  }

  /**
   * This fires an event from the interaction builder when an item relation is set. From the component HTML.
   * @param formControl the form control that is having it's settings chosen.
   */
  onSettingFormElement(formControl: any): void {
    this.formBuilderService.setInputType(formControl);
    this.settingFormElement.emit({
      formControl: formControl,
      formScreenIndex: this.formScreenIndex,
      formElementIndex: this.formElementIndex,
      formElementId: this.formControlItemOriginalId
    });
  }
  onRelationForFormElement(formControl: any): void {
    this.relationForFormElement.emit({
      formControl: formControl,
      formScreenIndex: this.formScreenIndex,
      formElementIndex: this.formElementIndex,
      formElementId: this.formControlItemOriginalId
    });
  }

  /**
   * This function sets the connection operator value when it is createdor changed  in form builder. It then calls a function to
   * emait the current full value of the Elemental operator and value model to the subject.
   *
   * @param operator the operator to set.
   */
  onConnectionOperator(operator: string): void {
    this.connectionOperator = operator;
    this.emitValueChange();
  }
  /**
   * This function sets the vlaue operator value when it is created or changed in form builder. It then calls a function to
   * emait the current full value of the Elemental operator and value model to the subject.
   *
   * @param operator the operator to set.
   */
  onValueOperator(operator: string) {
    this.valueOperator = operator;
    this.emitValueChange();
  }

  /**
   * This function takes the current state of the interaction, form control and the conditional builder and it emits
   * the value through the announceElementValueChange subject.
   */
  emitValueChange(): void {
    const valueChange =
      new ElementEmitValueModel(
        this.interactionId,
        this.formControlItem.originalId,
        this.formScreenIndex,
        this.formElementIndex,
        this.formControlItem.value,
        this.enableOperatorSelectors ? this.valueOperator : null,
        this.enableOperatorSelectors ? this.connectionOperator : null
      );

    this.elementSyncService.announceElementValueChange(valueChange);
  }


  onCode(formControl: any) {   // Coded just for debugging purposes
    // console.log(formControl);
    // console.log(this.visualizerFormGroup);
    // console.log(this.visualizerFormGroup.controls);

    const objEntries = Object.entries(this.visualizerFormGroup.controls);
    // console.log(objEntries);
    // let tempFrmGrp: any;

    for (let i = 0; i < objEntries.length; i++) {
      const oe = objEntries[i];
      // console.log(objEntries[i]);
      if (Array.isArray(oe)) {
        for (let j = 0; j < oe.length; j++) {
          const oeItem = oe[j];
          // console.log('j - ' + j.toString());
          // console.log(oeItem);

        }
      }
    }

  }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.

    if (this.elementValueChangeSubs) {
      this.elementValueChangeSubs.unsubscribe();
    }
  }
}
