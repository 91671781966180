import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SearchAuthService } from '@eva-core/search-auth-service';
import { KnowledgeSearchRecord } from '@eva-model/knowledge/knowledge';
import { environment } from '@environments/environment';
import { LoggingService } from '@eva-core/logging.service';
import algoliasearch from 'algoliasearch/lite';
import { AlgoliaIndex, AlgoliaSearchToken, AlgoliaTokenType } from '@eva-model/search/search';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-knowledge-section-search',
  templateUrl: './knowledge-section-search.component.html',
  styleUrls: ['./knowledge-section-search.component.scss']
})
export class KnowledgeSectionSearchComponent implements OnInit {

  @Output() resultClicked: EventEmitter<KnowledgeSearchRecord> = new EventEmitter<KnowledgeSearchRecord>();
  @Output() searchQueryChanged: EventEmitter<string> = new EventEmitter<string>();

  isLoading = true;

  /** Algolia */
  searchConfig = {
    ...environment.algolia,
    indexName: AlgoliaIndex.KNOWLEDGE_SEARCH + environment.algolia.indexEnding,
    apiKey: '',
    searchClient: null
  };

  algoliaTokenType: AlgoliaTokenType = AlgoliaTokenType.KNOWLEDGE_READER;

  hits: KnowledgeSearchRecord[] = [];
  searchParams: any = {
    hitsPerPage: 10,
    filters: '',
    distinct: true
  };

  searchToken: AlgoliaSearchToken; // set by ngOnInit

  constructor(private searchAuthService: SearchAuthService,
              private loggingService: LoggingService) { }

  async ngOnInit(): Promise<void> {
    try {
      await this.getSearchToken();
    } catch (err) {
      // Failed to get a secure search token
      this.loggingService.logMessage('Failed to get new search token. Please try again.', false, 'error');
      console.error('failed to get api token', err);
    }
  }

  /**
   * Fired every time Algolia search results change and binds the new results to the component property hits for display.
   *
   * @param searchResults - results from an Algolia search, from the <hits> component
   */
  onSearchChange(searchResults: { results: any, state: any }): void {
    if (searchResults && searchResults.results) {
      this.hits = searchResults.results.hits;
    }
  }

  onSearchQueryChanged(query: string) {
    this.searchParams.query = query;
    this.searchQueryChanged.emit(query);
  }

  selectDocumentResult(result: KnowledgeSearchRecord) {
    this.resultClicked.emit(result);
  }

//#region Token Functions

  /**
   * This gets the search token on knowledge
   */
  private async getSearchToken(): Promise<void> {
    this.searchAuthService.getSearchToken(AlgoliaTokenType.KNOWLEDGE_READER).pipe(take(1)).subscribe(
      (token) => {
        this.searchToken = token;
        if (this.searchToken.securedAPIKey !== '') {
          this.searchConfig.apiKey = this.searchToken.securedAPIKey;
          const searchClient = algoliasearch(environment.algolia.appId, this.searchToken.securedAPIKey);
          this.searchConfig.searchClient = searchClient;
          // init the view components
          this.isLoading = false;
        }

        return Promise.resolve();
      },
      (err) => Promise.reject(err)
    );
  }

//#endregion Token Functions

}
