import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

// import {LicenseManager} from "ag-grid-enterprise";
// eslint-disable-next-line max-len
// LicenseManager.setLicenseKey("ATB_Financial_Multi_Multiple_Applications_1_Devs__15_February_2021_[v2]_MTYxMzM0NzIwMDAwMA==cec98e2eb64e7231a8f4614ece7257da");

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
