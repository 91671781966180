<h2 mat-dialog-title>Create New Group</h2>
<mat-dialog-content class="create-group-card-content">
    <form [formGroup]="formGroup">
        <mat-form-field>
            <mat-label>Group Name</mat-label>
            <input matInput class="fullWidth" formControlName="name" placeholder="Type a name"
                (keyup)="changeName($event)" cdkFocusInitial required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Group Type</mat-label>
            <mat-select placeholder="Choose a type" (selectionChange)="groupTypeSelectionChange($event)"
                formControlName="type">
                <mat-option *ngFor="let groupType of groupTypeNames" [value]='groupTypes[groupType]'>
                    {{ groupType }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Group Description</mat-label>
            <textarea matInput (keyup)="changeDescription($event)" placeholder="Type a short description"
                formControlName="description" required></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" [disabled]="!formGroup.valid" (click)="returnGroupData()"
    [mat-dialog-close]="createGroupForm">Create Group</button>
</mat-dialog-actions>