import { Pipe, PipeTransform } from '@angular/core';
import { EvaDynamicFormControlModel } from '@eva-model/evaDynamicFormControlModel';

@Pipe({
  name: 'getCandidateElements',
  standalone: true
})
export class GetCandidateElementsPipe implements PipeTransform {

  transform(relationControls: any[],
    candidateControlFormScreens: { elements: EvaDynamicFormControlModel[], elementOriginalId: string,
      screenName: string, screenIndex: number}[], relationControl: any, relationIndex: number, subRelationIndex: number,
      controlIndex: number, type: 'if' | 'then', formScreens?: any[], FORM_CONTROL_ELEMENTS?: any[]): any[] {
      const candidateControl = candidateControlFormScreens.find(screenElement =>
        relationControl.relation[relationIndex].thenControl[0].originalId === screenElement.elementOriginalId);
      const elementScreenIndex = formScreens?.findIndex(screen => screen.FormElements.find(element =>
        element.originalId === relationControl.relation[relationIndex].thenControl[0].originalId));

      if (candidateControl) {
        const elements = [];
        const screenNames = [];
        formScreens?.forEach((formScreen, formScreenIndex) => {
          if (formScreenIndex > elementScreenIndex) {
            return;
          }
          screenNames.push(formScreen.name);
          let currentElements = formScreen.FormElements.map((formElement: any, index: number) => {
            formElement['index'] = index;

            const controlElement = FORM_CONTROL_ELEMENTS.find( (element) => {
              return element.type === formElement.type
                && (formElement.inputType === undefined || element.inputType === formElement.inputType);
            });

            formElement['icon'] = (controlElement) ? controlElement.icon : '';
            return formElement;
          });
          currentElements = currentElements.filter(element =>
            element.originalId !== relationControl.relation[relationIndex].thenControl[0].originalId);
          if (controlIndex > 0 && relationControl.relation[relationIndex].subRelation[subRelationIndex].ifConnective) {
            currentElements = currentElements.filter(element =>
              element.originalId !== relationControls[controlIndex - 1].relation[relationIndex].thenControl[0].originalId);
          }
          if (currentElements.length > 0) {
            elements.push(
              {
                screenName: formScreen.name,
                elements: currentElements
              }
            );
          }
        });
        let filteredControl = {
          elementOriginalId: candidateControl.elementOriginalId,
          screenName: screenNames,
          screenIndex: candidateControl.screenIndex,
          elements: elements
        };
        if (type === 'then') {
          filteredControl.elements = candidateControlFormScreens[candidateControlFormScreens.length - 1].elements;
          filteredControl.screenIndex = candidateControlFormScreens[candidateControlFormScreens.length - 1].screenIndex;
        }
        if (controlIndex > 0 && relationControl.relation[relationIndex].subRelation[subRelationIndex].ifConnective) {
          filteredControl = {
            elementOriginalId: candidateControl.elementOriginalId,
            screenName: screenNames,
            screenIndex: candidateControl.screenIndex,
            elements: elements
          };
          if (type === 'then') {
            filteredControl.elements = candidateControlFormScreens[candidateControlFormScreens.length - 1].elements;
            filteredControl.screenIndex = candidateControlFormScreens[candidateControlFormScreens.length - 1].screenIndex;
          }
        }
        return [filteredControl];
      } else {
        return [];
      }
  }

}
