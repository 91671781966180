import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthProvider } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';

import { User } from '@eva-model/User';
import { LoggingService } from '@eva-core/logging.service';
import { switchMap, startWith, tap, take } from 'rxjs/operators';

import { WINDOW } from '../providers/injection';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

@Injectable()
export class AuthService {

  user: Observable<User>;
  returnedToPreviousPage = false;
  firebaseAuthState: any;
  wrongEnvironment = true;
  url: string;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public logging: LoggingService,
    private perf: AngularFirePerformance,
    private firestoreService: FirestoreService,
    @Inject(WINDOW) private window: Window) {

      this.url = this.window.location.hostname;
      // determine if the user is in the correct environment.
      if (this.checkDomains(this.url)) {
        this.wrongEnvironment = false;
      }
      // gets the user information from firebase once a user has logged in.
      this.user = this.afAuth.authState.pipe(
        trace('auth-constructor'),
        switchMap(user => {
          this.firebaseAuthState = user;
          if (user) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
              .pipe(
                trace('auth-service-user')
              );
          } else {
            return of(null);
          }
        }),
      );
  }

  /**
   * This function checks for the current domain that the EVA application is running on
   *
   * @param domain current domain to check
   */
  checkDomains(domain: string): boolean {
    switch (domain) {
      case 'localhost':
        return true;
      case 'dev.atbeva.com':
        return true;
      case 'test.atbeva.com':
        return true;
      case 'staging.atbeva.com':
        return true;
      case 'atbeva.com':
        return true;
      default:
        return false;
    }
  }

  /**
   * googleLogin - allows the user to login using their google account using the
   * firebase google auth provider
   *
   * @return {type}  user credentials or error
   */
  googleLogin(): Promise<void | firebase.auth.UserCredential> {
    // the provider as well as different things the system needs to login the user
    const provider = new GoogleAuthProvider().addScope(
      'https://www.googleapis.com/auth/userinfo.email '
      + 'profile '
      + 'email '
      + 'https://www.googleapis.com/auth/cloud-language '
      + ''
    );
    return this.oAuthLogin(provider);
  }

  /**
   * private oAuthLogin - uses firebase signInWithPopup so a new window will
   * open up asking which google account the user wants to use
   *
   * @param  provider the provider. in this system we are only using google for now.
   * other possibilities would be github or facebook. look at the docs
   * @return          credentials or error
   */
  private async oAuthLogin(provider: firebase.auth.AuthProvider): Promise<void | firebase.auth.UserCredential> {
    try {
      const credential = await this.afAuth.signInWithPopup(provider);
      // const credential = await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
      //   return this.afAuth.signInWithPopup(provider);
      // })
      if (credential && credential.user) {
        await this.setUserData(credential.user);
      }
      return credential;
    } catch (err) {
      console.log('An error occurred: ' + err);
      this.handleSignInError(err);
    }
  }

  /**
   * private setUserData - creates a new user document when a user first signs into the system
   *
   * @param  user the credentials for the user
   * @return      the result of setting the user data in firebase
   */
  private async setUserData(user: firebase.User): Promise<void> {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`); // this.afs.doc(`users/${user.email}`);
    const data: User = {
      uid: user.uid,
      email: user.email,
      preferredName: (user.displayName) ? user.displayName : user.email,
      photoURL: (user.photoURL) ? user.photoURL : ''
    };
    return userRef.set(data, { merge: true });
  }


  /**
   * signOut - allows a user to logout and be redirected to the login page
   */
  async signOut(): Promise<void> {
    try {
      await this.afAuth.signOut();
      this.returnedToPreviousPage = true;
      this.router.navigate(['welcome']);
    } catch (err) {
      // an error occurred signing out.
      console.error(err);
    }
  }

  // /**
  //  * public getLocalIdToken - returns the user's ID token from local storage
  //  */
  // public getLocalIdToken() {
  //   return localStorage.getItem('userIdToken');
  // }

  /**
   * public getIdToken - get the user's ID token from firebase
   */
  public getIdToken(forceRefresh?: boolean): Promise<string> {
    // if (forceRefresh) {
    return this.afAuth.idToken.pipe(take(1)).toPromise();
    // } else {
    //   return this.afAuth.currentUser.getIdToken();
    // }
  }

  /**
   * This returns the email of the currently logged in user
   */
  public async getUserEmail(): Promise<string> {
    const user = await this.afAuth.user.pipe(take(1)).toPromise();
    return user.email;
  }

  /**
   * public getUserId - gets the user's ID from Firebase
   */
  public async getUserId(): Promise<string> {
    const user = await this.afAuth.user.pipe(take(1)).toPromise();
    return user?.uid;
  }

  /**
   * This function returns the current user firebase auth state
   */
  public getCurrentAuthState() {
    return this.firebaseAuthState;
  }
  /**
   * public getTokenHeader - uses the user's ID token from firebase
   * to create an Authorization header to be used when making requests to Firebase Functions
   *
   * @return the token header
   */
  public async getTokenHeader(): Promise<any> {
    try {
      const token = await this.afAuth.idToken.pipe(take(1)).toPromise();
      const tokenHeader = new Headers({
        'Authorization': `Bearer ${token}`
      });
      return tokenHeader;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // /**
  //  * signUpEmail - sign up for the system using an email address.
  //  *
  //  * @param  email: string    the email to sign up with
  //  * @param  password: string the password to sign up with
  //  * @return any                  the user if valid or else empty
  //  */
  // async signUpEmail(email: string, password: string): Promise<firebase.User> {
  //   try {
  //     const user = await this.afAuth.createUserWithEmailAndPassword(email, password);
  //     const userObject: firebase.User = user.user;
  //     this.setUserData(userObject);
  //     this.sendVerificationEmail(userObject);
  //     return userObject;
  //   } catch (err) { // errors from firebase that can come up with bad signup
  //     // // Handle Errors here.
  //     const errorCode = err.code;
  //     if (errorCode === 'auth/email-already-in-use') {
  //       this.logging.logMessage('The email is already in use. Sign up with a different email.', false, 'error');
  //       return;
  //     }
  //     if (errorCode === 'auth/invalid-email') {
  //       this.logging.logMessage('The email is invalid. Please format correctly.', false, 'error');
  //       return;
  //     }
  //     if (errorCode === 'auth/operation-not-allowed') {
  //       this.logging.logMessage('This operation is not allowed by the system. Sign up using a different provider.', false, 'error');
  //       return;
  //     }
  //     if (errorCode === 'auth/weak-password') {
  //       this.logging.logMessage('The password is too weak. Try adding in numbers, symbols and different letter cases.', false, 'error');
  //       return;
  //     } else {
  //       this.logging.logMessage('Unexpected error. Try again.', false, 'error');
  //       return;
  //     }
  //   }
  // }

  /**
   * emailLogin - log into the system using your email
   *
   * @param  email: string    email to login with
   * @param  password: string password to login with
   * @return                  user object if successful else error message
   */
  async emailLogin(email: string, password: string): Promise<firebase.User> {
    try {
      const user = await this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
        return this.afAuth.signInWithEmailAndPassword(email, password);
      });
      // const user = await this.afAuth.signInWithEmailAndPassword(email, password);
      const userObject: firebase.User = user.user;
        this.setUserData(userObject);

        if (!userObject.emailVerified) {
          this.sendVerificationEmail(userObject);
        }
        return userObject;
    } catch (err) {// all possible error codes (at time of writing) from Firebase we can try to handle or just show to user
      // Handle Errors here.
      const errorCode = err.code;
      if (errorCode === 'auth/email-already-in-use') {
        this.logging.logMessage('The email is already in use. Sign up with a different email.', false, 'error');
        return;
      }
      if (errorCode === 'auth/invalid-email') {
        this.logging.logMessage('The email is invalid. Please format correctly.', false, 'error');
        return;
      }
      if (errorCode === 'auth/operation-not-allowed') {
        this.logging.logMessage('This operation is not allowed by the system. Sign up using a different provider.', false, 'error');
        return;
      }
      if (errorCode === 'auth/weak-password') {
        this.logging.logMessage('The password is too weak. Try adding in numbers, symbols and different letter cases.', false, 'error');
        return;
      }
      if (errorCode === 'auth/wrong-password') {
        this.logging.logMessage(`Wrong password. Try again or click the 'Forgot your password?' button`, false, 'error');
        return;
      } else {
        this.logging.logMessage('Unexpected error. Try again.', false, 'error');
        console.log(err);
        return;
      }
    }
  }

  /**
   * sendVerificationEmail - verification email sent to user so they can login
   *
   * @param  user: firebase.User the user
   * @return                    success or fail messages if the email was sent
   */
  async sendVerificationEmail(user: firebase.User): Promise<void> {
    try {
      await user.sendEmailVerification();
      this.logging.logMessage('Verification email sent to ' + user.email, false, 'success');
      return;
    } catch (err) {
      console.error(err);
      this.logging.logMessage(`Something went wrong with sending your verification email. We'll try later`, false, 'error');
      return;
    }
  }

  /**
   * sendPasswordResetEmail - allows a user to reset their password if they
   *
   * @param  {type} email: string the email to send the password reset link
   */
  async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await this.afAuth.sendPasswordResetEmail(email);
      this.logging.logMessage(`Verification email sent to ${email} if it exists.`, false, 'success');
      return;
    } catch (err) {
      if (err.code === 'auth/invalid-email') {
        this.logging.logMessage(`Verification email sent to ${email} if it exists.`, false, 'success');
      } else {
        this.logging.logMessage(`Something went wrong with sending your password reset email. Please try later`, false, 'error');
      }
      return;
    }
  }


  /**
   * This was an attempt to link accounts together. Abandoned due to time constraints.
   * It is possible to do but it really requires some thought about which account will get linked and handling
   * the different cases because firebase ends up deleting one account when it links accounts
   * together so one login will no longer work. This definitely needs some architecting - SRD 2018-08-03
   */
  // TODO: linking accounts with different emails requires more though
  // will do after main story is done
  // linkAccountsTogether(credential: any) {
  //   // Get reference to the currently signed-in user
  //   const prevUser = this.afAuth.currentUser;
  //   console.log('previous user');
  //   console.log(prevUser);
  //   console.log(credential);
  //   // Sign in user with another account
  //   this.afAuth.signInWithCredential(credential).then(function(user) {
  //     console.log("Sign In Success", user);
  //     const currentUser = user;
  //
  //     console.log('previous user');
  //     console.log(prevUser);
  //
  //     console.log('current user');
  //     console.log(currentUser);
  //     // Merge prevUser and currentUser data stored in Firebase.
  //     // Note: How you handle this is specific to your application
  //
  //     // After data is migrated delete the duplicate user
  //     // return user.delete().then(function() {
  //     //   // Link the OAuth Credential to original account
  //     //   return prevUser.linkWithCredential(credential);
  //     // }).then(function() {
  //     //   // Sign in with the newly linked credential
  //     //   return this.afAuth.signInWithCredential(credential);
  //     // });
  //   }).catch(function(error) {
  //     console.log('Sign In Error', error);
  //   });
  //
  // }


  // /**
  //  * linkGoogle - function to link a current user account to a google account.
  //  * NOT IN USE. NEEDS WORK.
  //  */
  // linkGoogle(provider) {
  //   this.afAuth.currentUser.linkWithPopup(provider).catch((error) => {
  //     console.log(error);
  //     if (error.code === 'auth/credential-already-in-use') {
  //       this.logging.logMessage('Accounts are already linked. Sign in with Google.', false, 'error');
  //       // this.linkAccountsTogether(error.credential);
  //     }
  //   });
  // }

  // /**
  //  * linkEmail - - function to link a current user account to an email login account.
  //  * NOT IN USE. NEEDS WORK.
  //  */
  // linkEmail(email: string, password: string) {
  //   const credential = auth.EmailAuthProvider.credential(email, password);

  //   this.afAuth.currentUser.linkWithCredential(credential)
  //     .then(user => {
  //       return user;
  //     }).catch((error) => {
  //       if (error.code === 'auth/credential-already-in-use') {
  //         this.logging.logMessage('Linking accounts with different emails is not functional at this moment.', false, 'error');
  //         return;
  //         // this.linkAccountsTogether(credential);
  //       }
  //       if (error.code === 'auth/provider-already-linked') {
  //         this.logging.logMessage('Email is already linked.', false, 'error');
  //         return;
  //       }
  //       if (error.code === 'auth/invalid-credential') {
  //         this.logging.logMessage('Invalid credentials. Try again.', false, 'error');
  //         return;
  //       }
  //       if (error.code === 'auth/email-already-in-use') {
  //         this.logging.logMessage('This email is already in use and cannot be linked.', false, 'error');
  //         return;
  //         //  this.linkAccountsTogether(credential);
  //       }
  //       if (error.code === 'auth/operation-not-allowed') {
  //         this.logging.logMessage('Linking an email is not permitted on this system.', false, 'error');
  //         return;
  //       }
  //       if (error.code === 'auth/invalid-email') {
  //         this.logging.logMessage('This email is invalid. Try again.', false, 'error');
  //         return;
  //       }
  //       if (error.code === 'auth/wrong-password') {
  //         this.logging.logMessage('Using a wrong password. Try again.', false, 'error');
  //         return;
  //       }
  //       if (error.code === 'auth/invalid-verification-code') {
  //         this.logging.logMessage('Invalid verification code. Try again.', false, 'error');
  //         return;
  //       }
  //       if (error.code === 'auth/invalid-verification-id') {
  //         this.logging.logMessage('Invalid verification ID. Try again.', false, 'error');
  //         return;
  //       }
  //       if (error.code === 'auth/requires-recent-login') {
  //         this.logging.logMessage('System timeout for linking accounts. Log out and try this function again.', false, 'error');
  //         return;
  //       }
  //     });
  // }

  /**
   * check - check to see which providers are authorized by Firebase project to be used to log into the system
   *
   * @param  email: string email of the account
   * @return        a list of possible providers from Firebase
   */
  check(email: string) {
    return this.afAuth.fetchSignInMethodsForEmail(email);
    // .fetchSignInMethodsForEmail(email);
    // .fetchProvidersForEmail(email);
  }

  /**
   * updateUser - update the user with new data
   *
   * @param  user: User user Object
   * @param  data: any  the data to update
   * @return            the result of the update as a promise
   */
  updateUser(user: User, data: any) {
    return this.afs.doc(`users/${user.uid}`).update(data);
  }

  /**
   * getProviderForProviderId - get the provider data from Firebase based on which login provider to use
   * since we are mostly using google, it has the only case
   *
   * @param  {type} provider: string the provider type (google, facebook, github, etc.)
   * @return {type}                  the result of the firebase call as a promise
   */
  getProviderForProviderId(provider: string) {
    switch (provider) {
      case 'google.com':
        return new firebase.auth.GoogleAuthProvider().addScope(
          'https://www.googleapis.com/auth/userinfo.email '
          + 'profile '
          + 'email '
          + 'https://www.googleapis.com/auth/cloud-language '
          + ''
        );
      default:
        this.logging.logMessage('No provider found', false, 'error');
        return;
    }
  }

  // TODO: finish implementing this
  /**
   * private handleSignInError - different ways of handling sign in errors. depending on the error we might wants
   * to do different things but again ran out of time/was low priority so it wasn't finished.
   */
  private handleSignInError(error) {
    // Handle Errors here.
    console.log(error);
    console.log('inside handle sign in error');
    const errorCode = error.code;
    const errorMessage = error.message;
    if (errorCode === 'auth/auth-domain-config-required') {
      this.logging.logMessage('Auth-domain-config-required. Try signing in a different way.', false, 'error');
      return;
    }
    if (errorCode === 'auth/cancelled-popup-request') {
      this.logging.logMessage('Only one pop up window allowed. Please stop clicking the sign in button', false, 'error');
      return;
    }
    if (errorCode === 'auth/popup-blocked') {
      this.logging.logMessage('Popup blocked by browser. Use a button to trigger this function.', false, 'error');
      return;
    }
    if (errorCode === 'auth/popup-closed-by-user') {
      this.logging.logMessage(`Did you mean to close the popup?
        Please follow through the popup window to finish signing in.`, false, 'info');
      return;
    }
    if (errorCode === 'auth/operation-not-allowed') {
      this.logging.logMessage('Not allowed to sign in with the selected provider. Try a different method.', false, 'error');
      return;
    }
    if (errorCode === 'auth/operation-not-supported-in-this-environment.') {
      this.logging.logMessage('Can only use http or https', false, 'error');
      return;
    }
    if (errorCode === 'auth/unauthorized-domain') {
      this.logging.logMessage('Bad domain.', false, 'error');
      return;
    }
    if (errorCode === 'auth/email-already-in-use') {
      this.logging.logMessage('The email is already in use. Sign up with a different email.', false, 'error');
      return;
    }
    if (errorCode === 'auth/invalid-email') {
      this.logging.logMessage('The email is invalid. Please format correctly.', false, 'error');
      return;
    }
    if (errorCode === 'auth/operation-not-allowed') {
      this.logging.logMessage('This operation is not allowed by the system. Sign up using a different provider.', false, 'error');
      return;
    }
    if (errorCode === 'auth/weak-password') {
      this.logging.logMessage('The password is too weak. Try adding in numbers, symbols and different letter cases.', false, 'error');
      return;
    }
    // An error happened.
    if (errorCode === 'auth/account-exists-with-different-credential') {
      this.logging.logMessage('Account already exists with different credentials, sign in using them.', false, 'error');

      // TODO; when we have more than one provider finish this
      // this.linkingAccounts(error);
      return;
    } else {
      return;
    }
  }

  /**
   * deleteUser - delete the user from the system
   */
  async deleteUser() {
    const currentUser = await this.afAuth.currentUser;
    this.afs.doc(`users/${currentUser.uid}`).delete();
    currentUser.delete().then(function() {
      // User deleted.
      this.logging.logMessage('Deleted the current user. You will be taken to the login page.', false, 'success');
    }).catch(function(error) {
      console.log(error);
      this.logging.logMessage('Error deleting account.', false, 'error');
      // An error happened.
    });
  }

  /**
   * This checks if a user is an administrator.
   */
  async isUserAdmin(): Promise<boolean> {
    const userInfo = await this.userInfo(); // this gets the id token and allows us to check if user is admin
    if (userInfo && userInfo.claims && userInfo.claims.iea) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This checks if a user is a change user in the system.
   */
  async isChangeUser(): Promise<boolean> {
    const userInfo = await this.userInfo(); // this gets the id token and allows us to check if user can access change
    if (userInfo && userInfo.claims && userInfo.claims.change) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This checks if a user is an builder in the system.
   */
  async isBuilder(): Promise<boolean> {
    const userInfo = await this.userInfo(); // this gets the id token and allows us to check if user can access builders
    if (userInfo && userInfo.claims && userInfo.claims.builder) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This function checks if the user is a valid member of an organization
   */
  async isOrganizationUser(): Promise<boolean> {
    const userInfo = await this.userInfo(); // this gets the id token and allows us to check if user is an organization user
    if (userInfo && userInfo.claims && userInfo.claims.dom && typeof userInfo.claims.cd === 'string' &&
      Array.isArray(userInfo.claims.dom) && userInfo.claims.dom.length > 0 &&
      userInfo.claims.dom.includes(userInfo.claims.cd)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This function checks if the user is a valid member of an organization
   */
  async isOrganizationAdmin(): Promise<boolean> {
    const userInfo = await this.userInfo(); // this gets the id token and allows us to check if user is organization admin
    if (userInfo && userInfo.claims && userInfo.claims.da && typeof userInfo.claims.cd === 'string' &&
      Array.isArray(userInfo.claims.da) && userInfo.claims.da.length > 0 &&
      userInfo.claims.da.includes(userInfo.claims.cd)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get the firebase user information.
   */
  async userInfo(): Promise<firebase.auth.IdTokenResult> {
    const currentUser = await this.afAuth.user.pipe(take(1)).toPromise();
    return currentUser.getIdTokenResult();
  }
}
