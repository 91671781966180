<div class="form-builder">
  <div class="app-loading height-limit" *ngIf="isWaiting">
    <mat-spinner></mat-spinner>
    <ng-content></ng-content>
    <h4 class="wait-message">{{ waitMessage }}</h4>
  </div>

  <div [class.header-wrapper]="!isWaiting" [class.header-wrapper-hidden]="isWaiting">
    <app-page-header [title]="componentTitle" subTitle="The Interaction builder enables you to build interactions like forms into EVA. As these interactions are
    built and used, more and more processes at ATB will be automated. Below are the interactions that you have access to."></app-page-header>
  </div>
  <!-- If there are no interactions for user -->
  <!-- <div [class.header-wrapper-hidden]="isWaiting" class="interactions-card-nodata" *ngIf="page === PageEnum.MyInteractionsEmpty">
    <div class="inner-card-content">
      <div class="interaction-card-image" fxFlex="30%">
        <img src="../../../assets/images/Unique-Protection.png" alt="Interaction-Card-Image">
      </div>
      <div class="interaction-card-content" fxFlex="55%">
        <h3>My Interactions</h3>
        <p>The Interaction Builder enables you to build interactions like forms into EVA.
          As these forms are built and used, more and more processes at ATB will be automated within EVA.
          <br /><br />
          You don’t have any interactions set up yet. Click the button below to get started.
          <br /><br />
        </p>
        <button mat-raised-button (click)="showCreateNewInteraction()" class="white-bg-blue-border-btn">Create new
          interaction</button>
      </div>
      <div fxFlex="15%"></div>
    </div>
  </div> -->

  <div *ngIf="currentlyLoading" class="waiting-spinner">
    <mat-spinner diameter=50></mat-spinner>
    <span class="waiting-message">Loading your interactions</span>
  </div>
  <div *ngIf="searchAccess">
    You do not have permission to access this resource.
  </div>

  <!-- List of interactions if exists for the user -->
  <div class="interactions-card" *ngIf="!currentlyLoading && !searchAccess && page === PageEnum.MyInteractions" [class.header-wrapper-hidden]="isWaiting">

    <div class="inner-card-content">
      <div class="create-interaction-div">
          <button mat-raised-button color="primary" (click)="showCreateNewInteraction()">Create new
            interaction</button>
            <div class="import-container">
              <app-interaction-import-export
                buttonType="Upload"
                [submitFormToBlock]="submitFormToBlock"
                [interactionSubmittedToBlockSubscription]="interactionSubmittedToBlockSubscription"
                [openFormViewerDialog]="openFormViewerDialog"
                [interactionBuilderComponent]="this"
                [dynamicForm]="dynamicForm"
                [(isWaiting)]="isWaiting"
                [(waitMessage)]="waitMessage"></app-interaction-import-export>
            </div>
          <!-- <button mat-raised-button *ngIf="!loadMyInteractions" (click)="loadInteractions()"
            class="white-bg-blue-border-btn">Load My Interactions</button> -->
      </div>
    <!-- <div class="inner-card-content">
      <div class="create-interaction-div">
          <button mat-raised-button (click)="showCreateNewInteraction()" class="white-bg-blue-border-btn">Create new
            interaction</button>
            <div class="import-container">
              <app-interaction-import-export
                buttonType="Upload"
                [submitFormToBlock]="submitFormToBlock"
                [interactionSubmittedToBlockSubscription]="interactionSubmittedToBlockSubscription"
                [openFormViewerDialog]="openFormViewerDialog"
                [interactionBuilderComponent]="this"
                [dynamicForm]="dynamicForm"
                [(isWaiting)]="isWaiting"
                [(waitMessage)]="waitMessage"></app-interaction-import-export>
            </div>
          <button mat-raised-button *ngIf="!loadMyInteractions" (click)="loadInteractions()"
            class="white-bg-blue-border-btn">Load My Interactions</button>
      </div>

      <div class="empty-container" *ngIf="!loadMyInteractions"></div>
      <div *ngIf="loadMyInteractions">
        <div *ngIf="isWaitingForInteractions" class="waiting-spinner">
          <mat-spinner diameter=50></mat-spinner>
          <span class="waiting-message">Loading your interactions</span>
        </div>

        <div class="interactions-table" *ngIf="!isWaitingForInteractions">
          <h3 class="component-title">My Interactions</h3>
          <mat-form-field class="filter-field">
            <input [value]="interactionsData.filter" matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
          </mat-form-field>
          <table mat-table [dataSource]="interactionsData" matSort class="fullWidth">

            <ng-container matColumnDef="interaction">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Interactions </th>
              <td mat-cell *matCellDef="let element" class="interaction-name">
                <span (click)="startEdit(element)" class="cursor-pointer">{{element.name}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="group">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>
              <td mat-cell *matCellDef="let element"> {{element.group}} </td>
            </ng-container>

            <ng-container matColumnDef="lastModified">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last modified </th>
              <td mat-cell *matCellDef="let element">
                <mat-select placeholder="Select a version" [value]="element.selectedVersion" class="select-version"
                  (selectionChange)="setSelectedVersion($event, element)">
                  <mat-option *ngFor="let version of element.Versions" [value]="version.version.toString()">
                    {{version.version | date:'full'}}
                  </mat-option>
                </mat-select>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions
              </th>

              <td mat-cell class="action-buttons" *matCellDef="let row; let i=index;">
                <button matTooltip='Edit' matTooltipShowDelay='500' mat-icon-button (click)="startEdit(row)">
                  <mat-icon aria-label="Edit">edit</mat-icon>
                </button>

                <button matTooltip='Preview' matTooltipShowDelay='500' mat-icon-button (click)="openPreview(row)">
                  <mat-icon aria-label="Copy">file_copy</mat-icon>
                </button>

                <app-interaction-import-export
                  buttonType="Download"
                  [submitFormToBlock]="submitFormToBlock"
                  [interactionSubmittedToBlockSubscription]="interactionSubmittedToBlockSubscription"
                  [openFormViewerDialog]="openFormViewerDialog"
                  [interactionBuilderComponent]="this"
                  [dynamicForm]="row"
                  [(isWaiting)]="isWaiting"
                  [(waitMessage)]="waitMessage"></app-interaction-import-export>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator #paginator [length]="interactionsData.filteredData.length" [pageIndex]="0" [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </div>
      </div>
    </div> -->
      <ng-template #actionTemplate let-hit="hit">
        <button matTooltip="Edit" mat-icon-button (click)="startEdit(hit)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>

        <button matTooltip="Preview" mat-icon-button (click)="openPreview(hit)">
            <mat-icon aria-label="Preview">remove_red_eye</mat-icon>
        </button>

        <app-interaction-import-export
          buttonType="Download"
          [submitFormToBlock]="submitFormToBlock"
          [interactionSubmittedToBlockSubscription]="interactionSubmittedToBlockSubscription"
          [openFormViewerDialog]="openFormViewerDialog"
          [interactionBuilderComponent]="this"
          [dynamicForm]="hit"
          [(isWaiting)]="isWaiting"
          [(waitMessage)]="waitMessage">
        </app-interaction-import-export>
      </ng-template>
      <app-generic-search
        [actionTemplate]="actionTemplate"
        [indexName]="algoliaIndex"
        [tokenType]="algoliaTokenType"
        searchResultTitleProperty="name"
        searchResultSubTitleProperty="id"
        [propertyNameMapping]="interactionPropertyNameMapping"
        [searchFilters]="interactionFilters"
        [rowHeight]="rowHeight"
        [agGridContext]="context"
        [columnDefs]="columnDefs"
        [defaultColumnDef]="defaultColDef"
      ></app-generic-search>
    </div>
  </div>

  <!-- Form Builder for Group & Interaction -->
  <div [class.header-wrapper-hidden]="isWaiting" class="group-interaction-creation-card" *ngIf="page === PageEnum.CreateNewInteraction">
    <form [formGroup]="frmBuilderGrp">
        <h3 class="select-group-heading">
          Select a group
          <mat-icon class="select-group-info info-icon" matTooltip="Select from one of your groups or create a new one"
          matTooltipPosition="after">info_outline</mat-icon>
        </h3>
      <div class="group-interaction-creation-card-content">
          <p>Select or create a group of team members that will be involved with the interaction.</p>
          <div class="group-row">
            <mat-form-field class="group-selection" *ngIf="!isWaitingForGroups">
              <mat-label>Select a group</mat-label>
              <mat-select placeholder="Select a group..." (selectionChange)="onGroupSelectionChange($event)"
                formControlName="userGroup">
                <mat-option *ngFor="let userGroup of evaGlobalService.userGroups" [value]="userGroup.groupPublicKey">
                  {{ userGroup.groupName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="app-loading" *ngIf="isWaitingForGroups">
              <mat-spinner [diameter]=30 [strokeWidth]=5></mat-spinner>
              <ng-content></ng-content>
              <span class="verColor">
                <h6>{{ onWaitForGroupsMessage }}</h6>
              </span>
            </div>
            <div class="or">OR</div>
            <div class="create-group-link"><a (click)="openNewGroupDialog()">Create a new group</a></div>
            <div class="group-spacer"></div>
          </div>
      </div>

      <div [class.disabled]="this.dynamicForm['groupPublicKey'] === undefined">
        <h3 class="build-interaction-header">
          Build your interaction
          <mat-icon class="build-interaction-info info-icon" matTooltip="When we say interaction, we mean any kind of data capture.
                    Existing ATB processes that require a form can be built into EVA. Over time,
                    EVA will learn to ask you questions, saving you the time of filling out all of these forms."
          matTooltipPosition="after">
          info_outline
        </mat-icon>
        </h3>
        <div class="group-interaction-creation-card-content">
          <div class="interaction-name-container">
            <mat-form-field class="interaction-name-input" *ngIf="!isWaitingForGroups">
              <mat-label>Interaction Name</mat-label>
              <input matInput class="fullWidth" placeholder="Interaction Name" (keyup)="onInteractionNameChange($event)"
                formControlName="name">
            </mat-form-field>
            <div fxFlex></div>
            <div class="encryption-toggle"
              [class.disabled]="this.dynamicForm['groupPublicKey'] === undefined ? false : this.dynamicForm['name'] === undefined">
              <mat-slide-toggle class="customToggle" [checked]="isDynamicFormEncrypted"
                (change)="toggleFormEncryption($event)">
                Encryption:
              </mat-slide-toggle>
            </div>
          </div>
          <div class="group-interaction-container" [class.disabled]="this.dynamicForm['groupPublicKey'] === undefined
            ? false
            : this.dynamicForm['name'] === undefined">

            <div class="interaction-screen-config-row">
              <div fxFlex></div>
              <div class="screen-config-options"
                [class.disabled]="!this.dynamicForm.FormScreens
                  || (this.dynamicForm.FormScreens && this.dynamicForm.FormScreens.length === 0)
                  || (this.dynamicForm.FormScreens && this.dynamicForm.FormScreens.length > 0 && !this.dynamicForm.FormScreens[0].FormElements)
                  || (this.dynamicForm.FormScreens && this.dynamicForm.FormScreens.length > 0 && this.dynamicForm.FormScreens[0].FormElements && this.dynamicForm.FormScreens[0].FormElements.length === 0)">
                <a (click)="relationForFormElement()">Condition builder</a>
                <span class="vertical-left-border"></span>
                <a (click)="previewEvent()">{{ showPreview ? "Hide preview" : "Preview"}}</a>
                <span class="vertical-left-border"></span>
                <a class="screen-config-option" (click)="dynObjectEvent()">{{ showDynamicObjects ? "Hide dynamic objects" :
              "Dynamic objects"}}</a>
                <!-- <span class="vertical-left-border"></span>
            <a class="screen-config-option" (click)="dynDataEvent()">{{ showDynamicData ? "Hide dynamic data" : "Dynamic
              data"}}</a> -->
                <!-- <span class="vertical-left-border"></span>
                <a class="screen-config-option" (click)="summaryEvent()">{{ showSummary ? "Hide summary" : "Customize
              summary"}}</a> -->
              </div>
            </div>

            <div class="builder-container">
                <div class="controlsPanel" *ngIf="!showSummary">
                  <mat-accordion multi="false">

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Basic inputs
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <mat-nav-list>
                        <mat-list-item disableRipple *ngFor="let frmCntrl of frmCntrlsBasic; let i = index;">
                          <div class="control-item" matLine data-form-control="true" draggable="true"
                            (dragstart)="dragStartHandler($event)" [attr.data-form-control-type]=frmCntrl.type
                            [attr.data-form-control-input-type]=frmCntrl.inputType id={{frmCntrl.name}}>
                            <i class="{{frmCntrl.icon}}"></i>
                            <span class="control-item-text">&nbsp;&nbsp;{{frmCntrl.name}} </span>
                            <i (click)="addFormElement(frmCntrl.type, frmCntrl.inputType)"
                              class="fas fa-plus add-button"></i>
                          </div>
                        </mat-list-item>
                      </mat-nav-list>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Date/Time inputs
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <mat-nav-list>
                        <mat-list-item disableRipple *ngFor="let frmCntrl of frmCntrlsDateTime; let i = index;">
                          <div class="control-item" matLine data-form-control="true" draggable="true"
                            (dragstart)="dragStartHandler($event)" [attr.data-form-control-type]=frmCntrl.type
                            [attr.data-form-control-input-type]=frmCntrl.inputType id={{frmCntrl.name}}>
                            <i class="{{frmCntrl.icon}}"></i>
                            <span class="control-item-text">&nbsp;&nbsp;{{frmCntrl.name}} </span>
                            <i (click)="addFormElement(frmCntrl.type, frmCntrl.inputType)"
                              class="fas fa-plus add-button"></i>
                          </div>
                        </mat-list-item>
                      </mat-nav-list>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Basic Controls
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <mat-nav-list>
                        <mat-list-item disableRipple *ngFor="let frmCntrl of frmCntrlsAdditional">
                          <div class="control-item" matLine data-form-control="true" draggable="true"
                            (dragstart)="dragStartHandler($event)" [attr.data-form-control-type]=frmCntrl.type
                            [attr.data-form-control-input-type]=frmCntrl.inputType id={{frmCntrl.name}}>
                            <i class="{{frmCntrl.icon}}"></i>
                            <span class="control-item-text">&nbsp;&nbsp;{{frmCntrl.name}} </span>
                            <i (click)="addFormElement(frmCntrl.type, frmCntrl.inputType)"
                              class="fas fa-plus add-button"></i>
                          </div>
                        </mat-list-item>
                      </mat-nav-list>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Customized Controls
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <mat-nav-list>
                        <mat-list-item disableRipple *ngFor="let frmCntrl of frmCntrlsCustom">
                          <div class="control-item" matLine data-form-control="true" draggable="true"
                            (dragstart)="dragStartHandler($event)" [attr.data-form-control-type]=frmCntrl.type
                            [attr.data-form-control-input-type]=frmCntrl.inputType id={{frmCntrl.name}}>
                            <i class="{{frmCntrl.icon}}"></i>
                            <span class="control-item-text">&nbsp;&nbsp;{{frmCntrl.name}} </span>
                            <i (click)="addFormElement(frmCntrl.type, frmCntrl.inputType)"
                              class="fas fa-plus add-button"></i>
                          </div>
                        </mat-list-item>
                      </mat-nav-list>

                    </mat-expansion-panel>

                    <mat-expansion-panel *ngIf=true>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Special Controls
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <mat-nav-list>
                        <mat-list-item disableRipple *ngFor="let frmCntrl of frmCntrlsSpecial">
                          <div class="control-item" matLine data-form-control="true" draggable="true"
                            (dragstart)="dragStartHandler($event)" [attr.data-form-control-type]=frmCntrl.type
                            [attr.data-form-control-input-type]=frmCntrl.subType id={{frmCntrl.name}}>
                            <i class="{{frmCntrl.icon}}"></i>
                            <span class="control-item-text">{{frmCntrl.name}} </span>
                            <i (click)="addFormElement(frmCntrl.type, frmCntrl.name)" class="fas fa-plus add-button"></i>
                          </div>
                        </mat-list-item>
                      </mat-nav-list>

                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div class="spacer-container" *ngIf="!showSummary && !showPreviewFullWidth">
                </div>
                <div class="half-width" fxFlex="75%" *ngIf="!showSummary && !showPreviewFullWidth">
                  <mat-card id="formScrnZone" class="mat-elevation-z form-screen-tab-group">
                    <span class="add-screen-button" (click)="addNewFormScreen()">Add screen &nbsp; <i
                        class="fas fa-plus-circle"></i></span>
                    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight=false (selectedTabChange)="setCurrentScreenTab($event)"
                      *ngIf="this.dynamicForm['FormScreens'] !== undefined && this.dynamicForm['FormScreens'].length > 0">
                      <mat-tab *ngFor="let scrn of this.dynamicForm['FormScreens']; let scrnIndex = index;">
                        <ng-template mat-tab-label>
                          <div class="tab-label" (mouseover)="onHoverTabLabel(scrnIndex)"
                            (mouseleave)="onHoverLeaveTabLabel(scrnIndex)" draggable="true"
                            (dragstart)="onDragScreenTab($event, scrnIndex)"
                            (drop)="onDropReorderScreens($event, scrnIndex)" (dragover)="onDragOverAllowdrop($event)">
                            <span class="name-wrap" contenteditable="true" (focusout)="onTabLabelBlur($event, scrnIndex)"
                              (keydown)="onTabLabelChange($event, scrnIndex)">{{ scrn.name }}</span>
                            &nbsp;
                            <i (click)="removeFormScreenConfirm(scrn)" [class.deleteButtonHide]="!hasHovered.get[scrnIndex]"
                              [class.deleteButtonShow]="hasHovered.get(scrnIndex)" class="far fa-trash-alt"></i>
                          </div>
                        </ng-template>

                        <mat-card id="formControlDropZone" (drop)="dropHandler($event)"
                          (dragover)="dargOverHandler($event)" [attr.data-form-screen-index]=scrnIndex>
                          <div
                            [class.default-message-text]="!scrn['FormElements'] || (scrn['FormElements'] && scrn['FormElements'].length === 0)"
                            [class.default-message-text-hide]="scrn['FormElements'] && scrn['FormElements'].length > 0">
                            <p>Click or drag controls from the left to build your interactions.</p>
                            <p>You can change the name of your screen above by clicking it,</p>
                            <p>and add and reorder screens as needed.</p>
                          </div>
                          <ul class="none-list-style">
                            <li class="cursor-all-scroll"
                              *ngFor="let frmCntrl of scrn['FormElements']; let elmntIndex = index;" draggable="true"
                              (dragstart)="onDragControl($event, elmntIndex)"
                              (drop)="onDropReorderControl($event, scrnIndex, elmntIndex)"
                              (dragover)="onDragOverAllowdrop($event)">
                              <app-form-element-visualizer [fromControlItem]="this.jsonStringify(frmCntrl)"
                                [formScreenIndex]="scrnIndex" [formElementIndex]="elmntIndex"
                                [formScreenElementCount]="scrn['FormElements'].length"
                                (removeFormElementConfirm)="removeFormElementConfirm($event)"
                                (moveUpFormElement)="moveUpFormElement($event)"
                                (moveDownFormElement)="moveDownFormElement($event)"
                                (settingFormElement)="settingFormElement($event)"
                                (relationForFormElement)="relationForFormElement($event)">
                              </app-form-element-visualizer>

                            </li>
                          </ul>
                          <br /><br /><br />
                        </mat-card>
                      </mat-tab>
                    </mat-tab-group>
                  </mat-card>
                </div>

                <div class="spacer-container" *ngIf="showDynamicObjects">
                </div>

                <div class="dynamic-objects-container" *ngIf="showDynamicObjects">

                  <mat-card class="dynamic-objects-card">
                    <mat-card-header>
                      <h3>Dynamic objects</h3>
                    </mat-card-header>

                    <app-form-object-viewer [dynFrmObj]="jsonStringify(this.dynamicForm)">
                    </app-form-object-viewer>
                  </mat-card>
                </div>

                <div class="spacer-container" *ngIf="showPreview || showPreviewFullWidth">
                </div>

                <div [ngClass]="!showPreviewFullWidth ? 'custWidthCard' : 'fullWidthCard'" *ngIf="showPreview">
                  <mat-card class="preview-card">
                    <mat-card-header>
                      <h3>Preview</h3>
                    </mat-card-header>
                    <button mat-icon-button class="closeBtn" (click)="showPreview = false; showPreviewFullWidth = false">
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-card-content class="preview-card-content">
                      <ng-template appViewContainer></ng-template>
                    </mat-card-content>

                    <div fxLayout="column" class="preview-card-actions">
                      <a (click)="showPreviewFullWidth = !showPreviewFullWidth">
                        {{ showPreviewFullWidth ? " Close full preview" : " View full preview"}}
                      </a>
                    </div>
                  </mat-card>
                </div>

                <div fxFlex="100%" *ngIf="showSummary">
                  <mat-card class="summary-card">
                    <mat-card-header>
                      <h3>Customize summary screen</h3>
                    </mat-card-header>
                    <button mat-icon-button class="closeBtn" (click)="showSummary = false">
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-card-content class="summary-card-content">
                      This is a sample of the summary screen that will appear once the interaction is submitted. You can
                      rearrange the order
                      by dragging and dropping, and hide information from the summary if you want.

                      Form elements has to go here:
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
    </form>
  </div>
  <div *ngIf="page === PageEnum.CreateNewInteraction && !isWaiting" class="builder-actions">
    <div class="button-row-left">
      <button mat-raised-button color="primary" [class.disabled]="isInteractionNotValid() || isNotEdited()"
        (click)="submitFormToBlock({that: this})">Save Interaction</button>
      <button mat-stroked-button (click)="getInteractionId()">Interaction ID</button>
    </div>
  </div>
</div>
