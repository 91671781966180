<div class="training-card">
  <h3 class="component-title">Training & Resources</h3>

  <div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="training-card-tabs">
      <mat-tab label="Training videos">
        <div class="training-tab">
          <app-learn-evavideos [isAdministrator]="_isAdministrator"></app-learn-evavideos>
          <!-- <div class="featured-video-section"> -->
            <!-- <iframe width="560" height="475" src="https://www.youtube.com/embed/?listType=user_uploads&list=uh1C-sc2BnQ" frameborder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe> -->
          <!-- </div>
          <div class="video-list-section">

          </div>       -->
        </div>
      </mat-tab>
      <mat-tab label="Documents" class="document-tab">
        <app-about-page-documents [isAdministrator]="_isAdministrator"></app-about-page-documents>
      </mat-tab>
      <mat-tab label="FAQs" class="faq-tab">
        <app-about-page-faqs [isAdministrator]="_isAdministrator"></app-about-page-faqs>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>