import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicMaterialFormComponent, DynamicMaterialCheckboxComponent, DynamicMaterialChipsComponent, DynamicMaterialDatePickerComponent, DynamicMaterialInputComponent, DynamicMaterialRadioGroupComponent, DynamicMaterialSelectComponent, DynamicMaterialSlideToggleComponent, DynamicMaterialTextAreaComponent } from '@ng-dynamic-forms/ui-material';
import { CommonModule, NgTemplateOutlet } from '@angular/common';

import {
  DynamicFormControlModel,
  DynamicFormLayout,
  DynamicFormService,
  DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP
} from '@ng-dynamic-forms/core';

import { FormBuilderService } from '../../../providers/form-builder/form-builder.service';
import { Subscription } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ElementSyncService } from '../../../providers/dynamic-interactions/element-sync.service';
import { ElementEmitValueModel } from '../../../model/elementEmitValueModel';
import { IfThenLogicOptions } from '@eva-model/interactionElementRelationDialogModel';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-workflow-form-element-visualizer',
  templateUrl: './workflow-form-element-visualizer.component.html',
  styleUrls: ['./workflow-form-element-visualizer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgTemplateOutlet,
    DynamicMaterialFormComponent,
    DynamicMaterialCheckboxComponent,
    DynamicMaterialChipsComponent,
    DynamicMaterialDatePickerComponent,
    DynamicMaterialInputComponent,
    DynamicMaterialRadioGroupComponent,
    DynamicMaterialSelectComponent,
    DynamicMaterialSlideToggleComponent,
    DynamicMaterialTextAreaComponent
  ]
})
export class WorkflowFormElementVisualizerComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  set fromControlItem(fromControlItem: string) {
    if (!fromControlItem) {
      return;
    }
    const frmCntrlObj = JSON.parse(fromControlItem);
    this.frmCntrlItemOriginalId = frmCntrlObj.id;
    this.frmCntrlItem = this.formBuilderService.createDynamicFormControlModel(frmCntrlObj);
  }

  _secondValue: number;

  @Input() frmScrnIndex: number;
  @Input() frmElmntIndex: number;
  @Input() frmScrnElmntCnt: number;
  @Input() enableSetting = true;
  @Input() enableRelation = true;
  @Input() enableOperatorSelectors = false;
  @Input() enableValueEmiiter = false;
  @Input() interactionId = '';
  @Input()
  set secondValue(secondValue: number) {
    this._secondValue = Number(secondValue);
  }

  @Input()
  set elementValueOperator(elementValueOperator: string) {
    // TODO :: Validate elementConnectionOperator
    if (!this.enableOperatorSelectors) return;
    if (elementValueOperator === '===' || elementValueOperator === '!==') {
      this.valueOperator = elementValueOperator === '===' ? IfThenLogicOptions.IsEqualTo : IfThenLogicOptions.IsNotEqualTo;
      return;
    }
    this.valueOperator = elementValueOperator === '' || !elementValueOperator ? IfThenLogicOptions.IsEqualTo : elementValueOperator;
  }
  @Input()
  set elementConnectionOperator(elementConnectionOperator: string) {
    // TODO :: Validate elementConnectionOperator
    if (!this.enableOperatorSelectors) return;
    if (elementConnectionOperator === 'AND' || elementConnectionOperator === 'OR') {
      this.connectionOperator = elementConnectionOperator;
      return;
    }
    this.connectionOperator = 'AND';
  }
  @Input() scrnIdx = 0;
  @Input() elmntIdx = 0;
  @Input() initValue: any;

  @Output() removeFormElementConfirm = new EventEmitter<object>();
  @Output() moveUpFormElement = new EventEmitter<object>();
  @Output() moveDownFormElement = new EventEmitter<object>();
  @Output() settingFormElement = new EventEmitter<object>();
  @Output() relationForFormElement = new EventEmitter<object>();

  frmCntrlItem: any;
  frmCntrlItemOriginalId: string;
  visualizerFormModel: DynamicFormControlModel[] = [];
  visualizerFormGroup: UntypedFormGroup;
  visualizerFormLayout: DynamicFormLayout = null;
  ifThenLogicOptions = IfThenLogicOptions;

  tooltipShowDelay = 700;

  connectionOperator: string = null;
  valueOperator: string = null;
  isInitialized = false;

  private elementValueChangeSubs = new Subscription();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private ngDynFormService: DynamicFormService,
    private formBuilderService: FormBuilderService,
    private elementSyncService: ElementSyncService) {
    this.matIconRegistry.addSvgIcon(
      "conditional",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/images/condition-icon.svg"));
    this.matIconRegistry.addSvgIcon(
      "conditionalReverse",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/images/conditionalReverse.svg"));
  }

  ngOnInit() {
    this.formInitializer();
  }

  /**
   * This function initializes the form visualizer by adding the layout, group and model data for the visualizer
   */
  formInitializer(): void {
    const that = this;
    if (this.frmCntrlItem) {

      //#region Initialize the form control if init value exist
      if (this.initValue && !this.isInitialized) {
        if (this.frmCntrlItem.type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
          this.frmCntrlItem.group.forEach(checkbox => {
            Object.keys(this.initValue).forEach(checkboxLabel => {
              if (checkboxLabel === checkbox.label) {
                checkbox.value = this.initValue[checkboxLabel];
              }
            });
          });
        } else {
          this.frmCntrlItem.value = this.initValue;
          this.isInitialized = true;
        }
      }
      //#endregion

      //#region setting the form control layout
      const frmCntrlLayout = new Object();
      const isFrmCntrlItemElmntLayOut = this.frmCntrlItem.layout && this.frmCntrlItem.layout.element;
      frmCntrlLayout[this.frmCntrlItem.id] = {
        element: {
          // container: "eva_frm_elmnt_container_class",
          // control: 'eva_frm_elmnt_control_class',
          host: 'eva_frm_elmnt_Width95prcnt',
          // group: "eva_frm_elmnt_group_class",
          // hint: "eva_frm_elmnt_hint_class",
          option: isFrmCntrlItemElmntLayOut ? this.frmCntrlItem.layout.element.option : '',
          label: isFrmCntrlItemElmntLayOut ? this.frmCntrlItem.layout.element.label : '',
        }
      };
      //#endregion

      this.visualizerFormLayout = frmCntrlLayout as DynamicFormLayout;
      if (this.visualizerFormModel.length > 0) {
        this.visualizerFormModel[0] = this.frmCntrlItem;
      } else {
        this.visualizerFormModel.push(this.frmCntrlItem);
      }

      this.visualizerFormGroup = this.ngDynFormService.createFormGroup(this.visualizerFormModel);
      setTimeout(() => {
        this.disableElement(this.valueOperator);
      });

      //#region Enable emit value changes
      if (this.enableValueEmiiter) {
        Object.keys(this.visualizerFormGroup.controls).forEach(function (key, index) {
          const frmGrpControl: any = that.visualizerFormGroup.get(key);
          // const frmCntrlMdl = that.formVisualizerModelArray[scrnIndex].find(element => element.id === key);

          //#region Element value change observable subscription
          that.elementValueChangeSubs.add(
            frmGrpControl.valueChanges // .pipe(debounceTime(3000))
              .subscribe(
                (value) => {
                  const checkboxGroupValue = {};
                  if (that.visualizerFormModel[0].type === DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP) {
                    (<any>that.visualizerFormModel[0]).group.forEach(checkbox => {
                      let checkboxElement = null;
                      if (Object.keys(value).find(checkboxId => checkboxId === checkbox.id)) {
                        checkboxElement = checkbox;
                        checkboxGroupValue[checkboxElement.label] = checkboxElement.value;
                      }
                    });
                    value = checkboxGroupValue;
                  }
                  const valueChange =
                    new ElementEmitValueModel(
                      that.interactionId,
                      that.frmCntrlItem.originalId,
                      that.frmScrnIndex,
                      that.frmElmntIndex,
                      value,
                      that.enableOperatorSelectors ? that.valueOperator : null,
                      that.enableOperatorSelectors ? that.connectionOperator : null,
                      that._secondValue
                    );

                  that.elementSyncService.announceElementValueChange(valueChange);
                },
                (err) => { console.log(err); throw err; }
              )
          );
          //#endregion
        });
      }
      //#endregion
    }
  }

  /**
   * This function fires when dynamic-material-form is changed
   *
   * @param event Form change event
   */
  onDynamicFormChange(event): void {
    // TODO :: action based on the changes, the value changes!
    if (event) {
      // console.log(event);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {

      switch (propName) {
        case 'fromControlItem':
          if (!changes.fromControlItem.firstChange) {
            this.formInitializer();
          }

          break;
      }

    }
  }

  /**
   * This function fires when delete element button is clicked and emits an element removal change
   *
   * @param frmCntrl Form control being removed
   */
  onRemoveFormElmnt(frmCntrl: any): void {
    this.removeFormElementConfirm.emit({ frmScrnIndex: this.frmScrnIndex, frmElementId: this.frmCntrlItemOriginalId,
      frmElmntIndex: this.frmElmntIndex });
  }

  /**
   * This function fires when move up element by 1 button is clicked and emits an element move up change
   *
   * @param frmCntrl Form control being removed
   */
  onMoveUpFormElmnt(frmCntrl: any): void {
    this.moveUpFormElement.emit({ frmScrnIndex: this.frmScrnIndex, frmElmntIndex: this.frmElmntIndex });
  }

  /**
   * This function fires when move down element by 1 button is clicked and emits an element move down change
   *
   * @param frmCntrl Form control being removed
   */
  onMoveDownFormElmnt(frmCntrl: any): void {
    this.moveDownFormElement.emit({ frmScrnIndex: this.frmScrnIndex, frmElmntIndex: this.frmElmntIndex });
  }

  /**
   * This function fires when element settings button is clicked and emits an element settings selected change
   *
   * @param frmCntrl Form control being removed
   */
  onSettingFormElmnt(frmCntrl: any): void {
    this.formBuilderService.setInputType(frmCntrl);
    this.settingFormElement.emit({
      frmCntrl: frmCntrl,
      frmScrnIndex: this.frmScrnIndex,
      frmElmntIndex: this.frmElmntIndex,
      frmElementId: this.frmCntrlItemOriginalId
    });

  }

  /**
   * This function fires when element relation button is clicked and emits an element relation selected change
   *
   * @param frmCntrl Form control being removed
   */
  onRelationForFormElmnt(frmCntrl: any): void {
    this.relationForFormElement.emit({
      frmCntrl: frmCntrl,
      frmScrnIndex: this.frmScrnIndex,
      frmElmntIndex: this.frmElmntIndex,
      frmElementId: this.frmCntrlItemOriginalId
    });
  }

  /**
   * This function fires when connection operator - "AND"/"OR" is changed and emits change
   */
  onConnectionOperator(operator: string): void {
    this.connectionOperator = operator;
    this.emitValueChange();
  }

  /**
   * This function fires when value operator is changed and emits change
   */
  onValueOperator(operator: string): void {
    this.valueOperator = operator;
    // disable form control based on selected option
    this.disableElement(operator);
    this.emitValueChange();
  }

  disableElement(operator: string) {
    if (operator === IfThenLogicOptions.IsEmpty
      || operator === IfThenLogicOptions.IsNotEmpty
      || operator === IfThenLogicOptions.DateIsToday
      || operator === IfThenLogicOptions.DateIsAfterToday
      || operator === IfThenLogicOptions.DateIsBeforeToday
      || operator === IfThenLogicOptions.DateIsEqualToOrAfterToday
      || operator === IfThenLogicOptions.DateIsEqualToOrBeforeToday
      || operator === IfThenLogicOptions.DateIsNow
      || operator === IfThenLogicOptions.DateIsAfterNow
      || operator === IfThenLogicOptions.DateIsBeforeNow
      || operator === IfThenLogicOptions.DateIsEqualToOrAfterNow
      || operator === IfThenLogicOptions.DateIsEqualToOrBeforeNow
      || operator === IfThenLogicOptions.IsCurrentMonth
      || operator === IfThenLogicOptions.MonthIsBeforeCurrentMonth
      || operator === IfThenLogicOptions.MonthIsAfterCurrentMonth
      || operator === IfThenLogicOptions.MonthIsEqualToOrAfterCurrentMonth
      || operator === IfThenLogicOptions.MonthIsEqualToOrBeforeCurrentMonth
      || operator === IfThenLogicOptions.IsCurrentWeek
      || operator === IfThenLogicOptions.WeekIsBeforeCurrentWeek
      || operator === IfThenLogicOptions.WeekIsAfterCurrentWeek
      || operator === IfThenLogicOptions.WeekIsEqualToOrAfterCurrentWeek
      || operator === IfThenLogicOptions.WeekIsEqualToOrBeforeCurrentWeek
      || operator === IfThenLogicOptions.IsCurrentTime
      || operator === IfThenLogicOptions.TimeIsBeforeCurrentTime
      || operator === IfThenLogicOptions.TimeIsAfterCurrentTime
      || operator === IfThenLogicOptions.TimeIsEqualToOrAfterCurrentTime
      || operator === IfThenLogicOptions.TimeIsEqualToOrBeforeCurrentTime
      || operator === IfThenLogicOptions.MathEquation) {
      this.visualizerFormGroup.controls[this.frmCntrlItem.id].disable();
    } else {
      this.visualizerFormGroup.controls[this.frmCntrlItem.id].enable();
    }
  }

  /**
   * This function announces value changes in the form visualizer
   */
  emitValueChange(): void {
    const valueChange =
      new ElementEmitValueModel(
        this.interactionId,
        this.frmCntrlItem.originalId,
        this.frmScrnIndex,
        this.frmElmntIndex,
        this.frmCntrlItem.value,
        this.enableOperatorSelectors ? this.valueOperator : null,
        this.enableOperatorSelectors ? this.connectionOperator : null,
        this._secondValue
      );

    this.elementSyncService.announceElementValueChange(valueChange);
  }

  onCode(frmCntrl: any) {   // Coded just for debugging purposes
    // console.log(frmCntrl);
    // console.log(this.visualizerFormGroup);
    // console.log(this.visualizerFormGroup.controls);

    const objEntries = Object.entries(this.visualizerFormGroup.controls);
    // console.log(objEntries);
    // let tempFrmGrp: any;

    for (let i = 0; i < objEntries.length; i++) {
      const oe = objEntries[i];
      // console.log(objEntries[i]);
      if (Array.isArray(oe)) {
        for (let j = 0; j < oe.length; j++) {
          const oeItem = oe[j];
          // console.log('j - ' + j.toString());
          // console.log(oeItem);

          // if ( j === 1 ) tempFrmGrp = oeItem;
        }
      }
    }

    // tempFrmGrp.removeControl("checkbox1");
  }

  setSecondValue(event: any) {
    this._secondValue = Number(event.target.value);
    this.emitValueChange();
  }

  ngOnDestroy() {
    // TODO :: unsubscribe any observable who has subscription.

    if (this.elementValueChangeSubs) {
      this.elementValueChangeSubs.unsubscribe();
    }
  }
}
