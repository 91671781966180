<div class="speech-box" [ngClass]="{'speech-box-user': chatEntity.author === author.user, 'speech-box-eva': chatEntity.author === author.eva}">
    <div [innerHtml]="chatEntity.text | safeHtml"></div>
    <div *ngIf="chatEntity.author === author.eva && _formControl && chatEntity.id === (chatService.currentChatEntity$ | async)?.id && ((_formControl._list && _formControl._list.length > 0) || (_formControl.options && _formControl.options.length > 0) || (_formControl.group && _formControl.group.length > 0))" style="margin-top: 0.5rem">
        <div *ngIf="_formControl._list">
            <button *ngFor="let listItem of _formControl._list" mat-stroked-button color="primary" (click)="selectOption(listItem)" class="select-option" [class.select-option-selected]="listItem.selected">
                <span class="select-option-label">{{ listItem }}</span>
            </button>
        </div>
        <div *ngIf="_formControl.options">
            <button *ngFor="let option of _formControl.options" mat-stroked-button color="primary" (click)="selectOption(option)" [class.multi-select-option]="_formControl.multiple" class="select-option" [class.select-option-selected]="option.selected">
                <span class="select-option-label">{{ option.label }}</span>
            </button>
            <button *ngIf="_formControl.multiple" [disabled]="isAnySelected()" mat-stroked-button="primary" (click)="submitMultiSelect()" class="submit-button" [class.disabled]="isAnySelected()">
                Submit
            </button>
        </div>
        <div *ngIf="_formControl.group">
            <button *ngFor="let group of _formControl.group" mat-stroked-button color="primary" (click)="selectOption(group)" [class.multi-select-option]="_formControl.multiple" [class.select-option]="!_formControl.multiple" [class.select-option-selected]="group.value">
                <span class="select-option-label">{{ group.label }}</span>
            </button>
            <button [disabled]="isAnySelected()" mat-stroked-button="primary" (click)="submitMultiSelect()" class="submit-button" [class.disabled]="isAnySelected()">
                Submit
            </button>
        </div>
    </div>
    <div class="speech-box-originator" *ngIf="chatEntity.originator">From {{ chatEntity.originator }} <mat-icon aria-hidden="false" aria-label="Info" fontIcon="info" style="width:16px;height:16px;font-size:16px;" matTooltip="This message can be disabled in your Preferences under 'Assist Process entry from EVA Chat'"></mat-icon></div>
</div>